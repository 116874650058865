export const welcomeTips = {
  tip1: 'Главное меню, в котором вы можете осуществлять наблюдение за действиями на рынке, совершать торговые операции, а также воспользоваться сервисными функциями от брокера.',
  tip2: 'Воспользуйтесь кнопкой для пополнения вашего брокерского счета.',
  tip3: 'Здесь отображаются важные уведомления и форма для связи с вашим менеджером.',
  tip4: 'Ваш профиль, в котором можно изменить настройки безопасности и статус в качестве инвестора.',
  tip5: 'Здесь располагаются самые горячие и выгодные на данный момент рекомендации от команды брокера.',
  tip6: 'Блок-информер: представляет набор вкладок с отображением основной информации на рынке.',
  tip7: 'Для переключения счета воспользуйтесь этим выпадающим списком.',
  btnPrev: 'Назад',
  btnNext: 'Далее',
  btnComplete: 'Завершить'
}
