export const notification = {
  alert: 'Внимание!',
  margin: 'Вы используете маржинальное кредитование брокера.',
  sessionEnd: {
    message: 'Мы вас не дождались...',
    description:
      'В связи с отсутствием активности сессия была закрыта для вашей безопасности. Пожалуйста, зайдите снова.',
    mobileDescription: 'Сессия была закрыта. Пожалуйста, зайдите снова.'
  }
}
