import accountsService from '@/api/accountsService'
import { IPortfolioModel, IPositionModel } from '@/api/types'
import { useNotify } from '@/stores/notify'
import { defineStore } from 'pinia'
import { formatCurrency } from '@/utils/number.extensions'
interface IFinResult {
  label: string
  currency: number
  showPercent?: boolean
  percent?: number
}
interface IState {
  data: IPortfolioModel
  loading: boolean
  error?: Error
  timer: unknown
}
interface IGetters {
  emptyAccount: (state: IState) => boolean
  isMargin: (state: IState) => boolean
  finResults: (state: IState) => IFinResult[]
  portfolioInstrumentsMain: (state: IState) => IPositionModel[]
  portfolioInstrumentsAction: (state: IState) => IPositionModel[]
  portfolioInstrumentsDerivative: (state: IState) => IPositionModel[]
  portfolioInstrumentsDolgovyi: (state: IState) => IPositionModel[]
  portfolioInstrumentsPai: (state: IState) => IPositionModel[]
  portfolioInstrumentsCurrency: (state: IState) => IPositionModel[]
  portfolioInstrumentsAutoprofile: (state: IState) => never[]
  totalResultMain: (state: IState) => {
    profitPercent: number
    changesCurrencyTotal: number
    totalPriceTotal: number
    totalPriceNowTotal: number
  }
  marginFormatter: (state: IState) => {
    npr1: number | string
    npr2: number | string
    mInitial: number | string
    mMinimal: number | string
    portfolio: number | string
  }
  maxMarginAs100Percent: (state: IState) => number
  gradPosition: (state: IState) => number
  [key: string]: any
}
interface IActions {
  load: () => Promise<void>
  autoUpdate: () => Promise<void>
  startAutoUpdate: (interval: number) => Promise<void>
  stopAutoUpdate: () => void
  clearStore: () => void
  scheduleNextUpdate: (interval: number) => void
}

export const useAccountStore = defineStore<'account', IState, IGetters, IActions>({
  id: 'account',
  state: (): IState => ({
    data: {
      money: {
        free: 0,
        blocked: 0,
        expected: 0,
        go: 0,
        varmargin: 1
      },
      positions: [],
      cost: {
        total: 0,
        papers: 0,
        money: 0,
        profit: 0
      },
      profit: { balance: 0, totalDiff: 0, totalProfitPercent: 0 },
      margin: null
    },
    loading: false,
    error: undefined,
    timer: null
  }),
  persist: true,
  actions: {
    async load() {
      this.loading = true
      try {
        this.data = await accountsService.portfolio()
      } catch (error: any) {
        this.error = { name: error.code, message: error.message }
        const notify = useNotify()
        notify.showServiceError()
      }
      this.loading = false
    },
    async autoUpdate() {
      try {
        this.data = await accountsService.portfolio()
      } catch (error: any) {
        this.error = { name: error.code, message: error.message }
        const notify = useNotify()
        notify.showServiceError()
      }
    },
    async startAutoUpdate(interval: number) {
      if (this.timer) {
        clearInterval(this.timer as number)
      }
      await this.autoUpdate() // Получить данные сразу при запуске
      this.scheduleNextUpdate(interval)
    },
    // запланированный следующий запрос
    scheduleNextUpdate(interval) {
      this.timer = setTimeout(async () => {
        await this.autoUpdate()
        this.scheduleNextUpdate(interval) // Планируем следующий запрос
      }, interval)
    },
    // Функция для остановки обновления
    stopAutoUpdate() {
      if (this.timer) {
        clearInterval(this.timer as number)
      }
      this.timer = null
    },
    clearStore() {
      this.$reset()
    }
  },
  getters: {
    emptyAccount(state: IState) {
      // счет полностью пустой
      return state.data.cost.total === 0 && state.data.money.free === 0
    },
    isMargin(state: IState) {
      // счет полностью пустой
      return !!state.data.margin
    },
    finResults(state: IState) {
      return [
        {
          label: 'informationCostPortfolio',
          currency: state.data.cost.total ?? 0
        },
        {
          label: 'informationCostFreeMoney',
          currency: state.data.money.free ?? 0
        },
        {
          label: 'informationFinResult',
          currency: state.data.profit.totalDiff ?? 0,
          showPercent: true,
          percent: state.data.profit.totalProfitPercent ?? 0
        }
      ]
    },
    portfolioInstrumentsMain(state: IState) {
      return state.data.positions
    },
    portfolioInstrumentsAction(state: IState) {
      return state.data.positions.filter(
        (item: IPositionModel) => item.type === 1 || item.type === 6 || item.type === 8
      )
    },
    portfolioInstrumentsDerivative(state: IState) {
      return state.data.positions.filter(
        (item: IPositionModel) => item.type === 4 || item.type === 5
      )
    },
    portfolioInstrumentsDolgovyi(state: IState) {
      return state.data.positions.filter(
        (item: IPositionModel) => item.type === 2 || item.type === 9
      )
    },
    portfolioInstrumentsPai(state: IState) {
      return state.data.positions.filter((item: IPositionModel) => item.type === 3)
    },
    portfolioInstrumentsCurrency(state: IState) {
      return state.data.positions.filter(item => item.type === 7 || item.type === -7)
    },
    portfolioInstrumentsAutoprofile(state: IState) {
      //todo: реализовать
      return []
    },
    totalResultMain(state: IState) {
      return {
        profitPercent: state.data.profit.totalProfitPercent,
        changesCurrencyTotal: state.data.profit.totalDiff,
        totalPriceTotal: state.data.profit.balance,
        totalPriceNowTotal: state.data.profit.totalDiff + state.data.profit.balance
      }
    },
    marginFormatter(state: IState) {
      if (state.data.margin === null) {
        return {
          npr1: 0,
          npr2: 0,
          mInitial: 0,
          mMinimal: 0,
          portfolio: 0
        }
      }

      return {
        npr1: formatCurrency(state.data.margin.npr1),
        npr2: formatCurrency(state.data.margin.npr2),
        mInitial: formatCurrency(state.data.margin.mInitial),
        mMinimal: formatCurrency(state.data.margin.mMinimal),
        portfolio: formatCurrency(state.data.margin.portfolio)
      }
    },
    maxMarginAs100Percent(state: IState) {
      if (state.data.margin !== null) {
        if (state.data.margin.portfolio < state.data.margin.npr2) {
          return state.data.margin.npr2
        }
        return state.data.margin.portfolio
      }
      return 1
    },
    gradPosition(state: IState) {
      const margin = state.data.margin
      // Проверка на null выполняется один раз.  Если margin null, то сразу возвращаем 5.
      if (!margin) {
        return 5
      }

      const { npr1: minimal, portfolio: now } = margin
      //@ts-ignore
      const max: number = this.maxMarginAs100Percent

      // Избегаем лишнего вычисления range если оно равно 0, чтобы предотвратить деление на ноль.
      const range: number = max - minimal
      if (range === 0) {
        return now > minimal ? 100 : 0 // Если range 0, то проверяем now относительно minimal.
      }

      const raz = now - minimal
      return Math.ceil((raz / range) * 100)
    }
  }
})
