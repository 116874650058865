<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M13.07 10.4101C14.3154 8.66998 14.3154 6.33023 13.07 4.5901C13.6385 4.20206 14.3117 3.99627 15 4.00005C16.933 4.00005 18.5 5.56711 18.5 7.5001C18.5 9.4331 16.933 11.0002 15 11.0002C14.3117 11.0039 13.6385 10.7981 13.07 10.4101ZM5.5 7.5001C5.5 5.56711 7.067 4.0001 9 4.0001C10.933 4.0001 12.5 5.56711 12.5 7.5001C12.5 9.4331 10.933 11.0001 9 11.0001C7.067 11.0001 5.5 9.4331 5.5 7.5001ZM7.5 7.5001C7.5 8.32853 8.17157 9.0001 9 9.0001C9.82843 9.0001 10.5 8.32853 10.5 7.5001C10.5 6.67168 9.82843 6.0001 9 6.0001C8.17157 6.0001 7.5 6.67168 7.5 7.5001ZM16 17.0001V19.0001H2V17.0001C2 17.0001 2 13.0001 9 13.0001C16 13.0001 16 17.0001 16 17.0001ZM14 17.0001C13.86 16.2201 12.67 15.0001 9 15.0001C5.33 15.0001 4.07 16.3101 4 17.0001M15.95 13.0001C17.1907 13.9651 17.9411 15.4294 18 17.0001V19.0001H22V17.0001C22 17.0001 22 13.3701 15.94 13.0001H15.95Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
