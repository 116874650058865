<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 3L21 19.5L19.5858 20.9142L13 14.396V20L11 22V14H5V12C6.51785 12 7.76816 10.8797 7.97116 9.41876L3 4.5L4.5 3ZM17 2C17.55 2 18 2.45 18 3C18 3.51071 17.612 3.9352 17.1162 3.99324L17 4H16V9C16 10.6007 17.246 11.9039 18.8234 11.9949L19 12V14H18.458L14.0393 9.62903C14.0247 9.51323 14.0141 9.39626 14.0076 9.27826L14 9V4H10V5.63L6.48132 2.14601C6.6014 2.07263 6.73793 2.02383 6.8838 2.00676L7 2H17ZM10.579 12L9.58067 11.0122C9.46285 11.2797 9.32102 11.537 9.15622 11.7813L9 12H10.579Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
