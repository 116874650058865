<script setup lang="ts">
  import IconIndicator from '@/components/icons/IconIndicator.vue'
  import { useAccountStore } from '@/stores/accountStore'
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { formatCurrency } from '../utils/number.extensions'

  const props = defineProps({
    static: {
      type: Boolean,
      default: false
    },
    pointPosition: {
      type: Number,
      required: true,
      validator(value: number) {
        return value >= 0 && value <= 100
      }
    },
    componentIndex: {
      type: String,
      default: ''
    }
  })

  const modelValue = defineModel<boolean>({
    default: false
  })

  const { t } = useI18n()
  const account = useAccountStore()
  const hoverPoint = ref(false)
  const hoverMargin = ref(false)

  function calculatePosition(marginValue?: number) {
    if (account.data.margin === null || marginValue === undefined) {
      return 1
    }
    return Math.ceil(
      ((marginValue - account.data.margin?.npr1) /
        (account.maxMarginAs100Percent - account.data.margin?.npr1)) *
        100
    )
  }

  const npr1Position = computed(() => calculatePosition(account.data.margin?.npr1))
  const npr2Position = computed(() => calculatePosition(account.data.margin?.npr2))
  const yellowPosition = computed(() => {
    // ширина оси на которой размещаются точки это 70% от всего поля градиента, вычисляем позиции внутри
    return (15 + (npr2Position.value * 70) / 100).toFixed(2)
  })
  const widthSheet = ref(900)

  const divId = 'modal_id_' + props.componentIndex
  const gradient = computed(() => {
    return [
      'var(--color-AlertNormal) 0%',
      'var(--color-AlertNormal) 15%',
      `var(--color-WarningNormal) 15%`,
      `var(--color-WarningNormal) ${yellowPosition.value}%`,
      `var(--color-SuccessNormal) ${yellowPosition.value}%`,
      'var(--color-SuccessNormal) 85%',
      'var(--color-SuccessNormal) 100%'
    ]
  })

  function onClick(e: boolean) {
    const el = document.getElementById(divId)
    if (el) {
      widthSheet.value = el.getBoundingClientRect().width
    }
  }
</script>
<template>
  <v-sheet v-if="static && modelValue" class="text-center text-primary body-b1-bold">
    {{ account.marginFormatter.portfolio + '₽' }}
  </v-sheet>
  <v-sheet
    v-if="static && !modelValue"
    class="text-center pb-2 body-b3"
    style="color: var(--color-BlackInactive)"
  >
    {{ t('trading.marginOptions') }}
  </v-sheet>
  <div
    :id="divId"
    class="gradient-bar position-relative w-100 cursor-pointer my-2"
    :style="{
      background: `linear-gradient(to right, ${gradient.join(',')})`
    }"
  >
    <v-sheet class="ma-auto w-70 position-relative">
      <div class="point point24 position-absolute" :style="{ left: pointPosition + '%' }">
        <IconIndicator :color="static && modelValue ? '#3561EC' : '#F5F5F5'" />
      </div>
      <div class="npr1 position-absolute" :style="{ left: '0%' }">
        <span v-if="static && modelValue" class="npr-mobile additional-overline position-absolute">
          1
        </span>
      </div>
      <div class="npr2 position-absolute" :style="{ left: npr2Position + '%' }">
        <span v-if="static && modelValue" class="npr-mobile additional-overline position-absolute">
          2
        </span>
      </div>
    </v-sheet>
  </div>
  <v-sheet
    v-if="static && modelValue"
    class="pt-2 additional-overline"
    style="color: var(--color-BlackActive)"
  >
    {{ t('portfolio.marginPosition.mobileStaticNpr.npr1') }}
    <span style="color: var(--color-BlackInactive)">
      {{ t('portfolio.marginPosition.mobileStaticNpr.npr1Desc') }}
    </span>
    {{ account.marginFormatter.npr1 }}
    <br />
    {{ t('portfolio.marginPosition.mobileStaticNpr.npr2') }}
    <span style="color: var(--color-BlackInactive)">
      {{ t('portfolio.marginPosition.mobileStaticNpr.npr2Desc') }}
    </span>
    {{ account.marginFormatter.npr2 }}
    <br />
    <span style="color: var(--color-BlackInactive)">
      {{ t('portfolio.marginPosition.tooltipNpr.p1') }}
    </span>
    {{ account.marginFormatter.mMinimal }}
    <br />
    <span style="color: var(--color-BlackInactive)">
      {{ t('portfolio.marginPosition.tooltipNpr.p2') }}
    </span>
    {{ account.marginFormatter.mInitial }}
  </v-sheet>
  <v-overlay
    v-if="!static"
    location-strategy="connected"
    origin="overlap"
    :width="widthSheet"
    :activator="'#' + divId"
    location="center center"
    @update:model-value="onClick"
  >
    <v-sheet
      class="d-flex pt-4 pb-4 elevation-16-dp rounded-rc ga-2 flex-column"
      :width="widthSheet"
    >
      <v-sheet class="d-flex align-center justify-center w-100">
        <v-sheet class="title-h3 text-inactive">
          {{ t('portfolio.marginPosition.title') }}
          <v-tooltip
            content-class="tooltip"
            location="bottom left"
            offset="-5 -15"
            max-width="420"
            class="body-b2"
          >
            {{ t('portfolio.marginPosition.tooltipNpr.p3') }}
            <span class="body-b2-semibold">{{ account.marginFormatter.npr1 }}</span>
            <br />
            <span class="body-b3">{{ t('portfolio.marginPosition.tooltipNpr.p31') }}</span>
            <br />
            {{ t('portfolio.marginPosition.tooltipNpr.p4') }}
            <span class="body-b2-semibold">{{ account.marginFormatter.npr2 }}</span>
            <br />
            <span class="body-b3">{{ t('portfolio.marginPosition.tooltipNpr.p41') }}</span>
            <br />
            <i>{{ t('portfolio.marginPosition.tooltipNpr.p5') }}</i>
            <br />
            <br />
            {{ t('portfolio.marginPosition.tooltipNpr.p1') }}
            <span class="body-b2-semibold">{{ account.marginFormatter.mMinimal }}</span>
            <br />
            {{ t('portfolio.marginPosition.tooltipNpr.p2') }}
            <span class="body-b2-semibold">{{ account.marginFormatter.mInitial }}</span>
            <template #activator="{ props }">
              <v-icon v-bind="props" class="position-absolute pl-2" icon="help-fill" />
            </template>
          </v-tooltip>
        </v-sheet>
      </v-sheet>
      <v-sheet class="d-flex align-center w-100 mt-7">
        <div
          class="gradient-bar position-relative w-100"
          :style="{
            background: `linear-gradient(to right, ${gradient.join(',')} )`
          }"
        >
          <v-sheet class="ma-auto w-70 position-relative">
            <v-sheet
              :class="['point point24 position-absolute z-index bg-transparent']"
              :style="{ left: pointPosition + '%' }"
              @mouseenter="hoverPoint = true"
              @mouseleave="hoverPoint = false"
            >
              <IconIndicator />
            </v-sheet>
            <div class="npr1 position-absolute" :style="{ left: npr1Position + '%' }">
              <span class="additional-overline position-absolute">
                {{ account.marginFormatter.npr1 }}
              </span>
            </div>
            <div class="npr2 position-absolute" :style="{ left: npr2Position + '%' }">
              <span class="additional-overline position-absolute">
                {{ account.marginFormatter.npr2 }}
              </span>
            </div>
          </v-sheet>
        </div>
      </v-sheet>
      <v-sheet class="d-flex w-100 pb-1 pt-3 px-4 justify-space-around align-end">
        <v-sheet class="body-b2 opacity-focus">|</v-sheet>
        <v-sheet
          class="d-flex flex-column justify-center align-center"
          @mouseenter="hoverMargin = true"
          @mouseleave="hoverMargin = false"
        >
          <v-sheet :class="['body-b1-bold', hoverPoint ? 'text-primary' : '']">
            {{ account.marginFormatter.portfolio }}{{ t('portfolio.marginPosition.currency') }}
          </v-sheet>
          <v-sheet :class="['additional-overline', hoverPoint ? 'text-active' : 'text-inactive']">
            {{ t('portfolio.marginPosition.subOverline') }}
          </v-sheet>
        </v-sheet>
        <v-sheet class="body-b2 opacity-focus">|</v-sheet>
      </v-sheet>
    </v-sheet>
  </v-overlay>
</template>
<style scoped lang="scss">
  .gradient-bar {
    height: 6px;
  }
  .point {
    height: 30px;
    width: 22px;
    transform: translate(-50%, -39%);
    z-index: 200;
  }
  .npr1,
  .npr2,
  .min-margin,
  .init-margin {
    height: 6px;
    width: 2px;
    background-color: $colorBlackActive;
  }
  .min-margin span,
  .npr2 span {
    transform: translate(-50%, 37%);
  }
  .init-margin span,
  .npr1 span {
    transform: translate(-50%, -100%);
    &.npr-mobile {
      transform: translate(-50%, 37%);
    }
  }
  .z-index {
    z-index: 2010;
  }
</style>
