import { defineStore } from 'pinia'
import { useNotify } from '@/stores/notify'
import { IActionFavoriteModel, IFavoritePositionModel } from '@/api/types'
import instrumentService from '@/api/instrumentService'

interface IState {
  data: IFavoritePositionModel[]
  loading: boolean
  error?: Error
  timer: unknown
}
interface IGetter {
  favorites: (state: IState) => IFavoritePositionModel[] &
    {
      logo: string
      ticker: string
      title: string
      type: number
      changesCurrency: number
      changesPercent: number
    }[]
  [key: string]: any
}
interface IAction {
  load: () => Promise<void>
  deleteFromFavoritePosition: (id: number) => Promise<void>
  addToFavorite: (item: IActionFavoriteModel) => Promise<any>
  isFavorite: (paperId: number) => boolean
  autoUpdate: () => Promise<void>
  stopAutoUpdate: () => void
  startAutoUpdate: (interval: number) => Promise<void>
  scheduleNextUpdate: (interval: number) => void
  clearStore: () => void
}

export const useTradingStore = defineStore<'trading', IState, IGetter, IAction>({
  id: 'trading',
  state: (): IState => ({
    data: [],
    loading: false,
    error: undefined,
    timer: null
  }),
  persist: true,
  actions: {
    async load() {
      this.loading = true
      try {
        const { data } = await instrumentService.favorites()

        this.data = data
        this.error = undefined
      } catch (e) {
        const notify = useNotify()
        notify.showServiceError()
        console.error(e)
      }

      this.loading = false
    },
    async deleteFromFavoritePosition(id: number) {
      // this.loading = true

      await instrumentService.deleteFromFavorites({ paperId: id })
      // this.loading = false
    },
    async addToFavorite(item: IActionFavoriteModel) {
      this.loading = true
      const { data } = await instrumentService.addToFavorites(item)

      this.loading = false

      return data
    },
    isFavorite(paperId: number) {
      const search = this.favorites.find(item => item.paperId === paperId)
      if (search !== undefined) {
        return !!search
      }
      return false
    },
    async autoUpdate() {
      try {
        const { data } = await instrumentService.favorites()
        this.data = data
      } catch (error: any) {
        const notify = useNotify()
        notify.showServiceError()
        this.stopAutoUpdate()
      }
    },
    async startAutoUpdate(interval: number) {
      if (this.timer) {
        clearInterval(this.timer as number)
      }
      await this.autoUpdate() // Получить данные сразу при запуске
      this.scheduleNextUpdate(interval)
    },
    // запланированный следующий запрос
    scheduleNextUpdate(interval) {
      this.timer = setTimeout(async () => {
        await this.autoUpdate()
        this.scheduleNextUpdate(interval) // Планируем следующий запрос
      }, interval)
    },
    // Функция для остановки обновления
    stopAutoUpdate() {
      if (this.timer) {
        clearInterval(this.timer as number)
      }
      this.timer = null
    },
    clearStore() {
      this.$reset()
    }
  },
  getters: {
    favorites(state: IState) {
      const positions = state.data
      return [
        ...positions.map(pos => ({
          ...pos,
          logo: '',
          ticker: pos.ticker ?? '',
          title: pos.name,
          changesCurrency: pos.totalDiff,
          changesPercent: pos.totalProfitPercent,
          type: pos.typeId
        }))
      ]
    }
  }
})
