<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    :color="color"
  >
    <path d="M0 5C0 3.89543 0.895431 3 2 3H22C23.1046 3 24 3.89543 24 5V9H0V5Z" fill="#F5F5F5" />
    <rect y="9" width="24" height="6" fill="#0C47B7" />
    <path
      d="M0 15H24V19C24 20.1046 23.1046 21 22 21H2C0.89543 21 0 20.1046 0 19V15Z"
      fill="#E53B35"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
