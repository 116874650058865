<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M19 3C20.1046 3 21 3.89543 21 5V7C21 8.10457 20.1046 9 19 9H17C16.3046 9 15.6921 8.64507 15.3337 8.10648L9.774 12.146L15.214 16.099C15.5435 15.447 16.2196 15 17 15H19C20.1046 15 21 15.8954 21 17V19C21 20.1046 20.1046 21 19 21H17C15.8954 21 15 20.1046 15 19V18.415L8.78601 13.901C8.45645 14.553 7.78043 15 7 15H5C3.89543 15 3 14.1046 3 13V11C3 9.89543 3.89543 9 5 9H7C7.86692 9 8.60501 9.55157 8.88249 10.3229L15 5.877V5C15 3.89543 15.8954 3 17 3H19ZM19 17H17V19H19V17ZM7 11H5V13H7V11ZM19 5H17V7H19V5Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
