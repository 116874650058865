import dictionaryService from '@/api/dictionaryService'
import {
  IClientTerminalsModel,
  IInstrumentTypesModel,
  IOrderActionsModel,
  IOrderStatusesModel,
  ITradeSystemsModel,
  ITransactionOperationsModel,
  ITransactionStatusesModel
} from '@/api/types'
import { defineStore } from 'pinia'

interface IState {
  instrumentTypes: IInstrumentTypesModel[]
  orderActions: IOrderActionsModel[]
  orderStatuses: IOrderStatusesModel[]
  tradeSystems: ITradeSystemsModel[]
  clientTerminals: IClientTerminalsModel[]
  transactionStatuses: ITransactionStatusesModel[]
  transactionOperations: ITransactionOperationsModel[]
  lastUpdated: null | number
}
interface IGetter {
  isDataStale: (s: IState) => boolean
  [key: string]: any
}
interface IAction {
  fetchDictionaries: () => Promise<void>
  clearStore: () => void
  extractValue: (dictionary: string, value: number) => number
  isTrade: (typeId: number) => boolean
}

const cacheDuration = 10 * 60 * 1000 // 10 минут

export const useDictionaryStore = defineStore<'dictionary', IState, IGetter, IAction>({
  id: 'dictionary',
  state: (): IState => ({
    instrumentTypes: [],
    orderActions: [],
    orderStatuses: [],
    tradeSystems: [],
    clientTerminals: [],
    transactionStatuses: [],
    transactionOperations: [],
    lastUpdated: null // время последнего обновления
  }),
  getters: {
    isDataStale: state => {
      return !state.lastUpdated || Date.now() - state.lastUpdated > cacheDuration
    }
  },
  persist: true,
  actions: {
    async fetchDictionaries() {
      if (
        !this.isDataStale &&
        this.instrumentTypes.length > 0 &&
        this.orderActions.length > 0 &&
        this.orderStatuses.length > 0 &&
        this.tradeSystems.length > 0 &&
        this.clientTerminals.length > 0 &&
        this.transactionStatuses.length > 0 &&
        this.transactionOperations.length > 0
      ) {
        return // Если данные свежие, не делаем запрос
      }

      try {
        this.instrumentTypes = await dictionaryService.instrumentTypes()
        this.orderActions = await dictionaryService.orderActions()
        this.orderStatuses = await dictionaryService.orderStatuses()
        this.clientTerminals = await dictionaryService.clientTerminals()
        this.tradeSystems = await dictionaryService.tradeSystems()
        this.transactionStatuses = await dictionaryService.transactionStatuses()
        this.transactionOperations = await dictionaryService.transactionOperations()

        this.lastUpdated = Date.now() // обновляем время кэширования
      } catch (error) {
        // сбрасываем состояние если ошибка пришла с сервера
        this.$reset()
        console.error('Ошибка при получении справочников:', error)
      }
    },
    clearStore() {
      this.$reset()
    },
    extractValue(dictionary: string, value: number) {
      const search = (this[dictionary as keyof typeof this] as any[]).find(
        item => item.id === value
      )

      return search ? search.name : value
    },
    isTrade(typeId: number) {
      const types = this.instrumentTypes.find(item => item.id === typeId)
      if (types && types.isTrade) {
        return types.isTrade
      }
      return false
    }
  }
})
