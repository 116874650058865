<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M16 12H17.5V14.82L19.94 16.23L19.19 17.53L16 15.69V12ZM17 10C14.2386 10 12 12.2386 12 15C12 17.7614 14.2386 20 17 20C19.7614 20 22 17.7614 22 15C22 12.2386 19.7614 10 17 10ZM17 8C20.866 8 24 11.134 24 15C24 18.866 20.866 22 17 22C14.21 22 11.8 20.36 10.67 18H1V15C1 12.34 6.33 11 9 11C9.6 11 10.34 11.07 11.12 11.2C12.36 9.28 14.53 8 17 8ZM10 15C10 14.3 10.1 13.62 10.29 13C9.87 12.93 9.43 12.9 9 12.9C6.03 12.9 2.9 14.36 2.9 15V16.1H10.09C10.03 15.74 10 15.37 10 15ZM9 2C11.2091 2 13 3.79086 13 6C13 8.20914 11.2091 10 9 10C6.79086 10 5 8.20914 5 6C5 3.79086 6.79086 2 9 2ZM9 3.9C7.8402 3.9 6.9 4.8402 6.9 6C6.9 7.1598 7.8402 8.1 9 8.1C10.1598 8.1 11.1 7.1598 11.1 6C11.1 4.8402 10.1598 3.9 9 3.9Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
