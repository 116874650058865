<script setup lang="ts">
  import VueDatePicker, { DatePickerInstance } from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import { computed, onMounted, ref } from 'vue'
  import dayjs, { type Dayjs } from 'dayjs'
  import { vMaska } from 'maska/vue'
  import { ru } from 'date-fns/locale'
  import IconCancel from '@/components/icons/IconCancel.vue'
  import IconCalendar from '@/components/icons/IconCalendar.vue'
  import IDatePickerProps from '@/components/types/DatePicker'
  import { MaskaDetail } from 'maska'
  import customParseFormat from 'dayjs/plugin/customParseFormat'

  const date = ref()
  dayjs.extend(customParseFormat)

  const format = (date: Date) => {
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    label = 'Выберите дату',
    errorMessages = [],
    density = 'comfortable'
  } = defineProps<IDatePickerProps>()

  const modelValue = defineModel<string | Dayjs | null>()

  const isHovered = ref(false)
  const myDate = ref()
  const datepicker = ref<DatePickerInstance>(null)
  const optionsDefault = {
    mask: '##.##.####'
  }

  const onMaska = (event: CustomEvent<MaskaDetail>) => {
    if (event.detail.completed) {
      const dt = dayjs(event.detail.masked, 'DD.MM.YYYY').toISOString()

      emit('update:modelValue', dt)
      if (datepicker.value) {
        datepicker.value.closeMenu()
      }
    }
  }
  const emit = defineEmits(['update:modelValue'])
  const updateModelValue = () => {
    if (date.value) {
      myDate.value = dayjs(date.value).format('DD.MM.YYYY')
    } else {
      myDate.value = null
    }

    emit('update:modelValue', dayjs(date.value))
  }

  const updateDate = (dt: string) => {
    date.value = dt
  }

  const clearDatePicker = () => {
    date.value = null
    updateModelValue()
  }

  const mask = computed(() => {
    return optionsDefault
  })

  onMounted(() => {
    if (modelValue.value) {
      date.value = dayjs(modelValue.value)
      myDate.value = date.value.format('DD.MM.YYYY')
    }
  })
</script>

<template>
  <VueDatePicker
    ref="datepicker"
    v-model="date"
    :format="format"
    :max-date="maxDate"
    text-input
    auto-apply
    :format-locale="ru"
    teleport
    :enable-time-picker="false"
    @update:model-value="updateModelValue"
  >
    <template #trigger>
      <v-text-field
        v-model="myDate"
        v-maska="mask"
        :label="label"
        variant="outlined"
        class="date-picker-v"
        :density="density"
        :error-messages="
          errorMessages.map(e =>
            typeof e.$message === 'string' ? e.$message : e.$message?.value || ''
          )
        "
        @update:model-value="updateDate"
        @maska="onMaska"
        @mouseenter="isHovered = true"
        @mouseleave="isHovered = false"
      >
        <template #append-inner>
          <IconCancel
            v-if="isHovered && myDate"
            :color="'black'"
            class="append-inner-icon"
            @click="clearDatePicker"
          />
          <IconCalendar v-else class="append-inner-icon" />
        </template>
        <template #message="{ message }">
          <div class="b3">{{ message }}</div>
        </template>
      </v-text-field>
    </template>
  </VueDatePicker>
</template>

<style scoped lang="scss">
  .date-picker-v {
    --v-input-control-height: 40px;

    .append-inner-icon {
      cursor: pointer;
    }
  }
</style>
