<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3ZM12 4.9C13.16 4.9 14.1 5.84 14.1 7C14.1 8.16 13.16 9.1 12 9.1C10.8402 9.1 9.9 8.1598 9.9 7C9.9 5.8402 10.8402 4.9 12 4.9ZM12 12C9.33 12 4 13.33 4 16V19H20V16C20 13.33 14.67 12 12 12ZM12 13.9C14.97 13.9 18.1 15.36 18.1 16V17.1H5.9V16C5.9 15.36 9 13.9 12 13.9Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
