<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import authService from '@/api/authService'
  import { ITipMobile, useWelcomeTipsStore } from '@/stores/welcomeTipsStore'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { useI18n } from 'vue-i18n'
  import { useCookieConsentStore } from '@/stores/cookieConsentStore'
  import IconClose from '@/components/icons/IconClose.vue'

  const dialog = ref(false)
  const welcomeTipsStore = useWelcomeTipsStore()
  const { t } = useI18n()
  const currentStep = ref(0)
  const cookie = useCookieConsentStore()

  const steps = computed<ITipMobile[]>(() => {
    return welcomeTipsStore.getStepsMobile()
  })

  const needShow = computed(() => {
    return welcomeTipsStore.show && cookie.consentGiven
  })

  const completeWelcomeTips = () => {
    const ver = [...new Set(welcomeTipsStore.getStepsMobile().map(item => item.version))]
    if (ver.length > 0) {
      authService.authWelcomeTips(ver as string[])
      welcomeTipsStore.setCheckedVersions(ver as string[])
    }
  }

  const previous = () => {
    currentStep.value = currentStep.value - 1
  }

  const next = () => {
    currentStep.value = currentStep.value + 1
  }

  watch(
    () => [needShow.value, steps.value],
    newState => {
      const val1 = newState[0] as boolean
      const val2 = newState[1] as []
      dialog.value = val1 && val2.length > 0
    },
    { flush: 'post' }
  )

  onMounted(() => {
    if (needShow.value) {
      dialog.value = true
    }
  })
</script>

<template>
  <v-dialog v-model="dialog" max-width="480" height="100%" class="welcome-tip-dialog">
    <v-card
      class="d-flex flex-column ga-6 pa-6 rounded-lg overlay-content position-absolute"
      width="100%"
      height="100%"
    >
      <v-icon role="img" class="btn-close btn-close-mobile" @click="dialog = false">
        <IconClose />
      </v-icon>
      <v-card-title></v-card-title>
      <v-card-text class="ma-0 pa-0">
        <v-sheet class="d-flex flex-column justify-space-between" height="100%">
          <v-sheet>
            <v-img rounded="xl" :src="steps[currentStep].img" />
            <v-sheet class="body-b1 mt-8">{{ t(steps[currentStep].title) }}</v-sheet>
          </v-sheet>
          <v-sheet class="d-flex justify-space-between align-center">
            <v-sheet class="d-flex ga-4">
              <BaseButton
                v-if="currentStep !== 0"
                :label="t('welcomeTips.btnPrev')"
                variant="outlined"
                @click="previous"
              />
              <BaseButton
                v-if="currentStep !== steps.length - 1"
                :label="t('welcomeTips.btnNext')"
                @click="next"
              />
              <BaseButton
                v-if="currentStep === steps.length - 1"
                :label="t('welcomeTips.btnComplete')"
                @click="completeWelcomeTips"
              />
            </v-sheet>
            <v-sheet class="body-b1-bold">{{ currentStep + 1 }}/{{ steps.length }}</v-sheet>
          </v-sheet>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
  .v-dialog.welcome-tip-dialog {
    :deep(.v-overlay__content) {
      transition: width 0.5s ease-in-out;
      margin: 0 8px;
      width: 100%;
    }

    .btn-close {
      position: fixed;
      right: -24px;
      top: 0;
      background-color: white;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      padding: 12px;
    }
    .btn-close-mobile {
      position: fixed;
      right: 16px;
      top: 16px;
      background-color: var(--color-BlackHover);
      width: 36px;
      height: 36px;
      border-radius: 18px;
      padding: 0;
    }
  }
</style>
