// @ts-ignore
export const account = {
  title: 'Личные данные',
  privateInfo: 'Личная информация',
  privateInfoSubtitle: 'ФИО, паспорт, ИНН',
  contacts: 'Контакты',
  contactsSubtitle: 'Адрес, почта, телефон',
  other: 'Прочие данные',
  otherSubtitle: 'Выплата доходов, сведения о ПДЛ и т.д.',
  bankAccount: 'Банковские счета',
  bankAccountSubtitle: 'Реквизиты для вывода средств',
  privateModal: {
    changeAvatarBtn: 'Изменить аватар',
    imageInfo: 'Загрузите картинку в формате jpg или png',
    lastName: 'Фамилия',
    firstName: 'Имя',
    middleName: 'Отчество',
    passportSerial: 'Серия паспорта',
    passportNumber: 'Номер паспорта',
    passportDate: 'Дата выдачи',
    issuedBy: 'Кем выдан',
    codeDepartment: 'Код подразделения',
    inn: 'ИНН'
  },
  contactsModal: {
    addressRegistration: 'Адрес регистрации',
    address: 'Почтовый адрес',
    phoneNumber: 'Телефон',
    fax: 'Факс',
    email: 'Email',
    mobilePhone: 'Мобильный телефон',
    communicationTypesTitle: 'Способы обмена сообщениями',
    communicationTypes: {
      profile: 'Личный кабинет',
      email: 'Email',
      phone: 'Телефон',
      office: 'Оригиналы документов в офисе компании'
    }
  },
  otherModal: {
    dividendsTitle: 'Выплата доходов по ценным бумагам',
    dividends: {
      brokerageAccount: 'На брокерский счет',
      bankAccount: 'На банковский счет'
    },
    beneficialOwnerTitle: 'Сведения о бенефициарном владельце',
    beneficialOwnerEmpty: 'Бенефициарный владелец отсутствует',
    beneficiaryTitle: 'Сведения о выгодоприобретателе',
    beneficiaryEmpty: 'Бенефициарный владелец отсутствует',
    officialTitle: 'Сведения о Публичном Должностном Лице',
    official: {
      notiam: 'Не являюсь',
      iam: 'Являюсь'
    },
    businessRelationsTitle: 'Цели установления и предполагаемый характер деловых отношений',
    businessRelations: {
      brokerageServices: 'Брокерские услуги',
      depositoryServices: 'Депозитарное обслуживание',
      trustManagement: 'Доверительное управление',
      investmentConsulting: 'Инвестиционное консультирование'
    }
  },
  saveBtn: 'Сохранить данные',
  confirmationText:
    'Нажимая «Сохранить данные» я подтверждаю свое согласие на обработку указанных в анкете персональных данных и подтверждаю, что внесенные мной данные корректны.'
}
