<script setup lang="ts">
  import BaseInput from '@/components/BaseComponents/BaseInput.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox.vue'
  import { useRegistrationStore } from '@/stores/registrationStore'
  import { reactive, computed, ref } from 'vue'
  import { helpers, required } from '@vuelidate/validators'
  import { useVuelidate } from '@vuelidate/core'
  import { useI18n } from 'vue-i18n'
  import { filterNumber } from '@/utils/filterNumber.extensions'
  import registrationService from '@/api/registrationService'
  import { useNotify } from '@/stores/notify'

  const registrationStore = useRegistrationStore()
  const notifyStore = useNotify()
  const { t } = useI18n()
  const $externalResults = ref<{ [key: string]: string[] }>({})

  const phoneNumber = computed({
    get: () => registrationStore.data.phoneNumber,
    set: (value: string) => (registrationStore.data.phoneNumber = value)
  })

  const initialState = {
    phoneNumber: phoneNumber
  }

  const state = reactive({
    ...initialState
  })

  const rules = {
    phoneNumber: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.phoneNumber') }),
        required
      ),
      $autoDirty: true
    }
  }

  const v$ = useVuelidate(rules, state, { $externalResults })

  const sendSms = async () => {
    v$.value.$touch()

    if (!v$.value.$invalid) {
      const checkPhoneNumber = {
        phoneNumber: phoneNumber.value
      }

      try {
        await registrationService.checkPhone(checkPhoneNumber)
        registrationStore.setTimerSms(60)
        registrationStore.setSmsSend(true)
      } catch (error: any) {
        if (error.status === 400) {
          $externalResults.value.phoneNumber = [t('validation.wrongPhoneNumber')]
        } else {
          notifyStore.showServiceError()
        }
      }
    }
  }

  const checkBlur = () => {
    v$.value.phoneNumber.$touch()
  }

  const filterInput = (event: KeyboardEvent) => {
    phoneNumber.value = filterNumber(event)

    checkBlur()
  }
</script>

<template>
  <v-sheet class="d-flex flex-column align-center justify-center" max-width="460">
    <v-sheet class="title-h2 mb-10" max-width="330">
      {{ t('registration.steps.step1.title') }}
    </v-sheet>
    <v-form ref="registrationForm" fast-fail @submit.prevent="sendSms">
      <BaseInput
        v-model="phoneNumber"
        placeholder="+7 (___)___-__-__"
        name-field="phoneNumber"
        :error-messages="v$.phoneNumber.$errors.map(e => e.$message)"
        :label="t('registration.phoneNumber')"
        phone-mask
        class="w-100"
        @blur="checkBlur"
        @input="filterInput"
      />
      <BaseButton
        :label="t('registration.nextButton')"
        class="w-100 mb-10"
        type="submit"
        :disabled="!registrationStore.data.suggestion"
      />
      <v-sheet class="d-flex justify-space-between">
        <v-sheet width="32">
          <BaseCheckbox v-model="registrationStore.data.suggestion" />
        </v-sheet>
        <v-sheet class="body-b1 ml-4">
          {{ t('registration.steps.step1.confirmation') }}
        </v-sheet>
      </v-sheet>
    </v-form>
  </v-sheet>
</template>

<style scoped lang="scss"></style>
