<script setup lang="ts">
  import BaseCarousel from '@/components/BaseComponents/BaseCarousel.vue'
  import MainInformer from '@/components/MainInformer.vue'
  import { useDisplay } from 'vuetify'
  import AccountSelection from '@/components/AccountSelection.vue'
  import BaseCarouselMobile from '@/components/BaseComponents/BaseCarouselMobile.vue'

  const { mobile } = useDisplay()
</script>

<template>
  <v-sheet class="align-start d-flex flex-column ga-6 portfolio-bar">
    <v-sheet v-if="mobile" class="d-flex ga-6 w-100 px-4">
      <AccountSelection />
    </v-sheet>
    <BaseCarouselMobile v-if="mobile" />
    <v-row v-else>
      <BaseCarousel />
      <v-col cols="3">
        <MainInformer />
      </v-col>
    </v-row>
    <v-sheet class="w-100">
      <RouterView />
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss"></style>
