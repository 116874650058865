import httpCommunicator from '@/api/httpCommunicator'
import type { AxiosResponse } from 'axios'
import { IInformersModel } from '@/api/types'

const informerService = {
  async informers() {
    return httpCommunicator.get('/informer').then((response: AxiosResponse<IInformersModel>) => {
      return response.data
    })
  }
}
export default informerService
