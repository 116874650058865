export const history = {
  tabsOrder: 'Поручения',
  tabsTransaction: 'История операций',
  rangePicker: 'Начальная дата - Конечная дата',
  headers: {
    number: '№ Поручения',
    dateCreate: 'Дата создания',
    dateExp: 'Дата исполнения',
    operation: 'Операция',
    cb: 'ЦБ',
    count: 'Количество',
    amount: 'Сумма',
    status: 'Статус'
  },
  orderTypesItems: {
    stock: 'Биржевые',
    offExchange: 'Внебиржевые',
    ipo: 'IPO'
  },
  statusItems: {
    active: 'Активные',
    executed: 'Исполненные',
    removed: 'Снятые'
  },
  applicationTypesItems: {
    market: 'Рыночная',
    limit: 'Лимитная'
  },
  operationTypesItems: {
    withdrawalMoney: 'Вывод денег',
    addingFunds: 'Пополнение счета',
    translation: 'Перевод',
    paymentDividends: 'Выплата дивидендов',
    paymentCoupon: 'Выплата купона',
    commission: 'Комиссия',
    taxes: 'Налоги',
    transactionsCB: 'Операции с ЦБ',
    repaymentBonds: 'Погашение облигаций',
    other: 'Прочие'
  },
  labelOrderTypes: 'Типы',
  labelAccount: 'Счета',
  labelStatus: 'Статусы',
  labelApplicationTypes: 'Типы заявок',
  labelOperationTypes: 'Тип операции',
  tooltip: {
    edit: 'Редактировать (отменить текущее и выставить новое).',
    cancel: 'Отменить'
  }
}
