<script setup lang="ts">
  import BaseInput from '@/components/BaseComponents/BaseInput.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import CodeTimer from '@/components/CodeTimer.vue'
  import { reactive, ref } from 'vue'
  import { useRegistrationStore } from '@/stores/registrationStore'
  import { helpers, required } from '@vuelidate/validators'
  import { useVuelidate } from '@vuelidate/core'
  import { useI18n } from 'vue-i18n'
  import { filterNumber } from '@/utils/filterNumber.extensions'
  import registrationService from '@/api/registrationService'
  import { useNotify } from '@/stores/notify'

  const code = ref('')
  const registrationStore = useRegistrationStore()
  const { t } = useI18n()
  const notifyStore = useNotify()
  const $externalResults = ref<{ [key: string]: string[] }>({})

  const initialState = {
    code: code
  }

  const state = reactive({
    ...initialState
  })

  const rules = {
    code: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.code') }),
        required
      ),
      $autoDirty: true
    }
  }

  const v$ = useVuelidate(rules, state, { $externalResults })

  const checkBlur = () => {
    v$.value.code.$touch()
  }

  const sendSmsAgain = async () => {
    const checkPhoneNumber = {
      phoneNumber: registrationStore.data.phoneNumber
    }

    try {
      await registrationService.checkPhone(checkPhoneNumber)
      registrationStore.setTimerSms(60)
      registrationStore.setSmsSend(true)
    } catch (error) {
      notifyStore.showServiceError()
    }
  }

  const handleFormSubmit = async () => {
    v$.value.$touch()

    if (!v$.value.$invalid) {
      const checkCode = {
        phoneNumber: registrationStore.data.phoneNumber,
        code: code.value
      }
      try {
        await registrationService.checkCode(checkCode)
        registrationStore.step = '2'
      } catch (error: any) {
        if (error.status === 400) {
          $externalResults.value.code = [t('validation.wrongCode')]
        } else {
          notifyStore.showServiceError()
        }
      }
    }
  }

  const filterInput = (event: KeyboardEvent) => {
    code.value = filterNumber(event)

    checkBlur()
  }

  const handleBack = () => {
    registrationStore.setSmsSend(false)
  }
</script>

<template>
  <v-sheet class="d-flex flex-column align-center justify-center" max-width="460">
    <v-sheet class="title-h2 mb-10">{{ t('registration.steps.step1.smsTitle') }}</v-sheet>
    <v-sheet class="body-b1 mb-10">
      {{
        t('registration.steps.step1.smsSubtitle', {
          phoneNumber: registrationStore.data.phoneNumber
        })
      }}
    </v-sheet>
    <BaseInput
      v-model="code"
      :error-messages="v$.code.$errors.map(e => e.$message)"
      :label="t('registration.code')"
      class="w-100"
      input-mode="numeric"
      @blur="checkBlur"
      @input="filterInput"
    />
    <BaseButton
      :label="t('registration.steps.step1.confirmBtn')"
      class-name="w-100 mb-4"
      @click="handleFormSubmit"
    />
    <BaseButton
      :label="t('registration.steps.step1.backBtn')"
      class-name="w-100 mb-10"
      variant="outlined"
      @click="handleBack"
    />
    <v-sheet v-if="registrationStore.timerSms > 0" class="body-b1">
      <CodeTimer v-model="registrationStore.timerSms" />
    </v-sheet>
    <BaseButton
      v-else
      :label="t('registration.steps.step1.sendAgainBtn')"
      class-name="w-100"
      variant="outlined"
      block
      @click="sendSmsAgain"
    />
  </v-sheet>
</template>

<style scoped lang="scss"></style>
