<script setup lang="ts">
  import { ref, reactive } from 'vue'
  import { useVuelidate } from '@vuelidate/core'
  import { required, helpers } from '@vuelidate/validators'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import IconVisibleOn from '@/components/icons/IconVisibleOn.vue'
  import IconVisibleOff from '@/components/icons/IconVisibleOff.vue'
  import BaseInput from '@/components/BaseComponents/BaseInput.vue'
  import BaseCard from '@/components/BaseComponents/BaseCard.vue'
  import { useI18n } from 'vue-i18n'
  import RicomLogo from '@/components/RicomLogo.vue'
  import type { ICredentialModel } from '@/api/types'
  import authService from '@/api/authService'
  import { useNotify } from '@/stores/notify'
  import { useRouter } from 'vue-router'
  import { useRegistrationStore } from '@/stores/registrationStore'
  import { useDisplay } from 'vuetify'

  const login = ref('')
  const password = ref('')
  const visible = ref<boolean>(false)
  const { t } = useI18n()
  const $externalResults = ref<{ [key: string]: string[] }>({})
  const router = useRouter()
  const registrationStore = useRegistrationStore()
  const { mobile } = useDisplay()

  const initialState = {
    login: login,
    password: password
  }
  const notifyStore = useNotify()
  const emit = defineEmits(['login'])

  const state = reactive({
    ...initialState
  })

  const rules = {
    login: {
      required: helpers.withMessage(
        t('validation.required', { field: t('auth.loginOrPhone') }),
        required
      ),
      $autoDirty: true
    },
    password: {
      required: helpers.withMessage(
        t('validation.required', { field: t('auth.password') }),
        required
      ),
      $autoDirty: true
    }
  }

  const v$ = useVuelidate(rules, state, { $externalResults })

  const loginHandle = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        const credential: ICredentialModel = {
          username: login.value,
          password: password.value
        }
        await authService.login(credential)

        emit('login')
      }
    } catch (error: any) {
      if (error.status === 403) {
        notifyStore.focusTesting()
      } else if (error.status < 500) {
        $externalResults.value.password = [t('validation.wrongLoginOrPassword')]
      } else {
        notifyStore.showServiceError()
      }

      // throw error
    }
  }

  const toRegistration = async () => {
    registrationStore.clearStore()
    await router.push({ name: 'registration' })
  }
</script>

<template>
  <BaseCard :width="480" :class-card="['elevation-40-dp']">
    <template v-if="!mobile" #logo>
      <RicomLogo />
    </template>
    <template #header>
      <h2 class="d-flex mb-2 justify-start">{{ t('auth.loginTitle') }}</h2>
      <div class="b1 d-flex justify-start color-black-disabled">{{ t('auth.loginSubtitle') }}</div>
    </template>
    <template #content>
      <v-form ref="loginForm" fast-fail @submit.prevent="loginHandle">
        <BaseInput
          v-model="login"
          name-field="login"
          autocomplete="login"
          :label="t('auth.loginOrPhone')"
          :error-messages="v$.login.$errors.map(e => e.$message)"
          with-class="mb-6 w-100"
        />
        <BaseInput
          v-model="password"
          name-field="password"
          autocomplete="current-password"
          :type="!visible ? 'password' : 'text'"
          :label="t('auth.password')"
          :error-messages="v$.password.$errors.map(e => e.$message)"
          with-class="w-100"
          @append-click="visible = !visible"
        >
          <template #append-inner>
            <v-sheet style="cursor: pointer; height: 24px" @click="visible = !visible">
              <IconVisibleOn v-if="!visible" />
              <IconVisibleOff v-if="visible" />
            </v-sheet>
          </template>
        </BaseInput>

        <div class="d-none b2-bold mb-6 justify-start">{{ t('auth.forgotLoginOrPassword') }}</div>
        <BaseButton :label="t('next')" type="submit" class-name="mb-5" block />

        <BaseButton
          :label="t('auth.registration')"
          variant="outlined"
          block
          @click="toRegistration"
        />
      </v-form>
    </template>
  </BaseCard>
</template>

<style scoped lang="scss">
  .color-black-disabled {
    color: var(--color-BlackDisabled);
  }
</style>
