export const feedback = {
  title: 'Обратная связь',
  subtitle: 'Уведомления и поддержка',
  btnMarkAsRead: 'Отметить все как прочитанное',
  emptyText: 'Напишите нам',
  textareaMessage: 'Ваше сообщение',
  btnSend: 'Отправить',
  customer1: 'Риком',
  customer2: 'Советник',
  tabs: {
    manager: 'Менеджер',
    notifications: 'Уведомления',
    support: 'Служба поддержки',
    documents: 'Отправка документов'
  }
}
