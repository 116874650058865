<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M20.4758 23.3L17.5258 20.35C16.7258 20.8833 15.8634 21.2917 14.9388 21.575C14.0134 21.8583 13.0341 22 12.0008 22C10.6174 22 9.31745 21.7373 8.10078 21.212C6.88411 20.6873 5.82578 19.975 4.92578 19.075C4.02578 18.175 3.31345 17.1167 2.78878 15.9C2.26345 14.6833 2.00078 13.3833 2.00078 12C2.00078 10.9667 2.14245 9.98733 2.42578 9.062C2.70911 8.13733 3.11745 7.275 3.65078 6.475L0.675781 3.5L2.10078 2.075L21.9008 21.875L20.4758 23.3ZM12.0008 20C12.7508 20 13.4631 19.9 14.1378 19.7C14.8131 19.5 15.4508 19.225 16.0508 18.875L12.1758 15L10.6008 16.6L6.35078 12.35L7.75078 10.95L10.6008 13.8L10.7758 13.6L5.12578 7.95C4.77578 8.55 4.50078 9.18767 4.30078 9.863C4.10078 10.5377 4.00078 11.25 4.00078 12C4.00078 14.2167 4.78011 16.1043 6.33878 17.663C7.89678 19.221 9.78411 20 12.0008 20ZM20.3758 17.5L18.9008 16.025C19.2508 15.4417 19.5214 14.8123 19.7128 14.137C19.9048 13.4623 20.0008 12.75 20.0008 12C20.0008 9.78333 19.2218 7.89567 17.6638 6.337C16.1051 4.779 14.2174 4 12.0008 4C11.2508 4 10.5384 4.09567 9.86378 4.287C9.18845 4.479 8.55911 4.75 7.97578 5.1L6.50078 3.625C7.30078 3.10833 8.16345 2.70833 9.08878 2.425C10.0134 2.14167 10.9841 2 12.0008 2C13.3841 2 14.6841 2.26233 15.9008 2.787C17.1174 3.31233 18.1758 4.025 19.0758 4.925C19.9758 5.825 20.6881 6.88333 21.2128 8.1C21.7381 9.31667 22.0008 10.6167 22.0008 12C22.0008 13.0167 21.8591 13.9873 21.5758 14.912C21.2924 15.8373 20.8924 16.7 20.3758 17.5ZM15.0508 12.15L13.6508 10.75L16.2508 8.15L17.6508 9.55L15.0508 12.15Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
