import { App } from 'vue'

export default {
  install(app: App, options: { logout: () => void }) {
    let timer: ReturnType<typeof setTimeout> | null = null

    const resetTimer = () => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(
        () => {
          options.logout()
        },
        10 * 60 * 1000
      ) // 10 минут
    }

    const startActivityListener = () => {
      resetTimer()
      const events = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'touchmove']

      events.forEach(event => {
        window.addEventListener(event, resetTimer)
      })
    }

    const stopActivityListener = () => {
      if (timer) {
        clearTimeout(timer)
      }
      const events = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'touchmove']

      events.forEach(event => {
        window.removeEventListener(event, resetTimer)
      })
    }

    app.config.globalProperties.$startActivityWatcher = startActivityListener
    app.config.globalProperties.$stopActivityWatcher = stopActivityListener

    app.provide('startActivityWatcher', startActivityListener)
    app.provide('stopActivityWatcher', stopActivityListener)
  }
}
