<script setup lang="ts">
  import IconAccount from '@/components/icons/IconAccount.vue'
  import { useDialogStore } from '@/stores/dialogStore'
  import IconContacts from '@/components/icons/IconContacts.vue'
  import IconAccountCardAdd from '@/components/icons/IconAccountCardAdd.vue'
  import IconBankWallet from '@/components/icons/IconBankWallet.vue'
  import { useI18n } from 'vue-i18n'
  import AccountSelection from '@/components/AccountSelection.vue'
  import { useDisplay } from 'vuetify'
  import BaseAvatar from '@/components/BaseComponents/BaseAvatar.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import authService from '@/api/authService'
  import { useAccountInfoStore } from '@/stores/accountInfoStore'
  import { useRouter } from 'vue-router'

  const { t } = useI18n()
  const display = useDisplay()

  const dialogStore = useDialogStore()
  const accountInfoStore = useAccountInfoStore()
  const router = useRouter()

  const logout = async () => {
    await authService.logout()
    await router.push('/auth')
  }
</script>

<template>
  <v-sheet v-if="display.mobile.value" class="d-flex ga-6 w-100 px-4">
    <AccountSelection />
  </v-sheet>
  <v-sheet :class="{ 'px-4': display.mobile.value }">
    <v-sheet
      class="title-h1"
      :class="{ 'py-8': display.mobile.value, 'pb-8': !display.mobile.value }"
    >
      {{ display.mobile.value ? t('account.titleMobile') : t('account.title') }}
    </v-sheet>
    <v-sheet
      class="d-flex"
      :class="{ 'flex-column': display.mobile.value, 'w-100': !display.mobile.value }"
    >
      <v-sheet
        class="d-flex flex-column ga-6 justify-between"
        :width="display.mobile.value ? '100%' : '40%'"
      >
        <v-sheet v-if="display.mobile.value" class="d-flex align-center ga-4 pb-4" height="80">
          <v-sheet class="d-flex">
            <BaseAvatar :full-name="accountInfoStore.avatarFullName" />
          </v-sheet>
          <v-sheet>
            <BaseButton variant="outlined" :label="t('account.logoutBtn')" @click="logout" />
          </v-sheet>
        </v-sheet>
        <v-card
          class="elevation-04-dp pa-4 rounded-lg"
          max-width="496"
          hover
          @click="
            dialogStore.openDialog('AccountInformation', {
              width: display.mobile.value ? '100%' : '768px',
              height: display.mobile.value ? '100%' : 'auto',
              maxWidth: display.mobile.value ? '400px' : '',
              class:
                'd-flex flex-column' + (!display.mobile.value ? ' align-center' : ' align-start')
            })
          "
        >
          <v-sheet>
            <v-sheet
              class="d-flex flex-row align-center"
              :class="{ 'overflow-hidden': display.mobile.value }"
            >
              <v-sheet height="24px">
                <IconAccount />
              </v-sheet>
              <v-sheet>
                <v-card-title class="pa-0 title-h2 ml-4">
                  {{ t('account.privateInfo') }}
                </v-card-title>
              </v-sheet>
            </v-sheet>
            <v-card-subtitle class="pa-0 ml-10">
              {{ t('account.privateInfoSubtitle') }}
            </v-card-subtitle>
          </v-sheet>
        </v-card>

        <v-card
          class="elevation-04-dp pa-4 rounded-lg"
          max-width="496"
          hover
          @click="
            dialogStore.openDialog('AccountContacts', {
              width: display.mobile.value ? '100%' : '768px',
              height: display.mobile.value ? '100%' : 'auto',
              maxWidth: display.mobile.value ? '400px' : '',
              class:
                'd-flex flex-column' + (!display.mobile.value ? ' align-center' : ' align-start')
            })
          "
        >
          <v-sheet>
            <v-sheet class="d-flex flex-row align-center">
              <v-sheet height="24px">
                <IconContacts />
              </v-sheet>
              <v-sheet>
                <v-card-title class="pa-0 title-h2 ml-4">{{ t('account.contacts') }}</v-card-title>
              </v-sheet>
            </v-sheet>
            <v-card-subtitle class="pa-0 ml-10">
              {{ t('account.contactsSubtitle') }}
            </v-card-subtitle>
          </v-sheet>
        </v-card>

        <v-card
          class="elevation-04-dp pa-4 rounded-lg"
          max-width="496"
          hover
          @click="
            dialogStore.openDialog('AccountOther', {
              width: display.mobile.value ? '100%' : '768px',
              height: display.mobile.value ? '100%' : 'auto',
              maxWidth: display.mobile.value ? '400px' : '',
              class:
                'd-flex flex-column' + (!display.mobile.value ? ' align-center' : ' align-start')
            })
          "
        >
          <v-sheet>
            <v-sheet class="d-flex flex-row align-center">
              <v-sheet height="24px">
                <IconAccountCardAdd />
              </v-sheet>
              <v-sheet>
                <v-card-title class="pa-0 title-h2 ml-4">{{ t('account.other') }}</v-card-title>
              </v-sheet>
            </v-sheet>
            <v-card-subtitle class="pa-0 ml-10">
              {{ t('account.otherSubtitle') }}
            </v-card-subtitle>
          </v-sheet>
        </v-card>

        <v-card
          class="elevation-04-dp pa-4 rounded-lg"
          max-width="496"
          hover
          :to="{ name: 'account.bank' }"
        >
          <v-sheet>
            <v-sheet class="d-flex flex-row align-center">
              <v-sheet height="24px">
                <IconBankWallet />
              </v-sheet>
              <v-sheet>
                <v-card-title class="pa-0 title-h2 ml-4">
                  {{ t('account.bankAccount') }}
                </v-card-title>
              </v-sheet>
            </v-sheet>
            <v-card-subtitle class="pa-0 ml-10">
              {{ t('account.bankAccountSubtitle') }}
            </v-card-subtitle>
          </v-sheet>
        </v-card>
      </v-sheet>
      <v-sheet
        :width="display.mobile.value ? '100%' : '60%'"
        :class="{ 'mt-4': display.mobile.value, 'empty-space': !display.mobile.value }"
      >
        <v-img src="/img/capibara.png" width="327" />
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss">
  .empty-space {
    justify-items: end;
  }

  .v-card--hover:hover {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: transparent;
  }

  .v-theme--myCustomLightTheme a[href]:hover {
    text-decoration: none !important;
  }
</style>
