<script setup lang="ts">
  import { computed } from 'vue'
  import dayjs from 'dayjs'
  import { type IMessageChangeModel, IMessageModel } from '@/api/types'
  import IconDotSmall from '@/components/icons/IconDotSmall.vue'
  import { useAccountInfoStore } from '@/stores/accountInfoStore'
  import { useI18n } from 'vue-i18n'
  import messageService from '@/api/messageService'
  import { useMessagesStore } from '@/stores/messagesStore'
  import { FeedbackTabs } from '@/components/types'

  interface IMessageProps {
    message: IMessageModel
    category: FeedbackTabs
  }

  const accountStore = useAccountInfoStore()
  const props = defineProps<IMessageProps>()
  const { t } = useI18n()
  const messagesStore = useMessagesStore()

  const name = computed(() => {
    if (props.message.type === 1 || props.message.type === 2) {
      return t('feedback.customer1')
    }
    if (props.message.type === 3 || props.message.type === 4) {
      return t('feedback.customer2')
    }
    return accountStore.avatarFullName
  })

  const unread = computed(() => {
    return props.message.type === 1 || props.message.type === 3
  })

  const onIntersect = (isIntersecting: boolean) => {
    if (isIntersecting && (props.message.type === 1 || props.message.type === 3)) {
      const messageModel: IMessageChangeModel = {
        dateTime: props.message.dateTime
      }

      messageService.changeMessage(messageModel)
      messagesStore.load()
    }
  }
</script>

<template>
  <v-sheet
    v-intersect="onIntersect"
    class="d-flex bg-transparent justify-space-between align-center"
  >
    <v-sheet
      :class="[
        'd-flex flex-column pa-4 w-100 rounded-lg ga-4 elevation-01-dp',
        message.type === 0 ? 'ml-8 bg-success-background' : '',
        message.type !== 0 && !unread ? 'mr-8' : ''
      ]"
    >
      <v-sheet
        :class="['d-flex justify-space-between', message.type === 0 ? 'bg-success-background' : '']"
      >
        <v-sheet
          :class="['body-b3-bold color-main', message.type === 0 ? 'bg-success-background' : '']"
        >
          {{ name }}
        </v-sheet>
        <v-sheet
          :class="['body-b3 color-inactive', message.type === 0 ? 'bg-success-background' : '']"
        >
          {{ dayjs(message.dateTime).format('DD.MM.YYYY HH:mm') }}
        </v-sheet>
      </v-sheet>
      <v-sheet :class="['body-b1', message.type === 0 ? 'bg-success-background' : '']">
        {{ message.text }}
      </v-sheet>
    </v-sheet>
    <v-sheet v-if="unread" class="bg-transparent">
      <IconDotSmall color="red" />
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss">
  .color-main {
    color: var(--color-MainNormal);
  }

  .color-inactive {
    color: var(--color-BlackInactive);
  }
</style>
