import { IInformerModel, ILotsModel } from '@/api/types'
import { defineStore } from 'pinia'
import dictionaryService from '@/api/dictionaryService'
//todo: Данны по лотам могут менять раз в сутки перед запуском торгов
interface IState {
  lots: ILotsModel[]
  lastUpdated: null | number
}
interface IGetter {
  isDataStale: (s: IState) => boolean
  [key: string]: any
}
interface IAction {
  load: () => Promise<void>
  clearStore: () => void
  getLotName: (paperId: number) => string
  getLotTicker: (paperId: number) => string
}

const cacheDuration = 60 * 60 * 1000 // 60 минут

export const useLotsStore = defineStore<'lots', IState, IGetter, IAction>({
  id: 'lots',
  state: (): IState => ({
    lots: [],
    lastUpdated: null
  }),
  getters: {
    isDataStale: state => {
      return !state.lastUpdated || Date.now() - state.lastUpdated > cacheDuration
    }
  },
  persist: true,
  actions: {
    async load() {
      if (!this.isDataStale && this.lots.length > 0) {
        return // Если данные свежие, не делаем запрос
      }

      try {
        this.lots = await dictionaryService.lotsData()
        this.lastUpdated = Date.now()
      } catch (error) {
        console.error('Ошибка при получении справочников:', error)
      }
    },
    clearStore() {
      this.$reset()
    },
    getLotName(paperId: number) {
      const lot = this.lots.find(item => item.paperId === paperId)
      if (lot) {
        return lot.name
      } else {
        return ''
      }
    },
    getLotTicker(paperId: number) {
      const lot = this.lots.find(item => item.paperId === paperId)
      if (lot) {
        return lot.ticker
      } else {
        return ''
      }
    }
  }
})
