<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M10 15C10.41 15 10.81 14.97 11.21 14.94C11.4 14.18 11.72 13.46 12.16 12.83C11.47 12.94 10.74 13 10 13C7.58 13 5.3 12.4 4 11.45V8.64C5.47 9.47 7.61 10 10 10C12.39 10 14.53 9.47 16 8.64V10.19C16.5 10.07 17 10 17.55 10C17.7 10 17.85 10 18 10.03V6C18 3.79 14.42 2 10 2C5.58 2 2 3.79 2 6V16C2 18.21 5.59 20 10 20C10.66 20 11.31 19.96 11.92 19.88C11.57 19.29 11.31 18.64 11.16 17.94C10.79 18 10.41 18 10 18C6.13 18 4 16.5 4 16V13.77C5.61 14.55 7.72 15 10 15ZM10 4C13.87 4 16 5.5 16 6C16 6.5 13.87 8 10 8C6.13 8 4 6.5 4 6C4 5.5 6.13 4 10 4ZM21 16.5C21 17.32 20.75 18.08 20.33 18.71L19.24 17.62C19.41 17.28 19.5 16.9 19.5 16.5C19.5 15.12 18.38 14 17 14V15.5L14.75 13.25L17 11V12.5C19.21 12.5 21 14.29 21 16.5ZM17 17.5L19.25 19.75L17 22V20.5C14.79 20.5 13 18.71 13 16.5C13 15.68 13.25 14.92 13.67 14.29L14.76 15.38C14.59 15.72 14.5 16.1 14.5 16.5C14.5 17.88 15.62 19 17 19V17.5Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
