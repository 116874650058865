export const services = {
  title: 'Сервисы',
  reports: 'Отчеты',
  topUpAccount: 'Пополнить',
  description: 'Описание',
  descriptionReportDepo: 'Депозитарный отчет за выбранный период',
  descriptionReportBroker: 'Брокерский отчет за выбранный период',
  descriptionTopUp: 'Пополнение брокерского счета',
  descriptionReports: 'Отчеты участника',
  reportBrokerTitle: 'Отчет Брокер',
  reportDepoTitle: 'Отчет ДЕПО'
}
