<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M20 17V21H22L19 24L16 21H18V17H20ZM14 2L20 8V15H18V9H13V4H6V20H13L15 22H6C4.94045 22 4.08136 21.1891 4.00545 20.1499L4 20V4C4 2.9 4.89 2 6 2H14ZM10.4211 10L12 13.0222L13.5789 10H15L12.7105 14L15 18H13.5789L12 14.9778L10.4211 18H9L11.2895 14L9 10H10.4211Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
