export const portfolio = {
  title: 'Portfolio',
  table: {
    tabs: {
      common: 'Общие',
      action: 'Акции',
      derivative: 'Деривативы',
      dolgovyi: 'Долговые',
      pai: 'ПАИ',
      currency: 'Валюта',
      autoprofile: 'Автопортфель'
    },
    rowMenu: {
      info: 'Information',
      sell: 'Sell',
      buy: 'Buy'
    },
    columns: {
      name: 'Код ЦБ',
      total: 'Всего бумаг',
      buyDate: 'Дата покупки',
      buyPrice: 'Цена покупки',
      nowPrice: 'Цена сейчас',
      totalPrice: 'Сумма покупки',
      totalPriceNow: 'Сумма сейчас',
      changesCurrency: 'Изменение, ₽',
      changesPercent: 'Изменение, %'
    },
    summary: 'Итого'
  },
  hotActions: 'Быстрые действия:',
  btnUpTo: 'Удвоить',
  btnCloseAll: 'Закрыть все',
  btnTopUp: 'Пополнить счет',
  btnOpenAccount: 'Открыть счет / ИИС',
  emptyData: 'Здесь пока пусто',
  marginPosition: {
    mobileStaticNpr: {
      npr1: 'НПР 1',
      npr1Desc: '(портфель – начальная маржа) = ',
      npr2: 'НПР 2',
      npr2Desc: '(портфель – минимальная маржа) = '
    },
    tooltipNpr: {
      p1: 'Минимальная маржа = ',
      p2: 'Начальная маржа = ',
      p3: 'НПР 1 = ',
      p31: '(стоимость портфеля минус начальная маржа)',
      p4: 'НПР 2 = ',
      p41: '(стоимость портфеля минус минимальная маржа)',
      p5: 'НПР – норматив покрытия риска'
    },
    npr2: 'НПР2: ',
    npr1: 'НПР1: ',
    title: 'Маржинальные параметры',
    currency: ' ₽',
    subOverline: 'маржинальный портфель'
  }
}
