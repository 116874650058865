<script setup lang="ts">
  //@ts-nocheck
  import { computed, watch } from 'vue'
  import { useNotify } from '@/stores/notify'
  import { Notifications, useNotification } from '@kyvg/vue3-notification'
  import { useDisplay } from 'vuetify'
  import { stringToPositiveHash } from '@/utils/number.extensions'
  import { useI18n } from 'vue-i18n'

  type ColorIcon = {
    success: string
    info: string
    warn: string
    error: string
  }

  const style = getComputedStyle(document.documentElement)
  const notifyStore = useNotify()
  const { notify } = useNotification()
  const { mobile } = useDisplay()
  const { t } = useI18n()

  const systemFlexColumn = computed(() => {
    return ['d-flex', notifyStore.description.length > 30 || mobile.value ? 'flex-column' : 'ga-2']
  })
  const colorIcon: ColorIcon = {
    success: style.getPropertyValue('--color-SuccessNormal'),
    info: style.getPropertyValue('--color-MainNormal'),
    warn: style.getPropertyValue('--color-WarningNormal'),
    error: style.getPropertyValue('--color-AlertNormal')
  }

  const width = computed(() => {
    if (mobile.value) {
      return 'calc(100dvw - 16px)'
    }
    switch (notifyStore.group) {
      case 'session':
        return '620px'
      case 'system':
        return '890px'
      case 'app':
        return '600px'
      default:
        return '100%'
    }
  })

  watch(
    () => notifyStore.$state,
    newState => {
      if (newState.message) {
        notify({
          id: stringToPositiveHash(newState.type + newState.group),
          title: newState.message,
          text: newState.description,
          type: newState.type ?? undefined,
          group: newState.group,
          duration: newState.duration
        })
      }
    },
    { deep: true }
  )
</script>
<template>
  <Notifications
    class="vue-notification-group-session"
    group="session"
    position="top center"
    :width="width"
    dangerously-set-inner-html
    pause-on-hover
    ignore-duplicates
  >
    <template #body="props">
      <div :class="['d-flex', 'align-start', 'ga-4', 'notification', 'session', props.item.type]">
        <v-img src="/img/zhdun.png" width="120" />
        <div class="d-flex flex-column ga-4">
          <p class="title-h3">
            {{ props.item.title }}
          </p>
          <div class="body-b1-bold" style="color: var(--color-BlackInactive)">
            {{ t(`notification.sessionEnd.${mobile ? 'mobileDescription' : 'description'}`) }}
          </div>
        </div>
        <v-icon
          class="btn-close"
          icon="close"
          variant="plain"
          color="var(--color-BlackInactive)"
          @click="
            () => {
              props.close()
              notifyStore.$reset()
            }
          "
        />
      </div>
    </template>
  </Notifications>
  <Notifications
    class="vue-notification-group-system"
    group="system"
    position="top center"
    :width="width"
    dangerously-set-inner-html
    ignore-duplicates
  >
    <template #body="props">
      <div
        :class="[
          'd-flex',
          'align-start',
          'ga-4',
          'notification',
          'notification-system',
          props.item.type
        ]"
      >
        <div class="align-self-center">
          <v-icon
            icon="info"
            :color="
              props.item.type ? colorIcon[props.item.type as keyof ColorIcon] : colorIcon.info
            "
          />
        </div>
        <div :class="systemFlexColumn">
          <div class="body-b1-bold">
            {{ props.item.title }}
          </div>
          <div class="body-b1" v-html="props.item.text"></div>
          <v-icon
            class="btn-close"
            icon="close"
            variant="plain"
            color="var(--color-BlackInactive)"
            @click="
              () => {
                props.close()
                notifyStore.$reset()
              }
            "
          />
        </div>
      </div>
    </template>
  </Notifications>
  <Notifications
    class="vue-notification-group-app"
    :classes="['notification', 'notification-app']"
    group="app"
    :width="width"
    position="top center"
    dangerously-set-inner-html
    ignore-duplicates
  >
    <template #body="props">
      <div
        :class="[
          'd-flex',
          'align-start',
          'ga-4',
          'notification',
          'notification-system',
          props.item.type
        ]"
      >
        <div class="body-b1-bold">
          {{ props.item.title }}
        </div>
        <div class="body-b1" v-html="props.item.text"></div>
        <v-icon
          class="btn-close"
          icon="close"
          variant="plain"
          color="var(--color-BlackInactive)"
          @click="
            () => {
              props.close
              notifyStore.$reset()
            }
          "
        />
      </div>
    </template>
  </Notifications>
</template>
<style scope lang="scss">
  .vue-notification-wrapper {
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 6px !important;
  }
  .vue-notification-group-system,
  .vue-notification-group-session,
  .vue-notification-group-promote,
  .vue-notification-group-app {
    top: 24px !important;
  }
  .vue-notification-group-note {
    bottom: 24px !important;
    right: 24px !important;
  }
  @media (max-width: 599.98px) {
    .vue-notification-group-system,
    .vue-notification-group-session,
    .vue-notification-group-app,
    .vue-notification-group-promote {
      left: 8px !important;
      top: 55px !important;
    }
  }
  .notification-note {
  }
  .notification-system {
  }
  .notification-app {
  }
  .notification {
    font-weight: 700;
    padding: 16px;

    background: $colorMainBackground;

    // types (green, amber, red)
    &.success {
      background: $colorSuccessBackground;
    }

    &.warn,
    &.session {
      background: $colorWarningBackground;
    }
    &.error {
      background: $colorAlertBackground;
    }

    .btn-close {
      color: #e74c3c;
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
</style>
