<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M21 3H3C1.89543 3 1 3.89543 1 5V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V5C23 3.89543 22.1046 3 21 3ZM3 18V5H11V18H3ZM21 18H13V5H21V18ZM14 8.5H20V10H14V8.5ZM14 11H20V12.5H14V11ZM14 13.5H20V15H14V13.5Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
