<script setup lang="ts">
  import BaseNotification from '@/components/BaseComponents/BaseNotification.vue'
  import ModalDialog from '@/components/ModalDialog.vue'
  import { useAuthStore } from '@/stores/authStore'
  import { useNotify } from '@/stores/notify'
  import { useHead } from '@unhead/vue'
  import { onMounted, onUnmounted, ref, watch, inject, computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import WelcomeTip from '@/components/WelcomeTip.vue'
  import ReloadPromote from '@/components/ReloadPromote.vue'
  import InstallPromote from '@/components/InstallPromote.vue'
  import * as Sentry from '@sentry/vue'
  import { useYandexMetrika } from 'yandex-metrika-vue3'
  import { useDisplay } from 'vuetify'
  import WelcomeTipMobile from '@/components/WelcomeTipMobile.vue'
  import linkArray from '@/utils/headLinks'

  const notifyStore = useNotify()
  const authStore = useAuthStore()
  const ym = useYandexMetrika()

  const { t } = useI18n()
  const { mobile } = useDisplay()

  const isConnected = ref(true)
  const startActivityWatcher = inject('startActivityWatcher')
  const stopActivityWatcher = inject('stopActivityWatcher')

  onMounted(() => {
    useHead(linkArray)
    window.addEventListener('online', () => {
      isConnected.value = true
      console.log('online')
    })
    window.addEventListener('offline', () => {
      isConnected.value = false
      console.log('offline')
    })

    if (startActivityWatcher && typeof startActivityWatcher === 'function') {
      startActivityWatcher()
    }
  })
  const accountName = computed(() => authStore.accountName)

  watch(
    () => accountName.value,
    () => {
      Sentry.setUser({ username: accountName.value })
      if (import.meta.env.VITE_USER_NODE_ENV === 'production') {
        ym.userParams({ ClientID: accountName.value, UserID: accountName.value })
      }
    }
  )
  watch(
    () => [isConnected.value],
    () => {
      if (isConnected.value) {
        notifyStore.show(t('connectionRestored'), '', 'success', 'system', 4000)
      }
      if (!isConnected.value) {
        notifyStore.show(t('connectionLost'), '', 'error', 'system', 4000)
      }
    }
  )
  onUnmounted(() => {
    if (stopActivityWatcher && typeof stopActivityWatcher === 'function') {
      stopActivityWatcher()
    }
  })
</script>
<template>
  <v-app>
    <RouterView />
    <BaseNotification />
    <ModalDialog />
    <WelcomeTipMobile v-if="mobile" />
    <WelcomeTip v-else />
    <ReloadPromote />
    <InstallPromote />
  </v-app>
</template>

<style scoped></style>
