<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M19 12C19 12.5523 18.5523 13 18 13H14C13.4477 13 13 13.4477 13 14V18C13 18.5523 12.5523 19 12 19H11.5C10.9477 19 10.5 18.5523 10.5 18V14C10.5 13.4477 10.0523 13 9.5 13H6C5.44772 13 5 12.5523 5 12V11.5C5 10.9477 5.44772 10.5 6 10.5H9.5C10.0523 10.5 10.5 10.0523 10.5 9.5V6C10.5 5.44772 10.9477 5 11.5 5H12C12.5523 5 13 5.44772 13 6V9.5C13 10.0523 13.4477 10.5 14 10.5H18C18.5523 10.5 19 10.9477 19 11.5V12Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
