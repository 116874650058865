import { useOrderHistoryStore } from '@/stores/orderHistoryStore'
import { defineStore } from 'pinia'
import { INewOrderRequestModel, IOrderActionsModel } from '@/api/types'
import { orderService } from '@/api/orderService'
import { useDialogStore } from '@/stores/dialogStore'
import { useDictionaryStore } from '@/stores/dictionaryStore'

interface IAction {
  clearStore: () => void
  setActFromDataSet: (act: string) => void
  sendOrder: () => void
  massActionPosition: (paperIds: number[], actId: number) => void
}
interface IGetter {
  getState: (state: IState) => INewOrderRequestModel
  [key: string]: any
}
interface IState {
  data: INewOrderRequestModel
  loading: boolean
  error?: string
}
export const useOrderSaleStore = defineStore<'orderSaleStore', IState, IGetter, IAction>({
  id: 'orderSaleStore',
  state: (): IState => ({
    data: {
      paperId: 0,
      actId: 0,
      price: undefined,
      expire: undefined,
      amount: 0,
      execute: undefined,
      stopAllPositions: undefined,
      stopParameters: undefined
    },
    loading: false,
    error: undefined
  }),
  actions: {
    setActFromDataSet(act: string) {
      if (act && act.length > 0) {
        const dictionary = useDictionaryStore()
        const actDictionary: IOrderActionsModel = dictionary.orderActions.filter(
          (actions: IOrderActionsModel) => actions.name === act
        )[0]
        if (actDictionary.id) {
          this.data.actId = actDictionary.id
        }
      }
    },
    clearStore() {
      this.$reset()
    },
    async sendOrder() {
      this.loading = true
      try {
        const response = await orderService.sale(this.getState)
        if (response.status === 200) {
          const orderHistory = useOrderHistoryStore()
          orderHistory.data.unshift(response.data)
        }
      } catch (e: any) {
        console.error('sendOrder', e)
      } finally {
        this.loading = false
      }
    },
    async massActionPosition(paperIds: number[], actId: number) {
      if (paperIds.length > 0 && [1, 2].includes(actId)) {
        this.loading = true
        try {
          const response = await orderService.ordersMass(paperIds, actId)
          if (response.status === 200) {
            const dialogStore = useDialogStore()
            dialogStore.closeDialog()
          }
        } catch (e: any) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }
    }
  },
  getters: {
    getState(_: IState) {
      return this.data
    }
  }
})
