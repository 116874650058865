// @ts-ignore
export const accountMenu = {
  profileTitle: 'Profile',
  account: 'Account',
  clientInterview: 'Interview',
  security: 'Security',
  investorStatus: 'Investor status',
  investProfile: 'Invest-profile',
  exit: 'Logout'
}
