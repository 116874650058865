export const modal = {
  confirmAction: 'Подтвердите действие',
  ok: 'ОК',
  cancel: 'Отмена',
  massEffectPosition: {
    closeTitleH2: 'Закрыть позиции',
    doubleTitleH2: 'Удвоить позиции',
    closeSubTitle: 'И выйти «в деньги».',
    doubleSubTitle: 'Открыть заявки на удвоение активов.',
    account: 'по счету',
    ticker: 'Код ЦБ',
    amount: 'Всего бумаг',
    margin: 'Позиция',
    positionLong: 'Long',
    positionShort: 'Short',
    informBlock: {
      you: 'Вы',
      sell: 'продаете',
      buy: 'покупаете',
      portfolio: 'портфель по счету',
      allPrice: 'на общую сумму'
    },
    btnSubmit: 'Продать всё'
  }
}
