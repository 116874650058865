<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M5 20V18H9V16H5V14L2 17L5 20ZM11 17C11 17.71 10.85 18.39 10.58 19H22V16C22 13.79 18.42 12 14 12C13 12 12.04 12.09 11.15 12.26C10.32 12.42 9.56 12.64 8.89 12.92C10.17 13.83 11 15.32 11 17ZM20 16V17H13C13 15.96 12.77 14.97 12.36 14.08L14 14C17.31 14 20 14.9 20 16ZM14 3C11.7909 3 10 4.79086 10 7C10 9.20914 11.7909 11 14 11C16.2091 11 18 9.20914 18 7C18 4.79086 16.2091 3 14 3ZM14 5C15.1046 5 16 5.89543 16 7C16 8.10457 15.1046 9 14 9C12.8954 9 12 8.10457 12 7C12 5.89543 12.8954 5 14 5Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
