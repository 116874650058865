<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    :color="color"
  >
    <g clip-path="url(#clip0_1653_14954)">
      <path
        d="M0 6.03925V8.66659H3.752L0 6.03925ZM3.10933 20.6666H8.66667V16.7753L3.10933 20.6666ZM15.3333 16.7759V20.6666H20.89L15.3333 16.7759ZM0 15.3333V17.9606L3.75333 15.3333H0ZM20.8913 3.33325H15.3333V7.22459L20.8913 3.33325ZM24 17.9613V15.3333H20.246L24 17.9613ZM24 8.66659V6.03925L20.2473 8.66659H24ZM8.66667 3.33325H3.10933L8.66667 7.22459V3.33325Z"
        fill="#00247D"
      />
      <path
        d="M16.7608 15.3332L23.2355 19.8672C23.5517 19.5415 23.7789 19.14 23.8955 18.7012L19.0855 15.3332H16.7608ZM8.66747 15.3332H7.24014L0.766135 19.8666C1.11347 20.2199 1.5588 20.4726 2.05814 20.5899L8.66747 15.9619V15.3332ZM15.3341 8.66658H16.7615L23.2355 4.13325C22.8822 3.77476 22.4344 3.52393 21.9441 3.40991L15.3341 8.03791V8.66658ZM7.24014 8.66658L0.766135 4.13325C0.450104 4.45923 0.222678 4.86061 0.105469 5.29925L4.91547 8.66658H7.24014Z"
        fill="#CF1B2B"
      />
      <path
        d="M24 13.9999H14V20.6666H15.3333V16.7759L20.89 20.6666H21.3333C21.6875 20.6666 22.0381 20.596 22.3646 20.4587C22.6911 20.3214 22.9869 20.1204 23.2347 19.8673L16.76 15.3333H19.0847L23.8947 18.7013C23.9567 18.4766 24 18.2439 24 17.9999V17.9613L20.246 15.3333H24V13.9999ZM0 13.9999V15.3333H3.75333L0 17.9606V17.9999C0 18.7273 0.292667 19.3853 0.765333 19.8666L7.23933 15.3333H8.66667V15.9619L2.05733 20.5893C2.25333 20.6359 2.456 20.6666 2.66667 20.6666H3.10933L8.66667 16.7753V20.6666H10V13.9999H0ZM24 5.99992C24.0004 5.30124 23.7254 4.63055 23.2347 4.13325L16.7607 8.66659H15.3333V8.03792L21.9433 3.40992C21.7436 3.36094 21.5389 3.33521 21.3333 3.33325H20.8913L15.3333 7.22459V3.33325H14V9.99992H24V8.66659H20.2473L24 6.03925V5.99992ZM8.66667 3.33325V7.22459L3.10933 3.33325H2.66667C2.31242 3.3331 1.96171 3.40379 1.63519 3.54118C1.30866 3.67857 1.01291 3.87987 0.765333 4.13325L7.23933 8.66659H4.91467L0.104667 5.29925C0.0385201 5.52706 0.00331561 5.76273 0 5.99992L0 6.03925L3.752 8.66659H0V9.99992H10V3.33325H8.66667Z"
        fill="#EEEEEE"
      />
      <path
        d="M14 9.99992V3.33325H10V9.99992H0V13.9999H10V20.6666H14V13.9999H24V9.99992H14Z"
        fill="#CF1B2B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1653_14954">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
