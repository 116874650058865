import { IAccountModel, IAccountsModel, IInformerModel } from '@/api/types'
import { defineStore } from 'pinia'
import accountsService from '@/api/accountsService'

interface IState {
  data: string[]
  loading: boolean
  lastUpdated: null | number
  error?: Error
}
interface IGetter {
  isDataStale: (s: IState) => boolean
  [key: string]: any
}
interface IAction {
  load: () => Promise<void>
  clearStore: () => void
}

const cacheDuration = 10 * 60 * 1000 // 10 минут

export const useInvoiceNumbersStore = defineStore<'invoiceNumbers', IState, IGetter, IAction>({
  id: 'invoiceNumbers',
  state: (): IState => ({
    data: [],
    loading: false,
    lastUpdated: null, // время последнего обновления
    error: undefined
  }),
  persist: true,
  getters: {
    isDataStale: state => {
      return !state.lastUpdated || Date.now() - state.lastUpdated > cacheDuration
    }
  },
  actions: {
    async load() {
      if (this.loading && !this.isDataStale && this.data.length > 0) {
        return
      } else {
        try {
          this.loading = true
          const { data } = await accountsService.accounts()

          this.data = data.accounts.map((item: IAccountModel) => {
            return item.account
          })
        } catch (error: any) {
          this.error = { name: error.code, message: error.message }
        }
        this.loading = false
      }
    },
    clearStore() {
      this.$reset()
    }
  }
})
