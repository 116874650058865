<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M15 3C12.7909 3 11 4.79086 11 7C11 9.20914 12.7909 11 15 11C17.2091 11 19 9.20914 19 7C19 4.79086 17.2091 3 15 3ZM15 4.9C16.16 4.9 17.1 5.84 17.1 7C17.1 8.16 16.16 9.1 15 9.1C13.8402 9.1 12.9 8.1598 12.9 7C12.9 5.8402 13.8402 4.9 15 4.9ZM4 6V9H1V11H4V14H6V11H9V9H6V6H4ZM15 12C12.33 12 7 13.33 7 16V19H23V16C23 13.33 17.67 12 15 12ZM15 13.9C17.97 13.9 21.1 15.36 21.1 16V17.1H8.9V16C8.9 15.36 12 13.9 15 13.9Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
