<script setup lang="ts">
  import { MaskOptions } from 'maska'
  import { computed, ref, useAttrs, useSlots } from 'vue'
  import { IBaseInputProps } from '@/components/types'
  import IconError from '@/components/icons/IconError.vue'
  import { vMaska } from 'maska/vue'

  // Props
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    id = 'id-input' + Math.random(),
    nameField = 'field',
    withClass = '',
    phoneMask = false,
    currencyMask = false,
    numberMask = false,
    inputMode = 'text'
  } = defineProps<IBaseInputProps>()

  const modelValue = defineModel<string | null>()
  const inputRef = ref<HTMLInputElement | null>(null)

  defineExpose({
    focusInput: () => {
      if (inputRef.value !== null) {
        inputRef.value.focus()
      }
    }
  })

  const $slots = useSlots()

  const isMaskActive = ref(false)

  const attrs = useAttrs()

  const phoneOptions = {
    mask: '+7 (###) ###-##-##'
  }

  const optionsDefault = {
    mask: null
  }

  const numberOptions: MaskOptions = {
    mask: '#',
    eager: true,
    tokens: {
      '#': { pattern: /[0-9]/, repeated: true }
    }
  }

  const currencyOptions: MaskOptions = {
    mask: '#-#',
    eager: true,
    number: { locale: 'ru', unsigned: true, fraction: 6 },
    reversed: true
  }

  const hasError = computed(() => {
    if (Array.isArray(attrs['error-messages']) && attrs['error-messages'].length > 0) {
      return attrs['error-messages']
    }
    return null
  })

  const mask = computed(() => {
    if (isMaskActive.value && (phoneMask || currencyMask || numberMask)) {
      if (phoneMask) {
        return phoneOptions
      }
      if (currencyMask) {
        return currencyOptions
      }
      if (numberMask) {
        return numberOptions
      }
    }
    return optionsDefault
  })

  const applyMask = (event: ClipboardEvent) => {
    if (phoneMask) {
      const bufferData = event.clipboardData?.getData('text/plain')
      if (bufferData) {
        const digits = bufferData.replace(/\D/g, '')

        if (digits.length === 11) {
          modelValue.value = `+7 (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7, 9)}-${digits.slice(9, 11)}`
        } else if (digits.length === 10) {
          modelValue.value = `+7 (${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 8)}-${digits.slice(8, 10)}`
        } else if (digits.length === 12 && digits.startsWith('7')) {
          modelValue.value = `+7 (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7, 9)}-${digits.slice(9, 11)}`
        } else {
          modelValue.value = bufferData
        }
      }
    }
  }

  const check = (event: KeyboardEvent) => {
    if (modelValue.value && modelValue.value.length > 0) {
      if (phoneMask) {
        //Эта строка для комбинированого поля маски, т.е. ее может не быть (пример: поле для ввода логина или номера телефона)
        //isMaskActive.value = [107, 187, 16, 56, 55, 57].includes(event.keyCode)
        isMaskActive.value = true
      }
      if (currencyMask || numberMask) {
        isMaskActive.value = true
      }
    }
  }
</script>

<template>
  <v-text-field
    :id="nameField"
    ref="inputRef"
    v-model="modelValue"
    v-maska="mask"
    variant="outlined"
    :class="[withClass]"
    :inputmode="inputMode"
    @keyup="check"
    @paste="applyMask"
  >
    <template #append-inner>
      <IconError
        v-if="hasError"
        color="rgba(233, 30, 42, 1)"
        :class="[$slots['append-inner'] ? 'mr-4' : '']"
      />
      <slot name="append-inner"></slot>
    </template>

    <template #message="{ message }">
      <div class="b3">{{ message }}</div>
    </template>
  </v-text-field>
</template>

<style scoped lang="scss">
  .v-text-field.v-input--density-compact {
    :deep(.v-field__field .v-label.v-field-label) {
      font-size: $fontSizeBodyB2;
    }
    :deep(.v-label.v-field-label) {
      font-size: $fontSizeBodyB3;
    }
  }
  .v-text-field.v-input--disabled {
    :deep(.v-field--active .v-field__field .v-label.v-field-label) {
      font-size: $fontSizeBodyB2;
      font-style: normal;
      font-weight: 600;
      opacity: 1;
    }
    :deep(.v-field--active .v-label.v-field-label) {
      font-size: $fontSizeBodyB2;
      font-style: normal;
      font-weight: 600;
      opacity: 1;
    }
  }
</style>
