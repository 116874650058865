<script setup lang="ts">
  import { IInformerModel, IInformersModel } from '@/api/types'
  import { InformerTabs, IInformerTabs } from '@/components/types'
  import { onMounted, ref, computed, onUnmounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useInformerStore } from '@/stores/informerStore'
  import { formatCurrency } from '@/utils/number.extensions'

  const loading = ref(true)
  const tab = ref<InformerTabs>('stock')
  const { t } = useI18n()
  const informerStore = useInformerStore()
  const w = window

  const tabs = ref<IInformerTabs[]>([
    {
      name: 'indexes' as const,
      title: t('informer.tabs.indexes')
    },
    {
      name: 'stock' as const,
      title: t('informer.tabs.stock')
    },
    {
      name: 'currencies' as const,
      title: t('informer.tabs.currencies')
    }
  ])

  const informer = computed<IInformersModel>(() => informerStore.informers)

  onMounted(async () => {
    loading.value = true
    await informerStore.load()
    loading.value = false
    await informerStore.startAutoUpdate(1600)
  })

  onUnmounted(() => {
    informerStore.stopAutoUpdate()
  })
</script>

<template>
  <v-skeleton-loader
    v-if="loading"
    class="w-100"
    elevation="0"
    height="200px"
    type="chip, list-item, image"
  ></v-skeleton-loader>
  <v-sheet v-else id="tip6" class="position-relative w-100">
    <v-tabs v-model="tab" active-color="grey-darken-4" hide-slider density="comfortable">
      <v-tab
        v-for="(tb, key) in tabs"
        :key="key"
        :value="tb.name"
        :text="tb.title"
        class="rounded-tt-lg text-none"
        selected-class="selected"
        hide-slider
        density="compact"
      />
    </v-tabs>
    <v-tabs-window v-model="tab">
      <v-tabs-window-item
        v-for="(tb, key) in tabs"
        :key="key"
        :value="tb.name"
        transition="expand-transition"
        reverse-transition="expand-transition"
      >
        <v-sheet
          color="grey-lighten-4 instruments-tables rounded-bb-lg px-4 py-7 ga-4 d-flex flex-column"
        >
          <v-row
            v-for="(item, index) in informer[tb.name]"
            :key="index"
            class="table-row align-center ma-0 justify-space-around"
          >
            <v-col cols="2" class="d-flex justify-start pa-0">
              <v-sheet class="table-cell bg-transparent" width="24">
                <v-img
                  max-width="24"
                  max-height="24"
                  rounded="lg"
                  :src="`${w.env?.VITE_STATIC_BASE_URL || 'https://static.lk.ricom.ru'}/media/logo/instruments/${item.paperId}.png`"
                  lazy-src="/img/no-img.png"
                  :alt="item.ticker"
                />
              </v-sheet>
            </v-col>
            <v-col cols="3" class="d-flex justify-start pa-0">
              <v-sheet class="bg-transparent body-b1-bold">{{ item.ticker }}</v-sheet>
            </v-col>
            <v-col cols="4" class="d-flex justify-end pa-0 pr-2">
              <v-sheet class="bg-transparent body-b2">
                {{ tb.name === 'indexes' ? item.price : formatCurrency(item.price, '₽') }}
              </v-sheet>
            </v-col>
            <v-col cols="3" class="d-flex justify-end pa-0 pr-1">
              <v-sheet
                :class="[
                  'bg-transparent body-b2 font-weight-bold',
                  item.totalProfitPercent > 0 ? 'text-green' : 'text-red'
                ]"
              >
                {{
                  item.totalProfitPercent > 0
                    ? '+' + formatCurrency(item.totalProfitPercent, '%')
                    : formatCurrency(item.totalProfitPercent, '%')
                }}
              </v-sheet>
            </v-col>
          </v-row>
        </v-sheet>
      </v-tabs-window-item>
    </v-tabs-window>
  </v-sheet>
</template>

<style scoped lang="scss">
  .selected {
    background-color: #f5f5f5;
  }

  .v-col {
    align-items: center;
    height: 100%;
  }
</style>
