<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <rect width="40" height="40" rx="8" fill="currentColor" :fill-opacity="fillOpacity" />
    <path
      d="M15.0377 23.4238L14.6848 28.2633C15.1897 28.2633 15.4083 28.0518 15.6706 27.7979L18.0377 25.5922L22.9427 29.0944C23.8423 29.5832 24.4761 29.3258 24.7188 28.2875L27.9384 13.5785L27.9393 13.5777C28.2246 12.2811 27.4584 11.7742 26.5819 12.0922L7.65703 19.1564C6.36544 19.6452 6.385 20.3472 7.43747 20.6652L12.2758 22.1325L23.5143 15.2763C24.0432 14.9349 24.5241 15.1238 24.1285 15.4653L15.0377 23.4238Z"
      fill="white"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
