<script setup lang="ts">
  import IconStarFavorites from '@/components/icons/IconStarFavorites.vue'
  import { useTradingStore } from '@/stores/tradingStore'
  import { useI18n } from 'vue-i18n'
  import { IFavoriteStarProps } from '@/components/types'
  import IconStar from '@/components/icons/IconStar.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import IconDelete from '@/components/icons/IconDelete.vue'
  import IconClose from '@/components/icons/IconClose.vue'

  const tradingStore = useTradingStore()

  const { t } = useI18n()

  const props = withDefaults(defineProps<IFavoriteStarProps>(), {
    isFavorite: true,
    needTitle: false,
    isNeedConfirmation: true,
    iconLeft: false
  })

  const emits = defineEmits(['update'])

  async function handleDialogConfirm() {
    await tradingStore.deleteFromFavoritePosition(props.id)
    await tradingStore.load()
    emits('update')
  }

  const handleAddToFavorite = async (id: number) => {
    await tradingStore.addToFavorite({
      paperId: id
    })

    await tradingStore.load()
    emits('update')
  }
</script>

<template>
  <v-sheet v-if="isFavorite && needTitle && !iconLeft" class="body-b3">
    {{ t('favoriteTitle') }}
  </v-sheet>
  <v-sheet v-if="isFavorite" class="bg-transparent">
    <v-btn
      v-if="isNeedConfirmation"
      class="pa-0"
      height="32px"
      variant="text"
      density="compact"
      :ripple="false"
      icon
    >
      <IconStarFavorites />
      <v-dialog activator="parent" max-width="480" class="confirm-dialog">
        <template #default="{ isActive }">
          <v-card class="pa-6 rounded-lg dialog-content">
            <v-img role="img" class="btn-close" @click="isActive.value = false">
              <IconClose />
            </v-img>
            <v-card-text class="pa-0">
              <v-sheet class="d-flex justify-space-between align-center ga-6 body-b1">
                {{ t('trading.favoriteInstrument.deleteMsg', { name: name }) }}
              </v-sheet>
              <v-sheet class="flex-1-0 d-flex justify-start ga-6 mt-6">
                <BaseButton
                  type="button"
                  :label="t('trading.favoriteInstrument.deleteBtnText')"
                  @click="handleDialogConfirm"
                >
                  <template #[`icon`]>
                    <IconDelete color="rgb(255,255,255)" />
                  </template>
                </BaseButton>
                <BaseButton
                  type="button"
                  label="Отмена"
                  variant="outlined"
                  @click="isActive.value = false"
                />
              </v-sheet>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </v-btn>
    <v-btn
      v-else
      class="pa-0"
      height="32px"
      variant="text"
      density="compact"
      :ripple="false"
      icon
      @click.stop="handleDialogConfirm"
    >
      <IconStarFavorites />
    </v-btn>
  </v-sheet>
  <v-btn
    v-else
    variant="text"
    density="compact"
    :ripple="false"
    icon
    @click.stop="handleAddToFavorite(id)"
  >
    <IconStar />
  </v-btn>
  <v-sheet v-if="isFavorite && needTitle && iconLeft" class="body-b3">
    {{ t('favoriteTitle') }}
  </v-sheet>
</template>

<style scoped lang="scss">
  .btn-close {
    position: fixed;
    right: -24px;
    top: 0;
    background-color: white;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    padding: 12px;
  }

  .btn-close:hover {
    cursor: pointer;
  }

  .v-dialog.confirm-dialog {
    :deep(.v-overlay__content) {
      margin: 0;
    }
    :deep(.dialog-content) {
      border-radius: 16px 0 16px 16px !important;
    }
  }
</style>
