<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M9 13C10.1 13 11 13.89 11 15C11 16.11 10.11 17 9 17C7.89 17 7 16.11 7 15C7 13.89 7.9 13 9 13ZM17 1C14.24 1 12 3.24 12 6V8H3C1.9 8 1 8.9 1 10V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20V10C17 8.9 16.1 8 15 8H14V6C14 4.34 15.34 3 17 3C18.66 3 20 4.34 20 6V8H22V6C22 3.24 19.76 1 17 1ZM15 10V20H3V10H15Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
