<script setup lang="ts">
  import IconChevronLeft from '@/components/icons/IconChevronLeft.vue'
  import { useDialogStore } from '@/stores/dialogStore'
  import { useI18n } from 'vue-i18n'
  import { useDisplay } from 'vuetify'

  const dialogStore = useDialogStore()
  const { t } = useI18n()
  const display = useDisplay()

  const openDialog = (reportTitle: string, typeReport: string = 'broker') => {
    dialogStore.openDialog('ReportPeriod', {
      width: display.mobile.value ? '100%' : '768px',
      height: display.mobile.value ? '100%' : 'auto',
      maxWidth: display.mobile.value ? '400px' : '',
      class: 'd-flex flex-column' + !display.mobile.value ? ' align-center' : '',
      reportTitle: reportTitle,
      typeReport: typeReport
    })
  }
</script>

<template>
  <v-sheet v-if="!display.mobile.value" class="d-flex">
    <v-sheet class="align-center">
      <IconChevronLeft />
    </v-sheet>
    <v-sheet>
      <RouterLink :to="{ name: 'services' }" class="text-decoration-none">
        {{ t('services.title') }}
      </RouterLink>
    </v-sheet>
  </v-sheet>
  <v-sheet class="d-flex" :class="{ 'mx-4': display.mobile.value }">
    <v-sheet :class="{ 'w-100': display.mobile.value }">
      <v-sheet v-if="display.mobile.value" class="d-flex align-center mb-8">
        <v-sheet class="align-center" height="24">
          <IconChevronLeft />
        </v-sheet>
        <v-sheet>
          <RouterLink :to="{ name: 'services' }" class="text-decoration-none title-h1">
            {{ t('services.reports') }}
          </RouterLink>
        </v-sheet>
      </v-sheet>
      <v-sheet v-else class="title-h1 pb-8">{{ t('services.reports') }}</v-sheet>

      <v-sheet class="d-flex flex-column ga-6">
        <v-card
          class="elevation-04-dp pa-4 rounded-lg"
          max-width="496"
          hover
          @click="openDialog(t('services.reportBrokerTitle'))"
        >
          <v-sheet>
            <v-sheet class="d-flex flex-column align-start">
              <v-sheet class="title-h2">{{ t('services.reportBrokerTitle') }}</v-sheet>
              <v-sheet class="body-b2">{{ t('services.descriptionReportBroker') }}</v-sheet>
            </v-sheet>
          </v-sheet>
        </v-card>
        <v-card
          class="elevation-04-dp pa-4 rounded-lg"
          max-width="496"
          hover
          @click="openDialog(t('services.reportDepoTitle'), 'depo')"
        >
          <v-sheet class="d-flex flex-column align-start">
            <v-sheet class="title-h2">{{ t('services.reportDepoTitle') }}</v-sheet>
            <v-sheet class="body-b2">{{ t('services.descriptionReportDepo') }}</v-sheet>
          </v-sheet>
        </v-card>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss">
  .v-card--hover:hover {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: transparent;
  }

  .v-card {
    :deep(.body-b2) {
      color: var(--color-BlackActive);
      opacity: var(--v-card-subtitle-opacity, var(--v-medium-emphasis-opacity));
    }
  }

  .text-decoration-none {
    :deep(a[href], a[href]:visited) {
      color: var(--color-BlackActive);
    }
  }
</style>
