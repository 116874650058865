<script setup lang="ts">
  import PhoneNumber from '@/components/Registration/Step1/PhoneNumber.vue'
  import { useRegistrationStore } from '@/stores/registrationStore'
  import SMSCode from '@/components/Registration/Step1/SMSCode.vue'
  import PersonalDataForm from '@/components/Registration/Step2/PersonalDataForm.vue'
  import CheckDocuments from '@/components/Registration/Step3/CheckDocuments.vue'
  import { useDisplay } from 'vuetify'
  import { useI18n } from 'vue-i18n'

  const registrationStore = useRegistrationStore()
  const display = useDisplay()

  const { t } = useI18n()
</script>

<template>
  <v-stepper
    v-model="registrationStore.step"
    hide-actions
    alt-labels
    flat
    :mobile="display.mobile.value"
    height="100%"
    width="100%"
  >
    <v-sheet
      v-if="display.mobile.value && registrationStore.step !== '1'"
      class="position-absolute step-divider step-divider-left"
    ></v-sheet>
    <v-sheet
      v-if="display.mobile.value && registrationStore.step !== '3'"
      class="position-absolute step-divider step-divider-right"
    ></v-sheet>
    <v-stepper-header :class="{ 'overflow-hidden': display.mobile.value }">
      <v-stepper-item
        value="1"
        :class="{
          'm-width-400': display.mobile.value,
          'd-none': display.mobile.value && registrationStore.step !== '1'
        }"
      >
        <template #icon="{ step }">
          <v-sheet v-if="display.mobile.value">
            <span style="color: var(--color-SuccessNormal)">{{ step }}</span>
            <span style="color: var(--color-BlackDisabled)" class="body-b2">&nbsp;из 3</span>
          </v-sheet>
          <v-sheet v-else class="step-title">{{ step }}</v-sheet>
        </template>
        <v-sheet class="title-h3 step-title mb-6">
          {{ t('registration.steps.title1') }}
        </v-sheet>
        <v-sheet v-if="!display.mobile.value" class="body-b2 step-subtitle">
          {{ t('registration.steps.description1') }}
        </v-sheet>
      </v-stepper-item>

      <v-stepper-item
        value="2"
        :class="{
          'm-width-400': display.mobile.value,
          'd-none': display.mobile.value && registrationStore.step !== '2'
        }"
      >
        <template #icon="{ step }">
          <v-sheet v-if="display.mobile.value">
            <span style="color: var(--color-SuccessNormal)">{{ step }}</span>
            <span style="color: var(--color-BlackDisabled)" class="body-b2">&nbsp;из 3</span>
          </v-sheet>
          <v-sheet v-else class="step-title">{{ step }}</v-sheet>
        </template>
        <v-sheet class="title-h3 step-title mb-6">
          {{ t('registration.steps.title2') }}
        </v-sheet>
        <v-sheet v-if="!display.mobile.value" class="body-b2">
          {{ t('registration.steps.description2') }}
        </v-sheet>
      </v-stepper-item>

      <v-stepper-item
        value="3"
        :class="{
          'm-width-400': display.mobile.value,
          'd-none': display.mobile.value && registrationStore.step !== '3'
        }"
      >
        <template #icon="{ step }">
          <v-sheet v-if="display.mobile.value">
            <span style="color: var(--color-SuccessNormal)">{{ step }}</span>
            <span style="color: var(--color-BlackDisabled)" class="body-b2">&nbsp;из 3</span>
          </v-sheet>
          <v-sheet v-else class="step-title">{{ step }}</v-sheet>
        </template>
        <v-sheet class="title-h3 step-title mb-6">
          {{ t('registration.steps.title3') }}
        </v-sheet>
      </v-stepper-item>
    </v-stepper-header>

    <v-stepper-window
      class="d-flex justify-center ma-0 pa-6 mb-6"
      :class="{ 'elevation-40-dp rounded-2lg': display.mobile.value }"
    >
      <v-stepper-window-item value="1">
        <PhoneNumber v-if="!registrationStore.isSmsSend" />
        <SMSCode v-else />
      </v-stepper-window-item>
      <v-stepper-window-item value="2" class="registration-step-2">
        <PersonalDataForm />
      </v-stepper-window-item>
      <v-stepper-window-item value="3"><CheckDocuments /></v-stepper-window-item>
    </v-stepper-window>
  </v-stepper>
</template>

<style scoped lang="scss">
  .main-menu {
    border-bottom: 1px solid var(--color-BlackFocus);
    box-sizing: border-box;
    background: var(--color-WhiteWhite);
    backdrop-filter: blur(50px);
    z-index: 2004 !important;
    :deep(.v-toolbar__content) {
      padding: 1rem 1.5rem;
      justify-content: space-between;
      max-width: 1280px;
    }
    :deep(.v-toolbar__prepend) {
      margin: 0;
    }
    :deep(.v-toolbar__append) {
      margin: 0;
    }
  }
  .v-stepper-header {
    box-shadow: none !important;
    justify-content: center;
  }

  .v-stepper--alt-labels .v-stepper-item {
    flex-basis: fit-content;
  }
  .step-title {
    color: var(--color-BlackPressed);
  }

  .step-subtitle {
    color: var(--color-BlackInactive);
  }

  .v-stepper-item--selected {
    .step-title {
      color: var(--color-SuccessNormal);
    }
    .step-subtitle {
      color: var(--color-BlackInactive);
    }
  }

  .m-width-400 {
    min-width: 400px;
  }

  .step-divider {
    width: 414px;
    top: 134px;
    border-bottom: dotted 4px var(--color-SuccessNormal);
  }
  .step-divider-right {
    right: 0;
  }

  .step-divider-left {
    left: 0;
  }

  .v-stepper.v-sheet {
    overflow: visible;
    width: -webkit-fill-available;
    max-width: fit-content;
  }

  @media screen and (max-width: 400px) {
    .step-divider {
      width: 134px;
    }
  }

  @media (min-width: 361px) and (max-width: 400px) {
    .step-divider {
      width: 150px;
    }
  }

  @media (min-width: 401px) and (max-width: 539px) {
    .step-divider {
      width: 164px;
    }
  }

  @media (min-width: 540px) and (max-width: 600px) {
    .step-divider {
      width: 224px;
    }
  }

  @media (min-width: 601px) and (max-width: 768px) {
    .step-divider {
      width: 338px;
    }
  }

  @media (min-width: 769px) and (max-width: 820px) {
    .step-divider {
      width: 354px;
    }
  }
</style>
