<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M22 18H17C16.4477 18 16 17.5523 16 17V7C16 6.44772 16.4477 6 17 6H22C22.5523 6 23 6.44772 23 7V17C23 17.5523 22.5523 18 22 18ZM22 8H17V9H22V8ZM22 10H17V11H22V10ZM9 15V17H10V18H5V17H6V15H2C1.44772 15 1 14.5523 1 14V7C1 6.44772 1.44772 6 2 6H13C13.5523 6 14 6.44772 14 7V14C14 14.5523 13.5523 15 13 15H9ZM12 8H3V13H12V8Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
