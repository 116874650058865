// store/cookieConsent.ts
import { defineStore } from 'pinia'

interface IState {
  cancelGiven: boolean
  lastUpdated: null | number
}
interface IGetter {
  isDataStale: (s: IState) => boolean
  [key: string]: any
}
interface IAction {
  cancelInstall: () => void
  reset: () => void
}
const cacheDuration = 14 * 24 * 60 * 60 * 1000 // 14 дней

export const useCancelInstallAppStore = defineStore<'cancelInstallApp', IState, IGetter, IAction>({
  id: 'cancelInstallApp',
  state: (): IState => ({
    cancelGiven: localStorage.getItem('cancelInstallApp') === 'true',
    lastUpdated: null // время последнего обновления
  }),
  actions: {
    cancelInstall() {
      localStorage.setItem('cancelInstallApp', 'true')
      this.cancelGiven = true
      this.lastUpdated = Date.now()
    },
    reset() {
      localStorage.setItem('cancelInstallApp', 'false')
      this.$reset()
    }
  },
  getters: {
    isDataStale: state => {
      return !state.lastUpdated || Date.now() - state.lastUpdated > cacheDuration
    }
  }
})
