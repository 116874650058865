import { AxiosResponse } from 'axios'
import { IFaqModel, IPromoModel } from '@/api/types'
import httpCommunicator from '@/api/httpCommunicator'

const staticService = {
  async articles() {
    return httpCommunicator.get('/promo/ads/faq').then((response: AxiosResponse<IFaqModel[]>) => {
      return response.data
    })
  },
  async promo() {
    return httpCommunicator
      .get('/promo/ads/promo')
      .then((response: AxiosResponse<IPromoModel[]>) => {
        return response.data
      })
  },
  async article(id: number) {
    return httpCommunicator.get(`/promo/ads/faq/${id}`).then(response => {
      return response.data
    })
  }
}
export default staticService
