<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M2 4.26995L3.28 2.99995L20 19.7199L18.73 20.9999L13.9 16.1699L11.29 18.7799C9.34 20.7299 6.17 20.7299 4.22 18.7799C2.27 16.8299 2.27 13.6599 4.22 11.7099L5.71 10.2199C5.7 11.0399 5.83 11.8599 6.11 12.6499L5.64 13.1199C4.46 14.2899 4.46 16.1899 5.64 17.3599C6.81 18.5399 8.71 18.5399 9.88 17.3599L12.5 14.7599L10.88 13.1499C10.87 13.3899 10.77 13.6399 10.59 13.8299C10.2 14.2199 9.56 14.2199 9.17 13.8299C8.12 12.7699 7.63 11.3699 7.72 9.99995L2 4.26995ZM12.71 3.21995C14.66 1.26995 17.83 1.26995 19.78 3.21995C21.73 5.16995 21.73 8.33995 19.78 10.2899L18.29 11.7799C18.3 10.9599 18.17 10.1399 17.89 9.35995L18.36 8.87995C19.54 7.70995 19.54 5.80995 18.36 4.63995C17.19 3.45995 15.29 3.45995 14.12 4.63995L10.79 7.96995L9.38 6.54995L12.71 3.21995ZM13.41 8.16995C13.8 7.77995 14.44 7.77995 14.83 8.16995C16.2 9.53995 16.61 11.4999 16.06 13.2299L14.28 11.4599C14.23 10.7799 13.94 10.1099 13.41 9.58995C13 9.19995 13 8.55995 13.41 8.16995Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
