import { defineStore } from 'pinia'
import type { TabType } from '@/components/types'
import { INewOrderRequestModel } from '@/api/types'
import IColumnSettingStateModel, { IHeader } from '@/components/types/ColumnsSettings'
import { VDataTableHeaders } from 'vuetify/components/VDataTable'

// @ts-ignore
const initialHeadersPortfolio: IHeader[] = [
  {
    title: 'ID',
    key: 'paperId',
    sortable: false,
    align: 'center' as const
  },
  {
    title: 'portfolio.table.columns.name',
    align: 'center' as const,
    key: 'ticker',
    headerProps: {
      isBold: true,
      cellAlign: 'start' as const
    },
    sortable: false
  },
  {
    title: 'portfolio.table.columns.total',
    align: 'center' as const,
    key: 'total',
    sortable: false,
    headerProps: {
      cellAlign: 'end' as const
    }
  },
  {
    title: 'portfolio.table.columns.buyDate',
    align: 'center' as const,
    key: 'buyDate',
    sortable: false,
    headerProps: {
      class: 'body-b2',
      cellAlign: 'end' as const
    }
  },
  {
    title: 'portfolio.table.columns.buyPrice',
    align: 'center' as const,
    key: 'buyPrice',
    sortable: false,
    headerProps: {
      cellAlign: 'end' as const
    }
  },
  {
    title: 'portfolio.table.columns.nowPrice',
    align: 'center' as const,
    key: 'nowPrice',
    sortable: false,
    headerProps: {
      cellAlign: 'end' as const,
      isBold: true
    }
  },
  {
    title: 'portfolio.table.columns.totalPrice',
    align: 'end' as const,
    key: 'totalPrice',
    sortable: false,
    headerProps: {
      cellAlign: 'end' as const
    }
  },
  {
    title: 'portfolio.table.columns.totalPriceNow',
    align: 'end' as const,
    key: 'totalPriceNow',
    sortable: false,
    headerProps: {
      cellAlign: 'end' as const,
      isBold: true
    }
  },
  {
    title: 'portfolio.table.columns.changesCurrency',
    align: 'end' as const,
    key: 'changesCurrency',
    sortable: false,
    headerProps: {
      cellAlign: 'end' as const
    }
  },
  {
    title: 'portfolio.table.columns.changesPercent',
    align: 'end' as const,
    key: 'changesPercent',
    sortable: false,
    headerProps: {
      cellAlign: 'end' as const,
      isBold: true,
      color: 'var(--color-AdditionalGreen)'
    }
  }
]

const initialHeadersTrading: IHeader[] = [
  {
    title: 'ID',
    key: 'paperId',
    sortable: false,
    align: 'center' as const
  },
  {
    title: 'trading.table.columns.name',
    align: 'start' as const,
    key: 'ticker',
    headerProps: {
      isBold: true,
      cellAlign: 'start' as const
    },
    sortable: false
  },
  {
    title: 'trading.table.columns.title',
    align: 'start' as const,
    key: 'name',
    sortable: false,
    headerProps: {
      cellAlign: 'start' as const
    }
  },
  {
    title: 'trading.table.columns.price',
    align: 'end' as const,
    key: 'price',
    sortable: false,
    headerProps: {
      class: 'body-b2',
      cellAlign: 'end' as const,
      isBold: true
    }
  },
  {
    title: 'trading.table.columns.lot',
    align: 'center' as const,
    key: 'lot',
    sortable: false,
    headerProps: {
      cellAlign: 'center' as const
    }
  },
  {
    title: 'trading.table.columns.marginal',
    align: 'center' as const,
    key: 'marginal',
    sortable: false,
    headerProps: {
      cellAlign: 'center' as const
    }
  },
  {
    title: 'portfolio.table.columns.changesCurrency',
    align: 'end' as const,
    key: 'changesCurrency',
    sortable: false,
    headerProps: {
      cellAlign: 'end' as const
    }
  },
  {
    title: 'portfolio.table.columns.changesPercent',
    align: 'end' as const,
    key: 'changesPercent',
    sortable: false,
    headerProps: {
      cellAlign: 'end' as const,
      isBold: true,
      color: 'var(--color-AdditionalGreen)'
    }
  },
  {
    title: '',
    align: 'end' as const,
    key: 'custom',
    sortable: false,
    headerProps: {
      cellAlign: 'center' as const
    }
  }
]

interface IAction {
  addToSelectedColumns: (caseName: TabType, columnName: string) => void
  deleteFromAccessibleColumns: (caseName: TabType, columnName: string) => void
  addToAccessibleColumns: (caseName: TabType, columnName: string) => void
  deleteFromSelectedColumns: (caseName: TabType, columnName: string) => void
  apply: (caseName: TabType) => void
  disabledLeft: (caseName: TabType, columnName: string) => boolean
  disabledRight: (caseName: TabType, columnName: string) => boolean
}

interface IGetter {
  [key: string]: any
}

interface IState {
  cases: IColumnSettingStateModel
}

export const useTableColumnsStore = defineStore<'tableColumns', IState, IGetter, IAction>({
  id: 'tableColumns',
  state: (): IState => ({
    cases: {
      common: {
        accessibleColumns: [],
        selectedColumns: [
          'portfolio.table.columns.name',
          'portfolio.table.columns.total',
          'portfolio.table.columns.buyDate',
          'portfolio.table.columns.buyPrice',
          'portfolio.table.columns.totalPrice',
          'portfolio.table.columns.totalPriceNow',
          'portfolio.table.columns.changesCurrency',
          'portfolio.table.columns.changesPercent'
        ],
        headers: initialHeadersPortfolio
      },
      action: {
        accessibleColumns: [],
        selectedColumns: [
          'portfolio.table.columns.name',
          'portfolio.table.columns.total',
          'portfolio.table.columns.buyDate',
          'portfolio.table.columns.buyPrice',
          'portfolio.table.columns.totalPrice',
          'portfolio.table.columns.totalPriceNow',
          'portfolio.table.columns.changesCurrency',
          'portfolio.table.columns.changesPercent'
        ],
        headers: initialHeadersPortfolio
      },
      derivative: {
        accessibleColumns: [],
        selectedColumns: [
          'portfolio.table.columns.name',
          'portfolio.table.columns.total',
          'portfolio.table.columns.buyDate',
          'portfolio.table.columns.buyPrice',
          'portfolio.table.columns.totalPrice',
          'portfolio.table.columns.totalPriceNow',
          'portfolio.table.columns.changesCurrency',
          'portfolio.table.columns.changesPercent'
        ],
        headers: initialHeadersPortfolio
      },
      dolgovyi: {
        accessibleColumns: [],
        selectedColumns: [
          'portfolio.table.columns.name',
          'portfolio.table.columns.total',
          'portfolio.table.columns.buyDate',
          'portfolio.table.columns.buyPrice',
          'portfolio.table.columns.totalPrice',
          'portfolio.table.columns.totalPriceNow',
          'portfolio.table.columns.changesCurrency',
          'portfolio.table.columns.changesPercent'
        ],
        headers: initialHeadersPortfolio
      },
      pai: {
        accessibleColumns: [],
        selectedColumns: [
          'portfolio.table.columns.name',
          'portfolio.table.columns.total',
          'portfolio.table.columns.buyDate',
          'portfolio.table.columns.buyPrice',
          'portfolio.table.columns.totalPrice',
          'portfolio.table.columns.totalPriceNow',
          'portfolio.table.columns.changesCurrency',
          'portfolio.table.columns.changesPercent'
        ],
        headers: initialHeadersPortfolio
      },
      currency: {
        accessibleColumns: [],
        selectedColumns: [
          'portfolio.table.columns.name',
          'portfolio.table.columns.total',
          'portfolio.table.columns.buyDate',
          'portfolio.table.columns.buyPrice',
          'portfolio.table.columns.totalPrice',
          'portfolio.table.columns.totalPriceNow',
          'portfolio.table.columns.changesCurrency',
          'portfolio.table.columns.changesPercent'
        ],
        headers: initialHeadersPortfolio
      },
      autoprofile: {
        accessibleColumns: [],
        selectedColumns: [
          'portfolio.table.columns.name',
          'portfolio.table.columns.total',
          'portfolio.table.columns.buyDate',
          'portfolio.table.columns.buyPrice',
          'portfolio.table.columns.totalPrice',
          'portfolio.table.columns.totalPriceNow',
          'portfolio.table.columns.changesCurrency',
          'portfolio.table.columns.changesPercent'
        ],
        headers: initialHeadersPortfolio
      },
      favorites: {
        accessibleColumns: [],
        selectedColumns: [
          'trading.table.columns.name',
          'trading.table.columns.title',
          'trading.table.columns.price',
          'trading.table.columns.lot',
          'trading.table.columns.marginal',
          'portfolio.table.columns.changesCurrency',
          'portfolio.table.columns.changesPercent'
        ],
        headers: initialHeadersTrading
      }
    }
  }),
  persist: true,
  actions: {
    addToSelectedColumns(caseName: TabType, columnName: string) {
      // @ts-ignore
      console.log(columnName)
      this.cases[caseName].selectedColumns.push(columnName)
      this.deleteFromAccessibleColumns(caseName, columnName)
    },
    deleteFromAccessibleColumns(caseName: TabType, columnName: string) {
      // @ts-ignore
      const idx = this.cases[caseName].accessibleColumns.indexOf(columnName)

      // @ts-ignore
      this.cases[caseName].accessibleColumns.splice(idx, 1)
    },
    addToAccessibleColumns(caseName: TabType, columnName: string) {
      console.log(columnName)
      // @ts-ignore
      this.cases[caseName].accessibleColumns.push(columnName)
      this.deleteFromSelectedColumns(caseName, columnName)
    },
    deleteFromSelectedColumns(caseName: TabType, columnName: string) {
      // @ts-ignore
      const idx = this.cases[caseName].selectedColumns.indexOf(columnName)

      // @ts-ignore
      this.cases[caseName].selectedColumns.splice(idx, 1)
    },
    apply(caseName: TabType) {
      if (this.cases[caseName].selectedColumns.length) {
        const headers = caseName === 'favorites' ? initialHeadersTrading : initialHeadersPortfolio
        // @ts-ignore
        this.cases[caseName].headers = headers.filter(item => {
          if (item.key === 'paperId') {
            return true
          }
          const idx = (this.cases[caseName].selectedColumns as string[]).indexOf(item.title)

          return idx !== -1
        })
      }
    },
    disabledLeft(caseName: TabType, columnName: string) {
      const idx = this.cases[caseName].accessibleColumns.find(item => item === columnName)
      return !!idx
    },
    disabledRight(caseName: TabType, columnName: string) {
      const idx = this.cases[caseName].selectedColumns.find(item => item === columnName)
      return !!idx
    }
  },
  getters: {}
})
