<script setup lang="ts">
  import { useRegisterSW } from 'virtual:pwa-register/vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import IconRefresh from '@/components/icons/IconRefresh.vue'
  import { computed } from 'vue'

  const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swUrl, r) {
      r &&
        setInterval(async () => {
          const scriptSrc = '/env.js'
          // Находим старый скрипт
          const oldScript = document.querySelector(`script[src="${scriptSrc}"]`)
          if (oldScript) {
            oldScript.remove() // Удаляем старый скрипт
          }
          // Создаем новый скрипт
          const newScript = document.createElement('script')
          newScript.src = scriptSrc
          newScript.async = true
          // Добавляем новый скрипт в head
          document.head.appendChild(newScript)
          if (!(!r.installing && navigator)) {
            return
          }

          if ('connection' in navigator && !navigator.onLine) {
            return
          }

          if (r && r.waiting) {
            console.log('New Service Worker is waiting.')
            r.waiting.postMessage({ type: 'SKIP_WAITING' })
          } else if (r && r.installing) {
            console.log('New Service Worker is installing.')
          } else if (r && r.active) {
            console.log('Service Worker is active.')
          }

          await r.update()
          // const resp = await fetch(swUrl, {
          //   cache: 'no-store',
          //   headers: {
          //     cache: 'no-store',
          //     'cache-control': 'no-cache'
          //   }
          // })
          //
          // if (resp?.status === 200) {
          //   await r.update()
          // }
        }, 20000 /* 20s for testing purposes */)
    }
  })

  const version = computed(() => window.env.APP_VERSION)

  async function reload() {
    try {
      console.log('Attempting to update Service Worker...')
      await updateServiceWorker(true)
      console.log('Service Worker updated successfully.')
    } catch (err) {
      console.error('Error updating Service Worker:', err)
    }
  }
</script>

<template>
  <v-dialog
    v-model="needRefresh"
    location="top start"
    persistent
    absolute
    transition="dialog-top-transition"
    :scrim="!1"
    content-class="w-auto"
  >
    <v-card max-width="600px" rounded="lg">
      <v-sheet class="pa-6 d-flex ga-4">
        <v-card-text class="pa-0 body-b1" style="line-height: 24px">
          <v-sheet>
            Доступна новая версия приложения.
            <strong>{{ version }}</strong>
            Необходимо обновиться
          </v-sheet>
        </v-card-text>
        <v-card-actions class="pa-0 justify-end">
          <BaseButton label="Обновить" @click="reload">
            <template #icon>
              <IconRefresh color="white" />
            </template>
          </BaseButton>
        </v-card-actions>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
