import httpCommunicator from '@/api/httpCommunicator'
import type { AxiosResponse } from 'axios'
import { IAccountInfoModel, IAccountsModel, IBankModel, IPortfolioModel } from '@/api/types'

const accountsService = {
  async accounts(): Promise<AxiosResponse<IAccountsModel, unknown>> {
    return httpCommunicator.get('/accounts')
  },
  async portfolio() {
    return httpCommunicator
      .get('/accounts/portfolio')
      .then((response: AxiosResponse<IPortfolioModel>) => {
        return response.data
      })
  },

  async info() {
    return httpCommunicator
      .get('/accounts/info')
      .then((response: AxiosResponse<IAccountInfoModel>) => {
        return response.data
      })
  },

  async banks() {
    return httpCommunicator.get('/accounts/banks').then((response: AxiosResponse<IBankModel[]>) => {
      return response.data
    })
  }
}
export default accountsService
