<script setup lang="ts">
  import { computed, toRefs, useAttrs, ref } from 'vue'
  import { IBaseSelect } from '@/components/types'
  import IconError from '@/components/icons/IconError.vue'
  import IconChevronDown from '@/components/icons/IconChevronDown.vue'
  import IconCancel from '@/components/icons/IconCancel.vue'

  // Props
  const p = withDefaults(defineProps<IBaseSelect>(), {
    id: 'id-input' + Math.random(),
    nameField: 'field',
    withClass: ''
  })

  const attrs = useAttrs()
  const isHovered = ref(false)

  // Emit event for v-model
  const emit = defineEmits(['update:modelValue', 'change'])

  // Bind the modelValue to the input's value
  const { modelValue } = toRefs(p)

  const inputValue = computed({
    get: () => modelValue?.value,
    set: value => {
      emit('update:modelValue', value)
      emit('change')
    }
  })

  const className = computed(() => (p.withClass === '' ? 'w-25 mt-2' : p.withClass))
</script>

<template>
  <v-select
    :id="nameField"
    :ref="nameField"
    v-model="inputValue"
    variant="outlined"
    :class="className"
    :menu-icon="attrs['clearable'] && isHovered && inputValue ? undefined : IconChevronDown"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <template #clear="{ props }">
      <IconCancel
        v-if="attrs['clearable'] && isHovered && inputValue"
        :color="props.errorMessages?.length ? 'rgba(233, 30, 42, 1)' : 'black'"
        @click="inputValue = null"
      />
    </template>
    <template #append-inner>
      <IconError
        v-if="attrs['error-messages'] && (attrs['error-messages'] as [])?.length"
        color="rgba(233, 30, 42, 1)"
        :class="['append-inner' in $slots ? 'mr-4' : '']"
      />
    </template>

    <template #message="{ message }">
      <div class="b3">{{ message }}</div>
    </template>
  </v-select>
</template>

<style scoped lang="scss"></style>
