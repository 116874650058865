import { useAccountInfoStore } from '@/stores/accountInfoStore'
import { useAccountStore } from '@/stores/accountStore'
import { useAuthStore } from '@/stores/authStore'
import { useDialogStore } from '@/stores/dialogStore'
import { useDictionaryStore } from '@/stores/dictionaryStore'
import { useInformerStore } from '@/stores/informerStore'
import { useInvoiceNumbersStore } from '@/stores/invoiceNumbersStore'
import { useMessagesStore } from '@/stores/messagesStore'
import { useOrderHistoryStore } from '@/stores/orderHistoryStore'
import { useOrderSaleStore } from '@/stores/orderSaleStore'
import { useTradingStore } from '@/stores/tradingStore'
import { useTransactionHistoryStore } from '@/stores/transactionsStore'
import { useWelcomeTipsStore } from '@/stores/welcomeTipsStore'

export default function clearStores(flushToken: boolean = false) {
  const account = useAccountStore()
  const dictionary = useDictionaryStore()
  const invoices = useInvoiceNumbersStore()
  const orderHistory = useOrderHistoryStore()
  const accountInfoStore = useAccountInfoStore()
  const informerStore = useInformerStore()
  const welcomeTipsStore = useWelcomeTipsStore()
  const messagesStore = useMessagesStore()
  const orderSaleStore = useOrderSaleStore()
  const tradingStore = useTradingStore()
  const transactionStore = useTransactionHistoryStore()
  const dialogStore = useDialogStore()

  account.stopAutoUpdate()
  informerStore.stopAutoUpdate()
  informerStore.clearStore()
  orderHistory.stopAutoUpdate()
  tradingStore.stopAutoUpdate()
  messagesStore.stopAutoUpdate()

  dialogStore.closeDialog()
  transactionStore.clearStore()
  tradingStore.clearStore()
  orderSaleStore.clearStore()
  dictionary.clearStore()
  orderHistory.clearStore()
  messagesStore.clearStore()
  welcomeTipsStore.cleanVersions()
  account.clearStore()
  if (flushToken) {
    const authStore = useAuthStore()
    authStore.clearToken()
  }
  invoices.clearStore()
  accountInfoStore.clearStore()
}
