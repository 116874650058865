<script setup lang="ts">
  import { formatCurrency } from '@/utils/number.extensions'
  import { useDialogStore } from '@/stores/dialogStore'
  import { useDictionaryStore } from '@/stores/dictionaryStore'
  import { IPositionModel } from '@/api/types'
  import { useI18n } from 'vue-i18n'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { Instrument } from '@/components/types'

  interface CaseMobileProps {
    items: IPositionModel[]
    currentInstrument: Instrument
  }
  const { items, currentInstrument } = defineProps<CaseMobileProps>()

  const dialogStore = useDialogStore()
  const dictionaryStore = useDictionaryStore()
  const { t } = useI18n()

  const onRowClick = (event: MouseEvent, paperId: number, type: number) => {
    if (!dictionaryStore.isTrade(type)) {
      return false
    }
    dialogStore.openDialog('InstrumentInfo', {
      width: '100%',
      paperId,
      fullscreen: true
    })
  }

  const w = window

  const handleMassAction = (action: 1 | 2) => {
    dialogStore.openDialog('MassEffectPosition', {
      width: '100%',
      className: 'mass-action-position',
      instrument: currentInstrument,
      action, // 1 - удвоить
      fullscreen: true
    })
  }
</script>

<template>
  <v-sheet color="var(--color-AdditionalLightGrey)" min-height="200" width="100%" class="pt-2">
    <v-sheet
      width="100%"
      class="position-relative pa-4 d-flex flex-column ga-12 elevation-16-dp rounded-2lg"
      color="var(--color-WhiteWhite)"
    >
      <v-row
        v-for="(item, index) in items"
        :key="index"
        class="ma-0"
        @click="onRowClick($event, item.paperId, item.type)"
      >
        <v-col cols="2" class="pa-0">
          <v-img
            max-width="48"
            max-height="48"
            rounded="lg"
            :src="`${w.env?.VITE_STATIC_BASE_URL || 'https://static.lk.ricom.ru'}/media/logo/instruments/${item.paperId}.png`"
            lazy-src="/img/no-img.png"
            :alt="item.ticker"
          />
        </v-col>
        <v-col cols="10" class="pa-0">
          <div class="d-flex flex-column ga-1">
            <div class="d-flex justify-space-between">
              <div class="body-b1-bold">{{ item.ticker ?? '–' }}</div>
              <div class="body-b2">
                {{ item.amount ?? '–' }}&nbsp;шт&nbsp;
                <span class="delimiter">|</span>
                &nbsp;
                <span class="body-b1-bold">
                  {{ item.priceNow !== 0 ? formatCurrency(item.priceNow, '₽') : '–' }}
                </span>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div class="body-b2">
                {{ formatCurrency(item.totalNow, '₽') ?? '–' }}
              </div>
              <div :class="['body-b3-bold', item.totalProfitPercent > 0 ? 'green' : 'red']">
                {{ item.totalDiff ? formatCurrency(item.totalDiff, '₽') : '–' }}&nbsp;
                <span class="delimiter body-b3">|</span>
                &nbsp;{{ item.totalProfitPercent > 0 ? '+' : ''
                }}{{ formatCurrency(item.totalProfitPercent, '%') ?? '–' }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      color="var(--color-AdditionalLightGrey)"
      class="justify-space-between align-center d-flex pt-10 px-4 pb-4 ga-6"
    >
      <div class="body-b3">{{ t('portfolio.hotActions') }}</div>
      <BaseButton
        :label="t('portfolio.btnUpTo')"
        variant="plain"
        color="success-dark"
        disabled
        @click="handleMassAction(1)"
      />
      <BaseButton
        :label="t('portfolio.btnCloseAll')"
        variant="plain"
        color="alert-dark"
        disabled
        @click="handleMassAction(2)"
      />
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss">
  .green {
    color: var(--color-AdditionalGreen);
  }
  .red {
    color: var(--color-AlertNormal);
  }
  .delimiter {
    color: var(--color-BlackFocus);
  }
</style>
