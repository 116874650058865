import httpCommunicator from '@/api/httpCommunicator'
import type { AxiosResponse } from 'axios'
import { IActionFavoriteModel, IInstrumentModel } from '@/api/types'

const instrumentService = {
  async favorites() {
    return httpCommunicator.get('/instrument/favorites')
  },

  async getInstrument(paperId: number) {
    return httpCommunicator
      .get(`/instrument/info/${paperId}`)
      .then((response: AxiosResponse<IInstrumentModel>) => {
        return response.data
      })
  },

  async addToFavorites(data: IActionFavoriteModel) {
    return httpCommunicator.post('/instrument/favorites', data)
  },

  async deleteFromFavorites(data: IActionFavoriteModel) {
    return httpCommunicator.delete('/instrument/favorites', { data: data })
  }
}
export default instrumentService
