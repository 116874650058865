<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M15.5 11C18 11 20 13 20 15.5C20 16.38 19.75 17.21 19.31 17.9L22.39 21L21 22.39L17.88 19.32C17.19 19.75 16.37 20 15.5 20C13 20 11 18 11 15.5C11 13 13 11 15.5 11ZM15.5 13C14.1193 13 13 14.1193 13 15.5C13 16.8807 14.1193 18 15.5 18C16.8807 18 18 16.8807 18 15.5C18 14.1193 16.8807 13 15.5 13ZM5 2H19C20.11 2 21 2.89 21 4V12.03C20.5 11.23 19.81 10.54 19 10V4H5V18H9.5C9.81 18.75 10.26 19.42 10.81 20H5C3.89 20 3 19.11 3 18V4C3 2.89 3.89 2 5 2ZM7 6H17V8H7V6ZM7 10H12.03C11.23 10.5 10.54 11.19 10 12H7V10ZM7 14H9.17C9.06 14.5 9 15 9 15.5V16H7V14Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
