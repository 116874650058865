<script setup lang="ts">
  import { useDialogStore } from '@/stores/dialogStore'
  import { computed, defineAsyncComponent, onMounted, onUnmounted, ref } from 'vue'
  import IconClose from '@/components/icons/IconClose.vue'
  import { useDisplay } from 'vuetify'

  const landscapeClass = ref('')
  // Используем Pinia
  const dialogStore = useDialogStore()
  const display = useDisplay()

  // Импортируем все компоненты асинхронно
  const components = import.meta.glob('./Modals/*.vue')

  // Динамическая загрузка компонентов
  const dialogComponent = computed(() => {
    if (dialogStore.component === null) {
      return null
    }

    const componentKey = `./Modals/${dialogStore.component}.vue`

    if (components[componentKey]) {
      return defineAsyncComponent(
        () =>
          new Promise(resolve => {
            setTimeout(() => {
              ;(components[componentKey] as () => Promise<{ default: any }>)().then((mod: any) =>
                resolve(mod.default)
              )
            }, 500) // Задержка в 0.5 секунды
          })
      )
    }

    console.error(`Компонент ${dialogStore.component} не найден.`)
    return null
  })

  const dialogEvents = computed(() => {
    const events = dialogStore.events

    return Object.fromEntries(
      Object.entries(events).filter(([key, value]) => typeof value === 'function')
    )
  })

  function rotateEvent() {
    if (window.screen.orientation) {
      landscapeClass.value = window.screen.orientation.type.includes('landscape')
        ? 'v-dialog--landscape'
        : ''
    } else {
      landscapeClass.value =
        window.orientation === 90 || window.orientation === -90 ? 'v-dialog--landscape' : ''
    }
  }

  onMounted(() => {
    window.addEventListener('orientationchange', rotateEvent)
  })

  onUnmounted(() => {
    window.removeEventListener('orientationchange', rotateEvent)
  })
</script>

<template>
  <v-dialog
    v-model="dialogStore.isOpen"
    :max-width="dialogStore.props.maxWidth ?? '1080px'"
    :class="[
      'main-dialog',
      dialogStore.props.className,
      landscapeClass,
      display.mobile.value ? 'mobile-dialog' : ''
    ]"
    :width="dialogStore.props.width ?? 768"
    :height="dialogStore.props.height ?? 'auto'"
    :fullscreen="dialogStore.props.fullscreen"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card v-if="dialogComponent">
      <v-icon
        role="img"
        class="btn-close"
        :class="{ 'btn-close-mobile': display.mobile.value }"
        @click="dialogStore.closeDialog()"
      >
        <IconClose />
      </v-icon>

      <Suspense>
        <template #default>
          <component :is="dialogComponent" v-bind="dialogStore.props" v-on="dialogEvents" />
        </template>
        <template #fallback>
          <v-row justify="center" align="center" style="height: 200px">
            <v-progress-circular indeterminate color="primary" />
          </v-row>
        </template>
      </Suspense>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
  .v-dialog.main-dialog {
    &.v-dialog--fullscreen {
      top: 44px;
      &.v-dialog--landscape {
        top: 0;
      }
    }
    :deep(.v-overlay__content) {
      transition: width 0.5s ease-in-out;
      margin: 0;
    }
    :deep(.v-overlay__scrim) {
      background: var(--color-BlackDisabled);
      opacity: 1;
    }
    :deep(.v-card:first-child) {
      background: var(--color-WhiteWhite);
      border-radius: 16px 0 16px 16px;
    }
    &.mobile-dialog:deep(.v-card:first-child) {
      border-radius: 16px;
    }
    @media (orientation: landscape) {
      &.mobile-dialog:deep(.v-card:first-child) {
        border-radius: 0;
      }
    }
    &.mobile-dialog {
      --v-scrollbar-offset: 0;
    }
    .btn-close {
      position: fixed;
      right: -24px;
      top: 0;
      background-color: white;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      padding: 12px;
    }
    .btn-close-mobile {
      position: fixed;
      right: 16px;
      top: 16px;
      background-color: var(--color-BlackHover);
      width: 36px;
      height: 36px;
      border-radius: 18px;
      padding: 0;
    }
  }
</style>
