// @ts-ignore
export const trading = {
  orderSaleTitle: {
    sell: 'Поручение на продажу',
    buy: 'Поручение на покупку',
    all: 'Поручение'
  },
  title: 'Торговля',
  backBtn: 'Назад',
  table: {
    tabs: {
      favorite: 'Мои инструменты',
      marketToday: 'Рынок сегодня',
      readyMadeSolutions: 'Готовые решения',
      primaryPlacements: 'Первичные размещения'
    },
    columns: {
      name: 'Код ЦБ',
      title: 'Название',
      price: 'Цена',
      lot: 'Лот',
      marginal: 'Маржинальная',
      changesCurrency: 'Изменение, ₽',
      changesPercent: 'Изменение, %'
    }
  },
  favoriteInstrument: {
    deleteMsg: 'Удалить {name} из Моих инструментов?',
    deleteBtnText: 'Удалить'
  },
  searching: {
    fieldTitle: 'Добавить по названию, тикеру или ISIN-коду',
    title: 'Поиск инструментов'
  },
  orderSaleForAccount: 'по счету',
  orderSaleForThe: 'на',
  orderSaleCurrency: '₽',
  orderTableTicker: 'Тикер',
  orderTableLot: 'Лоты',
  orderTablePriceLast: 'Цена (last): ',
  orderTablePriceInstrument: 'Цена инструмента',
  orderTableForSum: 'Сумма',
  orderTableNpr1: 'НПР1 составит',
  orderTableNpr2: 'НПР2 составит',
  suggestion: {
    youTry: 'Всего можно',
    sell: 'продать',
    buy: 'купить',
    byPrice: 'на сумму',
    lots: 'лотов'
  },
  marginOptions: 'Маржинальные параметры'
}
