import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { myCustomLightTheme } from '@/themes/custom'
import { custom } from '@/components/icons/custom'
import { ru } from 'vuetify/locale'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'custom',
    sets: {
      custom
    }
  },
  locale: {
    locale: 'ru',
    fallback: 'ru',
    messages: { ru }
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme
    }
  }
})

export default vuetify
