<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M5.1 10L3 18V8H20C20 6.89543 19.1046 6 18 6H11L9 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H18C18.9 20 19.7 19.4 19.9 18.5L22.2 10H5.1ZM18 18H5L6.6 12H19.6L18 18Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
