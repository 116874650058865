export const registration = {
  title: 'Открытие счета',
  steps: {
    title1: 'Введите номер телефона',
    description1: '~ 1 минута',
    title2: 'Укажите ваши данные',
    description2: '~ 5 минут',
    title3: 'Подпишите документы',
    step1: {
      title: 'Введите номер телефона для получения СМС кода',
      confirmation:
        'Даю согласие на обработку персональных данных и подтверждаю, что я владелец указанного номера телефона.',
      smsTitle: 'Введите код из СМС',
      smsSubtitle: 'Код отправлен на номер {phoneNumber}',
      confirmBtn: 'Подтвердить номер',
      sendAgainBtn: 'Отправить код повторно',
      backBtn: 'Назад'
    },
    step2: {
      title: 'Заполните анкету',
      subtitle: 'Мы заботимся о безопасности и храним все данные в зашифрованном виде.',
      interviewBtn: 'Зачем заполнять анкету?',
      personalData: {
        title: 'Персональные данные',
        rezidentYes: 'Резидент РФ',
        rezidentNo: 'Не резидент',
        noMiddleName: 'Нет отчества',
        titleOfficial: 'Сведения о Публичном Должностном лице',
        officialNo: 'Не являюсь',
        officialYes: 'Являюсь'
      },
      additionalData: {
        title: 'Дополнительные данные',
        sexMale: 'Господин',
        sexFemale: 'Госпожа',
        questionOptions: {
          variant1: 'Девичья фамилия матери?',
          variant2: 'Имя первой учительницы?',
          variant3: 'Имя первого друга?',
          variant4: 'Имя первого питомца?',
          variant5: 'Какой марки была ваша первая машина?',
          variant6: 'Свой вариант'
        },
        controlQuestionText1: 'Запомните ваш контрольный вопрос.',
        controlQuestionText2: 'Он понадобиться, если потерян доступ к аккуанту.',
        paymentTitle: 'Выплата доходов',
        paymentBroker: 'На брокерский счет',
        paymentBank: 'На банковский счет',
        bankTitle: 'Банковские реквизиты',
        findBtn: 'Найти'
      }
    },
    step3: {
      title: 'Проверка документов',
      subtitle: 'Ознакомьтесь с документами и подтвердите данные.',
      interviewTitle: 'Анкета клиента с заявлениями',
      interviewSubtitle: 'Если в данных есть ошибки, внесите изменения.',
      confirmation:
        'Нажимая «Подтвердить данные» я подтверждаю свое согласие на обработку указанных в анкете персональных данных и соглашаюсь с рисками и условиями.',
      confirmBtn: 'Подветрдить данные',
      correctInterviewBtn: 'Исправить анкету'
    }
  },
  nextButton: 'Далее',
  phoneNumber: 'Номер телефона',
  code: 'Код из СМС',
  lastName: 'Фамилия',
  firstName: 'Имя',
  middleName: 'Отчество',
  passportSerial: 'Серия паспорта',
  passportNumber: 'Номер паспорта',
  passportDate: 'Дата выдачи',
  passportIssuedBy: 'Кем выдан',
  passportCodeDepartment: 'Код подразделения',
  inn: 'ИНН',
  addressRegistration: 'Адрес регистрации',
  birthday: 'Дата рождения',
  email: 'Email',
  controlQuestion: 'Контрольный вопрос',
  controlQuestionFree: 'Свой вариант вопроса',
  controlAnswer: 'Ответ на контрольный вопрос',
  bankBik: 'БИК банка',
  bankName: 'Название банка',
  bankCor: 'Корр. счет банка',
  bankRaschet: 'Расчетный (лицевой) счет',
  result: {
    list1: 'Логин и пароль для входа в личный кабинет.',
    list2: 'Сообщение о заключении договора на брокерское и  депозитарное обслуживание.',
    list3: 'Реквизиты для пополнения брокерского счета.',
    title: 'Уважаемый, {fio}',
    subtitle: 'Проводится проверка ваших персональных данных.',
    toAuthBtn: 'Войти в личный кабинет',
    text1: 'После проверки данных в',
    text2: 'течение одного рабочего дня',
    text3: 'на указанный адрес электронной почты будут высланы:'
  }
}
