import { defineStore } from 'pinia'
import { ModalComponents } from '@/components/types'

interface IState {
  props: Record<string, any>
  events: Record<string, any>
  component: null | string
  isOpen: boolean
  availableNames: ModalComponents[]
}

interface IAction {
  openDialog: (
    comp: ModalComponents,
    dialogProps?: Record<string, any>,
    dialogEvents?: Record<string, any>
  ) => void
  closeDialog: () => void
}
interface IGetter {
  [key: string]: any
}

export const useDialogStore = defineStore<'dialog', IState, IGetter, IAction>({
  id: 'dialog',
  state: () => ({
    isOpen: false,
    component: null,
    props: {},
    events: {},
    availableNames: ['InstrumentInfo']
  }),
  persist: false,
  actions: {
    openDialog(
      comp: string,
      dialogProps: Record<string, any> = {},
      dialogEvents: Record<string, any> = {}
    ) {
      this.props = dialogProps
      this.events = dialogEvents
      this.component = comp
      this.isOpen = true
    },
    closeDialog() {
      this.$reset()
    }
  },
  getters: {}
})
