import dayjs, { Dayjs } from 'dayjs'

export const dateMustBeLessThen = (value: Dayjs) => {
  const dateCurrent = dayjs()

  return dateCurrent.diff(value, 'years') >= 14
}

export const validateInn = (inn: string) => {
  if (!/^\d{10}$|^\d{12}$/.test(inn)) {
    return false
  }

  const checkCoefficients10 = [2, 4, 10, 3, 5, 9, 4, 6, 8]
  const checkCoefficients12_1 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
  const checkCoefficients12_2 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]

  const innArray = inn.split('').map(Number)

  const getControlSum = (weights: number[], length: number) =>
    (innArray.slice(0, length).reduce((sum, num, i) => sum + num * weights[i], 0) % 11) % 10

  if (inn.length === 10) {
    const checkSum = getControlSum(checkCoefficients10, 9)
    return checkSum === innArray[9]
  }

  if (inn.length === 12) {
    const checkSum1 = getControlSum(checkCoefficients12_1, 10)
    const checkSum2 = getControlSum(checkCoefficients12_2, 11)

    return checkSum1 === innArray[10] && checkSum2 === innArray[11]
  }

  return false
}
