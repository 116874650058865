export const topUpAccount = {
  tabs: {
    sbp: 'Система Быстрых Платежей',
    bank: 'По банковским реквизитам'
  },
  title: 'Пополнение счета',
  bank: {
    customerTitle: 'Получатель:',
    customer: 'АО "ИК "РИКОМ-ТРАСТ"',
    innTitle: 'ИНН:',
    inn: '7701033078',
    bikTitle: 'БИК:',
    bik: '044525505',
    raschetTitle: 'Расчетный счет:',
    raschet: '40701810200000000582',
    targetTitle: 'Назначение платежа:'
  },
  input: {
    placeholder: 'Пополнить счет на сумму',
    hint: 'Не менее 100 руб. Максимально 1 000 000 руб.'
  },
  totalText: 'Сумма к переводу:',
  commission: 'комиссия 0,4 %',
  buttonLabel: 'Получить QR-код',
  buttonMobileLabel: 'Пополнить',
  textHelp:
    'Если вы по какой-то причине захотите отменить перевод после его совершения, в течение 30 минут позвоните по телефону',
  phoneNumber: '8-800-200-53-07',
  textHelpScan: 'Отсканируйте код из мобильного приложения банк-клиента',
  textCopied: 'Скопировано!',
  textCopiedFailed: 'Не удалось скопировать'
}
