export const footer = {
  copy: 'АО «ИК «РИКОМ-ТРАСТ»',
  phone: '+7 (800) 200 53 07',
  phoneDesc: 'Звонок по России бесплатный',
  socialTitle: 'Социальные сети',
  link1: 'Старая версия',
  link2: 'Раскрытие информации',
  link3: 'База знаний',
  link4: 'Контакты',
  version: 'Версия {versionApp}'
}
