<script setup lang="ts">
  import RicomLogo from '@/components/RicomLogo.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import TopMenuElement from '@/components/BaseComponents/TopMenuElement.vue'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()
  const menuItem = [
    {
      name: 'portfolio',
      title: t('header.portfolio')
    },
    {
      name: 'trading',
      title: t('header.trading')
    },
    {
      name: 'services',
      title: t('header.services')
    }
  ]
</script>

<template>
  <div class="menu-prepend d-flex align-center ga-8">
    <BaseButton
      :ripple="false"
      label=""
      variant="text"
      :to="{ name: 'portfolio' }"
      size="default"
      class="home-logo"
    >
      <template #icon><RicomLogo /></template>
    </BaseButton>
    <v-sheet id="tip1" class="d-flex align-center top-menu h-80">
      <TopMenuElement
        v-for="item in menuItem"
        :key="item.name"
        :ripple="false"
        :label="item.title"
        :to="{ name: item.name }"
      />
    </v-sheet>
  </div>
</template>

<style scoped lang="scss">
  div.menu-prepend {
    .home-logo.v-btn {
      background: none;
      margin: 0 !important;
      padding-left: 0;
      :deep(.v-btn__overlay) {
        background: none;
      }
    }
  }
</style>
