import httpCommunicator from './httpCommunicator'
import type { ICodeModel, IPersonModel, IPhoneCheckModel } from '@/api/types'
import type { AxiosResponse } from 'axios'

const registrationService = {
  async checkPhone(data: IPhoneCheckModel) {
    const response: AxiosResponse = await httpCommunicator.post('/registration/send-sms', data)

    return response.data
  },

  async checkCode(data: ICodeModel) {
    const response: AxiosResponse = await httpCommunicator.post('/registration/send-code', data)

    return response.data
  },

  async sendPersonalData(data: IPersonModel) {
    const response: AxiosResponse = await httpCommunicator.post('/registration/person', data)

    return response.data
  },

  async checkPerson(data: IPhoneCheckModel) {
    const response: AxiosResponse = await httpCommunicator.post('/registration/person/check', data)

    return response.data
  }
}

export default registrationService
