import { IFeedbackMessagesModel, IMessageModel } from '@/api/types'
import { useNotify } from '@/stores/notify'
import { defineStore } from 'pinia'
import messageService from '@/api/messageService'
import { FeedbackTabs } from '@/components/types'

interface IState {
  data: IFeedbackMessagesModel
  loading: boolean
  error?: Error
  timer: unknown
}

interface IGetters {
  getUnreadCount: () => number
  getUnreadCategoryCount: (state: IState) => (category: FeedbackTabs) => number
  getFirstUnreadMessage: (state: IState) => (category: FeedbackTabs) => IMessageModel

  [key: string]: any
}

interface IActions {
  load: () => Promise<void>
  autoUpdate: () => Promise<void>
  startAutoUpdate: (interval: number) => Promise<void>
  stopAutoUpdate: () => void
  clearStore: () => void
  scheduleNextUpdate: (interval: number) => void
}

export const useMessagesStore = defineStore<'messages', IState, IGetters, IActions>({
  id: 'messages',
  state: (): IState => ({
    data: {
      manager: [],
      notifications: [],
      support: [],
      documents: []
    },
    loading: false,
    error: undefined,
    timer: null
  }),
  persist: true,
  actions: {
    async load() {
      this.loading = true
      try {
        this.data.manager = await messageService.messages()
      } catch (error: any) {
        this.error = { name: error.code, message: error.message }
        const notify = useNotify()
        notify.showServiceError()
      }
      this.loading = false
    },
    async autoUpdate() {
      try {
        this.data.manager = await messageService.messages()
      } catch (error: any) {
        this.error = { name: error.code, message: error.message }
        const notify = useNotify()
        notify.showServiceError()
      }
    },
    async startAutoUpdate(interval: number) {
      if (this.timer) {
        clearInterval(this.timer as number)
      }
      await this.autoUpdate() // Получить данные сразу при запуске
      this.scheduleNextUpdate(interval)
    },
    // запланированный следующий запрос
    scheduleNextUpdate(interval) {
      this.timer = setTimeout(async () => {
        await this.autoUpdate()
        this.scheduleNextUpdate(interval) // Планируем следующий запрос
      }, interval)
    },
    // Функция для остановки обновления
    stopAutoUpdate() {
      if (this.timer) {
        clearInterval(this.timer as number)
      }
      this.timer = null
    },
    clearStore() {
      this.$reset()
    }
  },
  getters: {
    getUnreadCategoryCount(state: IState) {
      return (category: FeedbackTabs) =>
        state.data[category] && state.data[category].length
          ? state.data[category].filter(item => item.type === 1 || item.type === 3).length
          : 0
    },
    getUnreadCount() {
      return (
        this.getUnreadCategoryCount('manager') +
        this.getUnreadCategoryCount('notifications') +
        this.getUnreadCategoryCount('support') +
        this.getUnreadCategoryCount('documents')
      )
    },
    getFirstUnreadMessage(state: IState) {
      return (category: FeedbackTabs) => {
        const findElement = state.data[category].find(item => item.type === 1 || item.type === 3)
        return findElement ? findElement : state.data[category][state.data[category].length - 1]
      }
    }
  }
})
