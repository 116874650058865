<script setup lang="ts">
  import { useRouter } from 'vue-router'
  import IconChevronLeft from '@/components/icons/IconChevronLeft.vue'

  const router = useRouter()

  const onClick = () => {
    router.back()
  }
</script>

<template>
  <v-btn variant="plain" :ripple="false" icon density="compact" @click="onClick">
    <IconChevronLeft />
  </v-btn>
</template>

<style scoped lang="scss"></style>
