<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M15.5 6V17.5C15.5 19.7091 13.7091 21.5 11.5 21.5C9.29086 21.5 7.5 19.7091 7.5 17.5V5C7.5 3.61929 8.61929 2.5 10 2.5C11.3807 2.5 12.5 3.61929 12.5 5V15.5C12.5 16.0523 12.0523 16.5 11.5 16.5C10.9477 16.5 10.5 16.0523 10.5 15.5V6H9V15.5C9 16.8807 10.1193 18 11.5 18C12.8807 18 14 16.8807 14 15.5V5C14 2.79086 12.2091 1 10 1C7.79086 1 6 2.79086 6 5V17.5C6 20.5376 8.46243 23 11.5 23C14.5376 23 17 20.5376 17 17.5V6H15.5Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
