import { ITransactionsModel } from '@/api/types'
import { defineStore } from 'pinia'
import { FilterTransactionsRequestModel } from '@/components/types'
import transactionHistoryService from '@/api/transactionHistoryService'

interface IState {
  data: ITransactionsModel[]
  loading: boolean
  total: number
  error?: Error
}
interface IActions {
  load: (args?: FilterTransactionsRequestModel) => Promise<void>
  clearStore: () => void
}
interface IGetters {
  [key: string]: any
}

export const useTransactionHistoryStore = defineStore<
  'transactionHistory',
  IState,
  IGetters,
  IActions
>({
  id: 'transactionHistory',
  state: (): IState => ({
    data: [],
    loading: false,
    total: 0,
    error: undefined
  }),
  persist: true,
  actions: {
    async load(args?: FilterTransactionsRequestModel) {
      this.loading = true
      try {
        const { data, total } = await transactionHistoryService.get(args)
        this.data = data
        this.total = total
        this.error = undefined
      } catch (error: any) {
        this.error = { name: error.code, message: error.message }
      }
      this.loading = false
    },
    clearStore() {
      this.$reset()
    }
  }
})
