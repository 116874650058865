<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M7 7H11V9H7C5.34315 9 4 10.3431 4 12C4 13.6569 5.34315 15 7 15H11V17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7ZM17 7C19.7614 7 22 9.23858 22 12H20C20 10.3431 18.6569 9 17 9H13V7H17ZM8 11H16V13H8V11ZM17 12H19V15H22V17H19V20H17V17H14V15H17V12Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
