type Format = 'view' | 'rest' | 'view-with-time' | 'ISO-8601'
interface String {
  dateFormatter(format: Format): string
}
String.prototype.dateFormatter = function (format: Format) {
  switch (format) {
    case 'view-with-time':
      return (
        new Date(this as string).toLocaleDateString() +
        ' ' +
        new Date(this as string).toLocaleTimeString()
      )
    case 'rest':
      return new Intl.DateTimeFormat('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).format(new Date(this as string))
    case 'view':
      return new Date(this as string).toLocaleDateString()
    case 'ISO-8601':
      return new Date(this as string).toISOString()
  }
}
