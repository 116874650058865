<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M10 11C12.21 11 14 9.21 14 7C14 4.79 12.21 3 10 3C7.79 3 6 4.79 6 7C6 9.21 7.79 11 10 11ZM10 5C11.11 5 12 5.9 12 7C12 8.1 11.11 9 10 9C8.89 9 8 8.11 8 7C8 5.89 8.9 5 10 5ZM12 19H2V16C2 13.33 7.33 12 10 12C10.91 12 12.13 12.16 13.35 12.47C13.26 12.8 13.2 13.15 13.2 13.5V14.39C12.22 14.1 11.1 13.9 10 13.9C7.03 13.9 3.9 15.36 3.9 16V17.1H12C12 17.13 12 17.17 12 17.2V19ZM20.8 16H16.5V13.5C16.5 12.7 17.2 12.2 18 12.2C18.8 12.2 19.5 12.7 19.5 13.5V14H20.8V13.5C20.8 12.1 19.4 11 18 11C16.6 11 15.2 12.1 15.2 13.5V16C14.6 16 14 16.6 14 17.2V20.7C14 21.4 14.6 22 15.2 22H20.7C21.4 22 22 21.4 22 20.8V17.3C22 16.6 21.4 16 20.8 16Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
