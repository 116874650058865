<script setup lang="ts">
  import { ref } from 'vue'

  // Пропсы и эмиты с типами
  interface LongTapProps {
    paperId: number
    duration?: number // Длительность тапа
  }

  interface LongTapEmits {
    (e: 'long-tap'): void // Событие долгого тапа
  }

  // Пропсы и эмиты
  const props = withDefaults(defineProps<LongTapProps>(), {
    duration: 1000 // Значение по умолчанию для длительности
  })

  const emit = defineEmits<LongTapEmits>()

  // Таймер
  const timer = ref<number | null>(null)

  // Запуск таймера
  const startTimer = () => {
    timer.value = setTimeout(() => {
      emit('long-tap') // Генерируем событие long-tap
    }, props.duration) as unknown as number // Приведение типа для setTimeout
  }

  // Остановка таймера
  const endTimer = () => {
    if (timer.value) {
      clearTimeout(timer.value)
      timer.value = null
    }
  }

  // Отмена таймера при движении
  const cancelTimer = () => {
    if (timer.value) {
      clearTimeout(timer.value)
      timer.value = null
    }
  }
</script>

<template>
  <div class="long-tap" @touchstart="startTimer" @touchend="endTimer" @touchmove="cancelTimer">
    <slot></slot>
  </div>
</template>

<style scoped>
  .long-tap {
    user-select: none; /* Запрещает выделение текста */
    -webkit-user-select: none; /* Для Safari */
    -moz-user-select: none; /* Для Firefox */
    -ms-user-select: none; /* Для IE/Edge */
  }
</style>
