<script setup lang="ts">
  import { useRegistrationStore } from '@/stores/registrationStore'
  import IconCheck from '@/components/icons/IconCheck.vue'
  import { useDisplay } from 'vuetify'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'

  const registrationStore = useRegistrationStore()
  const display = useDisplay()
  const router = useRouter()
  const { t } = useI18n()

  const items = [
    { text: t('registration.result.list1') },
    { text: t('registration.result.list2') },
    { text: t('registration.result.list3') }
  ]

  const toAuth = async () => {
    await router.push({ name: 'auth' })
  }
</script>

<template>
  <v-sheet
    class="d-flex justify-space-between align-start pa-6 ga-6 mb-6"
    :class="{ 'elevation-40-dp rounded-2lg mt-6': display.mobile.value }"
    :width="display.mobile.value ? 400 : 768"
  >
    <v-sheet v-if="!display.mobile.value" width="200">
      <v-img src="/img/owl.png" max-width="200" />
    </v-sheet>
    <v-sheet class="d-flex flex-column ga-4">
      <v-sheet class="d-flex justify-start">
        <v-sheet v-if="display.mobile.value" width="91">
          <v-img src="/img/owl.png" max-width="200" />
        </v-sheet>
        <v-sheet
          class="title-h2 mb-4"
          :class="{ 'body-b1-bold': !display.mobile.value, 'title-h2': display.mobile.value }"
        >
          {{
            t('registration.result.title', {
              fio: registrationStore.data.firstName + ' ' + registrationStore.data.middleName
            })
          }}
        </v-sheet>
      </v-sheet>
      <v-sheet class="d-flex flex-column ga-4">
        <v-sheet class="report-table-title">{{ t('registration.result.subtitle') }}</v-sheet>
        <v-sheet>
          {{ t('registration.result.text1') }}
          <span class="body-b1-bold">{{ t('registration.result.text2') }}</span>
          {{ t('registration.result.text3') }}
        </v-sheet>
      </v-sheet>
      <v-sheet>
        <v-sheet v-for="(item, i) in items" :key="i" :value="item" class="d-flex">
          <v-sheet>
            <IconCheck />
          </v-sheet>

          <v-sheet class="text-color-success-dark ml-2">{{ item.text }}</v-sheet>
        </v-sheet>
      </v-sheet>
      <BaseButton :label="t('registration.result.toAuthBtn')" block @click="toAuth" />
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss"></style>
