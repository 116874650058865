<script setup lang="ts">
  import { ref } from 'vue'
  import { formatCurrency } from '@/utils/number.extensions'
  import { useDialogStore } from '@/stores/dialogStore'
  import { useDictionaryStore } from '@/stores/dictionaryStore'
  import { IFavoritePositionModel, IPositionModel } from '@/api/types'
  import LotSize from '@/components/BaseComponents/LotSize.vue'
  import LongTap from '@/components/BaseComponents/LongTap.vue'
  import { useTradingStore } from '@/stores/tradingStore'
  import IconDelete from '@/components/icons/IconDelete.vue'
  import IconClose from '@/components/icons/IconClose.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { useI18n } from 'vue-i18n'

  interface FavoriteMobileProps {
    items: IFavoritePositionModel[]
  }
  const { items } = defineProps<FavoriteMobileProps>()

  const dialogStore = useDialogStore()
  const dictionaryStore = useDictionaryStore()
  const tradingStore = useTradingStore()
  const w = window
  const name = ref('')
  const dialog = ref(false)
  const id = ref(0)
  const { t } = useI18n()

  const onRowClick = (event: MouseEvent, paperId: number, type: number) => {
    if (!dictionaryStore.isTrade(type)) {
      return false
    }
    dialogStore.openDialog('InstrumentInfo', {
      width: '100%',
      paperId,
      fullscreen: true
    })
  }

  const removeFromFavorite = (paperId: number) => {
    const favorite = items.find(item => item.paperId === paperId)

    if (favorite) {
      name.value = favorite.ticker
      id.value = paperId
      dialog.value = true
    }
  }

  const handleDialogConfirm = async () => {
    await tradingStore.deleteFromFavoritePosition(id.value)
    await tradingStore.load()
  }
</script>

<template>
  <v-sheet color="var(--color-AdditionalLightGrey)" min-height="200" width="100%" class="pt-2">
    <v-sheet
      width="100%"
      class="position-relative pa-4 d-flex flex-column ga-12 elevation-16-dp rounded-2lg"
      color="var(--color-WhiteWhite)"
    >
      <LongTap
        v-for="(item, index) in items"
        :key="index"
        :duration="500"
        :paper-id="item.paperId"
        @long-tap="removeFromFavorite(item.paperId)"
      >
        <v-row class="ma-0" @click="onRowClick($event, item.paperId, item.typeId)">
          <v-col cols="2" class="pa-0">
            <v-img
              max-width="48"
              max-height="48"
              rounded="lg"
              :src="`${w.env?.VITE_STATIC_BASE_URL || 'https://static.lk.ricom.ru'}/media/logo/instruments/${item.paperId}.png`"
              lazy-src="/img/no-img.png"
              :alt="item.ticker"
            />
          </v-col>
          <v-col cols="10" class="pa-0">
            <div class="d-flex flex-column ga-1">
              <div class="d-flex justify-space-between">
                <div class="body-b1-bold">{{ item.ticker ?? '–' }}</div>
                <div class="body-b1-bold">
                  {{ formatCurrency(item.price, '₽') ?? '–' }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="body-b2">
                  <LotSize :ticker="item.ticker" />
                </div>
                <div :class="['body-b3-bold', item.totalProfitPercent > 0 ? 'green' : 'red']">
                  {{ item.totalDiff ? formatCurrency(item.totalDiff, '₽') : '–' }}&nbsp;
                  <span class="delimiter body-b3">|</span>
                  &nbsp;{{ item.totalProfitPercent > 0 ? '+' : ''
                  }}{{ formatCurrency(item.totalProfitPercent, '%') ?? '–' }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </LongTap>
    </v-sheet>
    <v-dialog v-model="dialog" max-width="480" width="100%" height="100%" class="confirm-dialog">
      <v-card class="pa-6 dialog-content">
        <v-icon role="img" class="btn-close" @click="dialog = false">
          <IconClose />
        </v-icon>
        <v-card-text class="pa-0 d-flex justify-center align-center">
          <v-sheet class="d-flex flex-column align-center">
            <v-sheet class="d-flex justify-space-between align-center ga-6 body-b1 text-center">
              {{ t('trading.favoriteInstrument.deleteMsg', { name: name }) }}
            </v-sheet>
            <v-sheet class="flex-1-0 d-flex justify-start ga-6 mt-6">
              <BaseButton
                type="button"
                :label="t('trading.favoriteInstrument.deleteBtnText')"
                @click="handleDialogConfirm"
              >
                <template #[`icon`]>
                  <IconDelete color="rgb(255,255,255)" />
                </template>
              </BaseButton>
              <BaseButton type="button" label="Отмена" variant="outlined" @click="dialog = false" />
            </v-sheet>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<style scoped lang="scss">
  .green {
    color: var(--color-AdditionalGreen);
  }
  .red {
    color: var(--color-AlertNormal);
  }
  .delimiter {
    color: var(--color-BlackFocus);
  }

  .btn-close {
    position: fixed;
    right: 24px;
    top: 24px;
    background-color: var(--color-BlackHover);
    width: 36px;
    height: 36px;
    border-radius: 18px;
    padding: 0;
  }

  .btn-close:hover {
    cursor: pointer;
  }

  .v-dialog.confirm-dialog {
    :deep(.v-overlay__content) {
      margin: 0;
    }
    :deep(.dialog-content) {
      border-radius: 16px !important;
    }
  }
</style>
