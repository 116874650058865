<script setup lang="ts">
  import { Tabs } from '@/components/types'
  import HistoryOrders from '@/components/HistoryOrders.vue'
  import HistoryTransactions from '@/components/HistoryTransactions.vue'
  import { ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import '@/utils/string.extensions'

  interface ITabs {
    name: Tabs
    title: string
  }
  //region> Uses
  const { t } = useI18n()
  //endregion
  //region> Refs
  const tab = ref('order' as Tabs)
  //endregion
  //region> Computed
  const historyComponent = {
    transaction: HistoryTransactions,
    order: HistoryOrders
  }
  //endregion
  //region> Items
  const tabs: ITabs[] = [
    {
      name: 'order',
      title: t('history.tabsOrder')
    },
    {
      name: 'transaction',
      title: t('history.tabsTransaction')
    }
  ]
  //endregion
</script>

<template>
  <div class="mt-6">
    <v-tabs v-model="tab" active-color="grey-darken-4" show-arrows hide-slider>
      <v-tab
        v-for="(tb, key) in tabs"
        :key="key"
        :value="tb.name"
        class="rounded-tt-lg text-none"
        selected-class="selected"
        hide-slider
      >
        {{ tb.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-window v-model="tab">
      <v-tabs-window-item
        v-for="(tb, key) in tabs"
        :key="key"
        :value="tb.name"
        :transition="false"
        :reverse-transition="false"
      >
        <v-sheet color="grey-lighten-4 case-tables rounded-bb-lg">
          <component :is="historyComponent[tb.name]" />
        </v-sheet>
      </v-tabs-window-item>
    </v-tabs-window>
  </div>
</template>

<style scoped lang="scss">
  .selected {
    background-color: #f5f5f5;
  }

  .v-table > .v-table__wrapper > table > tbody > tr > td,
  .v-table > .v-table__wrapper > table > thead > tr > th {
    height: 32px;
  }

  .v-data-table__td {
    box-sizing: content-box;
  }
</style>
