<script setup lang="ts">
  import { useAuthStore } from '@/stores/authStore'
  import AuthForm from '@/components/AuthForm.vue'
  import AuthSmsForm from '@/components/AuthSmsForm.vue'
  import { computed, onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useDisplay } from 'vuetify'
  import RicomLogo from '@/components/RicomLogo.vue'
  import { useNotification } from '@kyvg/vue3-notification'
  import { stringToPositiveHash } from '@/utils/number.extensions'

  const authStore = useAuthStore()
  const router = useRouter()
  const { mobile } = useDisplay()
  const { notify } = useNotification()

  const loginHandle = async () => {
    const idWarnSession = stringToPositiveHash('warnsession')
    notify.close(idWarnSession) // закроем окно о завершении сессии
    try {
      if (authStore.token) {
        await router.push('/')
        return
      }
      authStore.setSmsSend(true)
      authStore.setTimerSms(60)
    } catch (error) {
      console.log(error)
    }
  }
  const isImageLoaded = ref(false)
  const loaded = computed(() => (isImageLoaded.value && !mobile.value ? 'loaded' : ''))
  const bgImage = computed(() => (!mobile.value ? 'bg-image' : ''))

  const preloadImage = () => {
    const img = new Image()
    img.src = '/img/spring.jpg'
    img.onload = () => {
      isImageLoaded.value = true // Устанавливаем флаг после загрузки изображения
    }
  }

  const smsSend = async () => {
    try {
      await router.push('/')

      authStore.setSmsSend(false)
      authStore.setTimerSms(0)
    } catch (error) {
      console.log(error)
    }
  }

  onMounted(() => {
    if (!mobile.value) {
      preloadImage()
    }
  })
</script>

<template>
  <v-sheet
    v-if="mobile"
    class="position-absolute d-flex align-center justify-space-between w-100 px-8 pt-2"
  >
    <RicomLogo />
    <v-sheet tag="span" class="body-b3-bold" style="color: var(--color-BlackInactive)">
      +7 (800) 200 53 07
    </v-sheet>
  </v-sheet>
  <div :class="['d-flex', 'align-center', 'justify-center', loaded, bgImage]" style="height: 100vh">
    <AuthForm v-if="!authStore.isSmsSend" @login="loginHandle" />
    <AuthSmsForm v-else @send-sms="smsSend" />
  </div>
</template>

<style scoped lang="scss">
  .bg-image {
    background-size: cover; /* Это обеспечит, что изображение покроет весь экран */
    background-image: url('/public/img/spring_sm.jpg'); /* Путь к вашему изображению */
    background-position: center; /* Центрирование изображения */
    background-repeat: no-repeat; /* Чтобы изображение не повторялось */
    transition: background-image 1s ease-in-out;
    &.loaded {
      background-image: url('/public/img/spring.jpg');
    }
  }
</style>
