<script setup lang="ts">
  import BetaConsent from '@/components/BetaConsent.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import MainHeader from '@/components/MainHeader.vue'
  import { onMounted, onUnmounted } from 'vue'
  import { useMessagesStore } from '@/stores/messagesStore'
  import { useDisplay } from 'vuetify'
  import BottomNavigator from '@/components/BottomNavigator.vue'
  import { useRoute } from 'vue-router'
  import { useDialogStore } from '@/stores/dialogStore'
  import { ModalComponents } from '@/components/types'

  const dialogStore = useDialogStore()
  const messagesStore = useMessagesStore()
  const { mobile } = useDisplay()

  onMounted(async () => {
    // Отображаем модалку с инструментом в том случае, если пользователь передаёт в url query-параметры
    const route = useRoute()
    const modalName = route.query.modal as ModalComponents
    if (dialogStore.availableNames.includes(modalName) && route.query.paperId) {
      // TODO: подумать над уничтожением дублирования при вызове этого компонента (mobile/desktop). Сейчас есть дубилрование при вызове для мобилки/декстопа: 1080px / 100% + fullscreen
      dialogStore.openDialog(modalName, {
        width: '100%',
        paperId: Number(route.query.paperId)
      })
    }

    await messagesStore.startAutoUpdate(5000)
  })

  onUnmounted(() => {
    messagesStore.stopAutoUpdate()
  })
</script>

<template>
  <BottomNavigator v-if="mobile" app />
  <MainHeader v-else />
  <v-main app :class="{ 'mb-8': !mobile }">
    <v-container max-width="1280" class="py-12" :class="{ 'px-0 pt-2 pb-0': mobile }">
      <RouterView />
    </v-container>
  </v-main>
  <v-footer
    class="justify-lg-space-between align-center"
    :class="{ 'my-footer': !mobile, 'mb-18 bb-lg-1': mobile }"
  >
    <MainFooter />
  </v-footer>
  <BetaConsent />
  <!--<CookieConsent />-->
</template>

<style scoped lang="scss">
  .bb-lg-1 {
    border-top: 1px solid var(--color-BlackFocus);
  }
  .my-footer {
    border-top: solid 1px var(--color-BlackFocus);
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    flex-grow: 0;
  }
</style>
