<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <g id="Control / Additional / Screen rotation" clip-path="url(#clip0_10893_50577)">
      <path
        id="Vector"
        d="M12.4 19.45L4.55 11.6C4.16667 11.2167 3.975 10.7667 3.975 10.25C3.975 9.73333 4.16667 9.28333 4.55 8.9L8.9 4.55C9.28333 4.16667 9.73333 3.975 10.25 3.975C10.7667 3.975 11.2167 4.16667 11.6 4.55L19.45 12.4C19.8333 12.7833 20.025 13.2333 20.025 13.75C20.025 14.2667 19.8333 14.7167 19.45 15.1L15.1 19.45C14.7167 19.8333 14.2667 20.025 13.75 20.025C13.2333 20.025 12.7833 19.8333 12.4 19.45ZM13.75 18L18 13.75L10.25 6L6 10.25L13.75 18ZM12 24C10.35 24 8.79583 23.6875 7.3375 23.0625C5.87917 22.4375 4.60417 21.5792 3.5125 20.4875C2.42083 19.3958 1.5625 18.1208 0.9375 16.6625C0.3125 15.2042 0 13.65 0 12H2C2 13.1833 2.2 14.3167 2.6 15.4C3 16.4833 3.55417 17.4583 4.2625 18.325C4.97083 19.1917 5.81667 19.9292 6.8 20.5375C7.78333 21.1458 8.85833 21.575 10.025 21.825L7.4 19.2L8.8 17.8L14.7 23.7C14.2667 23.8 13.8208 23.875 13.3625 23.925C12.9042 23.975 12.45 24 12 24ZM22 12C22 10.8167 21.8 9.68333 21.4 8.6C21 7.51667 20.4458 6.54167 19.7375 5.675C19.0292 4.80833 18.1833 4.07083 17.2 3.4625C16.2167 2.85417 15.1417 2.425 13.975 2.175L16.6 4.8L15.2 6.2L9.3 0.3C9.73333 0.2 10.1792 0.125 10.6375 0.075C11.0958 0.025 11.55 0 12 0C13.65 0 15.2042 0.3125 16.6625 0.9375C18.1208 1.5625 19.3958 2.42083 20.4875 3.5125C21.5792 4.60417 22.4375 5.87917 23.0625 7.3375C23.6875 8.79583 24 10.35 24 12H22Z"
        fill="currentColor"
        :fill-opacity="fillOpacity"
      />
    </g>
    <defs>
      <clipPath id="clip0_10893_50577">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
