<script setup lang="ts">
  import IconMenu from '@/components/icons/IconMenu.vue'
  import { useDialogStore } from '@/stores/dialogStore'
  import { ICaseMenuProps } from '@/components/types'

  const props = withDefaults(defineProps<ICaseMenuProps>(), {
    tabType: 'common'
  })

  const dialogStore = useDialogStore()

  const openDialog = () => {
    dialogStore.openDialog('ColumnsSettings', {
      width: '520px',
      tabType: props.tabType
    })
  }
</script>

<template>
  <div class="pa-2">
    <v-btn icon variant="text" density="compact" :ripple="false" @click="openDialog">
      <IconMenu />
    </v-btn>
  </div>
</template>

<style scoped lang="scss"></style>
