<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M9 11V13H7V11H9ZM13 11V13H11V11H13ZM17 11V13H15V11H17ZM19 4C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22H5C3.89 22 3 21.1 3 20V6C3 4.89543 3.89543 4 5 4H6V2H8V4H16V2H18V4H19ZM19 20V9H5V20H19ZM9 15V17H7V15H9ZM13 15V17H11V15H13ZM17 15V17H15V15H17Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
