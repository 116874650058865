<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <rect width="40" height="40" rx="8" fill="currentColor" :fill-opacity="fillOpacity" />
    <path
      d="M31.9948 17.6389C32.0457 16.1584 31.7234 14.689 31.0578 13.3671C30.6062 12.8247 29.9795 12.4586 29.2868 12.3327C26.4218 12.0716 23.545 11.9646 20.6686 12.0121C17.8027 11.9624 14.9363 12.066 12.0813 12.3224C11.5169 12.4255 10.9945 12.6915 10.578 13.0878C9.65135 13.9463 9.54838 15.4151 9.44542 16.6563C9.29603 18.8879 9.29603 21.1272 9.44542 23.3588C9.4752 24.0574 9.57875 24.7509 9.75431 25.4275C9.87846 25.9499 10.1296 26.4332 10.4854 26.8342C10.9047 27.2515 11.4392 27.5326 12.0195 27.641C14.2394 27.9162 16.4761 28.0303 18.7123 27.9823C22.3161 28.034 25.4771 27.9823 29.2148 27.6927C29.8093 27.591 30.3589 27.3095 30.7901 26.8859C31.0784 26.5962 31.2937 26.2416 31.4182 25.8516C31.7864 24.7165 31.9673 23.5286 31.9536 22.3348C31.9948 21.7556 31.9948 18.2595 31.9948 17.6389ZM18.3416 22.9554V16.5528L24.4372 19.7696C22.7279 20.7212 20.473 21.797 18.3416 22.9554Z"
      fill="white"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
