import { defineStore } from 'pinia'
import { IAccountInfoModel } from '@/api/types'
import accountsService from '@/api/accountsService'
import { useNotify } from '@/stores/notify'

interface IState {
  data: IAccountInfoModel
  loading: boolean
  error?: Error
}
interface IAction {
  load: () => Promise<void>
  clearStore: () => void
}
interface IGetter {
  avatarFullName: (state: IState) => string
  fullName: (state: IState) => string
  [key: string]: any
}

export const useAccountInfoStore = defineStore<'accountInfo', IState, IGetter, IAction>({
  id: 'accountInfo',
  state: () => ({
    data: {
      firstName: '',
      lastName: '',
      middleName: '',
      passportSerial: '',
      passportNumber: '',
      passportDate: '',
      issuedBy: '',
      codeDepartment: '',
      inn: '',
      addressRegistration: '',
      address: '',
      phoneNumber: '',
      fax: '',
      email: '',
      mobilePhone: '',
      messages: {
        profile: false,
        email: false,
        phone: false,
        office: false
      },
      dividends: 'brokerageAccount',
      official: 'iam',
      businessRelations: {
        brokerageServices: false,
        depositoryServices: false,
        trustManagement: false,
        investmentConsulting: false
      },
      beneficiary: '',
      beneficialOwner: ''
    },
    loading: false,
    error: undefined
  }),
  persist: true,
  actions: {
    async load() {
      this.loading = true
      try {
        this.data = await accountsService.info()
      } catch (error: any) {
        // если возникнет ошибка, сбрасываем состояние, что бы не сохранялись предыдущие данные
        this.$reset()
        this.error = { name: error.code, message: error.message }
        const notify = useNotify()
        notify.showServiceError()
      }
      this.loading = false
    },
    clearStore() {
      this.$reset()
    }
  },
  getters: {
    avatarFullName: (state: IState): string => {
      let fullName = ''
      if (state.data.firstName) {
        fullName += state.data.firstName
      }
      if (state.data.lastName) {
        fullName += ' ' + state.data.lastName
      }

      return fullName
    },
    fullName: (state: IState): string => {
      let fullName = ''

      if (state.data.lastName) {
        fullName += state.data.lastName
      }

      if (state.data.firstName) {
        fullName += ' ' + state.data.firstName
      }

      if (state.data.middleName) {
        fullName += ' ' + state.data.middleName
      }

      return fullName
    }
  }
})
