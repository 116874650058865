<script setup lang="ts">
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { useCookieConsentStore } from '@/stores/cookieConsentStore'
  import { computed } from 'vue'

  const cookieConsentStore = useCookieConsentStore()
  const isCookieDialogVisible = computed(() => !cookieConsentStore.consentGiven)
</script>

<template>
  <v-dialog
    v-model="isCookieDialogVisible"
    persistent
    location="center"
    origin="center center"
    content-class="align-center"
  >
    <v-card max-width="720px" rounded="lg">
      <v-sheet class="pa-6 d-flex ga-4 flex-column">
        <v-card-title class="body-b2-semibold">Уважаемый Клиент!</v-card-title>
        <v-card-text class="body-b2" style="line-height: 24px">
          Уведомляем Вас об использовании бета-версии Личного кабинета Клиента (версия 2.0, далее -
          Продукт). Нажимая на кнопку "Согласен", Вы присоединяетесь к программе бета-тестирования
          Продукта, что означает:
          <br />
          – согласие с использованием Продукта в качестве полноценной версии Личного кабинета
          клиента, предусмотренного регламентом брокерского обслуживания АО "ИК "РИКОМ-ТРАСТ", без
          каких-либо ограничений;
          <br />
          – допущение технических и/или иных сбоев при работе с Продуктом, отказ от каких-либо
          претензий к АО "ИК "РИКОМ-ТРАСТ" по причине возникновения технических и/или иных сбоев,
          вне зависимости от причины их возникновения, в том числе при возникновении убытков,
          вызванных такими техническими сбоями;
          <br />
          – согласие на автоматизированную обработку АО "ИК "РИКОМ-ТРАСТ" Ваших персональных данных
          (файлы cookie, сведения о совершаемых в личном кабинете действиях, сведения об
          используемых для этого устройствах) путём совершения следующих действий: сбор, запись,
          систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, блокирование, удаление, уничтожение;
          <br />
          – присоединение к
          <a href="https://www.ricom.ru/media/docs/personal_data_politic.pdf" target="_blank">
            Политике АО "ИК "РИКОМ-ТРАСТ" в отношении обработки персональных данных клиентов
          </a>
          <br />
          С уважением,
          <br />
          АО "ИК "РИКОМ-ТРАСТ"
        </v-card-text>
        <v-card-actions>
          <BaseButton :slim="false" label="Согласен" @click="cookieConsentStore.acceptCookies" />
        </v-card-actions>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
