<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <g clip-path="url(#clip0_26_2755)">
      <path
        d="M13.83 11C13.343 9.62245 11.9343 8.79613 10.4943 9.0432C9.0542 9.29028 8.00157 10.5389 8.00157 12C8.00157 13.4611 9.0542 14.7097 10.4943 14.9568C11.9343 15.2039 13.343 14.3775 13.83 13H15V15H17V13H18V11M12.63 2C18.16 2 22.64 6.5 22.64 12C22.64 17.5 18.16 22 12.63 22C9.12 22 6.05 20.18 4.26 17.43L5.84 16.18C7.25 18.47 9.76 20 12.64 20C17.0583 20 20.64 16.4183 20.64 12C20.64 7.58172 17.0583 4 12.64 4C8.56 4 5.2 7.06 4.71 11H7.47L3.73 14.73L0 11H2.69C3.19 5.95 7.45 2 12.63 2ZM11 11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11Z"
        fill="currentColor"
        :fill-opacity="fillOpacity"
      />
    </g>
    <defs>
      <clipPath id="clip0_26_2755">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
