<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M9.5 16.5V7.50005L16.5 12L9.5 16.5ZM12 23C10.2 23 8.5125 22.5875 6.9375 21.7625C5.3625 20.9375 4.05 19.7834 3 18.3V21H1V15H7V17H4.55C5.4 18.25 6.47917 19.2292 7.7875 19.9375C9.09583 20.6459 10.5 21 12 21C13.9167 21 15.6542 20.45 17.2125 19.35C18.7708 18.25 19.8667 16.7917 20.5 14.975L22.45 15.425C21.7 17.6917 20.3667 19.5209 18.45 20.9125C16.5333 22.3042 14.3833 23 12 23ZM1.05 11C1.16667 9.88338 1.43333 8.81255 1.85 7.78755C2.26667 6.76255 2.84167 5.81672 3.575 4.95005L5 6.37505C4.46667 7.05838 4.03333 7.78755 3.7 8.56255C3.36667 9.33755 3.15833 10.15 3.075 11H1.05ZM6.4 4.97505L4.975 3.55005C5.85833 2.81672 6.80833 2.23755 7.825 1.81255C8.84167 1.38755 9.9 1.13338 11 1.05005V3.05005C10.15 3.13338 9.34167 3.34172 8.575 3.67505C7.80833 4.00838 7.08333 4.44172 6.4 4.97505ZM17.625 4.97505C16.9417 4.44172 16.2125 4.00838 15.4375 3.67505C14.6625 3.34172 13.85 3.13338 13 3.05005V1.05005C14.1167 1.15005 15.1875 1.40838 16.2125 1.82505C17.2375 2.24172 18.1833 2.81672 19.05 3.55005L17.625 4.97505ZM20.95 11C20.8667 10.15 20.6583 9.33755 20.325 8.56255C19.9917 7.78755 19.5583 7.05838 19.025 6.37505L20.45 4.95005C21.1833 5.81672 21.7583 6.76255 22.175 7.78755C22.5917 8.81255 22.85 9.88338 22.95 11H20.95Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
