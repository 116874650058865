import httpCommunicator from '@/api/httpCommunicator'
import { FilterOrdersRequestModel } from '@/components/types'
import { IOrderHistoryModel } from '@/api/types'
import { AxiosResponse } from 'axios'

const orderHistoryService = {
  async get(args?: FilterOrdersRequestModel) {
    return httpCommunicator
      .get('/orders', {
        params: args
      })
      .then(response => {
        return { data: response.data, total: response.headers['x-orders-total'] }
      })
  },
  async statuses(ids: number[]): Promise<AxiosResponse<IOrderHistoryModel[], unknown>> {
    return httpCommunicator.patch('/orders/statuses', ids)
  }
}
export default orderHistoryService
