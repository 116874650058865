import { defineStore } from 'pinia'
import { IVersion } from '@/api/types'

interface ITip {
  element: string
  classList: string[]
  title: string
  placement: string
  version?: string
}

export interface ITipMobile {
  title: string
  img: string
  version?: string
}

interface IDataAdaptive {
  mobile: ITipMobile[]
  desktop: ITip[]
}

interface IState {
  data: { [key: string]: IData }
  versions: IVersion[]
  loading: boolean
  error?: Error
}

interface IData {
  pageName: string
  tips: IDataAdaptive
}

interface IAction {
  clearStore: () => void
  setCheckedVersions: (versions: string[]) => void
  getStepsByPage: (page: string) => ITip[]
  getStepsMobile: () => ITipMobile[]
  setVersions: (versions: IVersion[]) => void
  cleanVersions: () => void
}
interface IGetter {
  show(state: IState): boolean
  noCheckedVersions(state: IState): string[]
  [key: string]: any
}

//@description Для подсказок на разных страницах должна быть своя отдельная версия, не должно быть версий 2.0.2 portfolio и 2.0.2 trading !!!!
export const useWelcomeTipsStore = defineStore<'welcomeTips', IState, IGetter, IAction>({
  id: 'welcomeTips',
  state: () => ({
    data: {
      '2.1.0': {
        pageName: 'portfolio',
        tips: {
          mobile: [
            {
              title: 'welcomeTips.tip2',
              img: '/img/step1.png'
            },
            {
              title: 'welcomeTips.tip3',
              img: '/img/step2.png'
            },
            {
              title: 'welcomeTips.tip4',
              img: '/img/step3.png'
            },
            {
              title: 'welcomeTips.tip7',
              img: '/img/step4.png'
            },
            {
              title: 'welcomeTips.tip8Mobile',
              img: '/img/step5.png'
            }
          ],
          desktop: [
            {
              element: '#tip1',
              classList: ['pr-6'],
              title: 'welcomeTips.tip1',
              placement: 'bottom-start'
            },
            {
              element: '#tip2',
              classList: ['pa-6', 'ma-n6'],
              title: 'welcomeTips.tip2',
              placement: 'bottom-end'
            },
            {
              element: '#tip3',
              classList: ['pa-6', 'ma-n6'],
              title: 'welcomeTips.tip3',
              placement: 'bottom-end'
            },
            {
              element: '#tip4',
              classList: ['attached-p14'],
              title: 'welcomeTips.tip4',
              placement: 'bottom-end'
            },
            {
              element: '#tip5',
              classList: ['pl-6', 'ml-n6'],
              title: 'welcomeTips.tip5',
              placement: 'bottom-start'
            },
            {
              element: '#tip6',
              classList: ['ma-n6', 'pa-6'],
              title: 'welcomeTips.tip6',
              placement: 'left-start'
            },
            {
              element: '#tip7',
              classList: ['ma-n6', 'pa-6'],
              title: 'welcomeTips.tip7',
              placement: 'bottom-start'
            }
          ]
        }
      }
    },
    versions: [],
    loading: false,
    error: undefined
  }),
  persist: {
    storage: sessionStorage,
    pick: ['versions']
  },
  actions: {
    clearStore() {
      this.$reset()
    },
    setCheckedVersions(versions: string[]) {
      this.versions.forEach(item => {
        item.checked = !item.checked ? versions.includes(item.version) : true
      })
    },
    setVersions(versions: IVersion[]) {
      const versionMap = new Map()
      versions.forEach(item => {
        versionMap.set(item.version, item)
      })
      this.versions = [...versions]

      Object.keys(this.data).forEach(version => {
        if (!versionMap.has(version)) {
          this.versions.push({ version: version, checked: false })
        }
      })
    },
    getStepsByPage(page: string): ITip[] {
      const res = []
      for (const [key, item] of Object.entries(this.data)) {
        if (item.pageName === page && this.noCheckedVersions.includes(key)) {
          res.push(
            ...item.tips.desktop.map(tip => ({
              ...tip,
              version: key
            }))
          )
        }
      }
      return res
    },
    getStepsMobile(): ITipMobile[] {
      const res = []
      for (const [key, item] of Object.entries(this.data)) {
        if (this.noCheckedVersions.includes(key)) {
          res.push(
            ...item.tips.mobile.map(tip => ({
              ...tip,
              version: key
            }))
          )
        }
      }
      return res
    },
    cleanVersions() {
      this.versions = []
    }
  },
  getters: {
    show(state) {
      const len = Object.keys(state.data).filter(item => this.noCheckedVersions.includes(item))
      return !!len.length
    },
    noCheckedVersions(state) {
      return state.versions.filter(item => !item.checked).map(item => item.version)
    }
  }
})
