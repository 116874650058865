<script setup lang="ts">
  import { useAccountStore } from '@/stores/accountStore'
  import GradientBar from '@/components/GradientBar.vue'
  import CaseMenu from '@/components/BaseComponents/CaseComponents/CaseMenu.vue'
  import { useDialogStore } from '@/stores/dialogStore'
  import { useDictionaryStore } from '@/stores/dictionaryStore'
  import { useI18n } from 'vue-i18n'
  import { ICaseCommonProps } from '@/components/types'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { useTableColumnsStore } from '@/stores/tableColumnsStore'
  import { formatCurrency } from '@/utils/number.extensions'
  import { StyleValue, onMounted, onUnmounted } from 'vue'

  const w = window

  const props = withDefaults(defineProps<ICaseCommonProps>(), {
    currentInstrument: 'common'
  })

  const tableColumnsStore = useTableColumnsStore()
  const accountStore = useAccountStore()
  const dialogStore = useDialogStore()
  const dictionaryStore = useDictionaryStore()
  const { t } = useI18n()

  const onRowClick = (event: MouseEvent, paperId: number, type: number) => {
    if (!dictionaryStore.isTrade(type)) {
      return false
    }
    dialogStore.openDialog('InstrumentInfo', {
      width: '1080',
      paperId
    })
  }

  const handleMassAction = (action: 1 | 2) => {
    dialogStore.openDialog('MassEffectPosition', {
      width: '416',
      className: 'mass-action-position',
      action,
      instrument: props.currentInstrument
    })
  }
  onMounted(() => {
    w.addEventListener('scroll', handlerScroll)
  })

  onUnmounted(() => w.removeEventListener('scroll', handlerScroll))

  const handlerScroll = () => {
    const tableHeader = document.querySelector('.papers-header')
    if (tableHeader) {
      const parent = tableHeader.parentElement
      if (parent) {
        const table = document.querySelector('.papers-table')
        const tableRect = table ? table.getBoundingClientRect() : null
        const rect = parent.getBoundingClientRect()

        const headerHeight = tableHeader ? tableHeader.clientHeight : 0

        if (rect.top <= 80 && tableRect && tableRect.bottom > headerHeight + 190) {
          tableHeader.classList.add('fixed-block')
        } else {
          tableHeader.classList.remove('fixed-block')
        }
      }
    }
  }
</script>

<template>
  <div class="papers-table">
    <div class="papers-header">
      <v-row class="papers-row">
        <template
          v-for="(column, key) in tableColumnsStore.cases[currentInstrument].headers"
          :key="key"
        >
          <v-col
            class="d-flex align-center bg-grey-lighten-4 mt-3 table-cell"
            :class="{
              'justify-center': column.key !== 'ticker',
              'justify-start': column.key === 'ticker'
            }"
          >
            <CaseMenu v-if="column.key === 'paperId'" :tab-type="currentInstrument" />
            <div v-else class="body-b3" v-html="t(column.title as string)"></div>
          </v-col>
        </template>
      </v-row>
      <v-row class="gradient-row">
        <v-col cols="12" class="pa-0 row-gradient">
          <GradientBar
            :component-index="currentInstrument"
            :point-position="accountStore.gradPosition"
          />
        </v-col>
      </v-row>
    </div>
    <template v-for="(item, key) in items" :key="key">
      <v-row
        class="cursor-pointer paper-row rounded-lg"
        @click="onRowClick($event, item.paperId, item.type)"
      >
        <template
          v-for="(column, key2) in tableColumnsStore.cases[currentInstrument].headers"
          :key="key2"
        >
          <v-col
            v-if="column.key === 'paperId'"
            class="d-flex align-center justify-center table-cell pa-0"
          >
            <v-sheet
              class="d-flex justify-center align-center bg-transparent"
              width="48"
              height="48"
            >
              <v-img
                rounded="lg"
                :src="`${w.env?.VITE_STATIC_BASE_URL || 'https://static.lk.ricom.ru'}/media/logo/instruments/${item.paperId}.png`"
                lazy-src="/img/no-img.png"
                :alt="item.ticker"
              />
            </v-sheet>
          </v-col>
          <v-col
            v-if="column.key === 'ticker'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
            >
              {{ item.ticker ?? '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'total'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
            >
              {{ item.amount ?? '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'buyDate'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ new Date(item.buyDate).toLocaleDateString('ru-RU') ?? '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'buyPrice'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ formatCurrency(item.price) ?? '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'nowPrice'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.priceNow !== 0 ? formatCurrency(item.priceNow) : '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'totalPrice'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.balance ? formatCurrency(item.balance) : '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'totalPriceNow'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.totalNow ? formatCurrency(item.totalNow) : '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'changesCurrency'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.totalDiff ? formatCurrency(item.totalDiff) : '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'changesPercent'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal',
                  color: item.totalProfitPercent > 0 ? column.headerProps?.color : 'red'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.totalProfitPercent > 0 ? '+' : '' }}{{ item.totalProfitPercent ?? '–' }}
            </div>
          </v-col>
        </template>
      </v-row>
    </template>
    <v-row v-if="items && items.length" class="summary">
      <template
        v-for="(column, key) in tableColumnsStore.cases[currentInstrument].headers"
        :key="key"
      >
        <v-col v-if="column.key === 'ticker'" class="d-flex align-center justify-start pa-0">
          <div style="font-weight: 600; text-align: left">
            {{ t('portfolio.table.summary') }}
          </div>
        </v-col>
        <v-col
          v-else-if="column.key === 'totalPrice'"
          class="d-flex align-center pa-0"
          :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
        >
          {{ formatCurrency(accountStore.totalResultMain.totalPriceTotal) }}
        </v-col>
        <v-col
          v-else-if="column.key === 'totalPriceNow'"
          class="d-flex align-center pa-0"
          :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
          style="font-weight: 600"
        >
          {{ formatCurrency(accountStore.totalResultMain.totalPriceNowTotal) }}
        </v-col>
        <v-col
          v-else-if="column.key === 'changesCurrency'"
          class="d-flex align-center pa-0"
          :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
        >
          {{ formatCurrency(accountStore.totalResultMain.changesCurrencyTotal) }}
        </v-col>
        <v-col
          v-else-if="column.key === 'changesPercent'"
          class="d-flex align-center pa-0"
          :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
          :style="{
            'font-weight': 600,
            color:
              accountStore.totalResultMain.profitPercent > 0
                ? 'var(--color-AdditionalGreen)'
                : 'red'
          }"
        >
          {{ accountStore.totalResultMain.profitPercent > 0 ? '+' : ''
          }}{{ accountStore.totalResultMain.profitPercent }}
        </v-col>
        <v-col v-else class="pa-0"></v-col>
      </template>
    </v-row>
    <v-row v-if="items && items.length" class="ma-0">
      <v-col cols="12" class="pa-0">
        <v-sheet class="border-t-thin" height="80">
          <v-sheet
            class="justify-space-between align-center d-flex pa-2 bg-grey-lighten-4 rounded-bb-lg"
          >
            <v-sheet class="label-color bg-transparent">
              <v-label class="body-b3-semibold">{{ t('portfolio.hotActions') }}</v-label>
            </v-sheet>
            <v-sheet class="bg-transparent">
              <v-sheet class="justify-end d-flex align-center bg-transparent">
                <BaseButton
                  :label="t('portfolio.btnUpTo')"
                  variant="plain"
                  color="success-dark"
                  disabled
                  @click="handleMassAction(1)"
                />
                <BaseButton
                  :label="t('portfolio.btnCloseAll')"
                  variant="plain"
                  color="alert-dark"
                  disabled
                  @click="handleMassAction(2)"
                />
              </v-sheet>
            </v-sheet>
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
  .th-gradient-bar {
    height: fit-content !important;
    background-color: var(--color-AdditionalLightGrey) !important;
    padding: 0 !important;
  }
  .label-color {
    color: var(--color-BlackInactive);
  }

  .fixed-block {
    position: fixed;
    top: 80px;
    height: 90px;
    left: 0;
    width: 100%;
    z-index: 201;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 245, 245);
    .papers-row,
    .gradient-row {
      width: 1280px;
      padding: 0 12px;
    }
  }

  .table-cell {
    flex-basis: 0;
    min-width: 100px;
  }

  .summary {
    height: 72px;
    margin: 10px 0;

    .v-col {
      flex-basis: 0;
      min-width: 100px;
    }
  }

  .paper-row {
    height: 72px;
    margin: 10px 0;
  }

  .paper-row:hover {
    background-color: var(--color-BlackHover);
  }

  .row-gradient {
    background: linear-gradient(to bottom, rgb(245, 245, 245) 50%, transparent 50%);
  }
</style>
