<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 5.55C15.79 5.55 19.17 7.68 20.82 11.05C20.23 12.27 19.4 13.32 18.41 14.17L19.82 15.58C21.21 14.35 22.31 12.81 23 11.05C21.27 6.66 17 3.55 12 3.55C10.73 3.55 9.51 3.75 8.36 4.12L10.01 5.77C10.66 5.64 11.32 5.55 12 5.55ZM10.93 6.69L13 8.76C13.57 9.01 14.03 9.47 14.28 10.04L16.35 12.11C16.43 11.77 16.49 11.41 16.49 11.04C16.5 8.56 14.48 6.55 12 6.55C11.63 6.55 11.28 6.6 10.93 6.69ZM2.01 3.42L4.69 6.1C3.06 7.38 1.77 9.08 1 11.05C2.73 15.44 7 18.55 12 18.55C13.52 18.55 14.98 18.26 16.32 17.73L19.74 21.15L21.15 19.74L3.42 2L2.01 3.42ZM9.51 10.92L12.12 13.53C12.08 13.54 12.04 13.55 12 13.55C10.62 13.55 9.5 12.43 9.5 11.05C9.5 11 9.51 10.97 9.51 10.92ZM6.11 7.52L7.86 9.27C7.63 9.82 7.5 10.42 7.5 11.05C7.5 13.53 9.52 15.55 12 15.55C12.63 15.55 13.23 15.42 13.77 15.19L14.75 16.17C13.87 16.41 12.95 16.55 12 16.55C8.21 16.55 4.83 14.42 3.18 11.05C3.88 9.62 4.9 8.44 6.11 7.52Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
