<script setup lang="ts">
  import { useDictionaryStore } from '@/stores/dictionaryStore'
  import { useI18n } from 'vue-i18n'
  import CaseMenu from '@/components/BaseComponents/CaseComponents/CaseMenu.vue'
  import { useTableColumnsStore } from '@/stores/tableColumnsStore'
  import { formatCurrency } from '@/utils/number.extensions'
  import { useDialogStore } from '@/stores/dialogStore'
  import { onMounted, onUnmounted, StyleValue } from 'vue'
  import FavoriteStar from '@/components/BaseComponents/FavoriteStar.vue'
  import { IFavoriteInstrumentsProps } from '@/components/types'

  const w = window
  const { t } = useI18n()
  const tableColumnsStore = useTableColumnsStore()
  const dialogStore = useDialogStore()
  const dictionaryStore = useDictionaryStore()

  defineProps<IFavoriteInstrumentsProps>()

  const onRowClick = (event: MouseEvent, paperId: number, type: number) => {
    if (!dictionaryStore.isTrade(type)) {
      return false
    }
    dialogStore.openDialog('InstrumentInfo', {
      width: '1080',
      paperId
    })
  }

  onMounted(() => {
    w.addEventListener('scroll', handlerScroll)
  })

  onUnmounted(() => w.removeEventListener('scroll', handlerScroll))

  const handlerScroll = () => {
    const tableHeader = document.querySelector('.papers-header')
    if (tableHeader) {
      const parent = tableHeader.parentElement
      if (parent) {
        const table = document.querySelector('.papers-table')
        const tableRect = table ? table.getBoundingClientRect() : null
        const rect = parent.getBoundingClientRect()

        const headerHeight = tableHeader ? tableHeader.clientHeight : 0

        if (rect.top <= 80 && tableRect && tableRect.bottom > headerHeight) {
          tableHeader.classList.add('fixed-block')
        } else {
          tableHeader.classList.remove('fixed-block')
        }
      }
    }
  }
</script>

<template>
  <div class="papers-table">
    <div class="papers-header">
      <v-row class="papers-row">
        <template v-for="(column, key) in tableColumnsStore.cases.favorites.headers" :key="key">
          <v-col
            class="d-flex align-center bg-grey-lighten-4 mt-3 table-cell"
            :class="[
              `justify-${column.align}`,
              {
                'table-cell-name': column.key === 'name',
                'custom-cell': column.key === 'custom'
              }
            ]"
          >
            <CaseMenu v-if="column.key === 'paperId'" tab-type="favorites" />
            <div
              v-else
              class="body-b3"
              v-html="column.title ? t(column.title as string) : ''"
            ></div>
          </v-col>
        </template>
      </v-row>
    </div>
    <template v-for="(item, key) in items" :key="key">
      <v-row
        class="cursor-pointer paper-row rounded-lg"
        @click="onRowClick($event, item.paperId, item.type)"
      >
        <template v-for="(column, key2) in tableColumnsStore.cases.favorites.headers" :key="key2">
          <v-col
            v-if="column.key === 'paperId'"
            class="d-flex align-center justify-center table-cell pa-0"
          >
            <v-sheet
              class="d-flex justify-center align-center bg-transparent"
              width="48"
              height="48"
            >
              <v-img
                rounded="lg"
                :src="`${w.env?.VITE_STATIC_BASE_URL || 'https://static.lk.ricom.ru'}/media/logo/instruments/${item.paperId}.png`"
                lazy-src="/img/no-img.png"
                :alt="item.ticker"
              />
            </v-sheet>
          </v-col>
          <v-col
            v-if="column.key === 'ticker'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
            >
              {{ item.ticker ?? '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'name'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0 table-cell-name"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
            >
              {{ item.title ?? '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'price'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.price ? formatCurrency(item.price) + '&nbsp;₽' : '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'lot'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.lot ?? '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'marginal'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.marginal === null ? '–' : item.marginal ? 'Да' : 'Нет' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'changesCurrency'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.changesCurrency ? formatCurrency(item.changesCurrency) : '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'changesPercent'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              {{ item.changesPercent > 0 ? '+' : '' }}{{ item.changesPercent ?? '–' }}
            </div>
          </v-col>
          <v-col
            v-if="column.key === 'custom'"
            :class="[`justify-${column.headerProps?.cellAlign ?? column.align}`]"
            class="d-flex table-cell align-center pa-0 custom-cell"
          >
            <div
              :style="
                {
                  fontWeight: column.headerProps?.isBold ? '600' : 'normal'
                } as StyleValue
              "
              :class="column.headerProps?.class"
            >
              <FavoriteStar :id="item.paperId" :name="item.ticker" />
            </div>
          </v-col>
        </template>
      </v-row>
    </template>
  </div>
</template>

<style scoped lang="scss">
  .th-gradient-bar {
    height: fit-content !important;
    background-color: var(--color-AdditionalLightGrey) !important;
    padding: 0 !important;
  }
  .label-color {
    color: var(--color-BlackInactive);
  }

  .fixed-block {
    position: fixed;
    top: 80px;
    height: 80px;
    left: 0;
    width: 100%;
    z-index: 201;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 245, 245);
    .papers-row {
      width: 1280px;
      padding: 0 12px;
      margin: -12px 0 0 0;
    }
  }

  .table-cell {
    flex-basis: 0;
    min-width: 100px;
    flex-grow: 1;
    padding: 12px 0;
    &.table-cell-name {
      min-width: 445px;
    }
    &.custom-cell {
      min-width: unset;
      max-width: 50px;
    }
  }

  .papers-row {
    margin: -12px 0;
  }

  .paper-row {
    height: 72px;
    margin: 10px 0;
  }

  .paper-row:hover {
    background-color: var(--color-BlackHover);
  }
</style>
