<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 21L13 18H22V21H10ZM4 19H5.4L15.45 9.225L14.025 7.8L4 17.6V19ZM2 21V16.75L15.45 3.575C15.8333 3.19167 16.3043 3 16.863 3C17.421 3 17.8917 3.19167 18.275 3.575L19.675 5C20.0583 5.38333 20.25 5.85 20.25 6.4C20.25 6.95 20.0583 7.41667 19.675 7.8L6.25 21H2Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
