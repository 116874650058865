import { validation } from '@/langs/ru/validation'
import { auth } from '@/langs/ru/auth'
import { footer } from '@/langs/ru/footer'
import { header } from '@/langs/ru/header'
import { trading } from '@/langs/ru/trading'
import { history } from '@/langs/ru/history'
import { order } from '@/langs/ru/order'
// @ts-ignore
import { portfolio } from '@/langs/ru/portfolio'
// @ts-ignore
import { accountMenu } from '@/langs/ru/accountMenu'
import { account } from '@/langs/ru/account'
import { modal } from '@/langs/ru/modal'
import { informer } from '@/langs/ru/informer'
import { faq } from '@/langs/ru/faq'
import { info } from '@/langs/ru/info'
import { columnsSettings } from '@/langs/ru/columnsSettings'
import { notification } from '@/langs/ru/notification'
import { services } from '@/langs/ru/services'
import { report } from '@/langs/ru/report'
import { topUpAccount } from '@/langs/ru/topUpAccount'
import { feedback } from '@/langs/ru/feedback'
import { welcomeTips } from '@/langs/ru/welcomeTips'
import { registration } from '@/langs/ru/registration'

// @ts-ignore
export const ru = {
  validation: validation,
  auth: auth,
  footer,
  header,
  trading,
  order,
  accountMenu,
  portfolio,
  history,
  account,
  modal,
  informer,
  faq,
  info,
  columnsSettings,
  notification,
  services,
  report,
  topUpAccount,
  feedback,
  welcomeTips,
  registration,
  welcome: 'Добро пожаловать',
  next: 'Далее',
  promoTitleInactive: 'Для начала торговли',
  promoTitlePurple: 'необходимо пополнить',
  promoTitleTeal: 'брокерский счет',
  btnReplenishAccount: 'Пополнить счет',
  btnMoveSecurities: 'Перевести бумаги',
  freeMoneyTitle: 'Свободные деньги',
  finResultTitle: 'Финансовый результат',
  portfolioValueTitle: 'Стоимость портфеля',
  btnBuy: 'Купить',
  btnSell: 'Продать',
  informationCostPortfolio: 'Стоимость портфеля',
  informationCostFreeMoney: 'Свободные деньги',
  informationFinResult: 'Финансовый результат',
  informationGraph: 'История стоимости портфеля',
  favoriteTitle: 'Мои инструменты',
  switcherExpireToday: 'Сегодня',
  switcherExpireMonth: 'Месяц',
  switcherExpirePrepend: 'Срок действия:',
  companyName: 'АО ИК «Риком-Траст»',
  connectionRestored: 'Соединение с интернетом восстановлено',
  connectionLost: 'Соединение с интернетом потеряно',
  errorTemporaryUnavailable: {
    message: 'Что то пошло не так.',
    description: 'Сервис временно недоступен'
  },
  errorSessionLost: {
    message: 'Мы вас не дождались...',
    description: 'В связи с отсутствием активности сессия была закрыта для вашей безопасности.',
    ps: 'Пожалуйста, зайдите снова.'
  },
  focusTesting: {
    message: 'Проводится закрытое тестирование.',
    description: 'Для участия в тестировании позвоните в клиентский отдел: +7 (800) 200-53-07'
  },
  million: 'млн',
  billion: 'млрд',
  title: {
    auth: 'Личный кабинет — Логин',
    registration: 'Личный кабинет — Регистрация',
    portfolio: 'Личный кабинет — Портфель',
    trading: 'Личный кабинет — Торговля',
    services: 'Личный кабинет — Сервисы',
    feedback: 'Личный кабинет — Обратная связь',
    account: 'Личный кабинет — Аккаунт',
    account_: {
      bank: 'Личный кабинет — Пополнение брокерского счета'
    },
    services_: {
      reports: 'Личный кабинет — Отчет'
    },
    notfound: 'Личный кабинет — 404',
    'history-orders': '',
    'history-transactions': ''
  },
  wellDone: 'Хорошо'
}
