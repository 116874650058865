import 'vuetify/styles'
import './styles/global.scss'
import './assets/fonts/fonts.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import i18n from '@/utils/i18n'
import vuetify from '@/utils/vuetify'
import activityWatcher from '@/utils/activityWatcher.extensions'
import Notifications from '@kyvg/vue3-notification'
import updateLocale from 'dayjs/plugin/updateLocale'
import arraySupport from 'dayjs/plugin/arraySupport'
import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import authService from '@/api/authService'
import { useNotify } from '@/stores/notify'
import * as Sentry from '@sentry/vue'
import { initYandexMetrika } from 'yandex-metrika-vue3'
import { createHead } from '@unhead/vue/client'

const app = createApp(App)
const pinia = createPinia()
const head = createHead()

const sentryEnvironment = window.location.hostname.includes('localhost')
  ? 'development'
  : import.meta.env.VITE_USER_NODE_ENV

Sentry.init({
  app,
  dsn: `https://${import.meta.env.SENTRY_KEY}@sentry.ricom.ru/3`,
  environment: sentryEnvironment,
  release: window.env.APP_VERSION,
  tracingOptions: {
    trackComponents: true,
    hooks: ['mount', 'unmount'],
    timeout: 500
  },
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  logErrors: false,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/(test-)?(lk|static|api)\.ricom/],
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.config.errorHandler = (err, vm, info) => {
  console.error(err, info)
  // Здесь можно вручную отправить ошибку в Sentry, если нужно
  Sentry.captureException(err, { extra: { info } })
}

dayjs.extend(updateLocale)
dayjs.extend(arraySupport)
dayjs.locale('ru')
dayjs.updateLocale('ru', {
  weekStart: 1
})

pinia.use(piniaPluginPersistedstate)
pinia.use(Sentry.createSentryPiniaPlugin())

app.use(pinia)
app.use(i18n)
app.use(router)
app.use(vuetify)
app.use(Notifications)
app.use(head)
app.use(activityWatcher, {
  logout: async () => {
    if (router.currentRoute.value.name === 'auth') {
      return
    }
    console.log('No activity, logout')

    const notify = useNotify()
    await authService.logout()
    notify.show(i18n.global.t('notification.sessionEnd.message'), '', 'warn', 'session')
    await router.push({ name: 'auth' })
  }
})

if (import.meta.env.VITE_USER_NODE_ENV === 'production') {
  initYandexMetrika(app, {
    id: '99326149',
    router: router,
    env: 'production',
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      triggerEvent: true
    },
    scriptSrc: 'https://mc.yandex.ru/metrika/tag.js'
  })
}

app.mount('#app')
