<script setup lang="ts">
  import BaseInput from '@/components/BaseComponents/BaseInput.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { useRegistrationStore } from '@/stores/registrationStore'
  import IconHelpFill from '@/components/icons/IconHelpFill.vue'
  import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox.vue'
  import DatePickerV from '@/components/DatePickerV.vue'
  import BaseRadio from '@/components/BaseComponents/BaseRadio.vue'
  import { useDialogStore } from '@/stores/dialogStore'
  import { computed, ComputedRef, reactive, ref } from 'vue'
  import { helpers, required, requiredIf } from '@vuelidate/validators'
  import { useVuelidate } from '@vuelidate/core'
  import { useI18n } from 'vue-i18n'
  import AdditionalDataForm from '@/components/Registration/Step2/AdditionalDataForm.vue'
  import { debounce } from 'lodash-es'
  import { dadataService } from '@/api/dadataService'
  import { IDaDataAddress } from '@/api/types'
  import { useDisplay } from 'vuetify'
  import { filterNumber } from '@/utils/filterNumber.extensions'
  import { validateInn } from '@/utils/customValidationRules'
  import registrationService from '@/api/registrationService'

  const { t } = useI18n()
  const registrationStore = useRegistrationStore()
  const dialogStore = useDialogStore()
  const { mobile } = useDisplay()
  const $externalResults = ref<{ [key: string]: string[] }>({})
  const checkPersonFail = ref(true)

  const fields = [
    'lastName',
    'firstName',
    'middleName',
    'passportSerial',
    'passportNumber',
    'passportDate',
    'passportIssuedBy',
    'passportCodeDepartment',
    'inn',
    'addressRegistration'
  ] as const

  interface IAddressData {
    value: string
    unrestricted_value: string
    city_district_kladr_id: string
    country_iso_code: string
    area_kladr_id: string
    settlement_kladr_id: string
    kladr_id: string
    fias_id: string
    region_kladr_id: string
    city_kladr_id: string
    street_kladr_id: string
    house_kladr_id: string
    flat: string
  }

  type RegistrationState = {
    [key in (typeof fields)[number]]: ComputedRef<string | null>
  }

  const addressData = ref<IAddressData[]>([])
  const addressSelectList = computed(() => {
    return addressData.value.map(item => item.value)
  })

  const state = reactive<RegistrationState>(
    fields.reduce((acc, field) => {
      acc[field] = computed({
        get: () => registrationStore.data[field],
        set: (value: string) => (registrationStore.data[field] = value)
      })
      return acc
    }, {} as RegistrationState)
  )

  const rules = {
    lastName: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.lastName') }),
        required
      ),
      $autoDirty: true
    },
    firstName: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.firstName') }),
        required
      ),
      $autoDirty: true
    },
    middleName: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.middleName') }),
        requiredIf(() => !registrationStore.data.noMiddleName)
      ),
      $autoDirty: true
    },
    passportSerial: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.passportSerial') }),
        required
      ),
      $autoDirty: true
    },
    passportNumber: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.passportNumber') }),
        required
      ),
      $autoDirty: true
    },
    passportDate: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.passportDate') }),
        required
      ),
      $autoDirty: true
    },
    passportIssuedBy: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.passportIssuedBy') }),
        required
      ),
      $autoDirty: true
    },
    passportCodeDepartment: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.passportCodeDepartment') }),
        required
      ),
      $autoDirty: true
    },
    inn: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.inn') }),
        required
      ),
      mustBeInn: helpers.withMessage(t('validation.mustBeInn'), validateInn),
      $autoDirty: true
    },
    addressRegistration: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.addressRegistration') }),
        required
      ),
      $autoDirty: true
    }
  }

  const v$ = useVuelidate(rules, state, { $externalResults })

  const nextForm = () => {
    v$.value.$touch()

    if (!v$.value.$invalid) {
      if (!registrationStore.additionalParamsFormShow) {
        registrationStore.additionalParamsFormShow = true
      } else {
        registrationStore.step = '3'
      }
    }
  }

  const onAddressChange = debounce(async (q: string) => {
    if (q.length < 1) {
      addressData.value = []
      return void 0
    }
    console.log('onAddressChange', q)
    try {
      const { data } = await dadataService.address(q)
      console.log('onAddressChange', data)
      if (data.suggestions.length > 0) {
        const addresses = data.suggestions as IDaDataAddress[]
        addressData.value = addresses.map(item => ({
          value: item.value,
          unrestricted_value: item.unrestricted_value,
          city_district_kladr_id: item.data.city_district_kladr_id,
          country_iso_code: item.data.country_iso_code,
          area_kladr_id: item.data.area_kladr_id,
          settlement_kladr_id: item.data.settlement_kladr_id,
          kladr_id: item.data.kladr_id,
          fias_id: item.data.fias_id,
          region_kladr_id: item.data.region_kladr_id,
          city_kladr_id: item.data.city_kladr_id,
          street_kladr_id: item.data.street_kladr_id,
          house_kladr_id: item.data.house_kladr_id,
          flat: item.data.flat
        })) as IAddressData[]
      }
    } catch (e) {
      console.error('onAddressChange', e)
    }
  }, 1000)

  const updateAddressData = (q: string) => {
    const a = addressData.value.find(item => q === item.value)
    if (a) {
      registrationStore.data.addressRegistrationKladr = {
        countryIsoCode: a.country_iso_code,
        areaKladrId: a.area_kladr_id,
        cityDistrictKladrId: a.city_district_kladr_id,
        settlementKladrId: a.settlement_kladr_id,
        kladrId: a.kladr_id,
        regionKladrId: a.region_kladr_id,
        cityKladrId: a.city_kladr_id,
        streetKladrId: a.street_kladr_id,
        houseKladrId: a.house_kladr_id,
        flat: a.flat
      }
    }
  }

  const filterInput = (
    field: 'passportSerial' | 'passportNumber' | 'inn',
    event: KeyboardEvent
  ) => {
    state[field] = filterNumber(event)
    v$.value[field].$touch()
  }

  const handleInputDebounced = debounce(async () => {
    await checkPerson()
  }, 400)

  const checkPerson = async () => {
    if (
      registrationStore.data.firstName &&
      registrationStore.data.lastName &&
      ((registrationStore.data.middleName && !registrationStore.data.noMiddleName) ||
        registrationStore.data.noMiddleName) &&
      registrationStore.data.inn
    ) {
      try {
        const checkPersonData = {
          phoneNumber: registrationStore.data.phoneNumber,
          firstName: registrationStore.data.firstName,
          lastName: registrationStore.data.lastName,
          middleName: registrationStore.data.middleName,
          noMiddleName: registrationStore.data.noMiddleName,
          inn: registrationStore.data.inn
        }
        await registrationService.checkPerson(checkPersonData)
        checkPersonFail.value = false
      } catch (error: any) {
        if (error.status === 409) {
          checkPersonFail.value = true
          $externalResults.value.inn = [t('validation.conflictPerson')]
        }
      }
    }
  }
</script>

<template>
  <v-sheet class="d-flex flex-column align-center justify-center" max-width="485">
    <v-sheet class="title-h2 mb-4">{{ t('registration.steps.step2.title') }}</v-sheet>
    <v-sheet class="body-b3 mb-8">{{ t('registration.steps.step2.subtitle') }}</v-sheet>
    <BaseButton
      :label="t('registration.steps.step2.interviewBtn')"
      variant="text"
      class="mb-4"
      @click="dialogStore.openDialog('RegistrationTip', { width: mobile ? '360' : '400' })"
    >
      <template #[`icon`]>
        <IconHelpFill color="rgb(105, 108, 236)" />
      </template>
    </BaseButton>
    <v-sheet class="d-flex flex-column align-start w-100 ga-2">
      <v-sheet class="title-h2">{{ t('registration.steps.step2.personalData.title') }}</v-sheet>
      <v-sheet class="mb-2">
        <v-radio-group v-model="registrationStore.data.resident" inline hide-details>
          <BaseRadio
            :label="t('registration.steps.step2.personalData.rezidentYes')"
            :value="true"
            hide-details
          />
          <BaseRadio
            :label="t('registration.steps.step2.personalData.rezidentNo')"
            :value="false"
            hide-details
          />
        </v-radio-group>
      </v-sheet>
      <BaseInput
        v-model="state.lastName"
        :label="t('registration.lastName')"
        :error-messages="v$.lastName.$errors.map(e => e.$message)"
        class="w-100"
        @blur="v$.lastName.$touch()"
        @input="v$.lastName.$touch()"
        @keyup="handleInputDebounced"
      />
      <BaseInput
        v-model="state.firstName"
        :label="t('registration.firstName')"
        :error-messages="v$.firstName.$errors.map(e => e.$message)"
        class="w-100"
        @blur="v$.firstName.$touch()"
        @input="v$.firstName.$touch()"
        @keyup="handleInputDebounced"
      />
      <BaseInput
        v-if="!registrationStore.data.noMiddleName"
        v-model="state.middleName"
        :error-messages="v$.middleName.$errors.map(e => e.$message)"
        :label="t('registration.middleName')"
        class="w-100"
        @blur="v$.middleName.$touch()"
        @input="v$.middleName.$touch()"
        @keyup="handleInputDebounced"
      />
      <v-sheet class="d-flex">
        <BaseCheckbox v-model="registrationStore.data.noMiddleName" class="mr-2" />
        <v-sheet>{{ t('registration.steps.step2.personalData.noMiddleName') }}</v-sheet>
      </v-sheet>
      <BaseInput
        v-model="state.inn"
        :error-messages="v$.inn.$errors.map(e => e.$message)"
        :label="t('registration.inn')"
        class="w-100"
        input-mode="numeric"
        @blur="v$.inn.$touch()"
        @input="filterInput('inn', $event)"
        @keyup="handleInputDebounced"
      />
      <BaseInput
        v-model="state.passportSerial"
        :error-messages="v$.passportSerial.$errors.map(e => e.$message)"
        :label="t('registration.passportSerial')"
        class="w-100"
        @blur="v$.passportSerial.$touch()"
        @input="filterInput('passportSerial', $event)"
      />
      <BaseInput
        v-model="state.passportNumber"
        :error-messages="v$.passportNumber.$errors.map(e => e.$message)"
        :label="t('registration.passportNumber')"
        class="w-100"
        @blur="v$.passportNumber.$touch()"
        @input="filterInput('passportNumber', $event)"
      />
      <DatePickerV
        v-model="state.passportDate"
        density="default"
        :max-date="new Date()"
        :error-messages="v$.passportDate.$errors"
        :label="t('registration.passportDate')"
        class="w-100"
      />
      <BaseInput
        v-model="state.passportIssuedBy"
        :error-messages="v$.passportIssuedBy.$errors.map(e => e.$message)"
        :label="t('registration.passportIssuedBy')"
        class="w-100"
        @blur="v$.passportIssuedBy.$touch()"
        @input="v$.passportIssuedBy.$touch()"
      />
      <BaseInput
        v-model="state.passportCodeDepartment"
        :error-messages="v$.passportCodeDepartment.$errors.map(e => e.$message)"
        :label="t('registration.passportCodeDepartment')"
        class="w-100"
        @blur="v$.passportCodeDepartment.$touch()"
        @input="v$.passportCodeDepartment.$touch()"
      />
      <VAutocomplete
        v-model="state.addressRegistration"
        :error-messages="v$.addressRegistration.$errors.map(e => e.$message) as string[]"
        :label="t('registration.addressRegistration')"
        variant="outlined"
        :no-data-text="t('registration.steps.step2.personalData.noDataText')"
        class="w-100"
        aria-autocomplete="none"
        autocomplete="off"
        filter-mode="every"
        :custom-filter="() => true"
        :items="addressSelectList"
        @update:model-value="updateAddressData"
        @update:search="onAddressChange"
        @blur="v$.addressRegistration.$touch()"
        @input="v$.addressRegistration.$touch()"
      />
      <v-sheet class="d-flex justify-start body-b1-bold ga-2">
        <v-sheet>{{ t('registration.steps.step2.personalData.titleOfficial') }}</v-sheet>
        <v-sheet v-if="!mobile">
          <v-tooltip
            location="bottom left"
            max-width="280"
            :offset="[4, -12]"
            content-class="elevation-16-dp border-sm"
          >
            <template #activator="{ props }">
              <v-icon v-bind="props" icon="help-fill" size="sm"></v-icon>
            </template>
            <v-sheet class="mb-2">
              Публичное должностное лицо (ПДЛ) — человек, который занимает значимую государственную
              должность сейчас или находился на этом посту раньше. Работает в законодательном,
              исполнительном, судебном или административном органе и выполняет государственные
              функции.
            </v-sheet>
            <v-sheet>
              Также к ПДЛ относятся связанные с госслужащими лица, супруги и близкие родственники:
              родители, дети, бабушки и дедушки, внуки, братья и сестры, усыновители и усыновленные.
            </v-sheet>
          </v-tooltip>
        </v-sheet>
      </v-sheet>
      <v-sheet class="body-b1 mb-4">
        <v-radio-group v-model="registrationStore.data.official" inline hide-details>
          <BaseRadio
            :label="t('registration.steps.step2.personalData.officialNo')"
            :value="false"
            hide-details
            class="text-black"
          />
          <BaseRadio
            :label="t('registration.steps.step2.personalData.officialYes')"
            :value="true"
            hide-details
          />
        </v-radio-group>
      </v-sheet>
    </v-sheet>
    <AdditionalDataForm v-if="registrationStore.additionalParamsFormShow" />
    <BaseButton
      :label="t('registration.nextButton')"
      class="w-100 mb-10"
      :disabled="checkPersonFail"
      @click="nextForm"
    />
  </v-sheet>
</template>

<style scoped lang="scss"></style>
