<template>
  <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="indicator">
      <g id="Icon" filter="url(#filter0_d_11970_8400)">
        <path
          d="M4.41421 3C3.52331 3 3.07714 4.07714 3.70711 4.70711L10.2929 11.2929C10.6834 11.6834 11.3166 11.6834 11.7071 11.2929L18.2929 4.7071C18.9229 4.07714 18.4767 3 17.5858 3H4.41421Z"
          :fill="color"
        />
        <path
          d="M4.06066 4.35355C3.74568 4.03857 3.96876 3.5 4.41421 3.5H17.5858C18.0312 3.5 18.2543 4.03857 17.9393 4.35355L11.3536 10.9393C11.1583 11.1346 10.8417 11.1346 10.6464 10.9393L4.06066 4.35355Z"
          :stroke="stroke"
          stroke-opacity="0.88"
        />
      </g>
      <g id="Icon_2" filter="url(#filter1_d_11970_8400)">
        <path
          d="M17.5858 27C18.4767 27 18.9229 25.9229 18.2929 25.2929L11.7071 18.7071C11.3166 18.3166 10.6834 18.3166 10.2929 18.7071L3.70711 25.2929C3.07714 25.9229 3.52331 27 4.41421 27L17.5858 27Z"
          :fill="color"
        />
        <path
          d="M17.9393 25.6464C18.2543 25.9614 18.0312 26.5 17.5858 26.5L4.41421 26.5C3.96876 26.5 3.74568 25.9614 4.06066 25.6464L10.6464 19.0607C10.8417 18.8654 11.1583 18.8654 11.3536 19.0607L17.9393 25.6464Z"
          :stroke="stroke"
          stroke-opacity="0.88"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_11970_8400"
        x="0.412109"
        y="0"
        width="21.1758"
        height="14.5858"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11970_8400" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_11970_8400"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_11970_8400"
        x="0.412109"
        y="15.4142"
        width="21.1758"
        height="14.5858"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11970_8400" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_11970_8400"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script setup>
  import { computed } from 'vue'

  const { color } = defineProps({
    color: {
      type: String,
      default: '#F5F5F5'
    }
  })
  const stroke = computed(() => (color === '#F5F5F5' ? 'black' : 'white'))
</script>
