<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.1399 7.55392L7.55553 8.96921C6.58897 10.0343 6 11.4484 6 13C6 15.9731 8.16143 18.5031 10.9992 18.9791L10.9991 21.0001C7.05276 20.508 4 17.0796 4 13C4 11.0069 4.72886 9.18404 5.93458 7.78341L6.1399 7.55392ZM11 3L12.4142 4.41421L11.825 5.00188L12 5C16.4183 5 20 8.58172 20 13C20 17.0793 16.9458 20.5075 13 21L12.9999 18.9789C15.8372 18.5025 18 15.9727 18 13C18 9.68629 15.3137 7 12 7L11.832 7.003L12.4142 7.58579L11 9L8 6L11 3Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
