<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M11.4485 11H13.4485V4C13.4485 3.71667 13.3527 3.47917 13.161 3.2875C12.9694 3.09583 12.7319 3 12.4485 3C12.1652 3 11.9277 3.09583 11.736 3.2875C11.5444 3.47917 11.4485 3.71667 11.4485 4V11ZM5.44853 15H19.4485V13H5.44853V15ZM3.99853 21H6.44853V19C6.44853 18.7167 6.54436 18.4792 6.73603 18.2875C6.9277 18.0958 7.1652 18 7.44853 18C7.73186 18 7.96936 18.0958 8.16103 18.2875C8.3527 18.4792 8.44853 18.7167 8.44853 19V21H11.4485V19C11.4485 18.7167 11.5444 18.4792 11.736 18.2875C11.9277 18.0958 12.1652 18 12.4485 18C12.7319 18 12.9694 18.0958 13.161 18.2875C13.3527 18.4792 13.4485 18.7167 13.4485 19V21H16.4485V19C16.4485 18.7167 16.5444 18.4792 16.736 18.2875C16.9277 18.0958 17.1652 18 17.4485 18C17.7319 18 17.9694 18.0958 18.161 18.2875C18.3527 18.4792 18.4485 18.7167 18.4485 19V21H20.8985L19.8985 17H4.99853L3.99853 21ZM20.8985 23H3.99853C3.34853 23 2.82353 22.7417 2.42353 22.225C2.02353 21.7083 1.90686 21.1333 2.07353 20.5L3.44853 15V13C3.44853 12.45 3.64436 11.9792 4.03603 11.5875C4.4277 11.1958 4.89853 11 5.44853 11H9.44853V4C9.44853 3.16667 9.7402 2.45833 10.3235 1.875C10.9069 1.29167 11.6152 1 12.4485 1C13.2819 1 13.9902 1.29167 14.5735 1.875C15.1569 2.45833 15.4485 3.16667 15.4485 4V11H19.4485C19.9985 11 20.4694 11.1958 20.861 11.5875C21.2527 11.9792 21.4485 12.45 21.4485 13V15L22.8235 20.5C23.0402 21.1333 22.9444 21.7083 22.536 22.225C22.1277 22.7417 21.5819 23 20.8985 23Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
