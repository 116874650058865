<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { VOnboardingWrapper, VOnboardingStep, useVOnboarding, StepEntity } from 'v-onboarding'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { useI18n } from 'vue-i18n'
  import { useWelcomeTipsStore } from '@/stores/welcomeTipsStore'
  import { useRoute } from 'vue-router'
  import { useCookieConsentStore } from '@/stores/cookieConsentStore'
  import authService from '@/api/authService'
  import { Placement } from '@popperjs/core/lib/enums'
  import { useDisplay } from 'vuetify'

  const { t } = useI18n()
  const wrapper = ref(null)
  const welcomeTipsStore = useWelcomeTipsStore()
  const route = useRoute()
  const cookie = useCookieConsentStore()
  const { start, finish } = useVOnboarding(wrapper)

  const steps = computed<StepEntity[]>(() => {
    return welcomeTipsStore.getStepsByPage(route.name as string).map(item => {
      return {
        attachTo: { element: item.element, classList: item.classList },
        content: { title: t(item.title) },
        options: {
          popper: {
            placement: item.placement as Placement
          }
        }
      }
    })
  })

  const needShow = computed(() => {
    return welcomeTipsStore.show && cookie.consentGiven
  })

  const completeWelcomeTips = () => {
    if (route.name) {
      const ver = [
        ...new Set(welcomeTipsStore.getStepsByPage(route.name as string).map(item => item.version))
      ]
      if (ver.length > 0) {
        authService.authWelcomeTips(ver as string[])
        welcomeTipsStore.setCheckedVersions(ver as string[])
      }
    }
  }

  watch(
    () => [needShow.value, steps.value],
    newState => {
      const val1 = newState[0] as boolean
      const val2 = newState[1] as []

      if (val1 && val2.length > 0) {
        start()
      } else {
        finish()
      }
    },
    { flush: 'post' }
  )
</script>

<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps">
    <template #default="{ previous, next, step, exit, isFirst, isLast, index }">
      <VOnboardingStep>
        <v-sheet
          w="480"
          class="d-flex flex-column ga-6 pa-6 rounded-lg overlay-content position-absolute"
        >
          <v-sheet class="body-b1">{{ step.content.title }}</v-sheet>
          <v-sheet class="d-flex justify-space-between align-center">
            <v-sheet class="d-flex ga-4">
              <BaseButton
                v-if="!isFirst"
                :label="t('welcomeTips.btnPrev')"
                variant="outlined"
                @click="previous"
              />
              <BaseButton v-if="!isLast" :label="t('welcomeTips.btnNext')" @click="next" />
              <BaseButton
                v-if="isLast"
                :label="t('welcomeTips.btnComplete')"
                @click="completeWelcomeTips"
              />
            </v-sheet>
            <v-sheet class="body-b1-bold">{{ index + 1 }}/{{ steps.length }}</v-sheet>
          </v-sheet>
        </v-sheet>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<style scoped lang="scss"></style>
