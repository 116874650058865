<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { computed } from 'vue'
  import { IBlockInformation } from '@/components/types'
  //import SimpleChart from '@/components/SimpleChart.vue'

  const { label = '', showPercent = false } = defineProps<IBlockInformation>()

  const currency = defineModel<number>('currency')
  const percent = defineModel<number>('percent')
  const loading = defineModel<boolean>('loading')

  const { t } = useI18n()
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB'
  })
  const value = computed(() => {
    return formatter.format(currency.value ?? 0)
  })
</script>

<template>
  <div class="block-information elevation-01-dp flex-1-1-100">
    <v-skeleton-loader v-if="loading" width="100%" type="heading"></v-skeleton-loader>
    <div v-if="!loading" class="body-b3 text-no-wrap">{{ label }}</div>
    <div v-if="!loading && value" class="title-h3 text-no-wrap">
      {{ value + (showPercent ? ` / ${percent}%` : '') }}
    </div>
    <!--    <SimpleChart v-if="value === '0,00&nbsp;₽'" />-->
  </div>
</template>

<style scoped lang="scss">
  .block-information {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 8px;
    background: var(--color-WhiteWhite);
    .body-b3 {
      color: var(--color-BlackActive);
    }
  }
</style>
