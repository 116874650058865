<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M19 19H5V5H11V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V13H19V19ZM14 3V5H17.59L14 9L15.41 10.41L19 6.41V10H21V3H14Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
    <path
      d="M12.5 17H10.5V14H7.5V12H10.5V9H12.5V12H15.5V14H12.5V17Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
