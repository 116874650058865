<script setup lang="ts">
  import IconSearch from '@/components/icons/IconSearch.vue'
  import { ref, shallowRef, watch } from 'vue'
  import { IBaseInputSearchState } from '@/components/types'
  import FavoriteStar from '@/components/BaseComponents/FavoriteStar.vue'
  import { useLotsStore } from '@/stores/lotsStore'
  import { useDictionaryStore } from '@/stores/dictionaryStore'
  import { useTradingStore } from '@/stores/tradingStore'
  import { useI18n } from 'vue-i18n'
  import IconClose from '@/components/icons/IconClose.vue'
  import { debounce } from 'lodash-es'
  import { useDialogStore } from '@/stores/dialogStore'
  import IconCancel from '@/components/icons/IconCancel.vue'
  import { ILotsModel } from '@/api/types'
  import { useDisplay } from 'vuetify'

  type Density = null | 'default' | 'comfortable' | 'compact'

  interface ContentItem {
    name: string
    ticker: string
    isin: string
    paperId: number
    typeId: number
    isFavorite: boolean
  }

  // Props
  defineProps<IBaseInputSearchState & { label: string; density: Density }>()
  // количество результатов при поиске для отрисовки
  const countResult = 20
  const w = window
  const { t } = useI18n()
  const search = ref()
  const display = useDisplay()
  const lotsStore = useLotsStore()
  const dictionaryStore = useDictionaryStore()
  const tradingStore = useTradingStore()
  const dialogStore = useDialogStore()
  const overlayModel = ref(false)
  const offset = ref([-124, display.mobile.value ? 0 : 185])
  const content = shallowRef<ContentItem[]>([])
  const mobileInputRef = ref()

  const onFocusIn = async () => {
    overlayModel.value = true
    window.scrollBy({ top: -200, behavior: 'smooth' })
  }

  const handleInputDebounced = debounce(() => {
    searchHandle()
  }, 400)

  const searchHandle = () => {
    if (search.value?.trim()) {
      const value = search.value?.toLowerCase().trim()
      content.value = lotsStore.lots
        .filter(({ name, ticker, isin }) => {
          const combineFields = `${name} ${ticker} ${isin}`.toLowerCase()
          return combineFields.includes(value)
        })
        .map((item: ILotsModel): ContentItem => {
          return {
            ...item,
            isFavorite: tradingStore.isFavorite(item.paperId)
          } as ContentItem
        })
        .slice(0, countResult)
    } else {
      content.value = []
    }
  }

  const handleInput = () => {
    handleInputDebounced()
  }

  const updateFavorites = () => {
    tradingStore.load()
    searchHandle()
  }

  const onOpenOverlay = () => {
    if (display.mobile.value && overlayModel.value) {
      const searchElement = document.getElementById('searchInputRef') as HTMLInputElement
      searchElement.focus()
    }
  }

  const onClickInstrument = (event: MouseEvent, paperId: number, type: number) => {
    if (!dictionaryStore.isTrade(type)) {
      return false
    }
    const mobileDialogProps = {
      width: '100%',
      fullscreen: true
    }
    const defaultDialogProps = {
      width: '1080',
      paperId
    }
    dialogStore.openDialog(
      'InstrumentInfo',
      display.mobile.value
        ? { ...defaultDialogProps, ...mobileDialogProps }
        : { ...defaultDialogProps }
    )
  }
</script>

<template>
  <v-text-field
    :id="nameField"
    v-model="search"
    type="search"
    autocomplete="off"
    :label="label"
    variant="outlined"
    hide-details
    :density="density"
    :class="[
      withClass,
      'search-input-overlay',
      overlayModel ? 'search-input-overlay-zindex' : '',
      display.mobile.value ? 'search-input-mobile' : ''
    ]"
    clearable
    @focus="onFocusIn"
    @input="handleInput"
  >
    <template #default>
      <v-overlay
        v-model="overlayModel"
        class="search-overlay"
        :class="{ 'search-mobile-overlay': display.mobile.value }"
        :location-strategy="display.mobile.value ? 'static' : 'connected'"
        activator="parent"
        :offset="offset"
        :open-on-click="false"
        open-delay="200"
        :content-class="display.mobile.value ? 'mobile-search' : ''"
        :height="display.mobile.value ? '100%' : 'auto'"
        @after-enter="onOpenOverlay"
      >
        <template #default="{ isActive }">
          <v-sheet
            static
            elevation="2"
            border="xs"
            floating
            rounded="lg"
            color="white"
            :width="display.mobile.value ? '100%' : '768px'"
            max-height="548"
            :height="display.mobile.value ? '100%' : 'auto'"
            class="py-6"
          >
            <v-sheet class="d-flex flex-column ga-6" max-height="548">
              <v-sheet class="d-flex justify-space-between px-6">
                <v-sheet class="title-h2">{{ t('trading.searching.title') }}</v-sheet>
                <v-sheet>
                  <v-icon
                    role="img"
                    class="btn-close"
                    :class="{ 'btn-close-mobile': display.mobile.value }"
                    @click="isActive.value = false"
                  >
                    <IconClose />
                  </v-icon>
                </v-sheet>
              </v-sheet>
              <v-text-field
                v-if="display.mobile.value"
                id="searchInputRef"
                ref="mobileInputRef"
                v-model="search"
                type="search"
                class="px-4"
                autocomplete="off"
                :label="label"
                variant="outlined"
                hide-details
                :density="density"
                clearable
                @input="handleInput"
              ></v-text-field>
              <v-sheet
                :style="!display.mobile.value ? 'margin-top: 68px' : ''"
                class="overflow-auto pb-6 d-flex flex-column"
                :class="{ 'px-6': !display.mobile.value, 'px-0': display.mobile.value }"
                max-height="376"
              >
                <v-row
                  v-for="(item, index) in content"
                  :key="index"
                  class="table-row align-center ma-0 px-4 py-3 justify-space-between search-result-row rounded-lg cursor-pointer"
                  @click.stop="onClickInstrument($event, item.paperId, item.typeId)"
                >
                  <v-col :cols="display.mobile.value ? 3 : 1" class="d-flex justify-start pa-0">
                    <v-sheet
                      class="d-flex justify-center align-center bg-transparent"
                      width="48"
                      height="48"
                    >
                      <v-img
                        max-width="48"
                        max-height="48"
                        rounded="lg"
                        :src="`${w.env?.VITE_STATIC_BASE_URL || 'https://static.lk.ricom.ru'}/media/logo/instruments/${item?.paperId}.png`"
                        :alt="item?.ticker"
                      />
                    </v-sheet>
                  </v-col>
                  <v-col cols="3" class="d-flex justify-start pa-0">
                    <v-sheet class="body-b1-bold bg-transparent">
                      {{ item?.ticker }}
                    </v-sheet>
                  </v-col>
                  <v-col cols="5" class="d-flex justify-start pa-0 pr-1">
                    <v-sheet class="body-b1 bg-transparent">
                      {{ item?.name }}
                    </v-sheet>
                  </v-col>
                  <v-col v-if="!display.mobile.value" cols="2" class="d-flex justify-start pa-0">
                    <v-sheet class="body-b2 text-capitalize bg-transparent">
                      {{ dictionaryStore.extractValue('instrumentTypes', item?.typeId) }}
                    </v-sheet>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pa-0 pl-1">
                    <v-sheet class="d-flex justify-end bg-transparent">
                      <FavoriteStar
                        :id="item.paperId"
                        :name="item.name"
                        :is-favorite="item.isFavorite"
                        :is-need-confirmation="false"
                        @update="updateFavorites"
                      />
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-sheet>
          </v-sheet>
        </template>
      </v-overlay>
    </template>
    <template #append-inner>
      <IconCancel v-if="search" @click="search = null" />
      <IconSearch v-else />
      <slot name="append-inner"></slot>
    </template>
    <template #message="{ message }">
      <div class="b3">{{ message }}</div>
    </template>
  </v-text-field>
</template>

<style scoped lang="scss">
  .v-text-field.v-input--density-compact {
    :deep(.v-field__field .v-label.v-field-label) {
      font-size: $fontSizeBodyB2;
    }

    :deep(.v-label.v-field-label) {
      font-size: $fontSizeBodyB3;
    }
  }

  .search-input-overlay {
    z-index: 201;
    position: relative;
    padding: 8px;
    border-radius: 8px 8px 0 0;
    background-color: var(--color-WhiteWhite);
  }

  .search-input-overlay-zindex {
    z-index: 2001;
    &.search-input-mobile {
      z-index: 0 !important;
    }
  }

  .search-result-row:hover {
    background-color: var(--color-BlackHover);
  }

  .table-row {
    height: 72px;
  }

  .btn-close {
    position: fixed;
    right: -20px;
    top: 0;
    background-color: white;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    padding: 12px;
  }

  .btn-close-mobile {
    position: fixed;
    right: 16px;
    top: 16px;
    background-color: var(--color-BlackHover);
    width: 36px;
    height: 36px;
    border-radius: 18px;
    padding: 0;
  }

  .v-overlay.search-overlay {
    &.search-mobile-overlay {
      --v-scrollbar-offset: 0;
      justify-content: center;
    }
  }
</style>
