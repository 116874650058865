<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <rect width="40" height="40" rx="8" fill="currentColor" :fill-opacity="fillOpacity" />
    <path
      d="M27.6887 21.7436C27.2792 21.2303 27.3963 21.0019 27.6887 20.543C27.694 20.5378 31.0751 15.901 31.4234 14.3286L31.4255 14.3275C31.5987 13.7545 31.4255 13.3333 30.5885 13.3333H27.8186C27.1134 13.3333 26.7883 13.6947 26.6141 14.0991C26.6141 14.0991 25.2039 17.4494 23.2088 19.6211C22.5649 20.2486 22.2672 20.4498 21.9157 20.4498C21.7426 20.4498 21.4734 20.2486 21.4734 19.6756V14.3275C21.4734 13.6403 21.276 13.3333 20.6923 13.3333H16.3369C15.8946 13.3333 15.6318 13.6539 15.6318 13.9525C15.6318 14.6041 16.6293 14.7539 16.7328 16.5872V20.565C16.7328 21.4366 16.5734 21.5969 16.2197 21.5969C15.2781 21.5969 12.9928 18.233 11.6385 14.383C11.3651 13.6361 11.098 13.3344 10.3876 13.3344H7.61666C6.82602 13.3344 6.66663 13.6958 6.66663 14.1002C6.66663 14.8146 7.60821 18.3671 11.0452 23.0605C13.3359 26.2641 16.5617 28 19.4963 28C21.2602 28 21.4755 27.6145 21.4755 26.9513C21.4755 23.8902 21.3161 23.601 22.1997 23.601C22.6092 23.601 23.3144 23.8022 24.9611 25.3474C26.8432 27.1797 27.1525 28 28.206 28H30.9759C31.7654 28 32.1655 27.6145 31.9354 26.8539C31.4086 25.2542 27.8492 21.9636 27.6887 21.7436Z"
      fill="white"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
