<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.39 11.14L16 12.75V11.14H14.39ZM17 7.14H13V9.04H17C18.71 9.04 20.1 10.43 20.1 12.14C20.1 13.41 19.33 14.51 18.23 14.98L19.63 16.38C21.05 15.5 22 13.93 22 12.14C22 9.38 19.76 7.14 17 7.14ZM2 4.41L5.11 7.52C3.29 8.26 2 10.05 2 12.14C2 14.9 4.24 17.14 7 17.14H11V15.24H7C5.29 15.24 3.9 13.85 3.9 12.14C3.9 10.55 5.11 9.24 6.66 9.07L8.73 11.14H8V13.14H10.73L13 15.41V17.14H14.73L18.74 21.15L20.15 19.74L3.41 3L2 4.41Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
