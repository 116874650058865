import { defineStore } from 'pinia'
import informerService from '@/api/informerService'
import { IInformerModel, IInformersModel } from '@/api/types'
import { useNotify } from '@/stores/notify'

interface IState {
  data: IInformersModel
  error?: Error
  timer?: unknown
}
interface IGetter {
  indexes: (s: IState) => IInformerModel[]
  stock: (s: IState) => IInformerModel[]
  currencies: (s: IState) => IInformerModel[]
  informers: (s: IState) => IInformersModel
  [key: string]: any
}
interface IAction {
  load: () => Promise<void>
  clearStore: () => void
  autoUpdate: () => Promise<void>
  stopAutoUpdate: () => void
  startAutoUpdate: (interval: number) => Promise<void>
  scheduleNextUpdate: (interval: number) => void
}

export const useInformerStore = defineStore<'informer', IState, IGetter, IAction>({
  id: 'informer',
  state: (): IState => ({
    data: {
      indexes: [],
      stock: [],
      currencies: []
    },
    error: undefined,
    timer: null
  }),
  getters: {
    indexes(state: IState) {
      return state.data.indexes
    },
    stock(state: IState) {
      return state.data.stock
    },
    currencies(state: IState) {
      return state.data.currencies
    },
    informers(state: IState) {
      return {
        ...state.data
      }
    }
  },
  persist: true,
  actions: {
    async load() {
      try {
        this.data = await informerService.informers()
      } catch (error) {
        console.error('Ошибка при получении справочников:', error)
      }
    },
    async autoUpdate() {
      try {
        this.data = await informerService.informers()
      } catch (error: any) {
        this.error = { name: error.code, message: error.message }
        const notify = useNotify()
        notify.showServiceError()
        this.stopAutoUpdate()
      }
    },
    async startAutoUpdate(interval: number) {
      if (this.timer) {
        clearInterval(this.timer as number)
      }
      await this.autoUpdate() // Получить данные сразу при запуске
      this.scheduleNextUpdate(interval)
    },
    // запланированный следующий запрос
    scheduleNextUpdate(interval) {
      this.timer = setTimeout(async () => {
        await this.autoUpdate()
        this.scheduleNextUpdate(interval) // Планируем следующий запрос
      }, interval)
    },
    // Функция для остановки обновления
    stopAutoUpdate() {
      if (this.timer) {
        clearInterval(this.timer as number)
      }
      this.timer = null
    },
    clearStore() {
      this.$reset()
    }
  }
})
