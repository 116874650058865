export const report = {
  periodBegin: 'Начало периода',
  periodEnd: 'Конец периода',
  closeOpenBalance: {
    moneyFree: 'Денежные средства (руб)',
    moneyMarginPortfolio: 'Маржинальный портфель',
    moneyNpr1: 'Норматив покрытия риска 1',
    moneyNpr2: 'Норматив покрытия риска 2'
  },
  numberAccount: 'Номер счета',
  choosePeriod: 'Введите период, за который Вы хотите просмотреть отчет',
  btnReportMake: 'Сформировать отчет',
  periodText: 'за период c {periodBegin} по {periodEnd}',
  btnClose: 'Закрыть',
  dateDocument: 'Дата документа',
  fio: 'ФИО',
  accountNumber: 'Счет номер',
  reportTableHeaders: {
    name: 'Код ЦБ',
    count: 'Кол-во',
    operation: 'Операция',
    date: 'Дата',
    sum: 'Сумма (руб)',
    note: 'Примечание',
    ts: 'Место сделки',
    dateTimeExecution: 'Дата сделки',
    dealPrice: 'Цена поручения',
    orderPrice: 'Цена сделки',
    commission: 'Комиссия (руб)',
    total: 'Итого (руб)',
    profit: 'Прибыль/убыток',
    regNumber: 'Рег. номер',
    instrumentType: 'Название ЦБ',
    instrumentCategory: 'Тип (категория)',
    emitent: 'Эмитент',
    issue: 'Вып.',
    nominal: 'Номинал, руб',
    operDate: 'Дата проведения операции в реестре',
    regDateTime: 'Дата учета операции в депозитарии',
    typeOperation: 'Тип операции',
    amountCredit: 'Количество ЦБ(шт). Приход',
    amountDebet: 'Количество ЦБ(шт). Расход',
    contragent: 'Контрагент'
  },
  openBalanceTitle: 'Входящий остаток',
  depoOperationsTitle: 'Операции с ценными бумагами',
  moneyOperationsTitle: 'Операции с денежными средствами',
  closeBalanceTitle: 'Исходящий остаток'
}
