<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M13.0008 2.03003C17.7308 2.50003 21.5008 6.25003 21.9508 11C22.5008 16.5 18.5008 21.38 13.0008 21.93V19.93C16.6408 19.5 19.5008 16.61 19.9608 12.97C20.5008 8.58003 17.3908 4.59003 13.0008 4.05003V2.05003V2.03003ZM11.0008 2.06003V4.06003C9.57078 4.26003 8.22078 4.84003 7.10078 5.74003L5.67078 4.26003C7.19078 3.00003 9.05078 2.25003 11.0008 2.06003ZM4.26078 5.67003L5.69078 7.10003C4.80078 8.23003 4.24078 9.58003 4.05078 11H2.05078C2.25078 9.04003 3.00078 7.19003 4.26078 5.67003ZM2.06078 13H4.06078C4.24078 14.42 4.81078 15.77 5.69078 16.9L4.27078 18.33C3.03078 16.81 2.26078 14.96 2.06078 13ZM7.10078 18.37C8.23078 19.25 9.58078 19.82 11.0008 20V22C9.04078 21.79 7.18078 21 5.67078 19.74L7.10078 18.37ZM12.0008 16.5L7.50078 12H11.0008V8.00003H13.0008V12H16.5008L12.0008 16.5Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
