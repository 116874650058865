<script setup lang="ts">
  import DatePickerV from '@/components/DatePickerV.vue'
  import BaseInput from '@/components/BaseComponents/BaseInput.vue'
  import BaseRadio from '@/components/BaseComponents/BaseRadio.vue'
  import BaseSelect from '@/components/BaseComponents/BaseSelect.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { useRegistrationStore } from '@/stores/registrationStore'
  import { computed, ComputedRef, reactive, ref } from 'vue'
  import { helpers, required, requiredIf } from '@vuelidate/validators'
  import { useVuelidate } from '@vuelidate/core'
  import { useI18n } from 'vue-i18n'
  import { dadataService } from '@/api/dadataService'
  import { IDaDataBank } from '@/api/types'
  import { vMaska } from 'maska/vue'
  import { useDisplay } from 'vuetify'
  import { dateMustBeLessThen } from '@/utils/customValidationRules'
  import dayjs from 'dayjs'
  import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox.vue'

  const $externalResults = ref<{ [key: string]: string[] }>({})

  const registrationStore = useRegistrationStore()
  const { t } = useI18n()
  const display = useDisplay()
  const currentClickTarget = ref('')

  const fields = [
    'birthday',
    'email',
    'controlQuestion',
    'controlQuestionFree',
    'controlAnswer',
    'bankBik',
    'bankName',
    'bankCor',
    'bankAcc'
  ] as const

  type RegistrationState = {
    [key in (typeof fields)[number]]: ComputedRef<string | null>
  }

  const state = reactive<RegistrationState>(
    fields.reduce((acc, field) => {
      acc[field] = computed({
        get: () => registrationStore.data[field],
        set: (value: string) => {
          registrationStore.data[field] = value
        }
      })
      return acc
    }, {} as RegistrationState)
  )
  const rules = {
    birthday: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.birthday') }),
        required
      ),
      dateMustBeLessThen: helpers.withMessage(
        t('validation.dateMustBeLessThen', {
          field: t('registration.birthday'),
          date: dayjs(new Date().setFullYear(new Date().getFullYear() - 14)).format('DD.MM.YYYY')
        }),
        dateMustBeLessThen
      ),
      $autoDirty: true
    },
    email: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.email') }),
        required
      ),
      $autoDirty: true
    },
    controlQuestion: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.controlQuestion') }),
        required
      ),
      $autoDirty: true
    },
    controlQuestionFree: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.controlQuestionFree') }),
        requiredIf(
          () =>
            registrationStore.data.controlQuestion ===
            t('registration.steps.step2.additionalData.questionOptions.variant6')
        )
      ),
      $autoDirty: true
    },
    controlAnswer: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.controlAnswer') }),
        required
      ),
      $autoDirty: true
    },
    bankBik: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.bankBik') }),
        requiredIf(() => registrationStore.data.payment === 'bank')
      ),
      $autoDirty: true
    },
    bankName: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.bankName') }),
        requiredIf(() => registrationStore.data.payment === 'bank')
      ),
      $autoDirty: true
    },
    bankCor: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.bankCor') }),
        requiredIf(() => registrationStore.data.payment === 'bank')
      ),
      $autoDirty: true
    },
    bankAcc: {
      required: helpers.withMessage(
        t('validation.required', { field: t('registration.bankRaschet') }),
        requiredIf(() => registrationStore.data.payment === 'bank')
      ),
      $autoDirty: true
    }
  }

  const v$ = useVuelidate(rules, state, { $externalResults })

  const checkBic = async (e: Event) => {
    if (state.bankBik) {
      try {
        const { data } = await dadataService.bicBank(state.bankBik)
        if (data.suggestions && data.suggestions.length === 1) {
          const bankData = data.suggestions[0] as IDaDataBank
          state.bankName = bankData.value
          state.bankCor = bankData.data.correspondent_account
        } else {
          $externalResults.value.bankBik = ['Неверный БИК банка']
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const changeControlQuestion = () => {
    if (
      registrationStore.data.controlQuestion !==
      t('registration.steps.step2.additionalData.questionOptions.variant6')
    ) {
      registrationStore.data.controlQuestionFree = ''
    }
  }

  const checkSelection = () => {
    if (registrationStore.data.typeAccount.length === 0 && currentClickTarget.value) {
      registrationStore.data.typeAccount.push(currentClickTarget.value)
    }
  }

  const clickCheckbox = (event: MouseEvent) => {
    const target = event.target as HTMLInputElement
    if (target) {
      currentClickTarget.value = target.value
    }
  }

  const controlQuestionOptions = [
    t('registration.steps.step2.additionalData.questionOptions.variant1'),
    t('registration.steps.step2.additionalData.questionOptions.variant2'),
    t('registration.steps.step2.additionalData.questionOptions.variant3'),
    t('registration.steps.step2.additionalData.questionOptions.variant4'),
    t('registration.steps.step2.additionalData.questionOptions.variant5'),
    t('registration.steps.step2.additionalData.questionOptions.variant6')
  ]
</script>

<template>
  <v-sheet class="d-flex flex-column ga-2 w-100">
    <v-sheet class="title-h2 mt-2">
      {{ t('registration.steps.step2.additionalData.title') }}
    </v-sheet>
    <v-sheet>
      <v-radio-group v-model="registrationStore.data.sex" inline hide-details>
        <BaseRadio
          :label="t('registration.steps.step2.additionalData.sexMale')"
          value="male"
          hide-details
        />
        <BaseRadio
          :label="t('registration.steps.step2.additionalData.sexFemale')"
          value="female"
          hide-details
        />
      </v-radio-group>
    </v-sheet>
    <DatePickerV
      v-model="state.birthday"
      density="default"
      :start-date="new Date(new Date().setFullYear(new Date().getFullYear() - 14))"
      :max-date="new Date(new Date().setFullYear(new Date().getFullYear() - 14))"
      :error-messages="v$.birthday.$errors"
      prevent-min-max-navigation
      :label="t('registration.birthday')"
      class="w-100"
    />
    <BaseInput
      v-model="state.email"
      :error-messages="v$.email.$errors.map(e => e.$message)"
      :label="t('registration.email')"
      class="w-100"
      @blur="v$.email.$touch()"
      @input="v$.email.$touch()"
    />
    <BaseSelect
      v-model="state.controlQuestion"
      :error-messages="v$.controlQuestion.$errors.map(e => e.$message)"
      :label="t('registration.controlQuestion')"
      :items="controlQuestionOptions"
      class="w-100"
      @blur="v$.controlQuestion.$touch()"
      @input="v$.controlQuestion.$touch()"
      @change="changeControlQuestion"
    />
    <BaseInput
      v-if="
        registrationStore.data.controlQuestion ===
        t('registration.steps.step2.additionalData.questionOptions.variant6')
      "
      v-model="state.controlQuestionFree"
      :error-messages="v$.controlQuestionFree.$errors.map(e => e.$message)"
      :label="t('registration.controlQuestionFree')"
      class="w-100"
      @blur="v$.controlQuestionFree.$touch()"
      @input="v$.controlQuestionFree.$touch()"
    />
    <BaseInput
      v-model="state.controlAnswer"
      :error-messages="v$.controlAnswer.$errors.map(e => e.$message)"
      :label="t('registration.controlAnswer')"
      class="w-100"
      @blur="v$.controlAnswer.$touch()"
      @input="v$.controlAnswer.$touch()"
    />
    <v-sheet class="body-b1">
      {{ t('registration.steps.step2.additionalData.controlQuestionText1') }}
      <br />
      {{ t('registration.steps.step2.additionalData.controlQuestionText2') }}
    </v-sheet>
  </v-sheet>
  <v-sheet class="d-flex flex-column align-start w-100 mt-4 ga-2">
    <v-sheet class="title-h2">
      {{ t('registration.steps.step2.additionalData.typeAccount.title') }}
    </v-sheet>
    <BaseCheckbox
      v-model="registrationStore.data.typeAccount"
      value="broker"
      :label="t('registration.steps.step2.additionalData.typeAccount.broker')"
      hide-details
      multiple
      @update:model-value="checkSelection"
      @click="clickCheckbox"
    />
    <v-sheet class="d-flex ga-2">
      <BaseCheckbox
        v-model="registrationStore.data.typeAccount"
        value="iis"
        :label="t('registration.steps.step2.additionalData.typeAccount.iis')"
        hide-details
        multiple
        @update:model-value="checkSelection"
        @click="clickCheckbox"
      />
      <v-sheet v-if="!display.mobile.value">
        <v-tooltip
          location="bottom left"
          max-width="280"
          :offset="[4, -12]"
          content-class="elevation-16-dp border-sm"
        >
          <template #activator="{ props }">
            <v-icon v-bind="props" icon="help-fill" size="sm"></v-icon>
          </template>
          <v-sheet class="bg-transparent">
            {{ t('registration.steps.step2.additionalData.typeAccount.iisHint') }}
          </v-sheet>
        </v-tooltip>
      </v-sheet>
    </v-sheet>

    <BaseCheckbox
      v-model="registrationStore.data.typeAccount"
      value="trustManagment"
      :label="t('registration.steps.step2.additionalData.typeAccount.trustManagement')"
      hide-details
      multiple
      @update:model-value="checkSelection"
      @click="clickCheckbox"
    />
  </v-sheet>
  <v-sheet class="d-flex flex-column ga-2 mt-4 w-100">
    <v-sheet class="title-h2">
      {{ t('registration.steps.step2.additionalData.paymentTitle') }}
    </v-sheet>
    <v-sheet class="mb-4">
      <v-radio-group
        v-model="registrationStore.data.payment"
        :inline="!display.mobile.value"
        hide-details
      >
        <BaseRadio
          :label="t('registration.steps.step2.additionalData.paymentBroker')"
          value="broker"
          hide-details
        />
        <BaseRadio
          :label="t('registration.steps.step2.additionalData.paymentBank')"
          value="bank"
          hide-details
        />
      </v-radio-group>
    </v-sheet>
    <v-sheet v-if="registrationStore.data.payment === 'bank'" class="d-flex flex-column ga-2">
      <v-sheet class="title-h2">
        {{ t('registration.steps.step2.additionalData.bankTitle') }}
      </v-sheet>
      <v-sheet class="d-flex">
        <BaseInput
          v-model="state.bankBik"
          v-maska="{ mask: '04#######' }"
          :error-messages="v$.bankBik.$errors.map(e => e.$message)"
          :label="t('registration.bankBik')"
          class="w-75 mr-2"
          number-mask
          @blur="v$.bankBik.$touch()"
          @input="v$.bankBik.$touch()"
        />
        <BaseButton
          variant="outlined"
          :label="t('registration.steps.step2.additionalData.findBtn')"
          height="56"
          @click="checkBic"
        />
      </v-sheet>
      <BaseInput
        v-model="state.bankName"
        :error-messages="v$.bankName.$errors.map(e => e.$message)"
        :label="t('registration.bankName')"
        class="w-100"
        @blur="v$.bankName.$touch()"
        @input="v$.bankName.$touch()"
      />

      <BaseInput
        v-model="state.bankCor"
        :error-messages="v$.bankCor.$errors.map(e => e.$message)"
        :label="t('registration.bankCor')"
        class="w-100"
        @blur="v$.bankCor.$touch()"
        @input="v$.bankCor.$touch()"
      />
      <BaseInput
        v-model="state.bankAcc"
        :error-messages="v$.bankAcc.$errors.map(e => e.$message)"
        :label="t('registration.bankRaschet')"
        class="w-100"
        @blur="v$.bankAcc.$touch()"
        @input="v$.bankAcc.$touch()"
      />
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss"></style>
