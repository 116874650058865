<script setup lang="ts">
  import IconReplenishAccount from '@/components/icons/IconReplenishAccount.vue'
  import IconChat from '@/components/icons/IconChat.vue'
  // import NotifyCounter from '@/components/BaseComponents/NotifyCounter.vue'
  import BaseAvatar from '@/components/BaseComponents/BaseAvatar.vue'
  import IconExit from '@/components/icons/IconExit.vue'
  import IconAccount from '@/components/icons/IconAccount.vue'
  // import IconAssignment from '@/components/icons/IconAssignment.vue'
  // import IconSettings from '@/components/icons/IconSettings.vue'
  // import IconGraduation from '@/components/icons/IconGraduation.vue'
  // import IconInvestProfile from '@/components/icons/IconInvestProfile.vue'
  import authService from '@/api/authService'
  import { useRoute, useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { useAccountInfoStore } from '@/stores/accountInfoStore'
  // import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { useDialogStore } from '@/stores/dialogStore'
  import { useMessagesStore } from '@/stores/messagesStore'
  import { useDisplay } from 'vuetify'

  const { t } = useI18n()

  const router = useRouter()
  const route = useRoute()

  const accountInfoStore = useAccountInfoStore()
  const dialogStore = useDialogStore()
  const display = useDisplay()

  const logout = async () => {
    await authService.logout()
    await router.push('/auth')
  }

  const messagesStore = useMessagesStore()

  const openDialogTopUp = () => {
    dialogStore.openDialog('TopUpAccount', {
      width: display.mobile.value ? '100%' : '768',
      height: display.mobile.value ? '100%' : '',
      maxWidth: display.mobile.value ? '400px' : ''
    })
  }
</script>

<template>
  <div class="user-menu">
    <v-sheet id="tip2" class="position-relative">
      <v-btn icon density="compact" :ripple="false" @click="openDialogTopUp">
        <IconReplenishAccount />
      </v-btn>
    </v-sheet>
    <v-sheet id="tip3" class="position-relative">
      <v-btn icon density="compact" :ripple="false" :to="{ name: 'feedback' }">
        <v-badge
          v-if="messagesStore.getUnreadCount > 0"
          :content="messagesStore.getUnreadCount"
          color="red"
        >
          <IconChat :color="route.name === 'feedback' ? 'rgba(105, 108, 236, 1)' : 'black'" />
        </v-badge>
        <IconChat v-else :color="route.name === 'feedback' ? 'rgba(105, 108, 236, 1)' : 'black'" />
      </v-btn>
    </v-sheet>
    <!--    <div class="tw-relative">-->
    <!--      <NotifyCounter :counter="102" />-->
    <!--    </div>-->
    <v-sheet id="tip4" class="position-relative">
      <v-btn id="main-menu" :ripple="false" icon>
        <BaseAvatar :full-name="accountInfoStore.avatarFullName" class="activator-menu" />
        <v-menu
          :open-delay="0"
          attach=".v-toolbar"
          activator="parent"
          class="user-menu"
          location="start top"
          :offset="[-70, 50]"
          :style="{ zIndex: 1900 }"
          open-on-hover
        >
          <v-card min-width="234" class="pt-3 pb-4 rounded-lg elevation-custom">
            <v-list class="pa-0">
              <v-list-item class="pb-6 px-6 pt-4">
                <template #[`title`]>
                  <h3 class="font-weight-bold">{{ t('accountMenu.profileTitle') }}</h3>
                </template>
              </v-list-item>
            </v-list>
            <v-list class="pa-0">
              <v-list-item class="py-2 px-6" :to="{ name: 'account' }">
                <template #[`prepend`]>
                  <IconAccount />
                </template>
                <div class="pl-4 body-b1">{{ t('accountMenu.account') }}</div>
              </v-list-item>
              <!--              <v-list-item class="pb-2 px-6">-->
              <!--                <template #[`prepend`]>-->
              <!--                  <IconAssignment />-->
              <!--                </template>-->
              <!--                <div class="pl-4">{{ t('accountMenu.clientInterview') }}</div>-->
              <!--              </v-list-item>-->
              <!--              <v-list-item class="pb-2 px-6">-->
              <!--                <template #[`prepend`]>-->
              <!--                  <IconSettings />-->
              <!--                </template>-->
              <!--                <div class="pl-4">{{ t('accountMenu.security') }}</div>-->
              <!--              </v-list-item>-->
              <!--              <v-list-item class="py-2 px-6">-->
              <!--                <template #[`prepend`]>-->
              <!--                  <IconGraduation />-->
              <!--                </template>-->
              <!--                <div class="pl-4">{{ t('accountMenu.investorStatus') }}</div>-->
              <!--              </v-list-item>-->
              <!--              <v-list-item class="py-2 px-6">-->
              <!--                <template #[`prepend`]>-->
              <!--                  <IconInvestProfile />-->
              <!--                </template>-->
              <!--                <div class="pl-4">{{ t('accountMenu.investProfile') }}</div>-->
              <!--              </v-list-item>-->
              <v-list-item class="py-2 px-6" @click="logout">
                <template #[`prepend`]>
                  <IconExit />
                </template>
                <v-list-item-title class="pl-4">{{ t('accountMenu.exit') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-btn>
    </v-sheet>
  </div>
</template>

<style scoped lang="scss">
  .user-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 56px;
    flex-shrink: 0;

    > div {
      background: none;
    }

    a[href]:hover {
      text-decoration: none;
    }

    a[href] {
      color: var(--color-BlackActive);
    }
  }

  .activator-menu {
    z-index: 2400;
  }

  .attached-p14 {
    padding: 14px;
    margin: -14px;
  }
</style>
