<script setup lang="ts">
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import IconMoveSecurities from '@/components/icons/IconMoveSecurities.vue'
  import { useI18n } from 'vue-i18n'
  import { useDialogStore } from '@/stores/dialogStore'
  import IconReplenishAccount from '@/components/icons/IconReplenishAccount.vue'
  import { useDisplay } from 'vuetify'
  const { t } = useI18n()
  const style = getComputedStyle(document.documentElement)
  const colorMainNormal = style.getPropertyValue('--colorMainNormal')
  const dialogStore = useDialogStore()
  const display = useDisplay()
</script>

<template>
  <div class="base-promo">
    <div class="title">
      <div>
        <div class="title-inactive">{{ t('promoTitleInactive') }}</div>
        <div class="title-purple">{{ t('promoTitlePurple') }}</div>
        <div class="title-teal">{{ t('promoTitleTeal') }}</div>
      </div>
      <div class="promo-buttons">
        <BaseButton
          :label="t('btnReplenishAccount')"
          class="promo-button"
          @click="
            dialogStore.openDialog('TopUpAccount', {
              width: display.mobile.value ? '100%' : '768',
              height: display.mobile.value ? '100%' : '',
              maxWidth: display.mobile.value ? '400px' : ''
            })
          "
        >
          <template #icon>
            <IconReplenishAccount :color="colorMainNormal" />
          </template>
        </BaseButton>
        <!--        <BaseButton :label="t('btnMoveSecurities')" variant="outlined" class="promo-button">-->
        <!--          <template #icon>-->
        <!--            <IconMoveSecurities :color="colorMainNormal" />-->
        <!--          </template>-->
        <!--        </BaseButton>-->
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  //@use './../../styles/variables.scss' as *;
  @mixin title_font {
    font-size: $fontSizeTitlePromo;
    font-style: normal;
    font-weight: 600;
    line-height: $lineHeightTitlePromo;
    letter-spacing: -0.4px;
  }
  .base-promo {
    display: flex;
    width: 912px;
    padding: 48px;
    align-items: center;
    background: url('/public/img/promo.png') white 50% / cover no-repeat;
    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 64px;
      .title-inactive {
        color: $colorBlackInactive;
        @include title_font;
      }
      .title-purple {
        color: $colorAdditionalPurple;
        @include title_font;
      }
      .title-teal {
        color: $colorAdditionalTeal;
        @include title_font;
      }
      .promo-buttons {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 24px;
        .promo-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 52px;
          padding-left: 26px;
          padding-right: 26px;
        }
      }
    }
  }
</style>
