<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M11 17H13L13.3 15.5C13.5 15.4167 13.6875 15.3292 13.8625 15.2375C14.0375 15.1458 14.2167 15.0333 14.4 14.9L15.85 15.35L16.85 13.65L15.7 12.65C15.7333 12.4167 15.75 12.2 15.75 12C15.75 11.8 15.7333 11.5833 15.7 11.35L16.85 10.35L15.85 8.65L14.4 9.1C14.2167 8.96667 14.0375 8.85417 13.8625 8.7625C13.6875 8.67083 13.5 8.58333 13.3 8.5L13 7H11L10.7 8.5C10.5 8.58333 10.3125 8.67083 10.1375 8.7625C9.9625 8.85417 9.78333 8.96667 9.6 9.1L8.15 8.65L7.15 10.35L8.3 11.35C8.26667 11.5833 8.25 11.8 8.25 12C8.25 12.2 8.26667 12.4167 8.3 12.65L7.15 13.65L8.15 15.35L9.6 14.9C9.78333 15.0333 9.9625 15.1458 10.1375 15.2375C10.3125 15.3292 10.5 15.4167 10.7 15.5L11 17ZM12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1958 11.45 10 12 10C12.55 10 13.0208 10.1958 13.4125 10.5875C13.8042 10.9792 14 11.45 14 12C14 12.55 13.8042 13.0208 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
