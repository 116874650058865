<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M14.08 20H2C0.91 19.96 0.04 19.09 0 18V4C0.04 2.91 0.91 2.04 2 2H22C23.09 2.04 23.96 2.91 24 4V14.53C23.42 14 22.75 13.61 22 13.34V4H2V18H14.08C14.03 18.33 14 18.66 14 19C14 19.34 14.03 19.68 14.08 20ZM14 16H4V14.75C4 13.09 7.34 12.25 9 12.25C10.66 12.25 14 13.09 14 14.75V16ZM14 10H18V11H14V10ZM9 6C7.63 6 6.5 7.13 6.5 8.5C6.5 9.87 7.63 11 9 11C10.37 11 11.5 9.87 11.5 8.5C11.5 7.13 10.37 6 9 6ZM14 8H20V9H14V8ZM21 15V18H24V20H21V23H19V20H16V18H19V15H21ZM20 6V7H14V6H20Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
