<script setup lang="ts">
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import IconDocument from '@/components/icons/IconDocument.vue'
  import { useRegistrationStore } from '@/stores/registrationStore'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { useNotify } from '@/stores/notify'
  import registrationService from '@/api/registrationService'
  import dayjs from 'dayjs'

  const registrationStore = useRegistrationStore()
  const router = useRouter()
  const { t } = useI18n()
  const notify = useNotify()

  const handleFormToStep2 = () => {
    registrationStore.step = '2'
  }

  const handleFormSubmit = async () => {
    try {
      registrationStore.data.birthday = dayjs(registrationStore.data.birthday).format('YYYY-MM-DD')
      registrationStore.data.passportDate = dayjs(registrationStore.data.passportDate).format(
        'YYYY-MM-DD'
      )
      await registrationService.sendPersonalData(registrationStore.data)
      await router.push({ name: 'registration-result' })
    } catch (error) {
      notify.showServiceError()
    }
  }
</script>

<template>
  <v-sheet class="d-flex flex-column align-center justify-center py-2" max-width="460">
    <v-sheet class="title-h2 mb-4">{{ t('registration.steps.step3.title') }}</v-sheet>
    <v-sheet class="body-b3 mb-10">{{ t('registration.steps.step3.subtitle') }}</v-sheet>
    <v-card class="elevation-04-dp pa-4 rounded-lg w-100">
      <v-sheet class="d-flex align-start ga-4">
        <v-sheet>
          <IconDocument />
        </v-sheet>
        <v-sheet class="d-flex flex-column align-start">
          <v-sheet class="body-b1-bold">{{ t('registration.steps.step3.interviewTitle') }}</v-sheet>
          <v-sheet class="body-b3">{{ t('registration.steps.step3.interviewSubtitle') }}</v-sheet>
        </v-sheet>
      </v-sheet>
    </v-card>
    <v-sheet
      max-height="170"
      border
      rounded
      class="d-flex flex-column overflow-auto w-100 pa-4 ga-4 my-6 rounded-lg"
    >
      <v-sheet>Заявление на депозитарное обслуживание</v-sheet>
      <v-sheet>Заявление на брокерское обслуживание</v-sheet>
      <v-sheet>Заявление на ЭДО</v-sheet>
    </v-sheet>
    <v-sheet class="body-b3 pa-4">
      {{ t('registration.steps.step3.confirmation') }}
    </v-sheet>
    <BaseButton
      :label="t('registration.steps.step3.confirmBtn')"
      class-name="w-100 mb-10"
      @click="handleFormSubmit"
    />
    <BaseButton
      :label="t('registration.steps.step3.correctInterviewBtn')"
      variant="outlined"
      class-name="w-100 mb-10"
      @click="handleFormToStep2"
    />
  </v-sheet>
</template>

<style scoped lang="scss"></style>
