// @ts-ignore
export const accountMenu = {
  profileTitle: 'Профиль',
  account: 'Личные данные',
  clientInterview: 'Анкета клиента',
  security: 'Безопасность',
  investorStatus: 'Статус инвестора',
  investProfile: 'Инвест-портфель',
  exit: 'Выход'
}
