import { h } from 'vue'
import type { IconSet, IconProps } from 'vuetify'
import IconAcceptationOfreturn from '@/components/icons/IconAcceptationOfreturn.vue'
import IconAcceptationSquare from '@/components/icons/IconAcceptationSquare.vue'
import IconAccount from '@/components/icons/IconAccount.vue'
import IconAccountActive from '@/components/icons/IconAccountActive.vue'
import IconAccountAdd from '@/components/icons/IconAccountAdd.vue'
import IconAccountBlock from '@/components/icons/IconAccountBlock.vue'
import IconAccountCardAdd from '@/components/icons/IconAccountCardAdd.vue'
import IconAccountCardDownload from '@/components/icons/IconAccountCardDownload.vue'
import IconAccountLock from '@/components/icons/IconAccountLock.vue'
import IconAccountMerge from '@/components/icons/IconAccountMerge.vue'
import IconAccountUnlock from '@/components/icons/IconAccountUnlock.vue'
import IconAccountWait from '@/components/icons/IconAccountWait.vue'
import IconAdditionalCookie from '@/components/icons/IconAdditionalCookie.vue'
import IconAdditionalFileUpload from '@/components/icons/IconAdditionalFileUpload.vue'
import IconAdditionalReaded from '@/components/icons/IconAdditionalReaded.vue'
import IconApparatSkzi from '@/components/icons/IconApparatSkzi.vue'
import IconArchiveAdd from '@/components/icons/IconArchiveAdd.vue'
import IconArchiveRemove from '@/components/icons/IconArchiveRemove.vue'
import IconArchiveRestore from '@/components/icons/IconArchiveRestore.vue'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import IconArrowReturn from '@/components/icons/IconArrowReturn.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'
import IconArrowSending from '@/components/icons/IconArrowSending.vue'
import IconAssignment from '@/components/icons/IconAssignment.vue'
import IconAutotrade from '@/components/icons/IconAutotrade.vue'
import IconBankWallet from '@/components/icons/IconBankWallet.vue'
import IconCalendar from '@/components/icons/IconCalendar.vue'
import IconCancel from '@/components/icons/IconCancel.vue'
import IconCancelFill from '@/components/icons/IconCancelFill.vue'
import IconCase from '@/components/icons/IconCase.vue'
import IconCaseActive from '@/components/icons/IconCaseActive.vue'
import IconChartSquare from '@/components/icons/IconChartSquare.vue'
import IconChat from '@/components/icons/IconChat.vue'
import IconChatActive from '@/components/icons/IconChatActive.vue'
import IconCheck from '@/components/icons/IconCheck.vue'
import IconCheckCircle from '@/components/icons/IconCheckCircle.vue'
import IconCheckFill from '@/components/icons/IconCheckFill.vue'
import IconCheckOff from '@/components/icons/IconCheckOff.vue'
import IconCheckOn from '@/components/icons/IconCheckOn.vue'
import IconCheckboxChecked from '@/components/icons/IconCheckboxChecked.vue'
import IconCheckboxIndeterminate from '@/components/icons/IconCheckboxIndeterminate.vue'
import IconCheckboxUnchecked from '@/components/icons/IconCheckboxUnchecked.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import IconChevronLeft from '@/components/icons/IconChevronLeft.vue'
import IconChevronRight from '@/components/icons/IconChevronRight.vue'
import IconChevronUp from '@/components/icons/IconChevronUp.vue'
import IconClean from '@/components/icons/IconClean.vue'
import IconClear from '@/components/icons/IconClear.vue'
import IconClip from '@/components/icons/IconClip.vue'
import IconClose from '@/components/icons/IconClose.vue'
import IconComplectation from '@/components/icons/IconComplectation.vue'
import IconConfirmDelivery from '@/components/icons/IconConfirmDelivery.vue'
import IconConnection from '@/components/icons/IconConnection.vue'
import IconContacts from '@/components/icons/IconContacts.vue'
import IconCopy from '@/components/icons/IconCopy.vue'
import IconCopyDoc from '@/components/icons/IconCopyDoc.vue'
import IconCoseSquare from '@/components/icons/IconCoseSquare.vue'
import IconCurrencyDollar from '@/components/icons/IconCurrencyDollar.vue'
import IconCurrencyRub from '@/components/icons/IconCurrencyRub.vue'
import IconCurrencyYen from '@/components/icons/IconCurrencyYen.vue'
import IconDelete from '@/components/icons/IconDelete.vue'
import IconDesktop from '@/components/icons/IconDesktop.vue'
import IconDocument from '@/components/icons/IconDocument.vue'
import IconDomain from '@/components/icons/IconDomain.vue'
import IconDotMedium from '@/components/icons/IconDotMedium.vue'
import IconDotSmall from '@/components/icons/IconDotSmall.vue'
import IconDownloadCircle from '@/components/icons/IconDownloadCircle.vue'
import IconDownloadInProgress from '@/components/icons/IconDownloadInProgress.vue'
import IconEdit from '@/components/icons/IconEdit.vue'
import IconError from '@/components/icons/IconError.vue'
import IconErrorFill from '@/components/icons/IconErrorFill.vue'
import IconEtalonCircle from '@/components/icons/IconEtalonCircle.vue'
import IconEtalonSquare from '@/components/icons/IconEtalonSquare.vue'
import IconEtalonSquare1 from '@/components/icons/IconEtalonSquare1.vue'
import IconExcel from '@/components/icons/IconExcel.vue'
import IconExit from '@/components/icons/IconExit.vue'
import IconExtermination from '@/components/icons/IconExtermination.vue'
import IconFileDownload from '@/components/icons/IconFileDownload.vue'
import IconFileUpload from '@/components/icons/IconFileUpload.vue'
import IconFilterAlt from '@/components/icons/IconFilterAlt.vue'
import IconFilterOff from '@/components/icons/IconFilterOff.vue'
import IconFilterOn from '@/components/icons/IconFilterOn.vue'
import IconFlagEnglish from '@/components/icons/IconFlagEnglish.vue'
import IconFlagRus from '@/components/icons/IconFlagRus.vue'
import IconFolder from '@/components/icons/IconFolder.vue'
import IconFolderOpen from '@/components/icons/IconFolderOpen.vue'
import IconGeo from '@/components/icons/IconGeo.vue'
import IconGraduation from '@/components/icons/IconGraduation.vue'
import IconHelp from '@/components/icons/IconHelp.vue'
import IconHelpFill from '@/components/icons/IconHelpFill.vue'
import IconIndicator from '@/components/icons/IconIndicator.vue'
import IconInfo from '@/components/icons/IconInfo.vue'
import IconInfo2 from '@/components/icons/IconInfo2.vue'
import IconInfoFill from '@/components/icons/IconInfoFill.vue'
import IconInfoPopup from '@/components/icons/IconInfoPopup.vue'
import IconInvestProfile from '@/components/icons/IconInvestProfile.vue'
import IconJournalTech from '@/components/icons/IconJournalTech.vue'
import IconLaptop from '@/components/icons/IconLaptop.vue'
import IconLinkAdd from '@/components/icons/IconLinkAdd.vue'
import IconLinkOff from '@/components/icons/IconLinkOff.vue'
import IconLinkOn from '@/components/icons/IconLinkOn.vue'
import IconListRemove from '@/components/icons/IconListRemove.vue'
import IconLoading from '@/components/icons/IconLoading.vue'
import IconLockOff from '@/components/icons/IconLockOff.vue'
import IconLockOn from '@/components/icons/IconLockOn.vue'
import IconLockOnTemp from '@/components/icons/IconLockOnTemp.vue'
import IconMarket from '@/components/icons/IconMarket.vue'
import IconMarketActive from '@/components/icons/IconMarketActive.vue'
import IconMenu from '@/components/icons/IconMenu.vue'
import IconMenuDotsDown from '@/components/icons/IconMenuDotsDown.vue'
import IconMinimize from '@/components/icons/IconMinimize.vue'
import IconMinus from '@/components/icons/IconMinus.vue'
import IconMoreHorizontal from '@/components/icons/IconMoreHorizontal.vue'
import IconMoreVertical from '@/components/icons/IconMoreVertical.vue'
import IconMoveSecurities from '@/components/icons/IconMoveSecurities.vue'
import IconNew from '@/components/icons/IconNew.vue'
import IconNotify from '@/components/icons/IconNotify.vue'
import IconOdnoklassniki from '@/components/icons/IconOdnoklassniki.vue'
import IconOpenAccount from '@/components/icons/IconOpenAccount.vue'
import IconPaperAdd from '@/components/icons/IconPaperAdd.vue'
import IconPasswordReset from '@/components/icons/IconPasswordReset.vue'
import IconPause from '@/components/icons/IconPause.vue'
import IconPhoto from '@/components/icons/IconPhoto.vue'
import IconPhotoAccent from '@/components/icons/IconPhotoAccent.vue'
import IconPinOff from '@/components/icons/IconPinOff.vue'
import IconPinOn from '@/components/icons/IconPinOn.vue'
import IconPlay from '@/components/icons/IconPlay.vue'
import IconPlus from '@/components/icons/IconPlus.vue'
import IconPrint from '@/components/icons/IconPrint.vue'
import IconRadioChecked from '@/components/icons/IconRadioChecked.vue'
import IconRadioUnchecked from '@/components/icons/IconRadioUnchecked.vue'
import IconRefresh from '@/components/icons/IconRefresh.vue'
import IconRename from '@/components/icons/IconRename.vue'
import IconReplenishAccount from '@/components/icons/IconReplenishAccount.vue'
import IconReset from '@/components/icons/IconReset.vue'
import IconSave from '@/components/icons/IconSave.vue'
import IconScreenrotation from '@/components/icons/IconScreenrotation.vue'
import IconSearch from '@/components/icons/IconSearch.vue'
import IconSearchAdditional from '@/components/icons/IconSearchAdditional.vue'
import IconSecureCategory from '@/components/icons/IconSecureCategory.vue'
import IconServices from '@/components/icons/IconServices.vue'
import IconServicesActive from '@/components/icons/IconServicesActive.vue'
import IconSessionConnect from '@/components/icons/IconSessionConnect.vue'
import IconSessionDisconnect from '@/components/icons/IconSessionDisconnect.vue'
import IconSettingPaper from '@/components/icons/IconSettingPaper.vue'
import IconSettings from '@/components/icons/IconSettings.vue'
import IconSignature from '@/components/icons/IconSignature.vue'
import IconSoftReturn from '@/components/icons/IconSoftReturn.vue'
import IconSoftSending from '@/components/icons/IconSoftSending.vue'
import IconSortAscending from '@/components/icons/IconSortAscending.vue'
import IconSortDescending from '@/components/icons/IconSortDescending.vue'
import IconStar from '@/components/icons/IconStar.vue'
import IconStarFavorites from '@/components/icons/IconStarFavorites.vue'
import IconStop from '@/components/icons/IconStop.vue'
import IconSync from '@/components/icons/IconSync.vue'
import IconTelegram from '@/components/icons/IconTelegram.vue'
import IconTest from '@/components/icons/IconTest.vue'
import IconTimelaps from '@/components/icons/IconTimelaps.vue'
import IconTimer from '@/components/icons/IconTimer.vue'
import IconTrayDownload from '@/components/icons/IconTrayDownload.vue'
import IconTrayUpload from '@/components/icons/IconTrayUpload.vue'
import IconTriangleDown from '@/components/icons/IconTriangleDown.vue'
import IconTriangleUp from '@/components/icons/IconTriangleUp.vue'
import IconUploadCircle from '@/components/icons/IconUploadCircle.vue'
import IconUploadProgress from '@/components/icons/IconUploadProgress.vue'
import IconVK from '@/components/icons/IconVK.vue'
import IconVisibleOff from '@/components/icons/IconVisibleOff.vue'
import IconVisibleOn from '@/components/icons/IconVisibleOn.vue'
import IconWarning from '@/components/icons/IconWarning.vue'
import IconWarningFill from '@/components/icons/IconWarningFill.vue'
import IconYouTube from '@/components/icons/IconYouTube.vue'
import IconSbp from '@/components/icons/IconSbp.vue'

type CustomIconProps = {
  color?: string
  fillOpacity?: string
} & IconProps

const custom: IconSet = {
  component: (props: CustomIconProps) => {
    switch (props.icon) {
      case 'acceptation-ofreturn':
        return h(IconAcceptationOfreturn, { color: props.color, fillOpacity: props.fillOpacity })
      case 'acceptation-square':
        return h(IconAcceptationSquare, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account':
        return h(IconAccount, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account-active':
        return h(IconAccountActive, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account-add':
        return h(IconAccountAdd, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account-block':
        return h(IconAccountBlock, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account-card-add':
        return h(IconAccountCardAdd, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account-card-download':
        return h(IconAccountCardDownload, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account-lock':
        return h(IconAccountLock, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account-merge':
        return h(IconAccountMerge, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account-unlock':
        return h(IconAccountUnlock, { color: props.color, fillOpacity: props.fillOpacity })
      case 'account-wait':
        return h(IconAccountWait, { color: props.color, fillOpacity: props.fillOpacity })
      case 'additional-cookie':
        return h(IconAdditionalCookie, { color: props.color, fillOpacity: props.fillOpacity })
      case 'additional-file-upload':
        return h(IconAdditionalFileUpload, { color: props.color, fillOpacity: props.fillOpacity })
      case 'additional-readed':
        return h(IconAdditionalReaded, { color: props.color, fillOpacity: props.fillOpacity })
      case 'apparat-skzi':
        return h(IconApparatSkzi, { color: props.color, fillOpacity: props.fillOpacity })
      case 'archive-add':
        return h(IconArchiveAdd, { color: props.color, fillOpacity: props.fillOpacity })
      case 'archive-remove':
        return h(IconArchiveRemove, { color: props.color, fillOpacity: props.fillOpacity })
      case 'archive-restore':
        return h(IconArchiveRestore, { color: props.color, fillOpacity: props.fillOpacity })
      case 'arrow-left':
        return h(IconArrowLeft, { color: props.color, fillOpacity: props.fillOpacity })
      case 'arrow-return':
        return h(IconArrowReturn, { color: props.color, fillOpacity: props.fillOpacity })
      case 'arrow-right':
        return h(IconArrowRight, { color: props.color, fillOpacity: props.fillOpacity })
      case 'arrow-sending':
        return h(IconArrowSending, { color: props.color, fillOpacity: props.fillOpacity })
      case 'assignment':
        return h(IconAssignment, { color: props.color, fillOpacity: props.fillOpacity })
      case 'autotrade':
        return h(IconAutotrade, { color: props.color, fillOpacity: props.fillOpacity })
      case 'bank-wallet':
        return h(IconBankWallet, { color: props.color, fillOpacity: props.fillOpacity })
      case 'calendar':
        return h(IconCalendar, { color: props.color, fillOpacity: props.fillOpacity })
      case 'cancel':
        return h(IconCancel, { color: props.color, fillOpacity: props.fillOpacity })
      case 'cancel-fill':
        return h(IconCancelFill, { color: props.color, fillOpacity: props.fillOpacity })
      case 'case':
        return h(IconCase, { color: props.color, fillOpacity: props.fillOpacity })
      case 'case-active':
        return h(IconCaseActive, { color: props.color, fillOpacity: props.fillOpacity })
      case 'chart-square':
        return h(IconChartSquare, { color: props.color, fillOpacity: props.fillOpacity })
      case 'chat':
        return h(IconChat, { color: props.color, fillOpacity: props.fillOpacity })
      case 'chat-active':
        return h(IconChatActive, { color: props.color, fillOpacity: props.fillOpacity })
      case 'check':
        return h(IconCheck, { color: props.color, fillOpacity: props.fillOpacity })
      case 'check-circle':
        return h(IconCheckCircle, { color: props.color, fillOpacity: props.fillOpacity })
      case 'check-fill':
        return h(IconCheckFill, { color: props.color, fillOpacity: props.fillOpacity })
      case 'check-off':
        return h(IconCheckOff, { color: props.color, fillOpacity: props.fillOpacity })
      case 'check-on':
        return h(IconCheckOn, { color: props.color, fillOpacity: props.fillOpacity })
      case 'checkbox-checked':
        return h(IconCheckboxChecked, { color: props.color, fillOpacity: props.fillOpacity })
      case 'checkbox-indeterminate':
        return h(IconCheckboxIndeterminate, { color: props.color, fillOpacity: props.fillOpacity })
      case 'checkbox-unchecked':
        return h(IconCheckboxUnchecked, { color: props.color, fillOpacity: props.fillOpacity })
      case 'chevron-down':
        return h(IconChevronDown, { color: props.color, fillOpacity: props.fillOpacity })
      case 'chevron-left':
        return h(IconChevronLeft, { color: props.color, fillOpacity: props.fillOpacity })
      case 'chevron-right':
        return h(IconChevronRight, { color: props.color, fillOpacity: props.fillOpacity })
      case 'chevron-up':
        return h(IconChevronUp, { color: props.color, fillOpacity: props.fillOpacity })
      case 'clean':
        return h(IconClean, { color: props.color, fillOpacity: props.fillOpacity })
      case 'clear':
        return h(IconClear, { color: props.color, fillOpacity: props.fillOpacity })
      case 'clip':
        return h(IconClip, { color: props.color, fillOpacity: props.fillOpacity })
      case 'close':
        return h(IconClose, { color: props.color, fillOpacity: props.fillOpacity })
      case 'complectation':
        return h(IconComplectation, { color: props.color, fillOpacity: props.fillOpacity })
      case 'confirm-delivery':
        return h(IconConfirmDelivery, { color: props.color, fillOpacity: props.fillOpacity })
      case 'connection':
        return h(IconConnection, { color: props.color, fillOpacity: props.fillOpacity })
      case 'contacts':
        return h(IconContacts, { color: props.color, fillOpacity: props.fillOpacity })
      case 'copy':
        return h(IconCopy, { color: props.color, fillOpacity: props.fillOpacity })
      case 'copy-doc':
        return h(IconCopyDoc, { color: props.color, fillOpacity: props.fillOpacity })
      case 'cose-square':
        return h(IconCoseSquare, { color: props.color, fillOpacity: props.fillOpacity })
      case 'currency-dollar':
        return h(IconCurrencyDollar, { color: props.color, fillOpacity: props.fillOpacity })
      case 'currency-rub':
        return h(IconCurrencyRub, { color: props.color, fillOpacity: props.fillOpacity })
      case 'currency-yen':
        return h(IconCurrencyYen, { color: props.color, fillOpacity: props.fillOpacity })
      case 'delete':
        return h(IconDelete, { color: props.color, fillOpacity: props.fillOpacity })
      case 'desktop':
        return h(IconDesktop, { color: props.color, fillOpacity: props.fillOpacity })
      case 'document':
        return h(IconDocument, { color: props.color, fillOpacity: props.fillOpacity })
      case 'domain':
        return h(IconDomain, { color: props.color, fillOpacity: props.fillOpacity })
      case 'dot-medium':
        return h(IconDotMedium, { color: props.color, fillOpacity: props.fillOpacity })
      case 'dot-small':
        return h(IconDotSmall, { color: props.color, fillOpacity: props.fillOpacity })
      case 'download-circle':
        return h(IconDownloadCircle, { color: props.color, fillOpacity: props.fillOpacity })
      case 'download-in-progress':
        return h(IconDownloadInProgress, { color: props.color, fillOpacity: props.fillOpacity })
      case 'edit':
        return h(IconEdit, { color: props.color, fillOpacity: props.fillOpacity })
      case 'error':
        return h(IconError, { color: props.color, fillOpacity: props.fillOpacity })
      case 'error-fill':
        return h(IconErrorFill, { color: props.color, fillOpacity: props.fillOpacity })
      case 'etalon-circle':
        return h(IconEtalonCircle, { color: props.color, fillOpacity: props.fillOpacity })
      case 'etalon-square':
        return h(IconEtalonSquare, { color: props.color, fillOpacity: props.fillOpacity })
      case 'etalon-square-1':
        return h(IconEtalonSquare1, { color: props.color, fillOpacity: props.fillOpacity })
      case 'excel':
        return h(IconExcel, { color: props.color, fillOpacity: props.fillOpacity })
      case 'exit':
        return h(IconExit, { color: props.color, fillOpacity: props.fillOpacity })
      case 'extermination':
        return h(IconExtermination, { color: props.color, fillOpacity: props.fillOpacity })
      case 'file-download':
        return h(IconFileDownload, { color: props.color, fillOpacity: props.fillOpacity })
      case 'file-upload':
        return h(IconFileUpload, { color: props.color, fillOpacity: props.fillOpacity })
      case 'filter-alt':
        return h(IconFilterAlt, { color: props.color, fillOpacity: props.fillOpacity })
      case 'filter-off':
        return h(IconFilterOff, { color: props.color, fillOpacity: props.fillOpacity })
      case 'filter-on':
        return h(IconFilterOn, { color: props.color, fillOpacity: props.fillOpacity })
      case 'flag-english':
        return h(IconFlagEnglish, { color: props.color, fillOpacity: props.fillOpacity })
      case 'flag-rus':
        return h(IconFlagRus, { color: props.color, fillOpacity: props.fillOpacity })
      case 'folder':
        return h(IconFolder, { color: props.color, fillOpacity: props.fillOpacity })
      case 'folder-open':
        return h(IconFolderOpen, { color: props.color, fillOpacity: props.fillOpacity })
      case 'geo':
        return h(IconGeo, { color: props.color, fillOpacity: props.fillOpacity })
      case 'graduation':
        return h(IconGraduation, { color: props.color, fillOpacity: props.fillOpacity })
      case 'help':
        return h(IconHelp, { color: props.color, fillOpacity: props.fillOpacity })
      case 'help-fill':
        return h(IconHelpFill, { color: props.color, fillOpacity: props.fillOpacity })
      case 'indicator':
        return h(IconIndicator, { color: props.color })
      case 'info':
        return h(IconInfo, { color: props.color, fillOpacity: props.fillOpacity })
      case 'info-2':
        return h(IconInfo2, { color: props.color, fillOpacity: props.fillOpacity })
      case 'info-fill':
        return h(IconInfoFill, { color: props.color, fillOpacity: props.fillOpacity })
      case 'info-popup':
        return h(IconInfoPopup, { color: props.color, fillOpacity: props.fillOpacity })
      case 'invest-profile':
        return h(IconInvestProfile, { color: props.color, fillOpacity: props.fillOpacity })
      case 'journal-tech':
        return h(IconJournalTech, { color: props.color, fillOpacity: props.fillOpacity })
      case 'laptop':
        return h(IconLaptop, { color: props.color, fillOpacity: props.fillOpacity })
      case 'link-add':
        return h(IconLinkAdd, { color: props.color, fillOpacity: props.fillOpacity })
      case 'link-off':
        return h(IconLinkOff, { color: props.color, fillOpacity: props.fillOpacity })
      case 'link-on':
        return h(IconLinkOn, { color: props.color, fillOpacity: props.fillOpacity })
      case 'list-remove':
        return h(IconListRemove, { color: props.color, fillOpacity: props.fillOpacity })
      case 'loading':
        return h(IconLoading, { color: props.color, fillOpacity: props.fillOpacity })
      case 'lock-off':
        return h(IconLockOff, { color: props.color, fillOpacity: props.fillOpacity })
      case 'lock-on':
        return h(IconLockOn, { color: props.color, fillOpacity: props.fillOpacity })
      case 'lock-on-temp':
        return h(IconLockOnTemp, { color: props.color, fillOpacity: props.fillOpacity })
      case 'market':
        return h(IconMarket, { color: props.color, fillOpacity: props.fillOpacity })
      case 'market-active':
        return h(IconMarketActive, { color: props.color, fillOpacity: props.fillOpacity })
      case 'menu':
        return h(IconMenu, { color: props.color, fillOpacity: props.fillOpacity })
      case 'menu-dots-down':
        return h(IconMenuDotsDown, { color: props.color, fillOpacity: props.fillOpacity })
      case 'minimize':
        return h(IconMinimize, { color: props.color, fillOpacity: props.fillOpacity })
      case 'minus':
        return h(IconMinus, { color: props.color, fillOpacity: props.fillOpacity })
      case 'more-horizontal':
        return h(IconMoreHorizontal, { color: props.color, fillOpacity: props.fillOpacity })
      case 'more-vertical':
        return h(IconMoreVertical, { color: props.color, fillOpacity: props.fillOpacity })
      case 'move-securities':
        return h(IconMoveSecurities, { color: props.color, fillOpacity: props.fillOpacity })
      case 'new':
        return h(IconNew, { color: props.color, fillOpacity: props.fillOpacity })
      case 'notify':
        return h(IconNotify, { color: props.color, fillOpacity: props.fillOpacity })
      case 'odnoklassniki':
        return h(IconOdnoklassniki, { color: props.color, fillOpacity: props.fillOpacity })
      case 'open-account':
        return h(IconOpenAccount, { color: props.color, fillOpacity: props.fillOpacity })
      case 'paper-add':
        return h(IconPaperAdd, { color: props.color, fillOpacity: props.fillOpacity })
      case 'password-reset':
        return h(IconPasswordReset, { color: props.color, fillOpacity: props.fillOpacity })
      case 'pause':
        return h(IconPause, { color: props.color, fillOpacity: props.fillOpacity })
      case 'photo':
        return h(IconPhoto, { color: props.color, fillOpacity: props.fillOpacity })
      case 'photo-accent':
        return h(IconPhotoAccent, { color: props.color, fillOpacity: props.fillOpacity })
      case 'pin-off':
        return h(IconPinOff, { color: props.color, fillOpacity: props.fillOpacity })
      case 'pin-on':
        return h(IconPinOn, { color: props.color, fillOpacity: props.fillOpacity })
      case 'play':
        return h(IconPlay, { color: props.color, fillOpacity: props.fillOpacity })
      case 'plus':
        return h(IconPlus, { color: props.color, fillOpacity: props.fillOpacity })
      case 'print':
        return h(IconPrint, { color: props.color, fillOpacity: props.fillOpacity })
      case 'radio-checked':
        return h(IconRadioChecked, { color: props.color, fillOpacity: props.fillOpacity })
      case 'radio-unchecked':
        return h(IconRadioUnchecked, { color: props.color, fillOpacity: props.fillOpacity })
      case 'refresh':
        return h(IconRefresh, { color: props.color, fillOpacity: props.fillOpacity })
      case 'rename':
        return h(IconRename, { color: props.color, fillOpacity: props.fillOpacity })
      case 'replenish-account':
        return h(IconReplenishAccount, { color: props.color, fillOpacity: props.fillOpacity })
      case 'reset':
        return h(IconReset, { color: props.color, fillOpacity: props.fillOpacity })
      case 'save':
        return h(IconSave, { color: props.color, fillOpacity: props.fillOpacity })
      case 'screenrotation':
        return h(IconScreenrotation, { color: props.color, fillOpacity: props.fillOpacity })
      case 'search':
        return h(IconSearch, { color: props.color, fillOpacity: props.fillOpacity })
      case 'search-additional':
        return h(IconSearchAdditional, { color: props.color, fillOpacity: props.fillOpacity })
      case 'secure-category':
        return h(IconSecureCategory, { color: props.color, fillOpacity: props.fillOpacity })
      case 'services':
        return h(IconServices, { color: props.color, fillOpacity: props.fillOpacity })
      case 'services-active':
        return h(IconServicesActive, { color: props.color, fillOpacity: props.fillOpacity })
      case 'session-connect':
        return h(IconSessionConnect, { color: props.color, fillOpacity: props.fillOpacity })
      case 'session-disconnect':
        return h(IconSessionDisconnect, { color: props.color, fillOpacity: props.fillOpacity })
      case 'setting-paper':
        return h(IconSettingPaper, { color: props.color, fillOpacity: props.fillOpacity })
      case 'settings':
        return h(IconSettings, { color: props.color, fillOpacity: props.fillOpacity })
      case 'signature':
        return h(IconSignature, { color: props.color, fillOpacity: props.fillOpacity })
      case 'soft-return':
        return h(IconSoftReturn, { color: props.color, fillOpacity: props.fillOpacity })
      case 'soft-sending':
        return h(IconSoftSending, { color: props.color, fillOpacity: props.fillOpacity })
      case 'sort-ascending':
        return h(IconSortAscending, { color: props.color, fillOpacity: props.fillOpacity })
      case 'sort-descending':
        return h(IconSortDescending, { color: props.color, fillOpacity: props.fillOpacity })
      case 'star':
        return h(IconStar, { color: props.color, fillOpacity: props.fillOpacity })
      case 'star-favorites':
        return h(IconStarFavorites, { color: props.color, fillOpacity: props.fillOpacity })
      case 'stop':
        return h(IconStop, { color: props.color, fillOpacity: props.fillOpacity })
      case 'sync':
        return h(IconSync, { color: props.color, fillOpacity: props.fillOpacity })
      case 'telegram':
        return h(IconTelegram, { color: props.color, fillOpacity: props.fillOpacity })
      case 'test':
        return h(IconTest, { color: props.color, fillOpacity: props.fillOpacity })
      case 'timelaps':
        return h(IconTimelaps, { color: props.color, fillOpacity: props.fillOpacity })
      case 'timer':
        return h(IconTimer, { color: props.color, fillOpacity: props.fillOpacity })
      case 'tray-download':
        return h(IconTrayDownload, { color: props.color, fillOpacity: props.fillOpacity })
      case 'tray-upload':
        return h(IconTrayUpload, { color: props.color, fillOpacity: props.fillOpacity })
      case 'triangle-down':
        return h(IconTriangleDown, { color: props.color, fillOpacity: props.fillOpacity })
      case 'triangle-up':
        return h(IconTriangleUp, { color: props.color, fillOpacity: props.fillOpacity })
      case 'upload-circle':
        return h(IconUploadCircle, { color: props.color, fillOpacity: props.fillOpacity })
      case 'upload-progress':
        return h(IconUploadProgress, { color: props.color, fillOpacity: props.fillOpacity })
      case 'vk':
        return h(IconVK, { color: props.color, fillOpacity: props.fillOpacity })
      case 'visible-off':
        return h(IconVisibleOff, { color: props.color, fillOpacity: props.fillOpacity })
      case 'visible-on':
        return h(IconVisibleOn, { color: props.color, fillOpacity: props.fillOpacity })
      case 'warning':
        return h(IconWarning, { color: props.color, fillOpacity: props.fillOpacity })
      case 'warning-fill':
        return h(IconWarningFill, { color: props.color, fillOpacity: props.fillOpacity })
      case 'you-tube':
        return h(IconYouTube, { color: props.color, fillOpacity: props.fillOpacity })
      case 'sbp':
        return h(IconSbp, { color: props.color, fillOpacity: props.fillOpacity })
    }
  }
}

export { custom }
