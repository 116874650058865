import { defineStore } from 'pinia'

interface IData {
  phoneNumber: string
  suggestion: boolean
  firstName: string
  lastName: string
  middleName: string
  noMiddleName: boolean
  passportSerial: string
  passportNumber: string
  passportDate: string
  passportIssuedBy: string
  passportCodeDepartment: string
  inn: string
  addressRegistration: string | null
  addressRegistrationKladr: {
    countryIsoCode: string
    areaKladrId: string
    cityDistrictKladrId: string
    settlementKladrId: string
    kladrId: string
    regionKladrId: string
    cityKladrId: string
    streetKladrId: string
    houseKladrId: string
    flat: string
  }
  official: boolean
  resident: boolean
  sex: 'male' | 'female'
  birthday: string
  email: string
  controlQuestion: string | null
  controlQuestionFree: string
  controlAnswer: string
  typeAccount: string[]
  payment: 'broker' | 'bank'
  bankBik: string | null
  bankName: string | null
  bankCor: string | null
  bankAcc: string | null
}

interface IState {
  data: IData
  step: string
  isSmsSend: boolean
  timerSms: number
  additionalParamsFormShow: boolean
}
interface IGetter {
  [key: string]: any
}
interface IAction {
  setSmsSend: (isSmsSend: boolean) => void
  setTimerSms: (timerSms: number) => void
  clearStore: () => void
}

export const useRegistrationStore = defineStore<'registration', IState, IGetter, IAction>({
  id: 'registration',
  state: (): IState => ({
    data: {
      phoneNumber: '',
      suggestion: true,
      resident: true,
      official: false,
      sex: 'male',
      payment: 'broker',
      firstName: '',
      lastName: '',
      middleName: '',
      noMiddleName: false,
      passportSerial: '',
      passportNumber: '',
      passportDate: '',
      passportIssuedBy: '',
      passportCodeDepartment: '',
      inn: '',
      addressRegistration: null,
      addressRegistrationKladr: {
        countryIsoCode: '',
        areaKladrId: '',
        cityDistrictKladrId: '',
        settlementKladrId: '',
        kladrId: '',
        regionKladrId: '',
        cityKladrId: '',
        streetKladrId: '',
        houseKladrId: '',
        flat: ''
      },
      birthday: '',
      email: '',
      controlQuestion: null,
      controlQuestionFree: '',
      controlAnswer: '',
      typeAccount: ['broker'],
      bankBik: null,
      bankName: null,
      bankCor: null,
      bankAcc: null
    },
    step: '1',
    isSmsSend: false,
    timerSms: 0,
    additionalParamsFormShow: false
  }),
  persist: true,
  actions: {
    setSmsSend(isSmsSend: boolean) {
      this.isSmsSend = isSmsSend
    },
    setTimerSms(timerSms: number) {
      this.timerSms = timerSms
    },
    clearStore() {
      this.$reset()
    }
  },
  getters: {}
})
