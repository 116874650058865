<script setup lang="ts">
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import BaseInput from '@/components/BaseComponents/BaseInput.vue'
  import BaseCard from '@/components/BaseComponents/BaseCard.vue'
  import { useI18n } from 'vue-i18n'
  import type { ICodeModel } from '@/api/types'
  import authService from '@/api/authService'
  import { reactive, ref } from 'vue'
  import { helpers, required } from '@vuelidate/validators'
  import { useVuelidate } from '@vuelidate/core'
  import Timer from './CodeTimer.vue'
  import { useAuthStore } from '@/stores/authStore'

  const { t } = useI18n()

  const code = ref('')
  const authStore = useAuthStore()
  const $externalResults = ref<{ [key: string]: string[] }>({})
  const initialState = {
    code: code
  }

  const emit = defineEmits(['sendSms'])

  const state = reactive({
    ...initialState
  })

  const rules = {
    code: {
      required: helpers.withMessage(
        t('validation.required', { field: t('auth.codeLabel') }),
        required
      ),
      $autoDirty: true
    }
  }

  const v = useVuelidate(rules, state, { $externalResults })

  const sendSms = () => {
    // eslint-disable-next-line no-useless-catch
    try {
      v.value.$touch()

      if (!v.value.$invalid) {
        const codeSend: ICodeModel = {
          phoneNumber: '',
          code: code.value
        }
        if (authService.checkCode(codeSend)) {
          emit('sendSms')
        } else {
          $externalResults.value.code = [t('validation.wrongCode')]
        }
      }
    } catch (error) {
      throw error
    }
  }

  const sendSmsAgain = () => {
    // eslint-disable-next-line no-useless-catch
    try {
      authStore.setTimerSms(60)
      authStore.setSmsSend(true)
    } catch (error) {
      throw error
    }
  }
</script>

<template>
  <BaseCard :width="480">
    <template #header>
      <h2 class="d-flex mb-3 justify-start">{{ t('auth.codeTitle') }}</h2>
      <div class="b1 d-flex justify-start">{{ t('auth.loginSubtitle') }}</div>
    </template>
    <template #content>
      <div>{{ t('auth.codeSentPhoneNumber', { phoneNumber: '+7 *** *** 22 32' }) }}</div>
      <v-form ref="loginForm" fast-fail @submit.prevent="sendSms">
        <BaseInput
          v-model="code"
          :error-message="v.code.$errors.map(e => e.$message)"
          name-field="code"
          :label="t('auth.codeLabel')"
          with-class="mb-6 w-100"
        />

        <BaseButton :label="t('next')" type="submit" class-name="mb-5" block />
        <div v-if="authStore.timerSms > 0" class="d-flex b2-bold mb-6 justify-start">
          <Timer />
        </div>
        <BaseButton
          v-else
          :label="t('auth.sendCodeAgain')"
          variant="outlined"
          block
          @click="sendSmsAgain"
        />
      </v-form>
    </template>
  </BaseCard>
</template>

<style scoped></style>
