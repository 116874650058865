<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M15 3V5H5V19L13.0225 19.0006C13.087 19.7154 13.2882 20.3908 13.5999 21.0004L5 21C3.89543 21 3 20.1046 3 19V5C3 3.89 3.89 3 5 3H15ZM21 11L21.0004 13.5999C20.3908 13.2882 19.7154 13.087 19.0006 13.0225L19 11H21ZM18 21V19H22V17H18V15L15 18L18 21ZM7.91 10.08L6.5 11.5L11 16L21 6L19.59 4.58L11 13.17L7.91 10.08Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
