<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M20 18H4V8H20M20 6H12L10 4H4C2.89 4 2 4.89 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V8C22 6.89 21.1 6 20 6Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
