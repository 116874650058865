<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M10 11C12.21 11 14 9.21 14 7C14 4.79 12.21 3 10 3C7.79 3 6 4.79 6 7C6 9.21 7.79 11 10 11ZM10 5C11.11 5 12 5.9 12 7C12 8.1 11.11 9 10 9C8.89 9 8 8.11 8 7C8 5.89 8.9 5 10 5ZM12 19H2V16C2 13.33 7.33 12 10 12C11 12 12.38 12.19 13.71 12.56C13.41 13.12 13.23 13.74 13.21 14.39C12.23 14.1 11.11 13.9 10 13.9C7.03 13.9 3.9 15.36 3.9 16V17.1H12C12 17.13 12 17.17 12 17.2V19ZM20.8 16V14.5C20.8 13.1 19.4 12 18 12C16.6 12 15.2 13.1 15.2 14.5V16C14.6 16 14 16.6 14 17.2V20.7C14 21.4 14.6 22 15.2 22H20.7C21.4 22 22 21.4 22 20.8V17.3C22 16.6 21.4 16 20.8 16ZM19.5 16H16.5V14.5C16.5 13.7 17.2 13.2 18 13.2C18.8 13.2 19.5 13.7 19.5 14.5V16Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
