import httpCommunicator from '@/api/httpCommunicator'
import { AxiosResponse } from 'axios'
import { IDaDataModel } from '@/api/types'
const config = {
  withCredentials: false
}

export const dadataService = {
  async address(query: string): Promise<AxiosResponse<IDaDataModel, string>> {
    return httpCommunicator.post('/dictionary/dadata', { subject: 'address', query }, config)
  },
  async bicBank(query: string): Promise<AxiosResponse<IDaDataModel, string>> {
    return httpCommunicator.post('/dictionary/dadata', { subject: 'bank', query }, config)
  }
}
