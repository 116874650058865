<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M9.5 3C10.6592 3 11.7476 3.30346 12.6899 3.83526C12.361 4.42615 12.1381 5.08361 12.0467 5.78187C11.3257 5.2881 10.4489 5 9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C11.6129 14 13.3687 12.5714 13.8617 10.6197C14.3726 11.0734 14.9702 11.4328 15.6255 11.6711C15.3582 12.4316 14.9521 13.1282 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C5.91015 16 3 13.0899 3 9.5C3 5.91015 5.91015 3 9.5 3Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
    <path
      d="M21 6.5C21 8.43 19.43 10 17.5 10C15.57 10 14 8.43 14 6.5C14 4.57 15.57 3 17.5 3C19.43 3 21 4.57 21 6.5Z"
      fill="#E91E63"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
