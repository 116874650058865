import httpCommunicator from '@/api/httpCommunicator'
import { IMessageModel, IMessageSendModel, IMessageChangeModel } from '@/api/types'
import { AxiosResponse } from 'axios'

const messageService = {
  async messages() {
    return httpCommunicator.get('/messages').then((response: AxiosResponse<IMessageModel[]>) => {
      return response.data
    })
  },
  async sendMessage(message: IMessageSendModel) {
    return httpCommunicator.post('/messages', message).then((response: AxiosResponse) => {
      return response
    })
  },
  async changeMessage(message: IMessageChangeModel) {
    return httpCommunicator.put('/messages', message).then((response: AxiosResponse) => {
      return response
    })
  }
}

export default messageService
