<script setup lang="ts">
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import RicomLogo from '@/components/RicomLogo.vue'

  const moveToSite = () => {
    window.location.href = 'https://ricom.ru'
  }
</script>

<template>
  <v-sheet class="w-100 body">
    <div class="position-relative d-flex w-100 h-100 justify-center flex-wrap align-center">
      <v-sheet class="w-98"></v-sheet>
      <v-sheet
        class="w-98 d-flex pa-6 overflow-hidden flex-column justify-start align-center ga-2 rounded-2lg"
      >
        <v-sheet class="align-stretch flex-column justify-start align-start ga-6 d-flex">
          <RicomLogo class-name="position-relative" />
          <v-sheet class="align-stretch text-center title-promo">404</v-sheet>
          <v-sheet class="align-stretch text-center title-h2">СТРАНИЦА НЕ НАЙДЕНА</v-sheet>
        </v-sheet>
        <v-sheet class="align-stretch flex-column justify-start align-start ga-4 d-flex">
          <v-sheet class="align-stretch text-center body-b1">
            К сожалению, что-то пошло не так и запрашиваемая вами страница не найдена.
          </v-sheet>
          <v-sheet class="align-stretch justify-start align-start ga-3 d-flex">
            <BaseButton
              width="100%"
              label="Личный кабинет"
              @click="$router.push({ name: 'portfolio' })"
            ></BaseButton>
          </v-sheet>
          <v-sheet class="align-stretch text-center body-b1">или</v-sheet>
          <v-sheet class="align-stretch justify-start align-start ga-3 d-flex">
            <BaseButton
              width="100%"
              variant="outlined"
              label="На главную страницу сайта"
              @click="moveToSite"
            ></BaseButton>
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </div>
  </v-sheet>
</template>

<style scoped lang="scss">
  .body {
    background-image: url('/img/error-404.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
  }
  .w-98 {
    width: 392px;
  }
</style>
