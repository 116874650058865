<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import IconArrowRight from '@/components/icons/IconArrowRight.vue'
  import staticService from '@/api/staticService'
  import { IPromoModel } from '@/api/types'
  import { useRoute } from 'vue-router'
  import { useDisplay } from 'vuetify'

  const cards = ref<IPromoModel[]>([])
  const loading = ref(true)
  const route = useRoute()
  const { mobile } = useDisplay()

  const cardChunks = computed(() => {
    const chunkSize = 3

    if (route.name === 'portfolio') {
      return cards.value.slice(0, chunkSize)
    }

    if (route.name === 'trading') {
      return cards.value.slice(3, 6)
    }

    if (route.name === 'services') {
      return cards.value.slice(6, 9)
    }
    return []
  })

  onMounted(async () => {
    loading.value = true
    cards.value = await staticService.promo()
    loading.value = false
  })

  const tipClass = computed(() => {
    const classes = ['d-flex  align-center portfolio-bar w-100 position-relative']
    return mobile.value ? [...classes, ''] : [...classes, 'pr-6']
  })

  const goToLink = (link: string) => {
    window.open(link, '_blank')
  }
</script>

<template>
  <v-col v-if="loading" id="tip5" cols="9" :class="tipClass">
    <v-sheet class="d-flex justify-space-between w-100">
      <v-skeleton-loader class="w-33" elevation="0" height="200px" type="card"></v-skeleton-loader>
      <v-skeleton-loader class="w-33" elevation="0" height="200px" type="card"></v-skeleton-loader>
      <v-skeleton-loader class="w-33" elevation="0" height="200px" type="card"></v-skeleton-loader>
    </v-sheet>
  </v-col>
  <template v-else>
    <v-col v-for="(card, cardIndex) in cardChunks" :key="cardIndex" cols="3">
      <v-card class="promo-card w-100" height="200" color="black" @click="goToLink(card.link)">
        <v-card-title class="pa-2">
          <v-img height="117px" :src="card.image" class="rounded rounded-2" cover></v-img>
        </v-card-title>
        <v-card-text class="py-3 px-4">
          <v-sheet color="black" class="d-flex align-center justify-space-between">
            <div class="b2">{{ card.title }}</div>
            <div>
              <IconArrowRight color="white" />
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
  </template>
</template>

<style scoped>
  .promo-card:hover {
    text-decoration: none !important;
  }
</style>
