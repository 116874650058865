<script setup lang="ts">
  import IconReplenishAccount from '@/components/icons/IconReplenishAccount.vue'
  import BaseSelect from '@/components/BaseComponents/BaseSelect.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { IReissueAccountModel } from '@/api/types'
  import authService from '@/api/authService'
  import { computed, onMounted, ref } from 'vue'
  import { useOrderHistoryStore } from '@/stores/orderHistoryStore'
  import { useTransactionHistoryStore } from '@/stores/transactionsStore'
  import { useDialogStore } from '@/stores/dialogStore'
  import { useAuthStore } from '@/stores/authStore'
  import { useAccountStore } from '@/stores/accountStore'
  import { useInvoiceNumbersStore } from '@/stores/invoiceNumbersStore'
  import { useI18n } from 'vue-i18n'
  import IconOpenAccount from '@/components/icons/IconOpenAccount.vue'
  import { useDisplay } from 'vuetify'

  const account = useAccountStore()
  const authStore = useAuthStore()
  const orderStore = useOrderHistoryStore()
  const transactionStore = useTransactionHistoryStore()
  const dialogStore = useDialogStore()
  const invoicesNumbers = useInvoiceNumbersStore()
  const { t } = useI18n()
  const { mobile } = useDisplay()

  const portfolio = ref(authStore.accountName)

  const changeAccount = async () => {
    const credential: IReissueAccountModel = {
      account: portfolio.value
    }
    await authService.accountReissue(credential)
    await account.load()
    await orderStore.load()
    await transactionStore.load()
  }

  const items = computed(() => invoicesNumbers.data)
  const loading = computed(() => invoicesNumbers.loading)

  onMounted(async () => {
    await invoicesNumbers.load()
  })
</script>

<template>
  <v-sheet class="w-100">
    <v-sheet id="tip7" class="position-relative">
      <BaseSelect
        v-model="portfolio"
        :items="items"
        :clearable="false"
        :loading="loading"
        density="compact"
        hide-details
        with-class="mt-0 mw-230"
        :disabled="loading || items.length <= 1"
        @change="changeAccount"
      />
    </v-sheet>
  </v-sheet>
  <v-sheet v-if="!mobile" class="flex-1-1-100">
    <BaseButton
      :label="t('portfolio.btnTopUp')"
      variant="text"
      @click="
        dialogStore.openDialog('TopUpAccount', {
          width: mobile ? '100%' : '768',
          height: mobile ? '100%' : '',
          maxWidth: mobile ? '400px' : ''
        })
      "
    >
      <template #[`icon`]>
        <IconReplenishAccount color="rgb(105, 108, 236)" />
      </template>
    </BaseButton>
  </v-sheet>
  <v-sheet v-if="!mobile" class="flex-1-1-100">
    <BaseButton :label="t('portfolio.btnOpenAccount')" variant="text" @click="() => false">
      <template #[`icon`]>
        <IconOpenAccount color="rgb(105, 108, 236)" />
      </template>
    </BaseButton>
  </v-sheet>
  <v-sheet v-if="mobile" class="d-flex align-center">
    <v-btn
      variant="plain"
      density="compact"
      icon
      :ripple="false"
      @click="
        dialogStore.openDialog('TopUpAccount', {
          width: mobile ? '100%' : '768',
          height: mobile ? '100%' : '',
          maxWidth: mobile ? '400px' : ''
        })
      "
    >
      <IconReplenishAccount color="rgb(105, 108, 236)" />
    </v-btn>
  </v-sheet>
  <v-sheet v-if="mobile" class="d-flex align-center">
    <v-btn variant="plain" density="compact" icon :ripple="false" @click="() => false">
      <IconOpenAccount color="rgb(105, 108, 236)" />
    </v-btn>
  </v-sheet>
</template>

<style scoped lang="scss">
  .mw-230 {
    min-width: 230px;
  }
</style>
