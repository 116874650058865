<script setup lang="ts">
  import IconChevronLeft from '@/components/icons/IconChevronLeft.vue'
  import { onMounted, ref } from 'vue'
  import { IBankModel } from '@/api/types'
  import accountsService from '@/api/accountsService'
  import { useI18n } from 'vue-i18n'
  import { useDisplay } from 'vuetify'

  const banks = ref<IBankModel[]>([])
  const { t } = useI18n()
  const display = useDisplay()

  onMounted(async () => {
    banks.value = await accountsService.banks()
  })
</script>

<template>
  <v-sheet class="d-flex">
    <v-sheet class="align-center">
      <IconChevronLeft />
    </v-sheet>
    <v-sheet>
      <RouterLink :to="{ name: 'account' }" class="text-decoration-none">
        {{ display.mobile.value ? t('info.bankAccounts.title') : t('info.titleBreadcrumbs') }}
      </RouterLink>
    </v-sheet>
  </v-sheet>
  <v-sheet class="d-flex" :class="{ 'flex-column px-4': display.mobile.value }">
    <v-sheet
      :width="display.mobile.value ? '100%' : '40%'"
      :min-height="!display.mobile.value ? 400 : '100%'"
    >
      <v-sheet v-if="!display.mobile.value" class="title-h1">
        {{ t('info.bankAccounts.title') }}
      </v-sheet>
      <v-sheet class="py-8 ga-6 d-flex flex-column">
        <v-sheet
          v-for="(item, index) in banks"
          :key="index"
          class="elevation-04-dp pa-4 rounded-lg d-flex align-center ga-6"
          max-width="420"
        >
          <v-sheet width="48" height="48">
            <v-sheet class="table-cell bg-transparent" width="48">
              <v-img max-width="48" max-height="48" rounded="lg" src="/img/no-img.png" alt="bank" />
            </v-sheet>
          </v-sheet>
          <v-sheet>
            <v-sheet class="title-h2">{{ item.rs }}</v-sheet>
            <v-sheet class="d-flex align-center justify-space-between">
              <v-sheet class="body-b1">{{ item.bank }}</v-sheet>
              <v-sheet class="body-b2">{{ t('info.bankAccounts.mainAccount') }}</v-sheet>
            </v-sheet>
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </v-sheet>
    <v-sheet
      :width="display.mobile.value ? '120%' : '60%'"
      :class="{ 'mt-4': display.mobile.value, 'empty-space': !display.mobile.value }"
    >
      <v-img src="/img/elephant.png" :width="display.mobile.value ? 400 : 327" />
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss">
  .empty-space {
    justify-items: end;
  }
</style>
