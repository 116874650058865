<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M14.9994 19.88C15.0394 20.18 14.9394 20.5 14.7094 20.71C14.3194 21.1 13.6894 21.1 13.2994 20.71L9.28937 16.7C9.05937 16.47 8.95937 16.16 8.99937 15.87V10.75L4.20937 4.62C3.86937 4.19 3.94937 3.56 4.37937 3.22C4.56937 3.08 4.77937 3 4.99937 3H18.9994C19.2194 3 19.4294 3.08 19.6194 3.22C20.0494 3.56 20.1294 4.19 19.7894 4.62L14.9994 10.75V19.88ZM7.03937 5L10.9994 10.06V15.58L12.9994 17.58V10.05L16.9594 5H7.03937Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
