<script setup lang="ts">
  import IconAcceptationOfreturn from '@/components/icons/IconAcceptationOfreturn.vue'
  import { useI18n } from 'vue-i18n'
  import { useDialogStore } from '@/stores/dialogStore'
  import IconReplenishAccount from '@/components/icons/IconReplenishAccount.vue'
  import { useDisplay } from 'vuetify'
  const { t } = useI18n()
  const dialogStore = useDialogStore()
  const display = useDisplay()
</script>

<template>
  <v-sheet
    class="d-flex"
    :class="{ 'flex-column mx-4': display.mobile.value, 'w-100': !display.mobile.value }"
    min-height="330"
  >
    <v-sheet :width="display.mobile.value ? '100%' : '40%'">
      <v-sheet class="title-h1 mb-4 d-flex align-center" height="80">
        {{ t('services.title') }}
      </v-sheet>
      <v-sheet class="d-flex flex-column ga-6">
        <v-card
          class="elevation-04-dp pa-4 rounded-lg"
          max-width="496"
          hover
          @click="
            dialogStore.openDialog('TopUpAccount', {
              width: display.mobile.value ? '100%' : '768',
              height: display.mobile.value ? '100%' : ''
            })
          "
        >
          <v-sheet>
            <v-sheet class="d-flex flex-row align-center">
              <v-sheet height="24px">
                <IconReplenishAccount />
              </v-sheet>
              <v-sheet>
                <v-card-title class="pa-0 title-h2 ml-4">
                  {{ t('services.topUpAccount') }}
                </v-card-title>
              </v-sheet>
            </v-sheet>
            <v-card-subtitle class="pa-0 ml-10">
              {{ t('services.descriptionTopUp') }}
            </v-card-subtitle>
          </v-sheet>
        </v-card>
        <v-card
          class="elevation-04-dp pa-4 rounded-lg"
          max-width="496"
          hover
          :to="{ name: 'services.reports' }"
        >
          <v-sheet>
            <v-sheet class="d-flex flex-row align-center">
              <v-sheet height="24px">
                <IconAcceptationOfreturn />
              </v-sheet>
              <v-sheet>
                <v-card-title class="pa-0 title-h2 ml-4">{{ t('services.reports') }}</v-card-title>
              </v-sheet>
            </v-sheet>
            <v-card-subtitle class="pa-0 ml-10">
              {{ t('services.descriptionReports') }}
            </v-card-subtitle>
          </v-sheet>
        </v-card>
      </v-sheet>
    </v-sheet>
    <v-sheet
      :width="display.mobile.value ? '100%' : '60%'"
      :class="{ 'mt-4': display.mobile.value, 'empty-space': !display.mobile.value }"
    >
      <v-img src="/img/pinguin.png" width="327" />
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
  .v-card--hover:hover {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: transparent;
  }

  .v-theme--myCustomLightTheme a[href]:hover {
    text-decoration: none !important;
  }

  .empty-space {
    justify-items: end;
  }
</style>
