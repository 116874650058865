export const feedback = {
  title: 'Обратная связь',
  subtitle: 'Сообщения и поддержка',
  btnMarkAsRead: 'Отметить все как прочитанное',
  emptyText: 'Напишите нам',
  textareaMessage: 'Ваше сообщение',
  btnSend: 'Отправить',
  customer1: 'Риком',
  customer2: 'Советник'
}
