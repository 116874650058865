<script setup lang="ts">
  import BlockInformation from '@/components/BaseComponents/BlockInformation.vue'
  import { useAccountStore } from '@/stores/accountStore'
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useDisplay } from 'vuetify'

  const { t } = useI18n()
  const { mobile } = useDisplay()
  const accountStore = useAccountStore()
  const finRes = computed(() => accountStore.finResults)
  const isLoading = computed(() => accountStore.loading)
</script>

<template>
  <div
    :class="[
      'd-flex justify-between ga-6 pb-6',
      'pt-1 w-100 overflow-auto scroll-width-none',
      mobile ? 'px-4' : 'px-1'
    ]"
  >
    <BlockInformation
      v-for="res in finRes"
      :key="res.label"
      class="w-auto"
      :currency="res.currency"
      :label="t(res.label)"
      :loading="isLoading"
      :show-percent="res.showPercent ?? false"
      :percent="res.percent ?? 0"
    />
    <BlockInformation key="chart" label="" class="mw-192" :loading="true" />
  </div>
</template>

<style scoped lang="scss">
  .mw-192 {
    min-width: 192px;
  }
  .scroll-width-none {
    scrollbar-width: none;
  }
</style>
