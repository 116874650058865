import { useNotify } from '@/stores/notify'
import axios, { AxiosError } from 'axios'
import { useAuthStore } from '@/stores/authStore'
import authService from '@/api/authService'
import i18n from '@/utils/i18n'
import router from '@/router'
import clearStores from '@/utils/clearStores'

const httpCommunicator = axios.create({
  baseURL: `${import.meta.env.VITE_API_BASE_URL || 'https://api.lk.ricom.ru'}`, // URL API
  timeout: 50000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Перехватчик запросов для добавления токена авторизации
httpCommunicator.interceptors.request.use(
  config => {
    if (config.url !== '/auth/refresh') {
      const authStore = useAuthStore() // Получаем доступ к хранилищу Pinia
      const token = authStore.token // Извлекаем токен
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Перехватчик ответов для обработки ошибок авторизации
httpCommunicator.interceptors.response.use(
  response => {
    return response
  },
  async (error: AxiosError) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.config &&
      error.config.url !== '/auth/refresh'
    ) {
      try {
        const response = await authService.refreshToken()
        error.config.headers.Authorization = `Bearer ${response.access_token}`
        return httpCommunicator.request(error.config) // Повторный запрос с новым токеном
      } catch (refreshError) {
        console.log('refreshTokenError', refreshError)

        await Promise.resolve(clearStores(true))
        const notify = useNotify()
        notify.show(
          i18n.global.t('errorSessionLost.message'),
          i18n.global.t('errorSessionLost.description') +
            '<br />' +
            i18n.global.t('errorSessionLost.ps'),
          'warn',
          'session'
        )
        window.location.href = '/auth'
        // Перенаправление на страницу логина
      }
    }
    if (error.response && error.response.status === 403) {
      clearStores(true)
      await router.push({ name: 'auth' })
    }
    return Promise.reject(error)
  }
)

export default httpCommunicator
