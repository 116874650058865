<script setup lang="ts">
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import RicomLogo from '@/components/RicomLogo.vue'
  import { useI18n } from 'vue-i18n'
  import { useDisplay } from 'vuetify'

  const { t } = useI18n()
  const display = useDisplay()
</script>

<template>
  <v-app-bar
    height="80"
    :elevation="0"
    class="d-flex align-center registration-main-menu tw-justify-self-start"
  >
    <template #prepend>
      <div class="menu-prepend d-flex align-center ga-8">
        <BaseButton
          :ripple="false"
          label=""
          variant="text"
          :to="{ name: 'portfolio' }"
          size="default"
          class="home-logo flex-0-1"
        >
          <template #icon><RicomLogo /></template>
        </BaseButton>
      </div>
    </template>
    <v-sheet
      v-if="!display.mobile.value"
      class="d-flex align-center justify-center top-menu h-80 title-h1 w-100"
    >
      {{ t('registration.title') }}
    </v-sheet>
    <v-sheet v-else class="d-flex align-center justify-end h-80 body-b3-bold w-100">
      +7 (800) 200 53 07
    </v-sheet>
  </v-app-bar>
  <v-main app>
    <v-container max-width="1280" class="pa-0" height="100%">
      <v-sheet height="100%" class="d-flex justify-center">
        <RouterView />
      </v-sheet>
    </v-container>
  </v-main>
</template>

<style scoped lang="scss">
  .top-menu {
    padding-right: 140px;
  }
</style>
