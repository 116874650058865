<script setup lang="ts">
  import router from '@/router'
  import { useI18n } from 'vue-i18n'
  import { computed } from 'vue'
  import IconCase from '@/components/icons/IconCase.vue'
  import IconServices from '@/components/icons/IconServices.vue'
  import IconMarket from '@/components/icons/IconMarket.vue'
  import IconChat from '@/components/icons/IconChat.vue'
  import IconAccount from '@/components/icons/IconAccount.vue'
  import IconCaseActive from '@/components/icons/IconCaseActive.vue'
  import IconServicesActive from '@/components/icons/IconServicesActive.vue'
  import IconMarketActive from '@/components/icons/IconMarketActive.vue'
  import IconChatActive from '@/components/icons/IconChatActive.vue'
  import IconAccountActive from '@/components/icons/IconAccountActive.vue'
  import { useRoute } from 'vue-router'

  const { t } = useI18n()
  const route = useRoute()

  const value = computed(() => {
    return ['portfolio', 'trading', 'services', 'feedback', 'account'].findIndex(
      value => value === route.name
    )
  })

  const menuItem = computed(() => {
    return [
      {
        name: 'portfolio',
        title: t('header.portfolio'),
        icon: value.value === 0 ? IconCaseActive : IconCase
      },
      {
        name: 'trading',
        title: t('header.trading'),
        icon: value.value === 1 ? IconMarketActive : IconMarket
      },
      {
        name: 'services',
        title: t('header.services'),
        icon: value.value === 2 ? IconServicesActive : IconServices
      },
      {
        name: 'feedback',
        title: t('header.feedback'),
        icon: value.value === 3 ? IconChatActive : IconChat
      },
      {
        name: 'account',
        title: t('header.account'),
        icon: value.value === 4 ? IconAccountActive : IconAccount
      }
    ]
  })
</script>

<template>
  <v-bottom-navigation
    v-model="value"
    height="auto"
    mandatory
    grow
    tile
    class="w-100 position-fixed pb-6"
  >
    <v-btn
      v-for="item in menuItem"
      :key="item.name"
      min-width="75"
      class="pa-0 pt-2"
      stacked
      :prepend-icon="item.icon"
      variant="plain"
      :ripple="false"
      @click.stop="() => router.push({ name: item.name })"
    >
      <span class="additional-overline text-decoration-none">{{ item.title }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<style scoped lang="scss">
  .v-bottom-navigation {
    border-top: 1px solid var(--color-BlackFocus);
    .v-btn--active {
      color: var(--color-MainNormal) !important;
    }
    :deep(.v-btn) {
      text-decoration-style: unset;
      .v-btn__content {
        font-size: 10px;
      }
      &.v-btn--selected .v-btn__prepend svg {
        color: var(--color-MainNormal);
        opacity: 1;
      }
    }
  }
</style>
