import httpCommunicator from '@/api/httpCommunicator'
import { FilterTransactionsRequestModel } from '@/components/types'

const transactionHistoryService = {
  async get(args?: FilterTransactionsRequestModel) {
    return httpCommunicator
      .get('/transactions', {
        params: args
      })
      .then(response => {
        return { data: response.data, total: response.headers['x-transactions-total'] }
      })
  }
}
export default transactionHistoryService
