<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0008 8C14.21 8 16.0008 9.79086 16.0008 12C16.0008 14.2091 14.21 16 12.0008 16C9.79168 16 8.00082 14.2091 8.00082 12C8.00082 9.79086 9.79168 8 12.0008 8ZM12.0008 10C10.8962 10 10.0008 10.8954 10.0008 12C10.0008 13.1046 10.8962 14 12.0008 14C13.1054 14 14.0008 13.1046 14.0008 12C14.0008 10.8954 13.1054 10 12.0008 10ZM10.0008 22C9.75082 22 9.54082 21.82 9.50082 21.58L9.13082 18.93C8.50082 18.68 7.96082 18.34 7.44082 17.94L4.95082 18.95C4.73082 19.03 4.46082 18.95 4.34082 18.73L2.34082 15.27C2.21082 15.05 2.27082 14.78 2.46082 14.63L4.57082 12.97L4.50082 12L4.57082 11L2.46082 9.37C2.27082 9.22 2.21082 8.95 2.34082 8.73L4.34082 5.27C4.46082 5.05 4.73082 4.96 4.95082 5.05L7.44082 6.05C7.96082 5.66 8.50082 5.32 9.13082 5.07L9.50082 2.42C9.54082 2.18 9.75082 2 10.0008 2H14.0008C14.2508 2 14.4608 2.18 14.5008 2.42L14.8708 5.07C15.5008 5.32 16.0408 5.66 16.5608 6.05L19.0508 5.05C19.2708 4.96 19.5408 5.05 19.6608 5.27L21.6608 8.73C21.7908 8.95 21.7308 9.22 21.5408 9.37L19.4308 11L19.5008 12L19.4308 13L21.5408 14.63C21.7308 14.78 21.7908 15.05 21.6608 15.27L19.6608 18.73C19.5408 18.95 19.2708 19.04 19.0508 18.95L16.5608 17.95C16.0408 18.34 15.5008 18.68 14.8708 18.93L14.5008 21.58C14.4608 21.82 14.2508 22 14.0008 22H10.0008ZM11.2508 4L10.8808 6.61C9.68082 6.86 8.62082 7.5 7.85082 8.39L5.44082 7.35L4.69082 8.65L6.80082 10.2C6.40082 11.37 6.40082 12.64 6.80082 13.8L4.68082 15.36L5.43082 16.66L7.86082 15.62C8.63082 16.5 9.68082 17.14 10.8708 17.38L11.2408 20H12.7608L13.1308 17.39C14.3208 17.14 15.3708 16.5 16.1408 15.62L18.5708 16.66L19.3208 15.36L17.2008 13.81C17.6008 12.64 17.6008 11.37 17.2008 10.2L19.3108 8.65L18.5608 7.35L16.1508 8.39C15.3808 7.5 14.3208 6.86 13.1208 6.62L12.7508 4H11.2508Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
