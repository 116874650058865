export const info = {
  title: 'Аналитика по инструменту',
  btnTitle: 'Посмотреть аналитику',
  error: 'Произошла ошибка. Повторите попытку позже',
  currentPrice: 'Текущая цена',
  totalDiff: 'Изменение',
  totalProfitPercent: 'Изменение',
  openPrice: 'Цена открытия',
  openPriceTooltip: 'цена первой сделки в данную торговую сессию',
  maxPrice: 'Максимальная цена',
  maxPriceTooltip: 'цена самой дорогой сделки в данную торговую сессию',
  tradingVolume: 'Объем торгов, шт',
  tradingVolumeTooltip:
    'объем купленных/проданных ценных бумаг данного эмитента в данную торговюю сессию в количественном выражении',
  closePrice: 'Предыдущее закрытие',
  closePriceTooltip: 'цена последней сделки в предыдущую торговую сессию',
  minPrice: 'Минимальная цена',
  minPriceTooltip: 'цена самой дешевой сделки в данную торговую сессию',
  tradingVolumeCurrency: 'Объем торгов в валюте',
  tradingVolumeCurrencyTooltip:
    'объем купленных/проданных ценных бумаг данного эмитента в данную торговюю сессию в валютном выражении',
  btnBuy: 'Купить',
  btnSell: 'Продать',
  priceProfit: 'Цена / прибыль',
  priceProfitTooltip:
    'финансовый показатель, равный отношению рыночной стоимости акции к годовой прибыли, полученной на акцию',
  priceBookVolume: 'Цена / балансовая оценка',
  priceBookVolumeTooltip:
    'финансовый коэффициент, равный отношению текущей рыночной капитализации компании к её балансовой стоимости',
  growsRatesEps: 'Темпы роста EPS',
  growsRatesEpsTooltip:
    'темпы роста финансового показателя, который равен отношению чистой прибыли компании, доступной для распределения, к среднегодовому числу обыкновенных акций',
  margin1: 'Маржинальная + шорт',
  margin0: 'Маржинальная',
  marginm1: 'Не маржинальная',
  lotName: '1 лот',
  action: {
    title1: 'шт',
    title2: 'акции',
    title3: 'акций'
  },
  titleBreadcrumbs: 'Личные данные',
  bankAccounts: {
    title: 'Банковские счета',
    addBtn: 'Добавить счет',
    mainAccount: 'Основной'
  },
  dividends: 'Дивиденды на акцию',
  dividendsTooltip:
    'Отслеживая динамику этого мультипликатора, можно понять, сколько дивидендов компания выплачивает на акцию. Используется для оценки инвестиционной привлекательности акций.',
  dividendsProfit: 'Дивидендная доходность',
  dividendsProfitTooltip:
    'Показывает, сколько компания выплатит дивидендов относительно цены её акций.',
  eps: 'EPS',
  epsTooltip:
    'EPS (Earnings per Share) — это мультипликатор оценки чистой прибыли компании, приходящейся на одну акцию.',
  rotateTip: 'Переверните устройство для полноценного графика'
}
