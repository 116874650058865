<script setup lang="ts">
  import { ITransactionOperationsModel, ITransactionsModel } from '@/api/types'
  import {
    DataOptions,
    FilterTransactionsRequestModel,
    TransactionOperation
  } from '@/components/types'
  import { useDictionaryStore } from '@/stores/dictionaryStore'
  import { formatCurrency } from '@/utils/number.extensions'
  import { debounce } from 'lodash-es'
  import { computed, onMounted, reactive, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import BaseSelect from '@/components/BaseComponents/BaseSelect.vue'
  import '@/utils/string.extensions'
  import { useTransactionHistoryStore } from '@/stores/transactionsStore'
  import RangeDatePickerV from '@/components/RangeDatePickerV.vue'
  import { useDisplay } from 'vuetify'
  import MoveBack from '@/components/BaseComponents/MoveBack.vue'

  const StatusColor: Record<number, string> = {
    0: 'text-BlackActive',
    1: 'text-WarningDark',
    2: 'text-AditionalBlue',
    3: 'text-AditionalGreen',
    4: 'text-MainDark',
    5: 'text-BlackDisabled',
    6: 'text-BlackDisabled'
  }
  //region> Uses
  const { t } = useI18n()
  const transactionStore = useTransactionHistoryStore()
  const dictionaryStore = useDictionaryStore()
  const { mobile } = useDisplay()
  //endregion
  //region> Refs
  const filterData = reactive<Partial<FilterTransactionsRequestModel>>({
    periodStart: undefined,
    periodEnd: undefined,
    operation: undefined
  })
  const tableOptions = reactive({
    page: 1,
    itemsPerPage: 10
  })
  //endregion
  //region> Computed
  const loading = computed<boolean>(() => {
    return transactionStore.loading
  })
  const items = computed<ITransactionsModel[]>(() => transactionStore.data)
  const period = computed({
    get: () => [filterData.periodStart, filterData.periodEnd],
    set: value => {
      filterData.periodStart = value[0]
      filterData.periodEnd = value[1]
    }
  })
  const itemLength = computed(() => transactionStore.total)
  const operation = computed({
    get: () => filterData.operation,
    set: (value: TransactionOperation) => {
      filterData.operation = value
    }
  })
  //endregion
  //region> Items
  const headers = [
    {
      align: 'center' as const,
      key: 'number',
      sortable: false,
      title: t('history.headers.number')
    },
    {
      align: 'center' as const,
      key: 'dateTimeExecution',
      sortable: false,
      title: t('history.headers.dateExp')
    },
    {
      align: 'center' as const,
      key: 'operation',
      sortable: false,
      title: t('history.headers.operation')
    },
    {
      align: 'center' as const,
      key: 'cb',
      sortable: false,
      title: t('history.headers.cb')
    },
    {
      align: 'center' as const,
      key: 'count',
      sortable: false,
      title: t('history.headers.count')
    },
    {
      align: 'center' as const,
      key: 'amount',
      sortable: false,
      title: t('history.headers.amount')
    },
    {
      align: 'center' as const,
      key: 'status',
      sortable: false,
      title: t('history.headers.status')
    }
  ]
  const operationTypesItems = dictionaryStore.transactionOperations.map(
    (item: ITransactionOperationsModel) => {
      return {
        title: item.name,
        value: item.id
      }
    }
  )
  //endregion

  const fetchData = async () => {
    const args: FilterTransactionsRequestModel = {
      page: tableOptions.page,
      limit: tableOptions.itemsPerPage,
      ...filterData
    }
    await transactionStore.load(args)
  }

  const handlerFetch = debounce(() => {
    fetchData()
  }, 200)

  const statusValue = (id: number) => {
    const filteredItem = dictionaryStore.transactionStatuses.filter(item => item.id === id)
    return filteredItem.length > 0 ? filteredItem[0].name : undefined
  }

  const actValue = (id: number) => {
    const filteredItem = dictionaryStore.transactionOperations.filter(item => item.id === id)
    return filteredItem.length > 0 ? filteredItem[0].name : undefined
  }

  const onChangeTransactionHistory = async () => {
    tableOptions.page = 1
    tableOptions.itemsPerPage = 10
    handlerFetch()
  }

  const classColor = (statusId: number) => {
    if (statusId in StatusColor) {
      return StatusColor[statusId]
    }
    return ''
  }

  const onPaginationChange = (e: DataOptions) => {
    tableOptions.page = e.page
    tableOptions.itemsPerPage = e.itemsPerPage
    handlerFetch()
  }

  watch(
    () => period.value,
    () => {
      onChangeTransactionHistory()
    }
  )

  onMounted(() => {
    handlerFetch()
  })
</script>

<template>
  <v-sheet v-if="mobile" class="w-100 px-4 py-6">
    <MoveBack class="position-absolute" />
    <h3 class="text-center">История операций</h3>
  </v-sheet>
  <div
    class="dvw-100 pt-4 pl-2 pr-2 align-start ga-4 align-stretch bg-grey-lighten-4 overflow-x-scroll"
  >
    <div class="d-flex ga-4" style="width: fit-content">
      <BaseSelect
        v-model="operation"
        bg-color="var(--color-WhiteWhite)"
        density="compact"
        :label="t('history.labelOperationTypes')"
        :clearable="true"
        :items="operationTypesItems"
        class="w-180"
        @change="onChangeTransactionHistory"
      />
      <v-sheet class="w-280 mt-2 body-b2 bg-transparent">
        <RangeDatePickerV v-model="period" :label="t('history.rangePicker')" />
      </v-sheet>
    </div>
  </div>
  <v-skeleton-loader
    v-if="loading"
    elevation="1"
    class="w-100"
    type="table-row-divider@10, table-row"
  ></v-skeleton-loader>
  <v-data-table-server
    v-if="items && items.length"
    :loading="loading"
    :page="tableOptions.page"
    :items-per-page="tableOptions.itemsPerPage"
    :items-length="itemLength"
    height="auto"
    item-value="id"
    :items-per-page-options="[10, 15, 25]"
    width="100%"
    :headers="headers"
    :items="items"
    last-icon="arrow-right"
    first-icon="arrow-left"
    next-icon="chevron-right"
    prev-icon="chevron-left"
    @update:options="onPaginationChange"
  >
    <template #headers="{ columns }">
      <tr>
        <template v-for="column in columns" :key="column.key">
          <th
            class="pa-1 body-b3 v-data-table__td v-data-table__th--sortable v-data-table__th v-data-table-column--align-center"
            colspan="1"
            rowspan="1"
          >
            {{ column.title }}
          </th>
        </template>
      </tr>
    </template>
    <template #item="{ item }">
      <tr
        :class="[
          'v-data-table__tr v-data-table__tr--clickable body-b2 cursor-default',
          mobile ? 'tr-mobile' : ''
        ]"
      >
        <td class="pa-1 v-data-table__td v-data-table-column--align-center">
          {{ item.orderNumber }}
        </td>
        <td class="pa-1 v-data-table__td v-data-table-column--align-center">
          {{
            'dateTimeExecution' in item && item.dateTimeExecution
              ? item.dateTimeExecution.dateFormatter('view-with-time')
              : '–'
          }}
        </td>
        <td class="pa-1 v-data-table__td">
          {{ actValue(item.actId as number) ?? '–' }}
        </td>
        <td class="pa-1 v-data-table__td v-data-table-column--align-center">
          {{ item.ticker ?? '–' }}
        </td>
        <td class="pa-1 v-data-table__td v-data-table-column--align-center">
          {{ item.amount ?? '–' }}
        </td>
        <td class="pa-1 v-data-table__td v-data-table-column--align-end">
          {{ formatCurrency(item.sum, false, 2, 2) }}
        </td>
        <td
          :class="[
            'body-b3-semibold',
            'pa-1',
            'v-data-table__td',
            'v-data-table-column--align-center',
            classColor(item.statusId)
          ]"
        >
          {{ statusValue(item.statusId) ?? '–' }}
          <v-tooltip
            v-if="item.note"
            content-class="elevation-16-dp border-sm"
            location="bottom left"
            max-width="280"
          >
            <template #activator="{ props }">
              <v-icon class="ml-2" v-bind="props" size="large">info-fill</v-icon>
            </template>
            <span>{{ item.note }}</span>
          </v-tooltip>
        </td>
      </tr>
    </template>
  </v-data-table-server>
  <v-sheet
    v-else-if="!(items && items.length)"
    class="bg-grey-lighten-4 align-center w-100 d-flex align-center justify-center align-self-center rounded-bb-lg empty-table"
    height="160px"
  >
    {{ t('portfolio.emptyData') }}
  </v-sheet>
</template>

<style scoped lang="scss">
  .mw-900 {
    min-width: 900px;
  }
  .selected {
    background-color: #f5f5f5;
  }

  .v-table > .v-table__wrapper > table > tbody > tr > td,
  .v-table > .v-table__wrapper > table > thead > tr > th {
    height: 32px;
  }
  .v-table > .v-table__wrapper > table > tbody > tr.tr-mobile > td {
    white-space: nowrap;
  }

  .v-data-table__td {
    box-sizing: content-box;
  }
</style>
