// @ts-ignore
export const order = {
  marketTitle: 'Рыночное',
  limitedTitle: 'Лимитированное',
  deferredTitle: 'Отложенное',
  modal: {
    success: 'принято',
    cancel: 'отменено',
    subtitle: 'Ваше поручение'
  }
}
