import httpCommunicator from '@/api/httpCommunicator'
import { INewOrderRequestModel } from '@/api/types'

export const orderService = {
  async sale(newOrder: INewOrderRequestModel) {
    return httpCommunicator.post('/orders', newOrder)
  },
  async cancelOrder(numberOrder: number) {
    return httpCommunicator.delete(`/orders/${numberOrder}`)
  },
  async ordersMass(paperIds: number[], actId: number) {
    return httpCommunicator.post(`/orders/mass`, {
      paperIds,
      actId
    })
  }
}
