<script setup lang="ts"></script>

<template>
  <v-checkbox
    true-icon="checkbox-checked"
    false-icon="checkbox-unchecked"
    density="compact"
  ></v-checkbox>
</template>

<style scoped lang="scss"></style>
