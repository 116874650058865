<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <rect width="40" height="40" rx="8" fill="currentColor" :fill-opacity="fillOpacity" />
    <path
      d="M12.8822 21.2336C12.2555 22.472 12.967 23.064 14.5905 24.0754C15.9707 24.9325 17.8775 25.246 19.1023 25.3714C18.6003 25.8565 20.9006 23.6324 14.2757 30.0385C12.8709 31.3921 15.1324 33.5638 16.5361 32.239L20.3363 28.5544C21.7911 29.9614 23.1856 31.3099 24.1364 32.2441C25.5411 33.5741 27.8016 31.4209 26.4122 30.0437C26.3079 29.944 21.2625 25.0785 21.5703 25.3765C22.8104 25.2511 24.6885 24.9192 26.0523 24.0805L26.0513 24.0795C27.6748 23.063 28.3864 22.472 27.7689 21.2336C27.3957 20.5306 26.3897 19.9427 25.0504 20.9592C25.0504 20.9592 23.2418 22.3518 20.325 22.3518C17.4072 22.3518 15.5996 20.9592 15.5996 20.9592C14.2613 19.9376 13.2512 20.5306 12.8822 21.2336Z"
      fill="white"
    />
    <path
      d="M20.323 20.474C23.8788 20.474 26.7823 17.6806 26.7823 14.2416C26.7823 10.7883 23.8788 7.99481 20.323 7.99481C16.7661 7.99481 13.8626 10.7883 13.8626 14.2416C13.8626 17.6806 16.7661 20.474 20.323 20.474ZM20.323 11.1593C22.0702 11.1593 23.4954 12.5376 23.4954 14.2416C23.4954 15.9313 22.0702 17.3095 20.323 17.3095C18.5757 17.3095 17.1506 15.9313 17.1506 14.2416C17.1495 12.5365 18.5747 11.1593 20.323 11.1593Z"
      fill="white"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
