<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M13.5009 4C12.6724 4 12.0009 4.67157 12.0009 5.5C12.0009 6.32843 12.6724 7 13.5009 7C14.3293 7 15.0009 6.32843 15.0009 5.5C15.0009 4.67157 14.3293 4 13.5009 4ZM13.1409 8.77C11.9509 8.87 8.70086 11.46 8.70086 11.46C8.50086 11.61 8.56086 11.6 8.72086 11.88C8.88086 12.15 8.86086 12.17 9.05086 12.04C9.25086 11.91 9.58086 11.7 10.1309 11.36C12.2509 10 10.4709 13.14 9.56086 18.43C9.20086 21.05 11.5609 19.7 12.1709 19.3C12.7709 18.91 14.3809 17.8 14.5409 17.69C14.7609 17.54 14.6009 17.42 14.4309 17.17C14.3109 17 14.1909 17.12 14.1909 17.12C13.5409 17.55 12.3509 18.45 12.1909 17.88C12.0009 17.31 13.2209 13.4 13.8909 10.71C14.0009 10.07 14.3009 8.67 13.1409 8.77Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
