<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M10.5903 13.4099C11.0003 13.7999 11.0003 14.4399 10.5903 14.8299C10.2003 15.2199 9.56031 15.2199 9.17031 14.8299C7.22031 12.8799 7.22031 9.70995 9.17031 7.75995L12.7103 4.21995C14.6603 2.26995 17.8303 2.26995 19.7803 4.21995C21.7303 6.16995 21.7303 9.33995 19.7803 11.2899L18.2903 12.7799C18.3003 11.9599 18.1703 11.1399 17.8903 10.3599L18.3603 9.87995C19.5403 8.70995 19.5403 6.80995 18.3603 5.63995C17.1903 4.45995 15.2903 4.45995 14.1203 5.63995L10.5903 9.16995C9.41031 10.3399 9.41031 12.2399 10.5903 13.4099ZM13.4103 9.16995C13.8003 8.77995 14.4403 8.77995 14.8303 9.16995C16.7803 11.1199 16.7803 14.2899 14.8303 16.2399L11.2903 19.7799C9.34031 21.7299 6.17031 21.7299 4.22031 19.7799C2.27031 17.8299 2.27031 14.6599 4.22031 12.7099L5.71031 11.2199C5.70031 12.0399 5.83031 12.8599 6.11031 13.6499L5.64031 14.1199C4.46031 15.2899 4.46031 17.1899 5.64031 18.3599C6.81031 19.5399 8.71031 19.5399 9.88031 18.3599L13.4103 14.8299C14.5903 13.6599 14.5903 11.7599 13.4103 10.5899C13.0003 10.1999 13.0003 9.55995 13.4103 9.16995Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
