<script setup lang="ts">
  import { useAccountStore } from '@/stores/accountStore'
  import { ref, computed } from 'vue'
  import { Instrument } from '@/components/types/CaseCommon'
  import { useI18n } from 'vue-i18n'
  import { IPositionModel } from '@/api/types'
  import CaseCommon from '@/components/BaseComponents/CaseComponents/CaseCommon.vue'
  import CaseMobile from '@/components/BaseComponents/CaseMobile.vue'
  import { useDisplay } from 'vuetify'

  const { t } = useI18n()

  interface ITabs {
    name: string
    title: string
  }

  const account = useAccountStore()
  const { mobile } = useDisplay()

  const tab = ref('common' as Instrument)

  const tabs = ref<ITabs[]>([
    {
      name: 'common',
      title: t('portfolio.table.tabs.common')
    },
    {
      name: 'action',
      title: t('portfolio.table.tabs.action')
    },
    {
      name: 'derivative',
      title: t('portfolio.table.tabs.derivative')
    },
    {
      name: 'dolgovyi',
      title: t('portfolio.table.tabs.dolgovyi')
    },
    {
      name: 'pai',
      title: t('portfolio.table.tabs.pai')
    },
    {
      name: 'currency',
      title: t('portfolio.table.tabs.currency')
    },
    {
      name: 'autoprofile',
      title: t('portfolio.table.tabs.autoprofile')
    }
  ])

  const instrumentsMain = computed<IPositionModel[]>(() => {
    if (tab.value === 'common') {
      return account.portfolioInstrumentsMain
    }
    if (tab.value === 'action') {
      return account.portfolioInstrumentsAction
    }
    if (tab.value === 'derivative') {
      return account.portfolioInstrumentsDerivative
    }
    if (tab.value === 'dolgovyi') {
      return account.portfolioInstrumentsDolgovyi
    }
    if (tab.value === 'pai') {
      return account.portfolioInstrumentsPai
    }
    if (tab.value === 'currency') {
      return account.portfolioInstrumentsCurrency
    }
    if (tab.value === 'autoprofile') {
      return account.portfolioInstrumentsAutoprofile
    }
    return []
  })

  const loading = computed(() => {
    return account.loading
  })
</script>

<template>
  <v-sheet class="mt-6">
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto elevation-0"
      max-width="100%"
      type="chip@6, ossein, table-thead, table-row@4, table-row-divider, table-tfoot, divider,text"
    ></v-skeleton-loader>
    <v-tabs v-else v-model="tab" active-color="grey-darken-4" hide-slider>
      <v-tab
        v-for="(tb, key) in tabs"
        :key="key"
        :value="tb.name"
        class="rounded-tt-lg text-none"
        selected-class="selected"
        hide-slider
      >
        {{ tb.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-window v-if="!loading" v-model="tab">
      <v-tabs-window-item
        v-for="(tb, key) in tabs"
        :key="key"
        :value="tb.name"
        transition="expand-transition"
        reverse-transition="expand-transition"
      >
        <v-sheet class="instruments-tables rounded-bb-lg">
          <CaseMobile
            v-if="mobile"
            v-show="instrumentsMain && instrumentsMain.length"
            :current-instrument="tab"
            :items="instrumentsMain"
          />
          <CaseCommon
            v-else
            v-show="instrumentsMain && instrumentsMain.length"
            :current-instrument="tab"
            :items="instrumentsMain"
          />
          <v-sheet
            v-if="!instrumentsMain.length"
            class="bg-grey-lighten-4 align-center w-100 d-flex align-center justify-center align-self-center rounded-bb-lg empty-table"
            height="160px"
          >
            {{ t('portfolio.emptyData') }}
          </v-sheet>
        </v-sheet>
      </v-tabs-window-item>
    </v-tabs-window>
  </v-sheet>
</template>

<style scoped lang="scss">
  .selected {
    background-color: #f5f5f5;
  }

  .empty-table {
    color: #949996 !important;
  }

  .instruments-table {
    :deep(.v-col) {
      font-size: 0.875rem;
    }
  }
</style>
