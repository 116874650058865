<script setup lang="ts">
  import { IBaseButtonProps } from '@/components/types'

  withDefaults(defineProps<IBaseButtonProps>(), {
    label: '',
    variant: 'flat',
    color: 'primary',
    type: 'button',
    width: 'full',
    className: '',
    block: false,
    elevation: 0,
    disabled: false,
    size: 'default'
  })

  const emit = defineEmits(['click'])

  const handleClick = (e: Event) => {
    emit('click', e)
  }
</script>

<template>
  <v-btn
    :size="size"
    :type="type"
    :color="color"
    :variant="variant"
    :block="block"
    :elevation="elevation"
    :disabled="disabled"
    :ripple="false"
    :class="[className, 'font-weight-bold text-none']"
    @mouseup.stop="handleClick"
  >
    <template v-if="$slots['icon']" #prepend>
      <slot name="icon"></slot>
    </template>
    {{ label }}
    <template v-if="$slots['append']" #append>
      <slot name="append"></slot>
    </template>
  </v-btn>
</template>

<style scoped></style>
