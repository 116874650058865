<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { computed } from 'vue'
  import IconVK from '@/components/icons/IconVK.vue'
  import IconTelegram from '@/components/icons/IconTelegram.vue'
  import IconYouTube from '@/components/icons/IconYouTube.vue'
  import IconFlagEnglish from '@/components/icons/IconFlagEnglish.vue'
  import IconFlagRus from '@/components/icons/IconFlagRus.vue'
  import { useDisplay } from 'vuetify'
  import { useRouter } from 'vue-router'

  const { t } = useI18n()
  const links = [
    {
      title: t('footer.link1'),
      href: '//www.ricom.ru/'
    },
    {
      title: t('footer.link2'),
      href: '//www.ricom.ru/info_disclosure/'
    },
    {
      title: t('footer.link3'),
      href: '//www.ricom.ru/faq/'
    },
    {
      title: t('footer.link4'),
      href: '//www.ricom.ru/regions/'
    }
  ]
  const { mobile } = useDisplay()
  const router = useRouter()
  const showLanguage = false
  const versionApp = computed(() => window.env.APP_VERSION)
</script>

<template>
  <v-container v-if="!mobile" class="v-container" max-width="1280">
    <div class="d-inline-flex w-100 flex-column align-center ga-4 justify-start pb-7">
      <div class="d-flex align-stretch gr-7 justify-space-between py-4 align-self-stretch">
        <div class="d-flex flex-column align-start ga-8 justify-start">
          <div class="d-flex flex-column align-start ga-2 justify-start">
            <div class="body-b1" style="color: var(--color-BlackActive)">
              {{ t('footer.phoneDesc') }}
            </div>
            <div class="title-h2" style="color: var(--color-BlackInactive)">
              {{ t('footer.phone') }}
            </div>
          </div>
          <div class="subtitle-s1" style="color: var(--color-BlackDisabled)">
            &copy; 1994–{{ new Date().getFullYear() }} {{ t('footer.copy') }}
          </div>
        </div>
        <div class="d-flex flex-column align-center justify-space-between">
          <div class="d-inline-flex align-start ga-6 justify-center px-2">
            <a
              v-for="link in links"
              :key="link.title"
              class="subtitle-s2"
              :href="link.href"
              target="_blank"
            >
              {{ link.title }}
            </a>
          </div>
          <div
            class="body-b3 justify-space-around align-center"
            style="color: var(--color-BlackInactive)"
          >
            {{ t('footer.version', { versionApp }) }}
          </div>
          <div v-if="showLanguage" class="d-inline-flex align-start justify-center pt-16">
            <div class="d-flex align-center justify-center rounded-lg">
              <div class="d-flex align-center ga-2 justify-center rounded-lg py-2 pl-2 pr-4">
                <div class="position-relative">
                  <IconFlagRus />
                </div>
                <div>Русский</div>
              </div>
            </div>
            <div class="d-flex align-center justify-center rounded-lg">
              <div class="d-flex align-center ga-2 justify-center rounded-lg py-2 pl-2 pr-4">
                <div class="position-relative">
                  <IconFlagEnglish />
                </div>
                <div>English</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-start ga-4 justify-start px-2">
          <div class="body-b1">{{ t('footer.socialTitle') }}</div>
          <div class="d-flex align-start ga-6 justify-start">
            <div class="w-10 h-10 position-relative">
              <a href="https://vk.com/ricom_trust" target="_blank">
                <IconVK viewBox="0 0 40 40" width="40" height="40" />
              </a>
            </div>
            <div class="w-10 h-10 position-relative">
              <a href="https://t.me/ricom_trast" target="_blank">
                <IconTelegram viewBox="0 0 40 40" width="40" height="40" />
              </a>
            </div>
            <div class="w-10 h-10 position-relative">
              <a href="https://www.youtube.com/@ricom_trust" target="_blank">
                <IconYouTube viewBox="0 0 40 40" width="40" height="40" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
  <v-container v-else v-show="router.currentRoute.value.name !== 'feedback'">
    <v-sheet class="d-flex flex-column ga-8 align-center">
      <a class="body-b3-bold" href="//www.ricom.ru/info_disclosure/">{{ t('footer.link2') }}</a>
      <span class="body-b3" style="color: var(--color-BlackInactive)">
        {{ t('footer.version', { versionApp }) }}
      </span>
      <v-sheet class="d-flex flex-column align-center">
        <span class="body-b1" style="color: var(--color-BlackActive)">
          {{ t('footer.phoneDesc') }}
        </span>
        <h2 style="color: var(--color-BlackInactive)">{{ t('footer.phone') }}</h2>
      </v-sheet>
      <v-sheet class="body-b1" style="color: var(--color-BlackDisabled)">
        &copy; 1994–{{ new Date().getFullYear() }} {{ t('footer.copy') }}
      </v-sheet>
    </v-sheet>
  </v-container>
</template>

<style scoped lang="scss">
  .main-footer {
    width: 100%;
    border-top: 1px $colorBlackFocus;
  }

  a[href] {
    &:hover {
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }

    text-decoration: none;
  }
</style>
