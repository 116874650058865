import { useLotsStore } from '@/stores/lotsStore'
import NotFoundView from '@/views/NotFoundView.vue'
import { createRouter, createWebHistory } from 'vue-router'
import AuthView from '@/views/AuthView.vue'
import { useAuthStore } from '@/stores/authStore'
import Main from '@/layouts/Main.vue'
import PortfolioView from '@/views/PortfolioView.vue'
import TradingView from '@/views/TradingView.vue'
import ServicesView from '@/views/ServicesView.vue'
import { useDictionaryStore } from '@/stores/dictionaryStore'
import AccountView from '@/views/AccountView.vue'
import BankAccounts from '@/views/BankAccounts.vue'
import Common from '@/layouts/Common.vue'
import Reports from '@/views/Reports.vue'
import FeedbackView from '@/views/FeedbackView.vue'
import RegistrationView from '@/views/RegistrationView.vue'
import i18n from '@/utils/i18n'
import ResultRegistrationView from '@/views/ResultRegistrationView.vue'
import Registration from '@/layouts/Registration.vue'
import HistoryOrders from '@/components/HistoryOrders.vue'
import HistoryTransactions from '@/components/HistoryTransactions.vue'

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    meta: { requiresAuth: false }
  },
  {
    path: '/registration',
    component: Registration,
    children: [
      {
        path: '',
        name: 'registration',
        component: RegistrationView,
        meta: { requiresAuth: false }
      },
      {
        path: 'success',
        name: 'registration-result',
        component: ResultRegistrationView,
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '',
        component: Common,
        children: [
          {
            path: '',
            name: 'portfolio',
            component: PortfolioView,
            meta: { requiresAuth: true }
          },
          {
            path: '/trading',
            name: 'trading',
            component: TradingView,
            meta: { requiresAuth: true }
          },
          {
            path: '/services',
            name: 'services',
            component: ServicesView,
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: '/account',
        name: 'account',
        component: AccountView,
        meta: { requiresAuth: true }
      },
      {
        path: '/account/bank',
        name: 'account.bank',
        component: BankAccounts,
        meta: { requiresAuth: true }
      },
      {
        path: '/services/reports',
        name: 'services.reports',
        component: Reports,
        meta: { requiresAuth: true }
      },
      {
        path: '/feedback',
        name: 'feedback',
        component: FeedbackView,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/history-orders',
    name: 'history-orders',
    component: HistoryOrders
  },
  {
    path: '/history-transactions',
    name: 'history-transactions',
    component: HistoryTransactions
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  const dictionaryStore = useDictionaryStore()
  const lots = useLotsStore()

  const isAuthenticated = authStore.isAuthenticated
  const entryPoint = authStore.entryPoint

  async function ds() {
    await dictionaryStore.fetchDictionaries()
    await lots.load()
  }

  if (isAuthenticated) {
    await ds()
  }

  if (to.name) {
    // Установка заголовков
    if (/\./.test(to.name as string)) {
      const path = (to.name as string).replace('.', '_.')
      document.title = i18n.global.t(`title.${path}`)
    } else {
      document.title = i18n.global.t(`title.${to.name as string}`)
    }
  } else {
    // Устанавливаем стандартный заголовок, если мета-заголовок не определён
    document.title = 'Название вашего приложения'
  }

  if (to.meta.requiresAuth && !isAuthenticated) {
    // Записываем fullPath и query-парамсы в том случае, если маршрут требует авторизации и пользователь не авторизован
    authStore.setEntryPoint({ url: to.fullPath, query: to.query })

    // Если маршрут требует авторизации и пользователь не авторизован, перенаправляем на страницу логина
    return router.push('/auth')
  } else {
    if (to.name === 'auth' && isAuthenticated) {
      return router.push('/')
    } else if (authStore.entryPoint?.url && isAuthenticated) {
      // Если ранее неавторизованный пользователь стучался по маршруту, который требовал авторизации, возвращаем его на маршрут, а также пушим в url query-парамы. Чистим энтрипоин
      authStore.clearEntryPoint()
      return router.push({ path: entryPoint?.url, query: entryPoint?.query })
    }
  }
})

// @ts-ignore
export default router
