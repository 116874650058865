<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M19.3596 2.71997L20.7796 4.13997L15.0596 9.84997C16.1296 11.39 16.2796 13.24 15.3796 14.44L9.05961 8.11997C10.2596 7.21997 12.1096 7.36997 13.6496 8.43997L19.3596 2.71997ZM5.92961 17.57C3.91961 15.56 2.68961 13.16 2.34961 10.92L7.22961 8.82997L14.6696 16.27L12.5796 21.15C10.3396 20.81 7.93961 19.58 5.92961 17.57Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
