<script setup lang="ts">
  import { ILotsModel, IOrderActionsModel, IOrderHistoryModel } from '@/api/types'
  import {
    DataOptions,
    FilterOrdersRequestModel,
    OrderApplicationTypes,
    OrderStatus
  } from '@/components/types'
  import { useDictionaryStore } from '@/stores/dictionaryStore'
  import { debounce } from 'lodash-es'
  import { computed, onMounted, onUnmounted, ref, reactive, watch } from 'vue'
  import { useOrderHistoryStore } from '@/stores/orderHistoryStore'
  import { useI18n } from 'vue-i18n'
  import BaseSelect from '@/components/BaseComponents/BaseSelect.vue'
  import '@/utils/string.extensions'
  import { orderService } from '@/api/orderService'
  import RangeDatePickerV from '@/components/RangeDatePickerV.vue'
  import { formatCurrency } from '@/utils/number.extensions'
  import { useDialogStore } from '@/stores/dialogStore'
  import { useOrderSaleStore } from '@/stores/orderSaleStore'
  import { useLotsStore } from '@/stores/lotsStore'
  import { useDisplay } from 'vuetify'
  import MoveBack from '@/components/BaseComponents/MoveBack.vue'

  const StatusColor: Record<number, string> = {
    0: 'text-BlackActive',
    1: 'text-WarningDark',
    2: 'text-AditionalBlue',
    3: 'text-AditionalGreen',
    4: 'text-MainDark',
    5: 'text-BlackDisabled',
    6: 'text-BlackDisabled'
  }
  //region> Uses
  const { t } = useI18n()
  const orderStore = useOrderHistoryStore()
  const dictionaryStore = useDictionaryStore()
  const dialogStore = useDialogStore()
  const orderSale = useOrderSaleStore()
  const lots = useLotsStore()
  const { mobile } = useDisplay()
  //endregion
  //region> Refs
  const filterData = reactive<Partial<FilterOrdersRequestModel>>({
    periodStart: undefined,
    periodEnd: undefined,
    status: undefined,
    applicationTypes: undefined
  })
  const tableOptions = reactive({
    page: 1,
    itemsPerPage: 10
  })
  // Индекс строки под ховером
  const hoveredRow = ref<number | null>(null)
  //endregion
  //region> Computed
  const loading = computed<boolean>(() => orderStore.loading)
  const items = computed<IOrderHistoryModel[]>(() => orderStore.data)
  const period = computed({
    get: () => {
      return [filterData.periodStart, filterData.periodEnd]
    },
    set: value => {
      filterData.periodStart = value[0]
      filterData.periodEnd = value[1]
    }
  })
  const itemLength = computed(() => orderStore.total)
  const status = computed({
    get: () => filterData.status,
    set: (value: OrderStatus) => {
      filterData.status = value
    }
  })
  const applicationTypes = computed({
    get: () => filterData.applicationTypes,
    set: (value: OrderApplicationTypes) => {
      filterData.applicationTypes = value
    }
  })
  //endregion
  //region> Items
  const headers = [
    {
      align: 'center' as const,
      key: 'number',
      sortable: false,
      title: t('history.headers.number')
    },
    {
      align: 'center' as const,
      key: 'dateCreate',
      sortable: false,
      title: t('history.headers.dateCreate')
    },
    {
      align: 'center' as const,
      key: 'operation',
      sortable: false,
      title: t('history.headers.operation')
    },
    {
      align: 'center' as const,
      key: 'cb',
      sortable: false,
      title: t('history.headers.cb')
    },
    {
      align: 'center' as const,
      key: 'count',
      sortable: false,
      title: t('history.headers.count')
    },
    {
      align: 'center' as const,
      key: 'amount',
      sortable: false,
      title: t('history.headers.amount')
    },
    {
      align: 'center' as const,
      key: 'status',
      sortable: false,
      title: t('history.headers.status')
    }
  ]
  const statusItems = [
    {
      title: t('history.statusItems.active'),
      value: 'active'
    },
    {
      title: t('history.statusItems.executed'),
      value: 'executed'
    },
    {
      title: t('history.statusItems.removed'),
      value: 'removed'
    }
  ]
  const applicationTypesItems = [
    {
      title: t('history.applicationTypesItems.market'),
      value: 'market'
    },
    {
      title: t('history.applicationTypesItems.limit'),
      value: 'limit'
    }
  ]
  //endregion

  const statusValue = (id: number) => {
    const filteredItem = dictionaryStore.orderStatuses.filter(item => item.id === id)
    return filteredItem.length > 0 ? filteredItem[0].name : undefined
  }

  const actValue = (id: number) => {
    const filteredItem = dictionaryStore.orderActions.filter(item => item.id === id)
    return filteredItem.length > 0 ? filteredItem[0].name : undefined
  }
  const handlerFetch = debounce(() => {
    fetchData()
  }, 200)

  const fetchData = async () => {
    const args: FilterOrdersRequestModel = {
      page: tableOptions.page,
      limit: tableOptions.itemsPerPage,
      ...filterData
    }
    await orderStore.load(args)
  }

  const onChangeOrderHistory = () => {
    tableOptions.page = 1
    tableOptions.itemsPerPage = 10
    handlerFetch()
  }

  const onPaginationChange = (e: DataOptions) => {
    tableOptions.page = e.page
    tableOptions.itemsPerPage = e.itemsPerPage
    handlerFetch()
  }

  const classColor = (statusId: number) => {
    if (statusId in StatusColor) {
      return StatusColor[statusId]
    }
    return ''
  }

  const getAction = (action: string | undefined) => {
    switch (action) {
      case 'купить':
        return 'buy'
      case 'продать':
        return 'sell'
      default:
        return 'all'
    }
  }

  const cancelOrder = async (orderNumber: number) => {
    try {
      const data = await orderService.cancelOrder(orderNumber)
      if (data.status === 200) {
        orderStore.changeOrderStatus(orderNumber, 6)
      }
    } catch (error) {
      console.log('cancelOrder', error)
    }
  }
  const editOrder = async (orderNumber: number) => {
    const order = orderStore.data.find(item => item.orderNumber === orderNumber) as
      | IOrderHistoryModel
      | undefined
    const action = dictionaryStore.orderActions.find(item => item.id === order?.actId) as
      | IOrderActionsModel
      | undefined

    const paper = lots.lots.find(p => p.paperId === order?.paperId) as ILotsModel | undefined

    if (action && order && paper) {
      const mobileDialogProps = {
        width: '100%',
        fullscreen: true
      }
      const dialogProps = {
        cardType: getAction(action.name),
        cardVariant: 'simple',
        paperId: order.paperId,
        tabName: order.applicationTypes
      }
      await cancelOrder(orderNumber)
      orderSale.data.amount = order.amount / paper.sizeLot
      orderSale.data.price = order.price || undefined
      dialogStore.openDialog(
        'TradingOrderSale',
        !mobile.value ? dialogProps : { ...dialogProps, ...mobileDialogProps }
      )
    }
  }

  watch(
    () => period.value,
    () => {
      onChangeOrderHistory()
    }
  )

  onMounted(async () => {
    handlerFetch()
    await orderStore.startAutoUpdate(2000)
  })
  onUnmounted(() => {
    orderStore.stopAutoUpdate()
  })
</script>

<template>
  <v-sheet v-if="mobile" class="w-100 px-4 py-6 position-relative">
    <MoveBack class="position-absolute" />
    <h3 class="text-center">Поручения</h3>
  </v-sheet>
  <div class="dvw-100 pt-4 pl-2 pr-2 align-start align-stretch bg-grey-lighten-4 overflow-x-scroll">
    <div class="d-flex ga-4" style="width: fit-content">
      <BaseSelect
        v-model="status"
        with-class="mt-2"
        :clearable="true"
        :items="statusItems"
        density="compact"
        bg-color="var(--color-WhiteWhite)"
        :label="t('history.labelStatus')"
        max-width="180"
        min-width="125"
        @change="onChangeOrderHistory"
      />
      <v-sheet class="w-280 mt-2 body-b2 bg-transparent">
        <RangeDatePickerV v-model="period" :label="t('history.rangePicker')" />
      </v-sheet>
      <BaseSelect
        v-model="applicationTypes"
        with-class="mt-2"
        class="w-180"
        :items="applicationTypesItems"
        :clearable="true"
        density="compact"
        bg-color="var(--color-WhiteWhite)"
        :label="t('history.labelApplicationTypes')"
        width="180"
        @change="onChangeOrderHistory"
      />
    </div>
  </div>
  <v-skeleton-loader
    v-if="loading"
    elevation="1"
    class="w-100"
    type="table-row-divider@10, table-row"
  ></v-skeleton-loader>
  <v-data-table-server
    v-if="items && items.length"
    :loading="loading"
    :page="tableOptions.page"
    :items-per-page="tableOptions.itemsPerPage"
    :items-length="itemLength"
    height="auto"
    item-value="id"
    :items-per-page-options="[10, 15, 25]"
    width="100%"
    :headers="headers"
    :items="items"
    last-icon="arrow-right"
    first-icon="arrow-left"
    next-icon="chevron-right"
    prev-icon="chevron-left"
    @update:options="onPaginationChange"
  >
    <template #headers="{ columns }">
      <tr>
        <template v-for="column in columns" :key="column.key">
          <th
            class="pa-1 body-b3 v-data-table__td v-data-table__th--sortable v-data-table__th v-data-table-column--align-center"
            colspan="1"
            rowspan="1"
          >
            {{ column.title }}
          </th>
        </template>
      </tr>
    </template>
    <template #item="{ item }">
      <tr
        :key="item.orderNumber"
        :class="[
          'v-data-table__tr v-data-table__tr--clickable body-b2 cursor-default',
          mobile ? 'tr-mobile' : ''
        ]"
        :style="
          [0, 1].includes(item.statusId) && hoveredRow === item.orderNumber
            ? 'background-color: var(--color-BlackFocus)'
            : ''
        "
        @mouseover="hoveredRow = item.orderNumber"
        @mouseleave="hoveredRow = null"
      >
        <td class="pa-1 v-data-table__td v-data-table-column--align-center">
          {{ item.orderNumber }}
        </td>
        <td class="pa-1 v-data-table__td v-data-table-column--align-center">
          {{
            'dateTimeCreate' in item && item.dateTimeCreate
              ? item.dateTimeCreate.dateFormatter('view-with-time')
              : '–'
          }}
        </td>
        <td class="pa-1 v-data-table__td">
          {{ actValue(item.actId as number) ?? '–' }}
        </td>
        <td class="pa-1 v-data-table__td v-data-table-column--align-center">
          {{ item.ticker ?? '–' }}
        </td>
        <td class="pa-1 v-data-table__td v-data-table-column--align-center">
          {{ item.amount ?? '–' }}
        </td>
        <td class="pa-1 v-data-table__td v-data-table-column--align-end">
          {{ formatCurrency(item.sum, false, 2, 2) }}
        </td>
        <td
          v-if="[0, 1].includes(item.statusId) && hoveredRow === item.orderNumber"
          style="width: 208px"
          :class="['pa-1', 'v-data-table__td', 'v-data-table-column--align-center']"
        >
          <div class="d-flex align-center justify-center ga-4">
            <v-tooltip
              location="bottom left"
              :offset="[4, -12]"
              max-width="280"
              content-class="elevation-16-dp border-sm"
            >
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="edit" @click.stop="editOrder(item.orderNumber)" />
              </template>
              <span>{{ t('history.tooltip.edit') }}</span>
            </v-tooltip>
            <v-tooltip
              location="bottom left"
              max-width="280"
              :offset="[4, -12]"
              content-class="elevation-16-dp border-sm"
            >
              <template #activator="{ props }">
                <v-icon
                  v-bind="props"
                  icon="close-square"
                  @click.stop="cancelOrder(item.orderNumber)"
                />
              </template>
              <span>{{ t('history.tooltip.cancel') }}</span>
            </v-tooltip>
          </div>
        </td>
        <td
          v-else
          width="208"
          :class="[
            'body-b3-semibold',
            'pa-1',
            'v-data-table__td',
            'v-data-table-column--align-center',
            classColor(item.statusId)
          ]"
        >
          {{ statusValue(item.statusId) ?? '–' }}
          <v-tooltip
            v-if="item.note"
            location="bottom left"
            max-width="280"
            :offset="[4, -12]"
            content-class="elevation-16-dp border-sm"
          >
            <template #activator="{ props }">
              <v-icon class="ml-2" v-bind="props" size="large">info-fill</v-icon>
            </template>
            <span>{{ item.note }}</span>
          </v-tooltip>
        </td>
      </tr>
    </template>
  </v-data-table-server>
  <v-sheet
    v-else-if="!(items && items.length)"
    class="bg-grey-lighten-4 align-center w-100 d-flex align-center justify-center align-self-center rounded-bb-lg empty-table"
    height="160px"
  >
    {{ t('portfolio.emptyData') }}
  </v-sheet>
</template>

<style scoped lang="scss">
  .dvw-100 {
    max-width: 100dvw;
  }
  .mw-900 {
    min-width: 900px;
  }
  .selected {
    background-color: #f5f5f5;
  }

  .v-table > .v-table__wrapper > table > tbody > tr > td,
  .v-table > .v-table__wrapper > table > thead > tr > th {
    height: 32px;
  }
  .v-table > .v-table__wrapper > table > tbody > tr.tr-mobile > td {
    white-space: nowrap;
  }

  .v-data-table__td {
    box-sizing: content-box;
  }
</style>
