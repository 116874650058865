<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M21.4004 7.50005C22.2004 8.30005 22.2004 9.60005 21.4004 10.3L18.6004 13.1L10.8004 5.30005L13.6004 2.50005C14.4004 1.70005 15.7004 1.70005 16.4004 2.50005L18.2004 4.30005L21.2004 1.30005L22.6004 2.70005L19.6004 5.70005L21.4004 7.50005ZM15.6004 13.3L14.2004 11.9L11.4004 14.7L9.30039 12.6L12.1004 9.80005L10.7004 8.40005L7.90039 11.2L6.40039 9.80005L3.60039 12.6C2.80039 13.4 2.80039 14.7 3.60039 15.4L5.40039 17.2L1.40039 21.2001L2.80039 22.6L6.80039 18.6L8.60039 20.4C9.40039 21.2 10.7004 21.2 11.4004 20.4L14.2004 17.6L12.8004 16.2L15.6004 13.3Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
