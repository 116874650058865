<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M21 16V19H24V21H21V24H19V21H16V19H19V16H21ZM19 5C20.1046 5 21 5.89543 21 7L21.0008 14.0831C20.6753 14.0284 20.341 14 20 14C19.6594 14 19.3253 14.0284 19.0002 14.0829L19 7H8V21L14.0831 21.0008C14.2037 21.7188 14.4517 22.3936 14.8033 23.001L8 23C6.89543 23 6 22.1046 6 21V7C6 5.89543 6.89543 5 8 5H19ZM16 1V3H4V17H2V3C2 1.89543 2.89543 1 4 1H16Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
