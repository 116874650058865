<script setup lang="ts"></script>

<template>
  <v-radio false-icon="radio-unchecked" true-icon="radio-checked"></v-radio>
</template>

<style scoped lang="scss">
  .v-selection-control-group {
    &.v-selection-control-group--inline {
      .v-radio {
        padding-left: 8px;
        padding-right: 8px;
      }

      .v-radio:first-child {
        padding-left: 0;
      }

      .v-radio:last-child {
        padding-right: 0;
      }
    }
  }
</style>
