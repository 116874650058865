<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8306 8H21.0006V6H8.83063L10.8306 8ZM15.8306 13H18.0006V11H13.8306L15.8306 13ZM14.0006 16.83V18H10.0006V16H13.1706L10.1706 13H6.00063V11H8.17063L5.17063 8H3.00063V6H3.17063L1.39062 4.22L2.80062 2.81L21.1806 21.19L19.7706 22.6L14.0006 16.83Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
