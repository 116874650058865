import { defineStore } from 'pinia'
import i18n from '@/utils/i18n'

type INotifyType = 'info' | 'success' | 'warn' | 'error'
type Groups = 'system' | 'app' | 'session' | 'promote'

interface IState {
  message: string
  description: string
  type: INotifyType
  duration?: number
  group: Groups
}
interface IGetter {
  [key: string]: any
}
interface IAction {
  show: (
    message: string,
    description: string,
    type?: INotifyType,
    group?: Groups,
    duration?: number
  ) => void
  showServiceError: () => void
  focusTesting: () => void
  clear: () => void
}

export const useNotify = defineStore<'notify', IState, IGetter, IAction>({
  id: 'notify',
  state: (): IState => ({
    message: '',
    description: '',
    type: 'info',
    duration: 3000,
    group: 'system'
  }),
  actions: {
    show(
      message: string,
      description: string,
      type?: INotifyType,
      group?: Groups,
      duration?: number
    ) {
      this.message = message
      this.description = description
      this.type = type ?? 'info'
      this.group = group ?? 'app'
      this.duration = duration ?? -1
      if (duration && duration > 0) {
        setTimeout(() => {
          this.$reset()
        }, duration)
      }
    },
    showServiceError() {
      this.message = i18n.global.t('errorTemporaryUnavailable.message')
      this.description = i18n.global.t('errorTemporaryUnavailable.description')
      this.group = 'system'
      this.type = 'error'
      this.duration = 4000
      setTimeout(() => {
        this.$reset()
      }, this.duration)
    },
    focusTesting() {
      this.message = i18n.global.t('focusTesting.message')
      this.description = i18n.global.t('focusTesting.description')
      this.type = 'error'
      this.group = 'system'
      this.duration = -1
    },
    clear() {
      this.$reset()
    }
  },
  getters: {}
})
