import { IOrderHistoryModel } from '@/api/types'
import { defineStore } from 'pinia'
import { FilterOrdersRequestModel } from '@/components/types'
import orderHistoryService from '@/api/orderHistoryService'
import { useDialogStore } from '@/stores/dialogStore'
import messageService from '@/api/messageService'
import { useNotify } from '@/stores/notify'

interface IState {
  data: IOrderHistoryModel[]
  loading: boolean
  total: number
  timer: ReturnType<typeof setTimeout> | null
  error?: Error
}
interface IAction {
  load: (args?: FilterOrdersRequestModel) => Promise<void>
  clearStore: () => void
  changeOrderStatus: (orderNumber: number, statusId: number) => void
  autoUpdate: () => Promise<void>
  startAutoUpdate: (interval: number) => Promise<void>
  scheduleNextUpdate: (interval: number) => void
  stopAutoUpdate: () => void
}
interface IGetter {
  arrayOrderNumbers: (state: IState) => number[]
  [key: string]: any
}

export const useOrderHistoryStore = defineStore<'orderHistory', IState, IGetter, IAction>({
  id: 'orderHistory',
  state: (): IState => ({
    data: [],
    loading: false,
    total: 0,
    error: undefined,
    timer: null
  }),
  persist: true,
  actions: {
    async load(args?: FilterOrdersRequestModel) {
      this.loading = true
      try {
        const { data, total } = await orderHistoryService.get(args)

        this.data = data as IOrderHistoryModel[]
        this.total = total as number
        this.error = undefined
      } catch (error: any) {
        this.error = { name: error.code, message: error.message }
      }
      this.loading = false
    },
    clearStore() {
      this.$reset()
    },
    //todo: not used, need extract dialogStore
    changeOrderStatus(orderNumber: number, statusId: number) {
      const order = this.data.find((item: IOrderHistoryModel) => item.orderNumber === orderNumber)
      if (order) {
        order.statusId = statusId
        const dialogStore = useDialogStore()
        dialogStore.openDialog('OrderSuccess', {
          width: 343,
          orderNumber: order.orderNumber,
          action: 'cancel'
        })
      }
    },
    async autoUpdate() {
      try {
        const { data } = await orderHistoryService.statuses(this.arrayOrderNumbers)
        this.data = data
      } catch (error: any) {
        this.error = { name: error.code, message: error.message }
        const notify = useNotify()
        notify.showServiceError()
      }
    },
    async startAutoUpdate(interval: number) {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.scheduleNextUpdate(interval)
    },
    // запланированный следующий запрос
    scheduleNextUpdate(interval) {
      this.timer = setTimeout(async () => {
        await this.autoUpdate()
        this.scheduleNextUpdate(interval) // Планируем следующий запрос
      }, interval)
    },
    // Функция для остановки обновления
    stopAutoUpdate() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = null
    }
  },
  getters: {
    arrayOrderNumbers(state: IState) {
      return state.data.map((item: IOrderHistoryModel) => item.orderNumber)
    }
  }
})
