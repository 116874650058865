<script setup lang="ts">
  import { computed } from 'vue'
  import { ILotsModel } from '@/api/types'
  import { useI18n } from 'vue-i18n'
  import { useLotsStore } from '@/stores/lotsStore'

  interface ILotSizeProps {
    ticker: string
    needDivider?: boolean
  }

  const props = withDefaults(defineProps<ILotSizeProps>(), {
    needDivider: false
  })
  const { t } = useI18n()
  const lotsStore = useLotsStore()

  const lotSize = computed(() => {
    const lot = lotsStore.lots.find((item: ILotsModel) => item.ticker === props.ticker)

    if (lot && lot.sizeLot) {
      return `${t('info.lotName')} = ${lot.sizeLot} ${t('info.action.title1')}`
    }
    return null
  })
</script>

<template>
  <v-divider
    v-if="lotSize && needDivider"
    length="14"
    style="align-self: center"
    vertical
  ></v-divider>
  <v-sheet v-if="lotSize" class="body-b2 text-inactive">{{ lotSize }}</v-sheet>
</template>

<style scoped lang="scss"></style>
