<script setup lang="ts">
  import { Carousel, Slide } from 'vue3-carousel'
  import IconArrowRight from '@/components/icons/IconArrowRight.vue'
  import 'vue3-carousel/carousel.css'
  import { computed, onMounted, ref } from 'vue'
  import { IPromoModel } from '@/api/types'
  import staticService from '@/api/staticService'
  import { useRoute } from 'vue-router'

  const carouselConfig = {
    itemsToShow: 2.2,
    wrapAround: true,
    gap: 12,
    breakpointMode: 'viewport'
    // preventExcessiveDragging: true
  }

  const cards = ref<IPromoModel[]>([])
  const loading = ref(true)
  const route = useRoute()

  const cardChunks = computed(() => {
    const chunkSize = 3

    if (route.name === 'portfolio') {
      return cards.value.slice(0, chunkSize)
    }

    if (route.name === 'trading') {
      return cards.value.slice(3, 6)
    }

    if (route.name === 'services') {
      return cards.value.slice(6, 9)
    }
    return []
  })

  onMounted(async () => {
    loading.value = true
    cards.value = await staticService.promo()
    loading.value = false
  })

  const goToLink = (link: string) => {
    window.open(link, '_blank')
  }
</script>

<template>
  <v-sheet v-if="loading" class="d-flex justify-space-between w-100">
    <v-skeleton-loader class="w-33" elevation="0" height="200px" type="card"></v-skeleton-loader>
    <v-skeleton-loader class="w-33" elevation="0" height="200px" type="card"></v-skeleton-loader>
    <v-skeleton-loader class="w-33" elevation="0" height="200px" type="card"></v-skeleton-loader>
  </v-sheet>
  <v-sheet v-else class="w-100">
    <Carousel v-bind="carouselConfig">
      <Slide v-for="(card, cardIndex) in cardChunks" :key="cardIndex">
        <v-card class="promo-card w-100" height="112" color="black" @click="goToLink(card.link)">
          <v-card-title class="pa-2 pb-1">
            <v-img height="62px" :src="card.image" class="rounded-lg" cover></v-img>
          </v-card-title>
          <v-card-text class="py-1 px-2 pt-0">
            <v-sheet color="black" class="d-flex align-center justify-space-between">
              <div class="additional-overline">{{ card.title }}</div>
              <div>
                <IconArrowRight color="white" />
              </div>
            </v-sheet>
          </v-card-text>
        </v-card>
      </Slide>
    </Carousel>
  </v-sheet>
</template>

<style scoped lang="scss"></style>
