import type { ThemeDefinition } from 'vuetify'

export const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: 'rgba(255, 255, 255, 1)',
    surface: 'rgba(255, 255, 255, 1)',
    primary: 'rgba(105, 108, 236, 1)',
    'primary-darken-1': 'rgba(13, 46, 149, 1)',
    error: 'rgba(233, 30, 42, 1)',
    info: 'rgba(105, 108, 236, 1)',
    success: 'rgba(0, 150, 136, 1)',
    warning: 'rgba(255, 154, 2, 1)',
    'on-primary': 'rgba(255, 255, 255, 1)',
    'on-error': 'rgb(255, 255, 255)',
    'success-dark': 'rgb(0, 121, 107)',
    'alert-dark': 'rgb(194, 24, 34)',
    'black-inactive-60': 'rgba(0,0,0,0.6)',
    'aditional-light-grey': 'rgb(245, 245, 245)',
    'surface-variant': 'rgba(0, 0, 0, 0.88)',
    BlackActive: 'rgba(0, 0, 0, 0.88)',
    WarningDark: 'rgba(220, 115, 27, 1)',
    AditionalBlue: 'rgba(53, 97, 236, 1)',
    AditionalGreen: 'rgba(20, 172, 68, 1)',
    MainDark: 'rgba(13, 46, 149, 1)',
    BlackDisabled: 'rgba(0, 0, 0, 0.38)',
    AlertNormal: 'rgba(233, 30, 42, 1)'
  }
}
