<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M14 20C14 21.1 13.1 22 12 22C10.9 22 10 21.1 10 20H14ZM12 2C12.6078 2 13.1545 2.27477 13.5221 2.70624C12.6873 3.58032 12.1376 4.72842 12.0225 6.00009C12.0155 6.00002 12.0077 6 12 6C9.2 6 7 8.2 7 11V17H17L17.0001 11.9775C17.1648 11.9924 17.3315 12 17.5 12C18.02 12 18.5234 11.9275 19.0005 11.792L19 16L21 18V19H3V18L5 16V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
    <path
      d="M21 6.5C21 8.43 19.43 10 17.5 10C15.57 10 14 8.43 14 6.5C14 4.57 15.57 3 17.5 3C19.43 3 21 4.57 21 6.5Z"
      fill="#E91E63"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
