<script setup lang="ts">
  import BasePromo from '@/components/BaseComponents/BasePromo.vue'
  import CaseTable from '@/components/CaseTable.vue'
  import HistoryComponent from '@/components/HistoryComponent.vue'
  import PortfolioFinancialResults from '@/components/PortfolioFinancialResults.vue'
  import { useAccountInfoStore } from '@/stores/accountInfoStore'
  import { onMounted, computed, onUnmounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useAccountStore } from '@/stores/accountStore'
  import AccountSelection from '@/components/AccountSelection.vue'
  import { useDisplay } from 'vuetify'
  import GradientBar from '@/components/GradientBar.vue'

  const gradientBar = ref(false)

  const { t } = useI18n()
  const account = useAccountStore()
  const { mobile } = useDisplay()
  const accountInfoStore = useAccountInfoStore()

  const emptyAccount = computed(() => {
    return account.emptyAccount
  })

  const gradientBarHandler = () => {
    gradientBar.value = !gradientBar.value
  }

  onMounted(async () => {
    await account.load()
    await accountInfoStore.load()
    await account.startAutoUpdate(1600)
  })
  onUnmounted(() => {
    account.stopAutoUpdate()
  })
</script>

<template>
  <v-sheet class="d-flex w-100 align-center ga-6 portfolio-bar" height="80px">
    <v-sheet class="flex-1-1-100">
      <span :class="['title-h1', mobile ? 'px-4' : '']">{{ t('portfolio.title') }}</span>
    </v-sheet>
    <AccountSelection v-if="!mobile" />
  </v-sheet>
  <v-sheet v-if="mobile" class="w-100 pa-4 ma-0 elevation-16-dp" @click.stop="gradientBarHandler">
    <GradientBar v-model="gradientBar" static :point-position="account.gradPosition" />
  </v-sheet>
  <v-sheet class="d-flex w-100 align-center ga-6 mt-8">
    <BasePromo v-if="emptyAccount" />
    <v-sheet v-else class="w-100 align-center">
      <PortfolioFinancialResults />
      <v-sheet v-if="mobile" class="d-flex w-100 align-start ga-6 pb-11 px-4">
        <v-btn
          variant="flat"
          class="elevation-04-dp"
          color="white"
          :ripple="false"
          :to="{ name: 'history-orders' }"
        >
          {{ t('history.tabsOrder') }}
        </v-btn>
        <v-btn
          variant="flat"
          class="elevation-04-dp"
          color="white"
          :ripple="false"
          :to="{ name: 'history-transactions' }"
        >
          {{ t('history.tabsTransaction') }}
        </v-btn>
      </v-sheet>
      <CaseTable />
      <HistoryComponent v-if="!mobile" />
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss">
  .content {
    width: 100%;
    min-height: calc(100vh - 100px);
  }
</style>
