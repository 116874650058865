<script setup lang="ts">
  import { useMessagesStore } from '@/stores/messagesStore'
  import FeedbackMessage from '@/components/BaseComponents/FeedbackMessage.vue'
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import IconArrowSending from '@/components/icons/IconArrowSending.vue'
  import { onMounted, ref, type ComponentPublicInstance, computed } from 'vue'
  import type { IMessageSendModel } from '@/api/types'
  import messageService from '@/api/messageService'
  import { useNotify } from '@/stores/notify'
  import { useI18n } from 'vue-i18n'
  import { useDisplay, useGoTo } from 'vuetify'
  import IconAdditionalReaded from '@/components/icons/IconAdditionalReaded.vue'
  import { FeedbackTabs, IFeedbackTabs } from '@/components/types'

  const messagesStore = useMessagesStore()
  const notifyStore = useNotify()
  const counter = ref(0)
  const messageText = ref('')
  const { t } = useI18n()
  const firstUnreadRef = ref()
  const bottomdiv = ref()
  const containerRef = ref()
  const goTo = useGoTo()
  const display = useDisplay()

  const onInputChange = (): void => {
    counter.value = messageText.value.length
  }

  const tabs = ref<IFeedbackTabs[]>([
    {
      name: 'manager' as const,
      title: t('feedback.tabs.manager')
    },
    {
      name: 'notifications' as const,
      title: t('feedback.tabs.notifications')
    },
    {
      name: 'support' as const,
      title: t('feedback.tabs.support')
    },
    {
      name: 'documents' as const,
      title: t('feedback.tabs.documents')
    }
  ])

  const sendMessageHandle = async (_: FeedbackTabs) => {
    // eslint-disable-next-line no-useless-catch
    try {
      if (messageText.value.length > 0) {
        const messageModel: IMessageSendModel = {
          text: messageText.value
        }
        await messageService.sendMessage(messageModel)

        messageText.value = ''
      }
    } catch (error: any) {
      notifyStore.showServiceError()
      throw error
    }
  }

  const scrollToBottom = () => {
    goTo(
      bottomdiv.value.find(
        (item: ComponentPublicInstance) => item?.$el?.id === `bottom${tab.value}`
      ),
      {
        container: containerRef.value.find(
          (item: ComponentPublicInstance) => item?.$el?.id === tab.value
        )
      }
    )
  }

  const tab = ref<FeedbackTabs>('manager')

  const customRef = computed(() => {
    return (dateTime: string) => {
      return dateTime === messagesStore.getFirstUnreadMessage(tab.value)?.dateTime
        ? 'firstUnreadRef'
        : undefined
    }
  })

  const scrollToRef = () => {
    if (firstUnreadRef.value && firstUnreadRef.value[0]) {
      goTo(firstUnreadRef.value[0], {
        container: containerRef.value.find(
          (item: ComponentPublicInstance) => item?.$el?.id === tab.value
        ),
        offset: firstUnreadRef.value[0].$el.clientHeight - 590
      })
    } else {
      scrollToBottom()
    }
  }

  onMounted(async () => {
    await scrollToRef()
  })
</script>

<template>
  <v-sheet>
    <v-sheet v-if="!display.mobile.value" class="title-h1 my-7">{{ t('feedback.title') }}</v-sheet>
    <v-sheet
      class="d-flex justify-space-between position-relative"
      :class="{ 'overflow-hidden': !messagesStore.data[tab].length }"
    >
      <v-sheet :width="display.mobile.value ? '100%' : '768px'">
        <v-sheet
          class="d-flex mb-7"
          :class="{
            'justify-space-between': !display.mobile.value,
            'justify-center': display.mobile.value
          }"
        >
          <v-sheet class="title-h3">{{ t('feedback.subtitle') }}</v-sheet>
        </v-sheet>
        <v-tabs
          v-model="tab"
          active-color="grey-darken-4"
          hide-slider
          density="comfortable"
          @update:model-value="scrollToRef"
        >
          <v-tab
            v-for="(tb, key) in tabs"
            :key="key"
            :value="tb.name"
            :text="tb.title"
            class="rounded-tt-lg text-none"
            selected-class="selected"
            hide-slider
            density="compact"
          />
        </v-tabs>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item
            v-for="(tb, tbKey) in tabs"
            :key="tbKey"
            :value="tb.name"
            transition="expand-transition"
            reverse-transition="expand-transition"
          >
            <v-sheet
              v-if="messagesStore.getUnreadCategoryCount(tb.name) > 0"
              class="position-absolute bg-transparent"
              style="bottom: 16px; right: 16px"
            >
              <v-badge
                :content="messagesStore.getUnreadCategoryCount(tb.name)"
                color="red"
                :offset-x="14"
                :offset-y="-2"
                :max="9"
              >
                <v-btn density="compact" :ripple="false" icon @click="scrollToBottom">
                  <IconAdditionalReaded />
                </v-btn>
              </v-badge>
            </v-sheet>

            <v-sheet
              :id="tb.name"
              ref="containerRef"
              width="100%"
              class="bg-additional-light-grey overflow-auto px-7 pt-7"
              :class="{
                'desktop-container rounded-bb-lg': !display.mobile.value,
                'mobile-container': display.mobile.value
              }"
            >
              <template v-if="messagesStore.data[tb.name].length">
                <v-sheet class="bg-transparent ga-6 d-flex flex-column">
                  <template v-for="(message, key) in messagesStore.data[tb.name]" :key="key">
                    <v-sheet :ref="customRef(message.dateTime)" class="bg-transparent">
                      <FeedbackMessage :message="message" :category="tb.name"></FeedbackMessage>
                    </v-sheet>
                  </template>
                  <v-sheet :id="`bottom${tb.name}`" ref="bottomdiv" />
                </v-sheet>
              </template>

              <v-sheet
                v-else
                class="bg-transparent d-flex h-100 align-center justify-center body-b2 text-inactive-color"
              >
                {{ t('feedback.emptyText') }}
              </v-sheet>
            </v-sheet>
          </v-tabs-window-item>
        </v-tabs-window>

        <v-sheet
          class="my-4"
          :class="{
            'position-fixed feedback-form d-flex justify-center w-100 bg-white':
              display.mobile.value
          }"
        >
          <v-form
            class="w-100"
            :class="{ 'mx-4': display.mobile.value }"
            @submit.prevent="sendMessageHandle(tab)"
          >
            <v-sheet class="d-flex ga-4 justify-space-between align-end">
              <v-textarea
                v-model="messageText"
                :label="t('feedback.textareaMessage')"
                density="compact"
                variant="outlined"
                auto-grow
                rows="1"
                hide-details
                width="75%"
                @input="onInputChange"
              >
                <template #append-inner>
                  <span class="body-b1 pb-2">{{ counter }} / 1000</span>
                </template>
              </v-textarea>
              <BaseButton v-if="!display.mobile.value" :label="t('feedback.btnSend')" type="submit">
                <template #icon>
                  <IconArrowSending color="white" />
                </template>
              </BaseButton>
              <v-btn v-else color="primary" :ripple="false" type="submit" min-width="40" width="40">
                <IconArrowSending color="white" />
              </v-btn>
            </v-sheet>
          </v-form>
        </v-sheet>
      </v-sheet>
      <v-sheet v-if="!display.mobile.value" class="w-40 empty-space"></v-sheet>
      <v-sheet v-else-if="!messagesStore.data[tab].length" class="jirafa"></v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="scss">
  .empty-space {
    background: transparent url('/img/gerafee.png') no-repeat right top;
    background-size: 327px;
  }

  .v-text-field.v-input--density-compact {
    :deep(.v-field__field .v-label.v-field-label) {
      font-size: $fontSizeBodyB1;
    }

    :deep(.v-label.v-field-label) {
      font-size: $fontSizeBodyB3;
    }

    :deep(.v-field__append-inner) {
      width: max-content;
      color: var(--color-BlackDisabled);
      align-items: flex-end;
      white-space: nowrap;
    }
  }

  .text-inactive-color {
    color: #949996;
  }

  .selected {
    background-color: #f5f5f5;
  }
  .desktop-container {
    max-height: 600px;
    min-height: 200px;
  }

  .mobile-container {
    height: calc(100vh - 300px);
    overflow: hidden;
  }

  .feedback-form {
    bottom: 80px;
    z-index: 200;
  }

  .jirafa {
    position: absolute;
    background: transparent url('/img/gerafee.png') no-repeat right bottom;
    background-size: 327px;
    height: 327px;
    width: 327px;
    right: -160px;
    z-index: 200;
    bottom: 0;
  }
</style>
