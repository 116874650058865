<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { ref, computed, onMounted, onUnmounted } from 'vue'
  import { useTradingStore } from '@/stores/tradingStore'
  import {
    ITradingInstrumentsModel,
    InstrumentVariant
  } from '@/components/types/TradingInstruments'
  import FavoriteInstrument from '@/components/BaseComponents/TradingComponents/FavoriteInstrument.vue'
  import SearchField from '@/components/BaseComponents/SearchField.vue'
  import { useDisplay } from 'vuetify'
  import FavoriteMobile from '@/components/BaseComponents/FavoriteMobile.vue'

  interface ITabs {
    name: string
    title: string
    data?: ITradingInstrumentsModel[]
  }

  const tradingStore = useTradingStore()
  const display = useDisplay()
  const tab = ref('favorites' as InstrumentVariant)
  const { t } = useI18n()

  const tabs = ref<ITabs[]>([
    {
      name: 'favorites',
      title: t('trading.table.tabs.favorite')
    }
    // {
    //   name: 'marketToday',
    //   title: t('trading.table.tabs.marketToday')
    // },
    // {
    //   name: 'readyMadeSolutions',
    //   title: t('trading.table.tabs.readyMadeSolutions')
    // },
    // {
    //   name: 'primaryPlacements',
    //   title: t('trading.table.tabs.primaryPlacements')
    // }
  ])

  const instrumentsFavorite = computed(() => {
    if (tab.value === 'favorites') {
      return tradingStore.favorites
    }
    return []
  })

  const loading = computed(() => {
    return tradingStore.loading
  })

  onMounted(async () => {
    await tradingStore.load()
    await tradingStore.startAutoUpdate(1600)
  })

  onUnmounted(() => {
    tradingStore.stopAutoUpdate()
  })
</script>

<template>
  <v-sheet
    class="d-flex w-100 align-center ga-6 portfolio-bar mb-6"
    :class="{ 'flex-column pl-2': display.mobile.value }"
  >
    <v-row v-if="!display.mobile.value">
      <v-col cols="3" class="align-self-center">
        <span class="title-h1">{{ t('trading.title') }}</span>
      </v-col>
      <v-col cols="6">
        <SearchField
          density="compact"
          name-field="search-input"
          block
          width="75%"
          :label="t('trading.searching.fieldTitle')"
        />
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-sheet v-else width="100%">
      <v-sheet class="pl-2 mb-6">
        <span class="title-h1">{{ t('trading.title') }}</span>
      </v-sheet>
      <SearchField
        density="compact"
        name-field="search-input"
        block
        :label="t('trading.searching.fieldTitle')"
      />
    </v-sheet>
  </v-sheet>
  <v-sheet class="d-flex w-100 align-center ga-6">
    <v-sheet class="w-100 align-center">
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto elevation-0"
        max-width="100%"
        type="chip@6, ossein, table-thead, table-row@4, table-row-divider, table-tfoot, divider,text"
      ></v-skeleton-loader>
      <v-tabs v-if="!loading" v-model="tab" active-color="grey-darken-4" show-arrows hide-slider>
        <v-tab
          v-for="(tb, key) in tabs"
          :key="key"
          :value="tb.name"
          class="rounded-tt-lg text-none"
          selected-class="selected"
          hide-slider
        >
          {{ tb.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-window v-if="!loading" v-model="tab">
        <v-tabs-window-item
          v-for="(tb, key) in tabs"
          :key="key"
          :value="tb.name"
          :transition="false"
          :reverse-transition="false"
        >
          <v-sheet color="instruments-tables rounded-bb" :class="{ 'pb-8': display.mobile.value }">
            <FavoriteMobile
              v-if="display.mobile.value"
              v-show="instrumentsFavorite && instrumentsFavorite.length"
              :items="instrumentsFavorite"
            />
            <FavoriteInstrument
              v-else
              v-show="instrumentsFavorite && instrumentsFavorite.length"
              :items="instrumentsFavorite"
            />
            <v-sheet
              v-if="!instrumentsFavorite.length"
              class="bg-grey-lighten-4 align-center w-100 d-flex align-center justify-center align-self-center rounded-bb-lg empty-table"
              height="160px"
            >
              {{ t('portfolio.emptyData') }}
            </v-sheet>
          </v-sheet>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
  .selected {
    background-color: #f5f5f5;
  }

  .empty-table {
    color: #949996 !important;
  }
</style>
