<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3ZM10 5C11.1046 5 12 5.89543 12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5ZM10 12C7.33 12 2 13.33 2 16V19H11.5C11.2483 18.394 11.0899 17.7534 11.03 17.1H3.9V16C3.9 15.36 7.03 13.9 10 13.9C10.5 13.9 11 13.95 11.5 14.03C11.7566 13.3985 12.1109 12.8114 12.55 12.29C11.61 12.1 10.71 12 10 12ZM17.5 12C15 12 13 14 13 16.5C13 19 15 21 17.5 21C20 21 22 19 22 16.5C22 14 20 12 17.5 12ZM17.5 13.5C19.16 13.5 20.5 14.84 20.5 16.5C20.5 17.06 20.35 17.58 20.08 18L16 13.92C16.42 13.65 16.94 13.5 17.5 13.5ZM14.92 15L19 19.08C18.58 19.35 18.06 19.5 17.5 19.5C15.84 19.5 14.5 18.16 14.5 16.5C14.5 15.94 14.65 15.42 14.92 15Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
