<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M21.34 13L22.5 14.41L17.75 19.16L15 16.16L16.16 15L17.75 16.58L21.34 13ZM10 12C10.89 12 12.07 12.15 13.26 12.45C14 12.64 14.76 12.88 15.43 13.19C15.16 13.39 14.9 13.61 14.67 13.85L14.65 13.88C14.42 14.12 14.21 14.38 14 14.66C13.54 15.38 13.21 16.21 13.08 17.1C13.03 17.39 13 17.69 13 18C13 18.2 13 18.4 13.04 18.59C13.05 18.73 13.07 18.86 13.09 19H2V16C2 13.33 7.33 12 10 12ZM10 13.9C7.03 13.9 3.9 15.36 3.9 16V17.1H11.05C11.18 16 11.5 15 12 14.11C11.36 14 10.68 13.9 10 13.9ZM10 3C12.21 3 14 4.79 14 7C14 9.21 12.21 11 10 11C7.79 11 6 9.21 6 7C6 4.79 7.79 3 10 3ZM10 5C8.9 5 8 5.89 8 7C8 8.11 8.89 9 10 9C11.11 9 12 8.1 12 7C12 5.9 11.11 5 10 5Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
