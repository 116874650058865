<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M11 1C12.3261 1 13.5979 1.52678 14.5355 2.46447C15.4732 3.40215 16 4.67392 16 6V8H17C18.1046 8 19 8.89543 19 10L19.0013 10.2556L18.9562 10.2244C20.7943 11.4859 22 13.6021 22 16C22 19.866 18.866 23 15 23C13.6656 23 12.4184 22.6266 11.3572 21.9787L11.3943 22.0012L5 22C3.89 22 3 21.1 3 20V10C3 8.89 3.89 8 5 8H6V6C6 3.23858 8.23858 1 11 1ZM15 11C12.2386 11 9.99991 13.2386 9.99991 16C9.99041 17.576 10.7334 19.062 12 20C13.7778 21.3333 16.2222 21.3333 18 20C19.259 19.0557 20 17.5738 20 16C20 14.4262 19.259 12.9443 18 12C17.1366 11.347 16.0826 10.9956 15 11ZM11.3923 10.0001L5 10V20L9.25562 20.0013L9.29146 20.0522C8.47821 18.9086 8 17.5102 8 16C8 13.5032 9.3072 11.3117 11.2745 10.0726L11.3923 10.0001ZM15.5 13V15.82L17.94 17.23L17.19 18.53L14 16.69V13H15.5ZM11 3C9.34315 3 8 4.34315 8 6V8H14V6C14 4.34315 12.6569 3 11 3Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
