<template>
  <svg width="28" height="33" viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11235_52827)">
      <path d="M0 7.18262L4.06896 14.3257V18.6828L0.00476 25.8119L0 7.18262Z" fill="#5B57A2" />
      <path
        d="M15.6232 11.7271L19.4359 9.43195L27.239 9.4248L15.6232 16.4137V11.7271Z"
        fill="#D90751"
      />
      <path
        d="M15.6016 7.14092L15.6232 16.5982L11.5447 14.1369V0L15.6016 7.14092Z"
        fill="#FAB718"
      />
      <path
        d="M27.239 9.42453L19.4356 9.43168L15.6016 7.14092L11.5447 0L27.239 9.42453Z"
        fill="#ED6F26"
      />
      <path
        d="M15.6232 25.8515V21.2631L11.5447 18.8486L11.5469 32.9996L15.6232 25.8515Z"
        fill="#63B22F"
      />
      <path
        d="M19.4264 23.5773L4.06868 14.3257L0 7.18262L27.2224 23.5679L19.4264 23.5773Z"
        fill="#1487C9"
      />
      <path
        d="M11.5472 33L15.6229 25.852L19.4261 23.5777L27.2221 23.5684L11.5472 33Z"
        fill="#017F36"
      />
      <path
        d="M0.00476074 25.8125L11.578 18.8495L7.68712 16.5049L4.06896 18.6834L0.00476074 25.8125Z"
        fill="#984995"
      />
    </g>
    <defs>
      <clipPath id="clip0_11235_52827">
        <rect width="28" height="33" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
