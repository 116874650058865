// store/cookieConsent.ts
import { defineStore } from 'pinia'

interface IState {
  consentGiven: boolean
}
interface IGetter {
  [key: string]: any
}
interface IAction {
  acceptCookies: () => void
}

export const useCookieConsentStore = defineStore<'cookieConsent', IState, IGetter, IAction>({
  id: 'cookieConsent',
  state: (): IState => ({
    consentGiven: localStorage.getItem('cookieConsent') === 'true'
  }),
  actions: {
    acceptCookies() {
      localStorage.setItem('cookieConsent', 'true')
      this.consentGiven = true
    }
  }
})
