<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
  >
    <path
      d="M14 2L17.123 5.123L13.248 9H13V4H6V20H18V11.747L20 9.746V20C20 21.0544 19.1841 21.9182 18.1493 21.9945L18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H14ZM20.06 4.1825L21.81 5.9325L11.75 16.0025L9 17.0025L10 14.2525L20.06 4.1825ZM22.06 2.1825L23.81 3.9325L22.75 5.0025L21 3.2525L22.06 2.1825Z"
      fill="currentColor"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script setup>
  import { computed } from 'vue'
  const { color } = defineProps({
    color: {
      type: String,
      default: 'black'
    }
  })
  const fillOpacity = computed(() => {
    return color === 'black' ? '0.6' : '1'
  })
</script>
