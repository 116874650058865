import httpCommunicator from '@/api/httpCommunicator'
import type { AxiosResponse } from 'axios'
import {
  IClientTerminalsModel,
  IInstrumentTypesModel,
  ILotsModel,
  IOrderActionsModel,
  IOrderStatusesModel,
  ITradeSystemsModel,
  ITransactionOperationsModel,
  ITransactionStatusesModel
} from '@/api/types'

const dictionaryService = {
  async instrumentTypes() {
    return httpCommunicator
      .get('/dictionary/instrument-types')
      .then((response: AxiosResponse<IInstrumentTypesModel[]>) => {
        return response.data
      })
  },
  async orderActions() {
    return httpCommunicator
      .get('/dictionary/order-actions')
      .then((response: AxiosResponse<IOrderActionsModel[]>) => {
        return response.data
      })
  },
  async orderStatuses() {
    return httpCommunicator
      .get('/dictionary/order-statuses')
      .then((response: AxiosResponse<IOrderStatusesModel[]>) => {
        return response.data
      })
  },
  async tradeSystems() {
    return httpCommunicator
      .get('/dictionary/trade-systems')
      .then((response: AxiosResponse<ITradeSystemsModel[]>) => {
        return response.data
      })
  },
  async clientTerminals() {
    return httpCommunicator
      .get('/dictionary/client-terminals')
      .then((response: AxiosResponse<IClientTerminalsModel[]>) => {
        return response.data
      })
  },
  async lotsData() {
    return httpCommunicator
      .get('/dictionary/instruments')
      .then((response: AxiosResponse<ILotsModel[]>) => {
        return response.data
      })
  },
  async transactionStatuses() {
    return httpCommunicator
      .get('/dictionary/transaction-statuses')
      .then((response: AxiosResponse<ITransactionStatusesModel[]>) => {
        return response.data
      })
  },
  async transactionOperations() {
    return httpCommunicator
      .get('/dictionary/transaction-operations')
      .then((response: AxiosResponse<ITransactionOperationsModel[]>) => {
        return response.data
      })
  }
}
export default dictionaryService
