import { useInvoiceNumbersStore } from '@/stores/invoiceNumbersStore'
import clearStores from '@/utils/clearStores'
import httpCommunicator from './httpCommunicator'
import { useAuthStore } from '@/stores/authStore'
import type { ICodeModel, ICredentialModel, ITokenModel, IReissueAccountModel } from '@/api/types'
import type { AxiosResponse } from 'axios'
import { useAccountInfoStore } from '@/stores/accountInfoStore'
import { useWelcomeTipsStore } from '@/stores/welcomeTipsStore'
import { useNotify } from '@/stores/notify'

const authService = {
  async login(credentials: ICredentialModel) {
    const response: AxiosResponse<ITokenModel> = await httpCommunicator.post(
      '/auth/login',
      credentials,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Version-App': window.env.APP_VERSION
        }
      }
    )
    const token = response.data.access_token
    const authStore = useAuthStore()
    const invoiceStore = useInvoiceNumbersStore()
    const accountInfoStore = useAccountInfoStore()
    const welcomeTipsStore = useWelcomeTipsStore()
    const notifyStore = useNotify()
    authStore.setToken(token) // Сохраняем токен в Pinia
    welcomeTipsStore.setVersions(response.data.versions)
    await invoiceStore.load()
    await accountInfoStore.load()
    notifyStore.clear()
    return response.data
  },

  checkCode(credentials: ICodeModel) {
    // @ts-ignore
    return credentials.code === '1111'
  },

  async logout() {
    clearStores()

    // Опционально можно сделать запрос на удаление refreshToken на сервере
    return httpCommunicator.post('/auth/logout').then(() => {
      const authStore = useAuthStore()
      authStore.clearToken() // Очищаем токен через Pinia
      console.log('Logged out successfully')
    })
  },

  async refreshToken() {
    // Сервер автоматически получит refresh_token из HttpOnly cookie
    const response: AxiosResponse<ITokenModel> = await httpCommunicator.get('/auth/refresh')
    const newAccessToken = response.data.access_token
    const authStore = useAuthStore()
    authStore.setToken(newAccessToken) // Обновляем access_token
    return response.data
  },

  async accountReissue(credentials: IReissueAccountModel) {
    const response: AxiosResponse = await httpCommunicator.post(
      `/auth/reissue/${credentials.account}`
    )

    const newAccessToken = response.data.access_token
    const authStore = useAuthStore()
    authStore.setToken(newAccessToken)
  },

  async authWelcomeTips(versions: string[]) {
    const response: AxiosResponse = await httpCommunicator.put(`/auth/wellcometips`, versions)

    return response.data
  }
}

export default authService
