<script setup lang="ts">
  import BaseButton from '@/components/BaseComponents/BaseButton.vue'
  import { Notifications, useNotification } from '@kyvg/vue3-notification'
  import { computed, onMounted, ref } from 'vue'
  import { useCancelInstallAppStore } from '@/stores/cancelInstallAppStore'
  import { BeforeInstallPromptEvent } from '@/global'
  import { useDisplay } from 'vuetify'

  const installAppStore = useCancelInstallAppStore()
  const { notify } = useNotification()
  const { mobile } = useDisplay()

  const width = computed(() => {
    if (mobile.value) {
      return 'calc(100dvw - 16px)'
    }
    return '666px'
  })

  let deferredPrompt: BeforeInstallPromptEvent

  function installPromptEvent() {
    if (deferredPrompt) {
      deferredPrompt.prompt()
    }
  }

  onMounted(() => {
    if ('onbeforeinstallprompt' in window) {
      window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
        e.preventDefault()
        deferredPrompt = e as BeforeInstallPromptEvent
        if (!installAppStore.cancelGiven) {
          notify({
            title: 'Установить ЛК как приложение?',
            text: 'Предлагаем установить «Личный кабинет» как отдельное приложение на ваше устройство.',
            type: 'info',
            group: 'promote',
            duration: -1
          })
        }
      })
    }
  })
</script>

<template>
  <Notifications
    :classes="['notification', 'notification-app']"
    class="vue-notification-group-promote"
    group="promote"
    :width="width"
    position="top center"
    max-width="100%"
    dangerously-set-inner-html
    ignore-duplicates
  >
    <template #body="{ item, close }">
      <v-card :max-width="width" rounded="lg" class="pa-6 d-flex ga-4">
        <v-card-item v-if="!mobile" class="pa-0">
          <v-img src="/img/install-app.png" color="white" width="180" />
        </v-card-item>
        <v-sheet class="pa-0 d-flex flex-column align-start" :class="{ 'ga-4': !mobile }">
          <v-card-title class="pa-0 align-center" :class="{ 'd-flex ga-4': mobile }">
            <v-img v-if="mobile" src="/img/install-app.png" color="white" width="103" />
            <h3 class="title-h3 wrap-auto">{{ item.title }}</h3>
          </v-card-title>
          <v-card-text class="pa-0 body-b1 d-flex ga-6" style="line-height: 24px">
            {{ item.text }}
          </v-card-text>
          <v-card-actions
            :class="{
              'mt-6 justify-space-between align-center': mobile,
              'justify-end align-start align-self-start': !mobile
            }"
            class="pa-0 d-flex ga-6"
          >
            <BaseButton label="Установить" @click="installPromptEvent" />
            <BaseButton
              label="Не предлагать"
              variant="outlined"
              @click.prevent="
                () => {
                  installAppStore.cancelInstall()
                  close()
                }
              "
            />
          </v-card-actions>
        </v-sheet>
      </v-card>
    </template>
  </Notifications>
</template>

<style scoped lang="scss">
  .wrap-auto {
    text-wrap: auto;
  }
  .vue-notification-wrapper {
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 6px !important;
  }
  .vue-notification-group-promote {
    top: 24px !important;
  }
  @media (max-width: 599.98px) {
    .vue-notification-group-promote {
      left: 8px !important;
      top: 55px !important;
    }
  }
  .notification {
    font-weight: 700;
    padding: 16px;
    background: $colorMainBackground;

    .btn-close {
      color: #e74c3c;
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
</style>
