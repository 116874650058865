// @ts-ignore
export const auth = {
  loginOrPhone: 'Логин или номер телефона',
  password: 'Пароль',
  registration: 'Зарегистрироваться',
  loginTitle: 'Вход в личный кабинет',
  loginSubtitle: 'Инвестиционная компания «РИКОМ-ТРАСТ»',
  forgotLoginOrPassword: 'Забыли логин или пароль?',
  codeTitle: 'Введите код из смс',
  codeSentPhoneNumber: 'Код отправлен на номер {phoneNumber}',
  codeLabel: 'Код из смс',
  codeDelay: 'Не пришел код? Отправить повторно через {delay}',
  sendCodeAgain: 'Отправить повторно'
}
