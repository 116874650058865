<script setup lang="ts">
  import { computed } from 'vue'
  import { ITopMenuElementProps } from '@/components/types'
  import { RouteLocationRaw, useRoute } from 'vue-router'

  const route = useRoute()
  const props = withDefaults(defineProps<ITopMenuElementProps>(), {
    label: '',
    state: 'enabled',
    to: undefined
  })
  const disabled = computed(() => {
    return props.to && route.name === props.to.name
  })
</script>

<template>
  <v-btn
    v-if="state !== undefined"
    :disabled="disabled"
    :active="disabled"
    variant="text"
    class="text-none additional-menu top-menu-element"
    active-color="primary"
    size="lg"
    :ripple="false"
    :to="to as RouteLocationRaw"
  >
    {{ label }}
  </v-btn>
</template>

<style scoped lang="scss">
  a[href].top-menu-element {
    --v-activated-opacity: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px;
    color: var(--color-BlackActive);
    height: auto;
    &:hover {
      text-decoration: none;
    }
    &[disabled] {
      color: var(--color-MainNormal);
      opacity: 1;
    }
  }
</style>
